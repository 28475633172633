<template>
  <div>

    <div class="tile max-width-1000">
      <div class="toolbar-wrapper" style="flex-direction: column;">
        <div class="toolbar">

          <div class="toolbar-section">
            <h2>Elevate Workouts</h2>
            <div class="loading-spinner small" style="margin: 0 0 0 1rem;" v-if="!pageLoaded"></div>
          </div>

          <div class="toolbar-section">

            <button class="button primary-action-button margin-left" @click="goToPreviousWeek"><i class="fas fa-chevron-left"/></button>

            <div class="dropdown is-active is-right" v-on-clickaway="closeDateSelectorModal">

              <div class="dropdown-trigger">
                <button class="button button-outline margin-left" @click="dateSelectorModal = !dateSelectorModal" :class="{'button-active': dateSelectorModal}">
                  {{ current_week[0] | monthDayFilter }} - {{ current_week[5] | monthDayFilter }} &nbsp;
                  <i class="fas fa-chevron-down" style="font-size: 12px; transition: 0.2s all ease;" :class="{'arrow-rotate': dateSelectorModal}"/>
                </button>
              </div>

              <div class="dropdown-menu" :class="{'menu-open': dateSelectorModal}">
                <v-date-picker v-model="selected_day" is-inline></v-date-picker>
              </div>

            </div>

            <button class="button primary-action-button margin-left" @click="goToNextWeek"><i class="fas fa-chevron-right"/></button>

          </div>


        </div>

        <div class="content-break-line"></div>

      </div>
    </div>

    <div class="tile max-width-1000">

      <div v-for="(day, index) in current_week" :key="index" class="tile is-parent" style="flex-direction: column;">
        <h4 style="margin-bottom: 0.5rem;">{{ day | dayOfWeekFilter }}</h4>

        <div class="tile is-child selectable-item" @click="selected_day = day" :class="{'selected': isSelectedDay(day)}" style="justify-content: center;">

          <div class="selectable-item-section">
            <p class="has-text-centered is-small">{{ day | monthFilter }}</p>
            <p class="has-text-centered font-primary-blue is-bold" style="font-size: 30px; line-height: 1;">{{ day | dayFilter }}</p>
          </div>

        </div>
      </div>

    </div>

    <div class="tile max-width-1000">
      <div class="toolbar-wrapper">
        <div class="toolbar">

          <h2>Timer</h2>

        </div>
      </div>
    </div>

    <div class="tile max-width-1000">
      <div class="tile is-parent">
        <div class="tile is-child box">

          <div class="form-line-wrapper">

            <div class="field" style="flex-grow: 1;">
              <label class="label">Title</label>
              <div class="control">
                <input v-model="workout_data.timer.title" type="text" placeholder="Cardio">
              </div>
            </div>

            <div class="field">
              <label class="label">Work Time</label>
              <div class="control">
                <input v-model.number="workout_data.timer.work_time" type="number" placeholder="seconds" min="0">
              </div>
            </div>

            <div class="field">
              <label class="label">Rest Time</label>
              <div class="control">
                <input v-model.number="workout_data.timer.rest_time" type="number" placeholder="seconds" min="0">
              </div>
            </div>

          </div>


        </div>
      </div>
    </div>

    <!-- TV 1 -->

    <div class="tile max-width-1000">
      <div class="toolbar-wrapper">
        <div class="toolbar">

          <div class="toolbar-section">
            <h2>TV 1</h2>
          </div>

        </div>
      </div>
    </div>

    <div class="tile max-width-1000">
      <div class="tile is-parent">
        <div class="tile is-child box">

          <div class="form-line-wrapper">

            <div class="field" style="flex-grow: 1;">
              <label class="label">Title</label>
              <div class="select">
                <select v-model="workout_data.tvs.one.title">
                  <option value="" disabled>Select an Option</option>
                  <option value="Cardio">Cardio</option>
                  <option value="Cardio & Strength">Cardio & Strength</option>
                  <option value="Strength">Strength</option>
                  <option value="Strength - Upper Body">Strength - Upper Body</option>
                  <option value="Strength - Lower Body">Strength - Lower Body</option>
                  <option value="Strength - Full Body">Strength - Full Body</option>
                  <option value="Competition Day">Competition Day</option>
                  <option value="Fun Day">Fun Day</option>
                  <option value="Hell Day">Hell Day</option>
                </select>
              </div>
            </div>

            <div class="field">
              <label class="label">Total Sets</label>
              <div class="control">
                <input v-model="workout_data.tvs.one.sets" type="text" placeholder="sets" >
              </div>
            </div>

            <div class="field">
              <label class="label">Format</label>
              <div class="select">
                <select v-model="workout_data.tvs.one.format">
                  <option value="" selected disabled>-</option>
                  <option value="Timer">Timer</option>
                  <option value="Reps">Reps</option>
                </select>
              </div>
            </div>

          </div>

          <div class="content-break-line"></div>

          <div class="form-line-wrapper">

            <div class="field w-32 flex-grow-0">
              <label class="label">Key</label>
              <div class="control">
                <input v-model="workout_data.tvs.one.exercises[0].key" type="text" placeholder="A1">
              </div>
            </div>

            <div class="field flex-grow">
              <label class="label">Exercise</label>
              <div class="control">
                <input v-model="workout_data.tvs.one.exercises[0].name" type="text" placeholder="Exercise Name">
              </div>
            </div>

            <div class="field w-32 flex-grow-0">
              <label class="label">Reps</label>
              <div class="control">
                <input v-model="workout_data.tvs.one.exercises[0].reps" type="text" placeholder="10ea">
              </div>
            </div>

          </div>

          <div class="form-line-wrapper">

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.one.exercises[1].key" type="text" placeholder="A2">
              </div>
            </div>

            <div class="field" style="flex-grow: 1;">
              <div class="control">
                <input v-model="workout_data.tvs.one.exercises[1].name" type="text" placeholder="Exercise Name">
              </div>
            </div>

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.one.exercises[1].reps" type="text" placeholder="10ea">
              </div>
            </div>

          </div>

          <div class="form-line-wrapper">

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.one.exercises[2].key" type="text" placeholder="A3">
              </div>
            </div>

            <div class="field" style="flex-grow: 1;">
              <div class="control">
                <input v-model="workout_data.tvs.one.exercises[2].name" type="text" placeholder="Exercise Name">
              </div>
            </div>

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.one.exercises[2].reps" type="text" placeholder="10ea">
              </div>
            </div>

          </div>

          <div class="form-line-wrapper">

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.one.exercises[3].key" type="text" placeholder="B1">
              </div>
            </div>

            <div class="field" style="flex-grow: 1;">
              <div class="control">
                <input v-model="workout_data.tvs.one.exercises[3].name" type="text" placeholder="Exercise Name">
              </div>
            </div>

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.one.exercises[3].reps" type="text" placeholder="10ea">
              </div>
            </div>

          </div>

          <div class="form-line-wrapper">

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.one.exercises[4].key" type="text" placeholder="B2">
              </div>
            </div>

            <div class="field" style="flex-grow: 1;">
              <div class="control">
                <input v-model="workout_data.tvs.one.exercises[4].name" type="text" placeholder="Exercise Name">
              </div>
            </div>

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.one.exercises[4].reps" type="text" placeholder="10ea">
              </div>
            </div>

          </div>

          <div class="form-line-wrapper">

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.one.exercises[5].key" type="text" placeholder="B3">
              </div>
            </div>

            <div class="field" style="flex-grow: 1;">
              <div class="control">
                <input v-model="workout_data.tvs.one.exercises[5].name" type="text" placeholder="Exercise Name">
              </div>
            </div>

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.one.exercises[5].reps" type="text" placeholder="10ea">
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

    <!-- TV 2 -->

    <div class="tile max-width-1000">
      <div class="toolbar-wrapper">
        <div class="toolbar">

          <div class="toolbar-section">
            <h2>TV 2</h2>
          </div>

        </div>
      </div>
    </div>

    <div class="tile max-width-1000">
      <div class="tile is-parent">
        <div class="tile is-child box">

          <div class="form-line-wrapper">

            <div class="field" style="flex-grow: 1;">
              <label class="label">Title</label>
              <div class="select">
                <select v-model="workout_data.tvs.two.title">
                  <option value="" disabled>Select an Option</option>
                  <option value="Cardio">Cardio</option>
                  <option value="Cardio & Strength">Cardio & Strength</option>
                  <option value="Strength">Strength</option>
                  <option value="Strength - Upper Body">Strength - Upper Body</option>
                  <option value="Strength - Lower Body">Strength - Lower Body</option>
                  <option value="Strength - Full Body">Strength - Full Body</option>
                  <option value="Competition Day">Competition Day</option>
                  <option value="Fun Day">Fun Day</option>
                  <option value="Hell Day">Hell Day</option>
                </select>
              </div>
            </div>

            <div class="field">
              <label class="label">Total Sets</label>
              <div class="control">
                <input v-model="workout_data.tvs.two.sets" type="text" placeholder="sets" >
              </div>
            </div>

            <div class="field">
              <label class="label">Format</label>
              <div class="select">
                <select v-model="workout_data.tvs.two.format">
                  <option value="" selected disabled>-</option>
                  <option value="Timer">Timer</option>
                  <option value="Reps">Reps</option>
                </select>
              </div>
            </div>

          </div>

          <div class="content-break-line"></div>

          <div class="form-line-wrapper">

            <div class="field w-32 flex-grow-0">
              <label class="label">Key</label>
              <div class="control">
                <input v-model="workout_data.tvs.two.exercises[0].key" type="text" placeholder="C1">
              </div>
            </div>

            <div class="field" style="flex-grow: 1;">
              <label class="label">Exercise</label>
              <div class="control">
                <input v-model="workout_data.tvs.two.exercises[0].name" type="text" placeholder="Exercise Name">
              </div>
            </div>

            <div class="field w-32 flex-grow-0">
              <label class="label">Reps</label>
              <div class="control">
                <input v-model="workout_data.tvs.two.exercises[0].reps" type="text" placeholder="10ea">
              </div>
            </div>

          </div>

          <div class="form-line-wrapper">

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.two.exercises[1].key" type="text" placeholder="C2">
              </div>
            </div>

            <div class="field" style="flex-grow: 1;">
              <div class="control">
                <input v-model="workout_data.tvs.two.exercises[1].name" type="text" placeholder="Exercise Name">
              </div>
            </div>

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.two.exercises[1].reps" type="text" placeholder="10ea">
              </div>
            </div>

          </div>

          <div class="form-line-wrapper">

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.two.exercises[2].key" type="text" placeholder="C3">
              </div>
            </div>

            <div class="field" style="flex-grow: 1;">
              <div class="control">
                <input v-model="workout_data.tvs.two.exercises[2].name" type="text" placeholder="Exercise Name">
              </div>
            </div>

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.two.exercises[2].reps" type="text" placeholder="10ea">
              </div>
            </div>

          </div>

          <div class="form-line-wrapper">

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.two.exercises[3].key" type="text" placeholder="D1">
              </div>
            </div>

            <div class="field" style="flex-grow: 1;">
              <div class="control">
                <input v-model="workout_data.tvs.two.exercises[3].name" type="text" placeholder="Exercise Name">
              </div>
            </div>

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.two.exercises[3].reps" type="text" placeholder="10ea">
              </div>
            </div>

          </div>

          <div class="form-line-wrapper">

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.two.exercises[4].key" type="text" placeholder="D2">
              </div>
            </div>

            <div class="field" style="flex-grow: 1;">
              <div class="control">
                <input v-model="workout_data.tvs.two.exercises[4].name" type="text" placeholder="Exercise Name">
              </div>
            </div>

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.two.exercises[4].reps" type="text" placeholder="10ea">
              </div>
            </div>

          </div>

          <div class="form-line-wrapper">

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.two.exercises[5].key" type="text" placeholder="D3">
              </div>
            </div>

            <div class="field" style="flex-grow: 1;">
              <div class="control">
                <input v-model="workout_data.tvs.two.exercises[5].name" type="text" placeholder="Exercise Name">
              </div>
            </div>

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.two.exercises[5].reps" type="text" placeholder="10ea">
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

    <!-- TV 3 -->

    <div class="tile max-width-1000">
      <div class="toolbar-wrapper">
        <div class="toolbar">

          <div class="toolbar-section">
            <h2>TV 3</h2>
          </div>

        </div>
      </div>
    </div>

    <div class="tile max-width-1000">
      <div class="tile is-parent">
        <div class="tile is-child box">

          <div class="form-line-wrapper">

            <div class="field" style="flex-grow: 1;">
              <label class="label">Title</label>
              <div class="select">
                <select v-model="workout_data.tvs.three.title">
                  <option value="" disabled>Select an Option</option>
                  <option value="Cardio">Cardio</option>
                  <option value="Cardio & Strength">Cardio & Strength</option>
                  <option value="Strength">Strength</option>
                  <option value="Strength - Upper Body">Strength - Upper Body</option>
                  <option value="Strength - Lower Body">Strength - Lower Body</option>
                  <option value="Strength - Full Body">Strength - Full Body</option>
                  <option value="Competition Day">Competition Day</option>
                  <option value="Fun Day">Fun Day</option>
                  <option value="Hell Day">Hell Day</option>
                </select>
              </div>
            </div>

            <div class="field">
              <label class="label">Total Sets</label>
              <div class="control">
                <input v-model="workout_data.tvs.three.sets" type="text" placeholder="sets" >
              </div>
            </div>

            <div class="field">
              <label class="label">Format</label>
              <div class="select">
                <select v-model="workout_data.tvs.three.format">
                  <option value="" selected disabled>-</option>
                  <option value="Timer">Timer</option>
                  <option value="Reps">Reps</option>
                </select>
              </div>
            </div>

          </div>

          <div class="content-break-line"></div>

          <div class="form-line-wrapper">

            <div class="field w-32 flex-grow-0">
              <label class="label">Key</label>
              <div class="control">
                <input v-model="workout_data.tvs.three.exercises[0].key" type="text" placeholder="C1">
              </div>
            </div>

            <div class="field" style="flex-grow: 1;">
              <label class="label">Exercise</label>
              <div class="control">
                <input v-model="workout_data.tvs.three.exercises[0].name" type="text" placeholder="Exercise Name">
              </div>
            </div>

            <div class="field w-32 flex-grow-0">
              <label class="label">Reps</label>
              <div class="control">
                <input v-model="workout_data.tvs.three.exercises[0].reps" type="text" placeholder="10ea">
              </div>
            </div>

          </div>

          <div class="form-line-wrapper">

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.three.exercises[1].key" type="text" placeholder="C2">
              </div>
            </div>

            <div class="field" style="flex-grow: 1;">
              <div class="control">
                <input v-model="workout_data.tvs.three.exercises[1].name" type="text" placeholder="Exercise Name">
              </div>
            </div>

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.three.exercises[1].reps" type="text" placeholder="10ea">
              </div>
            </div>

          </div>

          <div class="form-line-wrapper">

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.three.exercises[2].key" type="text" placeholder="C3">
              </div>
            </div>

            <div class="field" style="flex-grow: 1;">
              <div class="control">
                <input v-model="workout_data.tvs.three.exercises[2].name" type="text" placeholder="Exercise Name">
              </div>
            </div>

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.three.exercises[2].reps" type="text" placeholder="10ea">
              </div>
            </div>

          </div>

          <div class="form-line-wrapper">

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.three.exercises[3].key" type="text" placeholder="D1">
              </div>
            </div>

            <div class="field" style="flex-grow: 1;">
              <div class="control">
                <input v-model="workout_data.tvs.three.exercises[3].name" type="text" placeholder="Exercise Name">
              </div>
            </div>

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.three.exercises[3].reps" type="text" placeholder="10ea">
              </div>
            </div>

          </div>

          <div class="form-line-wrapper">

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.three.exercises[4].key" type="text" placeholder="D2">
              </div>
            </div>

            <div class="field" style="flex-grow: 1;">
              <div class="control">
                <input v-model="workout_data.tvs.three.exercises[4].name" type="text" placeholder="Exercise Name">
              </div>
            </div>

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.three.exercises[4].reps" type="text" placeholder="10ea">
              </div>
            </div>

          </div>

          <div class="form-line-wrapper">

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.three.exercises[5].key" type="text" placeholder="D3">
              </div>
            </div>

            <div class="field" style="flex-grow: 1;">
              <div class="control">
                <input v-model="workout_data.tvs.three.exercises[5].name" type="text" placeholder="Exercise Name">
              </div>
            </div>

            <div class="field w-32 flex-grow-0">
              <div class="control">
                <input v-model="workout_data.tvs.three.exercises[5].reps" type="text" placeholder="10ea">
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

    <div class="tile max-width-1000">
      <div class="toolbar-wrapper">
        <div class="toolbar">

          <div class="toolbar-section"></div>
          <div class="toolbar-section">
            <button @click="saveWorkout"
                    class="button-dark margin-left"
                    :class="{'is-loading': submitStatus}"
                    :disabled="submitStatus || !pageLoaded">Save &nbsp;<i class="fas fa-angle-right"/></button>
          </div>

        </div>
      </div>
    </div>


  </div>
</template>

<script>
  import { startOfWeek, addDays, subDays, eachDayOfInterval, format, isSameDay, set, startOfDay, endOfDay } from 'date-fns'
  import {db} from './../../firebase/firebaseInit';
  import { mixin as clickaway } from 'vue-clickaway';

  export default {
    name: 'Workouts',
    mixins: [ clickaway ],
    data() {
      return {
        pageLoaded: false,
        submitStatus: false,
        dateSelectorModal: false,

        current_day: new Date(),
        current_week: [],

        selected_day: new Date(),

        workout_id: '',

        workout_data: {
          date: null,
          timer: {
            title: 'Cardio',
            work_time: Number(50),
            rest_time: Number(20)

          },
          tvs: {
            one: {
              title: 'Cardio',
              sets: '3',
              format: 'Timer',
              exercises: [
                { key: '', name: '', reps: '' },
                { key: '', name: '', reps: '' },
                { key: '', name: '', reps: '' },
                { key: '', name: '', reps: '' },
                { key: '', name: '', reps: '' },
                { key: '', name: '', reps: '' }
              ]
            },
            two: {
              title: 'Strength',
              sets: '3',
              format: 'Reps',
              exercises: [
                { key: '', name: '', reps: '' },
                { key: '', name: '', reps: '' },
                { key: '', name: '', reps: '' },
                { key: '', name: '', reps: '' },
                { key: '', name: '', reps: '' },
                { key: '', name: '', reps: '' }
              ]
            },
            three: {
              title: 'Strength',
              sets: '3',
              format: 'Reps',
              exercises: [
                { key: '', name: '', reps: '' },
                { key: '', name: '', reps: '' },
                { key: '', name: '', reps: '' },
                { key: '', name: '', reps: '' },
                { key: '', name: '', reps: '' },
                { key: '', name: '', reps: '' }
              ]
            }
          }
        }

      }
    },
    filters: {
      monthDayFilter(value) {
        return format(value, 'MMM d');
      },
      monthFilter(value) {
        return format(value, 'MMM');
      },
      dayFilter(value) {
        return format(value, 'd');
      },
      dayOfWeekFilter(value) {
        return format(value, 'EEEE');
      }
    },
    computed: {

    },
    methods: {

      closeDateSelectorModal() {
        this.dateSelectorModal = false;
      },

      setCurrentWeek() {
        const firstDayOfWeek = startOfWeek(this.current_day, { weekStartsOn: 1 });
        const lastDayOfWeek = addDays(firstDayOfWeek, 5);

        this.current_week = eachDayOfInterval({ start: firstDayOfWeek, end: lastDayOfWeek })
      },

      goToNextWeek() {
        this.current_day = addDays(this.current_day, 7);
        this.setCurrentWeek();
      },

      goToPreviousWeek() {
        this.current_day = subDays(this.current_day, 7);
        this.setCurrentWeek();
      },

      isSelectedDay(day_of_week) {
        return isSameDay(day_of_week, this.selected_day)
      },

      async getWorkout() {

        this.pageLoaded = false;
        this.workout_id = '';
        const endOfCurrentDay = endOfDay(this.selected_day);
        const startOfCurrentDay = startOfDay(this.selected_day);

        await db.collection('workouts')
          .where('date', '<', endOfCurrentDay)
          .where('date', '>=', startOfCurrentDay)
          .get().then(querySnap => {
            if (!querySnap.empty) {
              this.workout_data = querySnap.docs[0].data();
              this.workout_id = querySnap.docs[0].id;
            } else {
              this.workout_data = {
                date: null,
                timer: {title: 'Cardio', work_time: Number(50), rest_time: Number(20)},
                tvs: {
                  one: {title: 'Cardio', sets: '3', format: 'Timer', exercises: [{ key: '', name: '', reps: '' }, { key: '', name: '', reps: '' }, { key: '', name: '', reps: '' }, { key: '', name: '', reps: '' }, { key: '', name: '', reps: '' }, { key: '', name: '', reps: '' }]},
                  two: {title: 'Strength', sets: '3', format: 'Reps', exercises: [{ key: '', name: '', reps: '' }, { key: '', name: '', reps: '' }, { key: '', name: '', reps: '' }, { key: '', name: '', reps: '' }, { key: '', name: '', reps: '' }, { key: '', name: '', reps: '' }]},
                  three: {title: 'Strength', sets: '3', format: 'Reps', exercises: [{ key: '', name: '', reps: '' }, { key: '', name: '', reps: '' }, { key: '', name: '', reps: '' }, { key: '', name: '', reps: '' }, { key: '', name: '', reps: '' }, { key: '', name: '', reps: '' }]}
                }
              };
            }

          })

        this.pageLoaded = true;

      },

      async saveWorkout() {

        this.submitStatus = true;
        this.workout_data.date = set(this.selected_day, {hours: 12, minutes: 0, seconds: 0});

        if (this.workout_id) {

          await db.collection('workouts').doc(this.workout_id).set(this.workout_data).then(doc => {
            this.submissionSuccess('Workout Updated', 'Workout exercises successfully updated.');
            this.submitStatus = false;
          }).catch(err => {
            console.log(err);
            this.submissionFailure(err.message);
            this.submitStatus = false;
          });

        } else {

          await db.collection('workouts').add(this.workout_data).then(doc => {
            this.submissionSuccess('Workout Created', 'Workout exercises successfully created.');
            this.submitStatus = false;
          }).catch(err => {
            console.log(err);
            this.submissionFailure(err.message);
            this.submitStatus = false;
          });

        }

        this.getWorkout();

      },

      submissionSuccess(title, message) {
        this.$notify({
          group: 'master',
          title: `${title}`,
          text: `${message}`,
          type: 'success'
        })
      },
      submissionFailure(message) {
        this.$notify({
          group: 'master',
          title: 'An Error Occurred',
          text: `${message}`,
          type: 'error'
        })
      }

    },
    created() {
      this.setCurrentWeek();
      this.getWorkout();
    },
    watch: {
      'selected_day': function(newVal) {
        this.current_day = newVal;
        this.setCurrentWeek();
        this.getWorkout();
        this.dateSelectorModal = false;
      }
    }
  }

</script>

<style scoped>

</style>

<template>
  <div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar">
          <div class="toolbar-section">
            <h2>AP Individual Programs</h2>
          </div>

          <div class="toolbar-section">
            <router-link to="/ap-individual/new" class="button-dark flex-shrink-0">Add Program</router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent">
        <div class="tile is-child box">

          <div class="loading-spinner" v-if="!pageLoaded"></div>

          <div class="table-container" v-if="pageLoaded && programs.length > 0">
            <table class="table is-fullwidth animated fadeIn fast" >

              <thead>
              <tr>
                <th style="width: 3%; min-width: 40px;"></th>
                <th>Name</th>
                <th>Start Date</th>
                <th>End Date</th>
<!--                <th class="has-text-right">Totals</th>-->
              </tr>
              </thead>

              <tbody>

              <router-link v-for="program in programs" v-bind:key="program.id" tag="tr" :to="{name: 'Athlete Individual Program Detail', params: {id: program.id}}" class="is-selectable">
                <td>
                  <span v-if="program.is_active" class="status-circle is-active"><i class="fas fa-circle"></i></span>
                  <span v-else class="status-circle is-inactive"><i class="far fa-circle"></i></span>
                </td>
                <td class="font-primary-blue">{{ program.name }}</td>
                <td>{{ program.start_date | startDateFilter }}</td>
                <td>{{ program.end_date | endDateFilter }}</td>
<!--                <td class="has-text-right">Number of athletes total</td>-->
              </router-link>

              </tbody>

            </table>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { db } from './../../firebase/firebaseInit';
  import { format } from 'date-fns';

  export default {
    name: 'AthleteIndividual',
    data() {
      return {
        pageLoaded: false,
        programs: []
      }
    },
    filters: {
      startDateFilter: function(value) {
        return format(value, 'M/d/y')
      },
      endDateFilter: function(value) {
        return format(value, 'M/d/y')
      }
    },
    methods: {

      async getAthletePrograms() {

        await db.collection('athleteindividualprograms').orderBy('start_date', 'asc').orderBy('end_date', 'asc').get().then(
          querySnapshot => {
            querySnapshot.forEach(doc => {
              const programData = {
                id: doc.id,
                name: doc.data().name,
                is_active: doc.data().is_active,
                start_date: doc.data().start_date.toDate(),
                end_date: doc.data().end_date.toDate(),
                description: doc.data().description,
                schedule: doc.data().schedule
              }

              this.programs.push(programData);
            });
        });

        this.pageLoaded = true;

      }

    },
    async created() {
      await this.getAthletePrograms()
    }
  }
</script>

<style scoped>

</style>

<template>
  <div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar toolbar-centered max-width-800">
          <div class="toolbar-section">
            <h2 class="has-text-left">New Email</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent is-centered">
        <div class="tile is-child box max-width-800">

          <!--<h3 class="detail-info-header has-text-left">Email Type</h3>-->

          <div class="selectable-item-wrapper row">

            <div class="selectable-item" @click="email_type = 'group'" :class="{'selected': email_type === 'group'}">
              <div class="selectable-item-section">
                <p class="is-semibold font-primary-blue has-text-left">Group Email</p>
                <p class="is-small has-text-left">Choose a specific Elevate or Athlete group.</p>
              </div>
            </div>

            <div class="selectable-item" @click="email_type = 'individual'" :class="{'selected': email_type === 'individual'}">
              <div class="selectable-item-section">
                <p class="is-semibold font-primary-blue has-text-left">Individual Email</p>
                <p class="is-small has-text-left">Specify one or many Elevate member recipients.</p>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar toolbar-centered max-width-800">
          <div class="toolbar-section">
            <h2 class="has-text-left">Message Details</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent is-centered">
        <div class="tile is-child box max-width-800">

          <!--<h3 class="detail-info-header has-text-left" style="margin-top: 2rem;">Message Details</h3>-->

          <div class="field">
            <label class="label">From</label>
            <div class="select">
              <select disabled>
                <option value="" selected>Rise Training and Fitness (contact@risetrainingmn.com)</option>
              </select>
            </div>
          </div>

          <div class="field" v-if="!email_type">
            <div class="label-container">
              <label class="label">To</label>
              <p class="validation-error">{{ errors.to }}</p>
            </div>
            <div class="select">
              <select disabled>
                <option value="">Select an email type</option>
              </select>
            </div>
          </div>

          <div class="field" v-if="email_type === 'group'">
            <div class="label-container">
              <label class="label">Recipient Group</label>
              <p class="validation-error">{{ errors.to }}</p>
            </div>
            <div class="select">
              <select v-model="recipient_group" class="select-full-width">
                <!--<option value="" disabled></option>-->
                <option value="" disabled>-</option>
                <option value="Elevate - Active">Elevate - Active Members</option>
                <option value="Elevate - Inactive">Elevate - Inactive Members</option>
                <option value="Elevate - All">Elevate - All Members</option>
              </select>
            </div>
          </div>

          <div class="field" v-if="email_type === 'individual'">
            <div class="label-container">
              <label class="label">Individual Recipients</label>
              <p class="validation-error">{{ errors.to }}</p>
            </div>
            <MemberSearchInput :members="individualMemberIds" @addMember="addIndividualEmail"></MemberSearchInput>
          </div>



          <div class="email-list-container" v-if="email_type === 'individual'">

            <!-- empty state -->
            <div class="email-list-item disabled" v-if="email_type === 'individual' && !individual_members.length">
              <p>Select a minimum of one member.</p>
            </div>

            <!-- individual email recipients -->
            <div v-for="(member, index) in individual_members" :key="member.id" class="email-list-item">
              <p class="is-semibold font-secondary-blue">{{ member.first_name }} {{ member.last_name }} <span class="is-small" style="font-weight: 400;"> • {{ member.email }}</span></p>
              <button class="button secondary-action-button" style="border: unset; margin-left: 0.2rem;" @click="removeEmailRecipient(index)"><i class="fas fa-times"></i></button>
            </div>

          </div>

          <div class="field">
            <div class="label-container">
              <label class="label">Subject</label>
              <p class="validation-error">{{ errors.subject }}</p>
            </div>
            <div class="control">
              <input v-model="subject" type="text">
            </div>
          </div>

          <div class="field">
            <div class="label-container">
              <label class="label">Message</label>
              <p class="validation-error">{{ errors.message }}</p>
            </div>
            <!--<textarea v-model="message" class="textarea" rows="8"></textarea>-->
            <vue-editor v-model="message" :editor-toolbar="customToolbar" @focus="onEditorFocus" @blur="onEditorBlur"></vue-editor>
          </div>

        </div>
      </div>
    </div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar toolbar-centered max-width-800">
          <div class="toolbar-section"></div>
          <div class="toolbar-section">
            <router-link to="/email" class="button-light">Cancel</router-link>
            <button
              class="button-dark margin-left"
              :class="{'is-loading': submitStatus}"
              @click="validateEmail"
              :disabled="submitStatus">Send &nbsp;<i class="fas fa-angle-right"></i></button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {db,functions} from "./../../firebase/firebaseInit";
  import MemberSearchInput from './../components/MemberSearchInput';
  import {VueEditor, Quill} from 'vue2-editor';

  // Quill default tag
  let Block = Quill.import('blots/block');
  Block.tagName = 'DIV';
  Quill.register(Block, true);

  export default {
    name: 'NewEmail',
    components: {MemberSearchInput, VueEditor},
    data: function() {
      return {
        pageLoaded: true,
        submitStatus: false,

        email_type: '',

        // group
        recipient_group: '',
        group_email_addresses: [],

        // individual
        individual_members: [],

        subject: '',
        message: '',
        footer: '',

        // vue-editor toolbar
        customToolbar: [
          [{ 'header': [1, 2, 3, false] }],
          ["bold", "italic", "underline", ],
          [{ 'color': [] }, { 'background': [] }],
          [{ list: "ordered" }, { list: "bullet" }],
          [{"align": []}, "link"],
          ["clean"]
        ],

        // Errors
        errors: {
          to: '',
          subject: '',
          message: ''
        }
      }
    },
    filters: {

    },
    computed: {
      individualMemberIds() {
        return this.individual_members.map(member => member.id)
      },
      individualEmailAddresses() {
        if (this.email_type === 'individual') {
          return this.individual_members.map(member => member.email).join(', ');
        } else {
          return '';
        }
      },
      groupEmailAddresses() {
        let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (this.email_type === 'group') {
          return this.group_email_addresses.filter(email => emailRegex.test(email));
        } else {
          return [];
        }
      }
    },
    methods: {

      getGroupEmailRecipients() {
        this.group_email_addresses = [];
        let membersRef = db.collection('clients');

        if (this.recipient_group === 'Elevate - Active') {
          membersRef = membersRef.where('is_active', '==', true)
        } else if (this.recipient_group === 'Elevate - Inactive') {
          membersRef = membersRef.where('is_active', '==', false)
        } else if (this.recipient_group === 'Elevate - All') {}

        membersRef.get().then(querySnap => {
          querySnap.forEach(doc => {
            const email = doc.data().email;
            this.group_email_addresses.push(email)
          })
        })
      },

      async addIndividualEmail(member_id) {
        await db.collection('clients').doc(member_id).get().then(doc => {
          const memberData = {
            id: doc.id,
            first_name: doc.data().first_name,
            last_name: doc.data().last_name,
            is_active: doc.data().is_active,
            email: doc.data().email
          }

          this.individual_members.push(memberData)
        })
      },

      removeEmailRecipient(index) {
        this.individual_members.splice(index, 1)
      },

      // Vue Editor Focus & Blur

      onEditorBlur() {
        document.querySelector('.quillWrapper').classList.remove('editor-focused')
      },

      onEditorFocus() {
        document.querySelector('.quillWrapper').classList.add('editor-focused')
      },

      validateEmail() {

        // address validation for group or individual email
        if (this.email_type === 'group') {
          this.errors.to = this.groupEmailAddresses.length ? '' : 'Select a group.';
        } else if (this.email_type === 'individual') {
          this.errors.to = this.individualEmailAddresses ? '' : 'Select recipient(s).';
        }

        this.errors.subject = this.subject ? '' : 'Subject is required.';
        this.errors.message = this.message ? '' : 'Message is required.';

        if (!this.errors.to && !this.errors.subject && !this.errors.message) {
          this.email_type === 'group' ? this.sendGroupEmail() : this.sendIndividualEmail();
        } else {
          this.submissionFailure('Please fill out required information.')
        }
      },

      // sendEmail() {
      //   this.submitStatus = true
      //
      //   functions.httpsCallable('sendAdminEmail')({
      //     email_type: this.email_type,
      //     recipients: this.groupEmailAddresses,
      //     subject: this.subject,
      //     message: this.messageAndFooter
      //   }).then(() => {
      //     this.createEmailRecord();
      //     }).catch(err => {
      //       this.submissionFailure(err.message);
      //       console.log(err);
      //       this.submitStatus = false
      //   })
      // },

      async sendGroupEmail() {

        this.submitStatus = true;

        try {

          await functions.httpsCallable('sendGroupEmail')({
            template_id: '21739875',
            template_model: {
              subject: this.subject,
              body: this.message
            },
            to: this.groupEmailAddresses
          }).then(() => {
            this.createEmailRecord();
          });

        } catch (err) {
          console.error(err);
          this.submissionFailure(err.message);
          this.submitStatus = false;
        }

      },

      async sendIndividualEmail() {

        this.submitStatus = true;

        try {

          await functions.httpsCallable('sendIndividualEmail')({
            template_id: '21717867',
            template_model: {
              subject: this.subject,
              body: this.message
            },
            to: this.individualEmailAddresses,
          }).then(() => {
            this.createEmailRecord();
          });

        } catch (err) {
          console.error(err);
          this.submissionFailure(err.message);
          this.submitStatus = false;
        }

      },

      createEmailRecord() {
        db.collection('emails').add({
          email_type: this.email_type,
          sender: 'contact@risetrainingmn.com',
          recipient_group: this.email_type === 'group' ? this.recipient_group : '',
          individual_recipients: this.email_type === 'individual' ? this.individualEmailAddresses.split(', ') : '',
          subject: this.subject,
          message: this.message,
          sent_at: new Date(),
          status: 'Delivered',
        }).then(() => {
          this.submissionSuccess();
          this.$router.push({name: 'Email'})
        }).catch(err => {
          console.log(err);
          this.submissionFailure(err.message);
          this.submitStatus = false
        })
      },

      submissionFailure(message) {
        this.$notify({
          group: 'master',
          title: 'An Error Occurred',
          text: `${message}`,
          type: 'error'
        })
      },
      submissionSuccess() {
        this.$notify({
          group: 'master',
          title: 'Email Sent',
          text: 'Email was successfully sent.',
          type: 'success'
        })
      }

    },
    created: function() {

    },
    watch: {
      'email_type': function() {
        this.recipient_group = '';
        this.individual_members = [];
        this.group_email_addresses = []
      },
      'recipient_group': function() {
        this.getGroupEmailRecipients()
      }
    }
  }
</script>

<style scoped>

  .email-list-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
  .email-list-item {
    display: flex;
    align-items: center;
    padding: 0.5rem 0.75rem;
    background-color: #f2f6ff;
    border-radius: 4px;
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }
  .email-list-item.disabled {
    background-color: #f5f5f5;
  }
  .email-list-item.disabled > p {
    color: #7a7a7a!important;
  }


</style>

<template>
  <div>

    <div class="tile max-width-800">
      <div class="tile is-parent is-centered">
        <div class="tile is-child">

          <h2 class="has-text-left">New Membership Plan</h2>
          <p class="is-small has-text-left">Create a new or future membership plan for an existing member.</p>

        </div>
      </div>
    </div>

    <!---- TAB 1 ---->
    <template v-if="currentTab === 1">

      <div class="tile max-width-800">
        <div class="tile is-parent is-centered">
          <div class="tile is-child box">



            <div class="step-title-container">
              <h3 class="has-text-left">Select a Member</h3>
  <!--              <div class="step-completed-check" :class="{'checked': memberLoaded && selected_member_id}"><i class="fas fa-check"></i></div>-->
            </div>


            <MemberSearchInput v-if="!selected_member_id" :members="selected_member_id" @addMember="selectMember" style="margin-bottom: 1rem;"></MemberSearchInput>

            <div class="loading-spinner animated fadeIn fast" v-if="!memberLoaded"></div>

  <!--            <h2 v-if="memberLoaded && !selected_member_id" class="empty-state animated fadeIn fast">No member selected.-->
  <!--              <span style="display: block;">-->
  <!--                <router-link to="/members/newmember" style="font-size: 14px;">+ Create a New Member</router-link>-->
  <!--              </span>-->
  <!--            </h2>-->

            <div class="member-list-container animated fadeIn fast" v-if="memberLoaded && selected_member_id">
              <div class="member-list-item">
                <h5 class="is-semibold font-primary-blue">{{ selected_member.first_name }} {{ selected_member.last_name }} &nbsp;</h5>
                <h6 class="has-text-left">{{ selected_member.email }}  &nbsp;•&nbsp; {{ selected_member.phone_number | phoneNumberFilter }}</h6>

                <div style="display: flex; margin-top: 0.5rem;">
                  <span v-if="selected_member.is_active" class="status-label is-small success-label margin-right">Membership Active</span>
                  <span v-if="!selected_member.is_active" class="status-label is-small gray-label margin-right">Membership Inactive</span>
                  <span v-if="selected_member.customer_id" class="status-label is-small light-blue-label">Stripe Enabled</span>
                  <span v-if="!selected_member.customer_id" class="status-label is-small gray-label">Stripe Inactive</span>
                </div>

              </div>
              <button class="button primary-action-button" style="height: unset;" @click="clearSelectedMember">Clear</button>
            </div>






            <div class="step-title-container">
              <h3 class="has-text-left">Membership Type</h3>
  <!--              <div class="step-completed-check" :class="{'checked': selected_membership_type}"><i class="fas fa-check"></i></div>-->
            </div>


            <div class="dropdown is-active" style="width: 100%;" v-on-clickaway="closeMembershipTypeDropdown">

              <div class="dropdown-selector" @click="membershipTypeDropdownOpen = !membershipTypeDropdownOpen" tabindex="0">
                <p v-if="!selected_membership_type" style="color: #c8ccd0;">Select a Membership Type</p>
                <p v-if="selected_membership_type">{{ selected_membership_type }}</p>
                <i class="fas fa-chevron-down" style="font-size: 12px; transition: 0.2s all ease;" :class="{'arrow-rotate': membershipTypeDropdownOpen}"/>
              </div>

              <div class="dropdown-menu" :class="{'menu-open': membershipTypeDropdownOpen}" style="width: 100%">
                <div class="dropdown-content" style="padding: 0.75rem;">

                  <div v-for="type in membership_types" @click="setSelectedMembershipType(type.name, type.id, type.metadata.billing_type)" :key="type.id" class="search-member" tabindex="0">
                    <div class="search-member-item">
                      <h1>{{ type.name }}</h1>
                      <h2 class="has-text-left">{{ type.metadata.description }}</h2>
                    </div>
                  </div>

                </div>

              </div>


            </div>

            <div class="step-title-container">
              <h3 class="has-text-left">Pricing Plan</h3>
            </div>

            <div class="dropdown is-active" style="width: 100%;" v-on-clickaway="closePricingPlanDropdown">

              <div class="dropdown-selector" @click="pricingPlanDropdownOpen = !pricingPlanDropdownOpen" tabindex="0">
                <p v-if="!selected_pricing_plan.name" style="color: #c8ccd0;">Select a Pricing Plan</p>
                <p v-if="selected_pricing_plan.name">{{ selected_pricing_plan.name }}</p>
                <i class="fas fa-chevron-down" style="font-size: 12px; transition: 0.2s all ease;" :class="{'arrow-rotate': pricingPlanDropdownOpen}"/>
              </div>

              <div class="dropdown-menu" :class="{'menu-open': pricingPlanDropdownOpen}" style="width: 100%;">
                <div class="dropdown-content" style="padding: 0.75rem;  max-height: 300px; overflow-y: scroll;">

                  <div v-for="plan in pricing_plans" @click="setSelectedPricingPlan(plan.nickname, plan.id)" :key="plan.id" class="search-member" tabindex="0">
                    <div class="search-member-item">
                      <h1>{{ plan.nickname }}</h1>
                      <h2 class="has-text-left">{{ plan.metadata.description }}</h2>
                    </div>
                    <div class="search-member-item">
                      <p>{{ plan.unit_amount | amountFilter }}<span v-if="billing_type === 'subscription'">/month</span></p>
                      <h2 class="has-text-right" v-if="plan.metadata.duration">for {{ plan.metadata.duration }} months</h2>
                    </div>
                  </div>

                </div>

              </div>

            </div>





            <div class="step-title-container">
              <div>
                <h3 class="has-text-left">Enable Billing</h3>
                <p class="is-small has-text-left">Automatically process subscription/invoice billing via Stripe.</p>
              </div>

              <label class="switch">
                <input v-model="billing_enabled" type="checkbox">
                <span class="slider round"></span>
              </label>

            </div>








            <div class="step-title-container">
              <h3>Payment Method</h3>
            </div>

            <div class="selectable-item-wrapper row" v-if="billing_enabled">

              <div class="selectable-item" @click="collection_method = 'charge_automatically'" :class="{'selected': collection_method === 'charge_automatically'}" tabindex="0">
                <div class="selectable-item-section">
                  <p class="is-semibold font-primary-blue has-text-left">Auto</p>
                  <p class="is-small has-text-left">Automatically charge the default payment method.</p>
                </div>
              </div>

              <div class="selectable-item" @click="collection_method = 'send_invoice'" :class="{'selected': collection_method === 'send_invoice'}" tabindex="0">
                <div class="selectable-item-section">
                  <p class="is-semibold font-primary-blue has-text-left">Manual</p>
                  <p class="is-small has-text-left">Invoice(s) sent via email with payment instructions.</p>
                </div>
              </div>

            </div>

            <div style="background: #f7f8fa; padding: 1.5rem; border-radius: 8px;" v-if="!billing_enabled">
              <p>Only available when billing is enabled.</p>
            </div>




            <div class="step-title-container">
              <h3>Schedule</h3>
            </div>

            <template v-if="billing_type === 'subscription'">

              <div class="selectable-item">
                <p>Start now for <span style="display: inline-block; max-width: 50px;"><input type="number" placeholder="0" min="0"></span> months.</p>
              </div>

              <div class="selectable-item">
                <p>Start on
                  <v-date-picker mode='single'
                                 :popover="{visibility: 'focus'}" style="display: inline-block">
                    <input type='text' placeholder="MM/DD/YYYY"
                           slot-scope="{ inputProps, inputEvents }"
                           v-bind="inputProps"
                           v-on="inputEvents">
                  </v-date-picker>
                  for
                  <span style="display: inline-block; max-width: 50px;"><input type="number" placeholder="0" min="0"></span> months.</p>
              </div>

            </template>

            <div style="background: #f7f8fa; padding: 1.5rem; border-radius: 8px;" v-if="billing_type !== 'subscription'">
              <p>Only available for subscription membership plans.</p>
            </div>


<!--            <label for="now" class="radio">-->
<!--              <input type="radio" value="now" id="now">-->
<!--              Start now for <span style="display: inline-block; max-width: 50px;"><input type="number" placeholder="0" min="0"></span> months.-->
<!--            </label>-->

<!--            <label for="schedule" class="radio">-->
<!--              <input type="radio" value="schedule" id="schedule">-->
<!--              Start on-->
<!--              <v-date-picker mode='single'-->
<!--                             :popover="{visibility: 'focus'}" style="display: inline-block">-->
<!--                <input type='text' placeholder="MM/DD/YYYY"-->
<!--                       slot-scope="{ inputProps, inputEvents }"-->
<!--                       v-bind="inputProps"-->
<!--                       v-on="inputEvents">-->
<!--              </v-date-picker>-->
<!--              for-->
<!--              <span style="display: inline-block; max-width: 50px;"><input type="number" placeholder="0" min="0"></span> months.-->
<!--            </label>-->

<!--            <p class="has-text-left">-->
<!--              Start on-->
<!--              <v-date-picker mode='single'-->
<!--                             :popover="{visibility: 'focus'}" style="display: inline-block">-->
<!--                <input type='text' placeholder="MM/DD/YYYY"-->
<!--                       slot-scope="{ inputProps, inputEvents }"-->
<!--                       v-bind="inputProps"-->
<!--                       v-on="inputEvents">-->
<!--              </v-date-picker>-->
<!--              for-->
<!--              <span style="display: inline-block; max-width: 50px;"><input type="number" placeholder="0" min="0"></span>-->
<!--              months.-->
<!--            </p>-->

<!--            <div class="field">-->

<!--              <div class="label-container">-->
<!--                <label class="label">Session Date</label>-->
<!--&lt;!&ndash;                <p class="validation-error">{{ errors.session_date }}</p>&ndash;&gt;-->
<!--              </div>-->

<!--              <div class="control has-icons-right">-->

<!--              </div>-->
<!--            </div>-->




          </div>
        </div>
      </div>

      <div class="tile max-width-800">
        <div class="toolbar-wrapper">
          <div class="toolbar toolbar-centered">
            <div class="toolbar-section">
              <router-link to="/members" class="button-light">Cancel</router-link>
            </div>

            <div class="toolbar-section">
              <button class="button-dark margin-left" @click="currentTab = 2" :disabled="!selected_member_id">Next &nbsp;<i class="fas fa-angle-right"></i></button>
            </div>
          </div>
        </div>
      </div>

    </template>





  </div>
</template>

<script>
  import MemberSearchInput from './../components/MemberSearchInput'
  import {db, functions} from './../../firebase/firebaseInit';
  import { mixin as clickaway } from 'vue-clickaway';

  export default {
    name: 'NewMembershipPlan',
    components: {MemberSearchInput},
    mixins: [ clickaway ],
    // props: ['members'],
    data: function() {
      return {
        currentTab: 1,
        memberLoaded: true,
        membershipTypesLoaded: false,
        submitStatus: false,

        billing_enabled: true,
        billing_type: null, // 'subscription' or 'invoice'
        collection_method: 'charge_automatically', // 'charge_automatically' or 'send_invoice'
        start_immediately: true, // start subscription now. If false, start_date must not be null.
        start_date: null,
        iterations: null,

        membershipTypeDropdownOpen: false,
        pricingPlanDropdownOpen: false,

        membership_types: [],
        pricing_plans: [],
        selected_membership_type: '',
        selected_pricing_plan: {
          name: '',
          id: ''
        },

        selected_member_id: '',
        selected_member: {
          id: '',
          first_name: '',
          last_name: '',
          is_active: null,
          email: '',
          phone_number: '',
          customer_id: ''
        }
      }
    },
    filters: {
      amountFilter: function(cents) {
        if (cents % 100 === 0) {
          return (cents / 100) + '.00'
        } else {
          return cents / 100
        }
      },
      phoneNumberFilter: function(value) {
        return value.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
      }
    },
    computed: {
      filteredMembershipTypes: function() {
        return this.membership_types.filter(type => !type.name.includes('Student Plan'))
      }
    },
    methods: {
      getAllPricingPlans: function(product_id) {
        functions.httpsCallable('getAllPricingPlans')({
          product_id: product_id
        }).then(
          result => {
            this.pricing_plans = result.data.data
            this.pricing_plans.sort((a, b) => a.created - b.created)
          }
        )
      },

      getAllMembershipTypes: function() {
        this.membershipTypesLoaded = false

        functions.httpsCallable('getAllProducts')().then(
          result => {
            this.membership_types = result.data.data.filter(product => product.metadata.type === 'elevate');
            this.membership_types.sort((a, b) => a.created - b.created);
            this.membershipTypesLoaded = true
          }
        ).catch(err => {
          console.log(err)
        })

      },

      async selectMember(member_id) {
        this.memberLoaded = false
        this.members = [];
        this.selected_member_id = member_id;

        await db.collection('clients').doc(member_id).get().then(doc => {
          this.selected_member.id = doc.id;
          this.selected_member.first_name = doc.data().first_name;
          this.selected_member.last_name = doc.data().last_name;
          this.selected_member.is_active = doc.data().is_active;
          this.selected_member.email = doc.data().email;
          this.selected_member.phone_number = doc.data().phone_number;
          this.selected_member.customer_id = doc.data().customer_id;

        })

        this.memberLoaded = true

      },

      clearSelectedMember: function() {
        this.members = []
        this.selected_member_id = ''
      },

      setSelectedMembershipType: function(name, product_id, billing_type) {
        this.selected_membership_type = name;
        this.billing_type = billing_type;
        this.getAllPricingPlans(product_id)
        this.membershipTypeDropdownOpen = false;
      },

      setSelectedPricingPlan(name, price_id) {
        this.selected_pricing_plan.name = name;
        this.selected_pricing_plan.id = price_id;
        this.pricingPlanDropdownOpen = false;
      },

      closeMembershipTypeDropdown() {
        this.membershipTypeDropdownOpen = false;
      },
      closePricingPlanDropdown() {
        this.pricingPlanDropdownOpen = false;
      }

    },
    created: function() {
      // this.getAllPricingPlans()
      this.getAllMembershipTypes()
    },
    watch: {
      'selected_membership_type'() {
        this.selected_pricing_plan.name = '';
        this.selected_pricing_plan.id = '';
      }
    }
  }
</script>

<style scoped>

  .step-title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 0.5rem 0;
  }
  .step-title-container:not(:first-child) {
    margin-top: 2.5rem;
  }


  .member-list-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    /*border: 1px solid #e4e7ea;*/
    /*background: #f7f8fa;*/
    border: 1px solid #e4e7ea;
    border-radius: 8px;
    padding: 1rem;
  }
  .member-list-item {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    align-items: flex-start;
    justify-content: space-between;
    /*padding: 0.75rem 0;*/
    /*border-top: 1px solid #e4e7ea;*/
  }

  .dropdown-selector {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: 1px solid #c8ccd0;
    border-radius: 6px;
    padding: 0.5rem;
    transition: 0.3s all ease;
  }
  .dropdown-selector:hover {
    border-color: #9299a0;
    cursor: pointer;
  }
  .dropdown-selector:focus {
    outline: none;
    border: 1px solid #2c9fe5;
    box-shadow: 0 0 0 0.15rem #87bbf0!important;
  }

  .search-member {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
  }
  .search-member:hover, .search-member:focus {
    background: #f2f6ff;
    cursor: pointer;
    outline: none;
  }
  .search-member:not(:last-child) {
    border-bottom: 1px solid #f2f6ff;
  }
  .search-member:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .search-member:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .search-member h1 {
    color: #123968;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-align: left;
  }
  .search-member h1 {
    color: #123968;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.3;
    text-align: left;
    transition: 0.2s all ease;
  }
  .search-member:hover h1, .search-member:focus h1 {
    color: #2c9fe5;
  }
  .search-member h2 {
    color: #9299a0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    /*text-transform: uppercase;*/
    /*letter-spacing: 0.05rem;*/
  }
  .search-member h3 {
    color: #9299a0;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
  }


</style>

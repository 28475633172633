<template>
  <div>


    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar toolbar-centered max-width-800">

          <div class="toolbar-section">
            <h2>New Team Member</h2>
          </div>

        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent is-centered">
        <div class="tile is-child box max-width-800">

          <div class="field">
            <div class="label-container">
              <label class="label">First Name</label>
              <p class="validation-error">{{ errors.first_name }}</p>
            </div>
            <div class="control">
              <input v-model="new_member.first_name" type="text" placeholder="Jane">
            </div>
          </div>

          <div class="field">
            <div class="label-container">
              <label class="label">Last Name</label>
              <p class="validation-error">{{ errors.last_name }}</p>
            </div>
            <div class="control">
              <input v-model="new_member.last_name" type="text" placeholder="Appleseed">
            </div>
          </div>

          <div class="field">
            <div class="label-container">
              <label class="label">Email</label>
              <p class="validation-error">{{ errors.email }}</p>
            </div>
            <div class="control">
              <input v-model="new_member.email" type="email" placeholder="example@me.com">
            </div>
          </div>

          <div class="field">
            <div class="label-container">
              <label class="label">Phone Number</label>
              <p class="validation-error">{{ errors.phone_number }}</p>
            </div>
            <div class="control">
              <input v-model="phone_number_input" v-mask="'(###) ###-####'" type="tel" placeholder="(218) 555-0100" maxlength="14">
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar toolbar-centered max-width-800">

          <div class="toolbar-section">
            <h2>User Details</h2>
          </div>

        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent is-centered">
        <div class="tile is-child box max-width-800">

          <div class="field checkbox-container">
            <div class="checkbox-info">
<!--              <label class="label">Admin Access</label>-->
              <p class="is-semibold font-secondary-blue">Admin Access</p>
              <p class="is-small">Allow full user privileges to Rise Admin data & users.</p>
            </div>
            <label class="switch">
              <input v-model="custom_claims.is_admin" type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>

          <hr class="content-break-line">

          <div class="field">
            <div class="label-container">
              <label class="label">Position</label>
              <p class="validation-error">{{ errors.position }}</p>
            </div>
            <div class="control">
              <input v-model="new_member.position" type="text" placeholder="Job Title">
            </div>
          </div>

          <div class="field">
            <div class="label-container">
              <label class="label">Bio</label>
            </div>
            <textarea v-model="new_member.bio" class="textarea" rows="3"/>
          </div>

        </div>
      </div>
    </div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar toolbar-centered max-width-800">

          <div class="toolbar-section"></div>
          <div class="toolbar-section">
            <router-link to="/team" class="button-light">Cancel</router-link>
            <button @click="validateNewTeamMember"
                    class="button-dark margin-left"
                    :class="{'is-loading': submitStatus}"
                    :disabled="submitStatus">Create &nbsp;<i class="fas fa-angle-right"/></button>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {db,functions} from './../../firebase/firebaseInit'
  import {inputCheck, firstNameCheck, lastNameCheck, emailCheck, phoneNumberCheck} from '../errors'

  export default {
    name: 'NewTeamMember',
    data() {
        return {
          submitStatus: false,
          phone_number_input: '',

          // member data
          new_member: {
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            position: '',
            bio: '',
            member_since: null
          },

          // admin custom claims
          custom_claims: {
            team_member: true,
            is_admin: false
          },

          // errors
          errors: {
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            position: ''
          }
        }
    },
    filters: {

    },
    computed: {

    },
    methods: {

      validateNewTeamMember() {
        this.errors.first_name = firstNameCheck(this.new_member.first_name)
        this.errors.last_name = lastNameCheck(this.new_member.last_name)
        this.errors.email = emailCheck(this.new_member.email)
        this.errors.phone_number = phoneNumberCheck(this.new_member.phone_number)
        this.errors.position = inputCheck(this.new_member.position)

        if (!this.errors.first_name && !this.errors.last_name && !this.errors.email && !this.errors.phone_number && !this.errors.position) {
          this.createNewTeamMember()
        } else {
          this.submissionFailure('Please fill out required information.')
        }


      },

      async createNewTeamMember() {
        this.submitStatus = true

        await functions.httpsCallable('createNewTeamMember')({
          email: this.new_member.email,
          custom_claims: this.custom_claims
        }).then(result => {
          this.createTeamMemberRecord(result.data.uid)
        }).catch(err => {
          console.log(err.message)
          this.submissionFailure(err.message)
          this.submitStatus = false
        })

      },

      createTeamMemberRecord(uid) {
        this.new_member.member_since = new Date();

        db.collection('team').doc(uid).set(this.new_member).then(() => {
          this.submissionSuccess('Account Created', `An account for ${this.new_member.first_name} ${this.new_member.last_name} was created.`)
          this.$router.push({name: 'Team'})
        }).catch(err => {
          this.submissionFailure(err.message)
          this.submitStatus = false
        })
      },

      submissionSuccess(title, message) {
        this.$notify({
          group: 'master',
          title: `${title}`,
          text: `${message}`,
          type: 'success'
        })
      },
      submissionFailure(message) {
        this.$notify({
          group: 'master',
          title: 'An Error Occurred',
          text: `${message}`,
          type: 'error'
        })
      }

    },
    created() {

    },
    watch: {
      'phone_number_input': function() {
        return this.new_member.phone_number = this.phone_number_input.replace(/\D+/g, "")
      }
    }
  }


</script>

<style scoped>

</style>

<template>
    <div>

      <div class="tile">
        <div class="toolbar-wrapper">
          <div class="toolbar">
            <div class="toolbar-section">
              <h2>Athletes</h2>
            </div>

            <div class="toolbar-section">

<!--              &lt;!&ndash;- Filter Dropdown -&ndash;&gt;-->
<!--              <div class="dropdown is-active" @click="filterMenuOpen = !filterMenuOpen">-->
<!--                <div class="dropdown-trigger">-->
<!--                  <button class="button button-outline" aria-haspopup="true" aria-controls="dropdown-menu">-->
<!--                    <span class="icon is-small"><i class="fas fa-sliders-h"/></span> Filters-->
<!--                    <span class="icon is-small" style="margin-left: 0.1rem;"><i v-bind:class="{'arrow-rotate': filterMenuOpen}" class="fas fa-angle-down arrow-icon" aria-hidden="true"></i></span>-->
<!--                  </button>-->
<!--                </div>-->
<!--                <div v-bind:class="{'menu-open': filterMenuOpen}" class="dropdown-menu" id="dropdown-menu" role="menu">-->
<!--                  <div class="dropdown-content is-selectable">-->
<!--                    <div @click="filterStatus = 'All'" class="dropdown-item"><span class="filter-status-icon"><i v-bind:class="filterStatus === 'All' ? 'fa-check-circle' : 'fa-circle'" class="far"></i></span>All</div>-->
<!--                    <div @click="filterStatus = 'Active'" class="dropdown-item"><span class="filter-status-icon"><i v-bind:class="filterStatus === 'Active' ? 'fa-check-circle' : 'fa-circle'" class="far"></i></span>Active</div>-->
<!--                    <div @click="filterStatus = 'Inactive'" class="dropdown-item"><span class="filter-status-icon"><i v-bind:class="filterStatus === 'Inactive' ? 'fa-check-circle' : 'fa-circle'" class="far"></i></span>Inactive</div>-->
<!--                    <div @click="filterStatus = 'Adult Fitness'" class="dropdown-item"><span class="filter-status-icon"><i v-bind:class="filterStatus === 'Adult Fitness' ? 'fa-check-circle' : 'fa-circle'" class="far"></i></span>Adult Fitness</div>-->
<!--                    <div @click="filterStatus = 'Athlete Performance'" class="dropdown-item"><span class="filter-status-icon"><i v-bind:class="filterStatus === 'Athlete Performance' ? 'fa-check-circle' : 'fa-circle'" class="far"></i></span>Athlete Performance</div>-->
<!--                    &lt;!&ndash;<div class="dropdown-item"><span class="filter-status-icon"><i class="far fa-circle"></i></span>Overdue</div>&ndash;&gt;-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div> &lt;!&ndash;- Filter Dropdown End -&ndash;&gt;-->

              <router-link to="/athletes/new" class="button-dark margin-left">New Athlete</router-link>

            </div>
          </div>
        </div>
      </div>

      <div class="tile">

        <div class="tile is-parent">
          <div class="tile is-child box client-list">

            <div class="loading-spinner" v-if="!pageLoaded"></div>

            <div class="table-container" v-if="pageLoaded">
              <table class="table is-fullwidth animated fadeIn">

                <thead>
                <tr>
                  <th style="width: 3%; min-width: 40px;"></th>
                  <th>Name</th>
<!--                  <th>Membership Type</th>-->
                  <th>Email</th>
                  <th style="text-align: right;">Date Joined</th>
                </tr>
                </thead>

                <tbody>

<!--                  <router-link v-for="athlete in athletes" v-bind:key="client.id" tag="tr" :to="{name: 'Member Detail', params: {member_id: client.id}}" class="is-selectable">-->
                  <router-link v-for="athlete in athletes" :key="athlete.id" tag="tr" to="/athletes" class="is-selectable">
                    <td>
                      <span v-if="athlete.is_active" class="status-circle is-active"><i class="fas fa-circle"></i></span>
                      <span v-else class="status-circle is-inactive"><i class="far fa-circle"></i></span>
                    </td>
                    <td class="client-col font-primary-blue">{{ athlete.first_name }} {{ athlete.last_name }}</td>
<!--                    <td>-->
<!--                      <span class="status-label is-small light-blue-label">{{ client.membership_type }} <span v-if="client.membership_type === 'Punchcard'">({{ client.punchcard_count }})</span></span>-->
                      <!--<span v-if="client.program === 'Athlete Performance'" class="is-label-dark">Ath. Performance</span>-->
                      <!--<span v-else-if="client.program === 'Adult Fitness'" class="is-label-light">Adult Fitness</span>-->
<!--                    </td>-->
                    <td>{{ athlete.email }}</td>
                    <td style="text-align: right;">{{ athlete.signup_date | signupDateFilter }}</td>
                  </router-link>

                </tbody>

              </table>
            </div>

          </div>
        </div>

      </div>

    </div>
</template>

<script>
  import {db} from './../../firebase/firebaseInit'
  import { formatDistanceStrict } from 'date-fns'

    export default {
      name: "Members",
      data: function() {
        return {
          athletes: [],
          filterMenuOpen: false,
          filterStatus: "All",
          pageLoaded: false
        }
      },
      filters: {
        signupDateFilter: function(value) {
          return formatDistanceStrict(value, new Date(), {addSuffix: true})
        }
      },
      computed: {
        // filteredClients: function() {
        //   if (this.filterStatus === "All") {
        //     return this.clients
        //   } else if (this.filterStatus === "Active") {
        //     return this.clients.filter(function(client) {
        //       return client.is_active
        //     })
        //   } else if (this.filterStatus === "Inactive") {
        //     return this.clients.filter(function(client) {
        //       return client.is_active === false
        //     })
        //   } else if (this.filterStatus === "Adult Fitness") {
        //     return this.clients.filter(function(client) {
        //       return client.program === "Adult Fitness"
        //     })
        //   } else if (this.filterStatus === "Athlete Performance") {
        //     return this.clients.filter(function(client) {
        //       return client.program === "Athlete Performance"
        //     })
        //   }
        // }
      },
      methods: {
        async getClients() {
          await db.collection('athletes').orderBy('last_name', 'asc').get().then(
            querySnapshot => {
              querySnapshot.forEach(doc => {

                const athlete = {
                  id: doc.id,
                  first_name: doc.data().first_name,
                  last_name: doc.data().last_name,
                  email: doc.data().email,
                  phone_number: doc.data().phone_number,
                  is_active: doc.data().is_active,
                  signup_date: doc.data().signup_date.toDate()
                }

                this.athletes.push(athlete)
              })
            });

          this.pageLoaded = true;

        }
      },
      created() {
        this.getClients()
      }
    }
</script>

<style scoped>

  /*--- TOOLBAR STYLES ---*/

  .arrow-icon {
    transition: 0.23s all ease-in-out;
  }
  .arrow-rotate {
    transition: 0.23s all ease-in-out;
    transform: rotate(180deg);
  }

  .filter-status-icon {
    margin-right: 0.5em;
  }
  .filter-status-icon .fa-check-circle {
    color: #2c9fe5;
  }
  .filter-status-icon .fa-circle {
    color: #bbc0c4;
  }



</style>

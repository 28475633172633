<template>
    <div>

      <div class="tile">
        <div class="toolbar-wrapper">
          <div class="toolbar toolbar-centered max-width-800">
            <div class="toolbar-section">
              <h2 class="has-text-left">Personal Information</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="tile">
        <div class="tile is-parent is-centered">
          <div class="tile is-child box max-width-800">

            <div class="columns">

              <div class="column">

                <div class="field">
                  <div class="label-container">
                    <label class="label">First Name</label>
                    <p class="validation-error">{{ errors.first_name }}</p>
                  </div>
                  <div class="control">
                    <input v-model="addMemberData.first_name" type="text" placeholder="John" required>
                  </div>
                </div>

                <div class="field">
                  <div class="label-container">
                    <label class="label">Last Name</label>
                    <p class="validation-error">{{ errors.last_name }}</p>
                  </div>
                  <div class="control">
                    <input v-model="addMemberData.last_name" type="text" placeholder="Doe" required>
                  </div>
                </div>

                <div class="field">
                  <div class="label-container">
                    <label class="label">Email</label>
                    <p class="validation-error">{{ errors.email }}</p>
                  </div>
                  <div class="control">
                    <input v-model="addMemberData.email" type="email" placeholder="example@me.com" required>
                  </div>
                </div>

                <div class="field">
                  <div class="label-container">
                    <label class="label">Phone Number</label>
                    <p class="validation-error">{{ errors.phone_number }}</p>
                  </div>
                  <div class="control">
                    <input v-model="phone_number_input" v-mask="'(###) ###-####'" type="tel" placeholder="(701) 555-0100" maxlength="14">
                  </div>
                </div>

                <div class="field">
                  <label class="label">Notes</label>
                  <textarea v-model="addMemberData.notes" class="textarea"></textarea>
                </div>

              </div>

            </div>

          </div>
        </div>
      </div>

      <div class="tile">
        <div class="toolbar-wrapper">
          <div class="toolbar toolbar-centered max-width-800">
            <div class="toolbar-section">
              <h2 class="has-text-left">Program Information</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="tile">
        <div class="tile is-parent is-centered">
          <div class="tile is-child box max-width-800">

            <!--PROGRAM PLAN-->
            <div class="field">
              <div class="label-container">
                <label class="label">Membership Type</label>
                <p class="validation-error">{{ errors.membership_type }}</p>
              </div>

              <div class="program-item-wrapper">
                <div @click="addMemberData.membership_type = 'Unlimited'" v-bind:class="{'selected': addMemberData.membership_type === 'Unlimited'}" class="program-item">
                  <p>Unlimited</p>
                </div>

                <div @click="addMemberData.membership_type = '3-Day'" v-bind:class="{'selected': addMemberData.membership_type === '3-Day'}" class="program-item">
                  <p>3-Day</p>
                </div>

                <div @click="addMemberData.membership_type = '2-Day'" v-bind:class="{'selected': addMemberData.membership_type === '2-Day'}" class="program-item">
                  <p>2-Day</p>
                </div>

                <div @click="addMemberData.membership_type = 'Punchcard'" v-bind:class="{'selected': addMemberData.membership_type === 'Punchcard'}" class="program-item">
                  <p>Punchcard</p>
                </div>
              </div>

              <div class="program-item-wrapper" v-if="addMemberData.program === 'Athlete Performance'">
                <div class="program-item disabled">Not available for Athlete Performance</div>
              </div>

            </div>

            <!--PUNCHCARD COUNT-->
            <div class="field" v-if="addMemberData.membership_type === 'Punchcard'">
              <label class="label">Punchard Count</label>

              <div class="program-item-wrapper" v-if="addMemberData.membership_type === 'Punchcard'">
                <div class="control">
                  <input v-model.number="addMemberData.punchcard_count" type="number" placeholder="0" required>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="tile">
        <div class="toolbar-wrapper">
          <div class="toolbar toolbar-centered max-width-800">
            <div class="toolbar-section"></div>

            <div class="toolbar-section">
              <router-link to="/members" class="button-light">Cancel</router-link>
              <button @click="validateNewMember" class="button-dark ml-4">Add Member</button>
            </div>
          </div>
        </div>
      </div>

    </div>
</template>

<script>
  import {db} from './../../firebase/firebaseInit'
  import numeral from 'numeral'

  export default {
        name: "NewMember",
        data: function() {
          return {
            phone_number_input: '',
            addMemberData: {
              is_active: true,
              first_name: '',
              last_name: '',
              email: '',
              phone_number: '',
              membership_type: '',
              punchcard_count: null,
              signup_date: null,
              notes: '',
              sessions_completed: {
                in_person: 0,
                online: 0
              },
              goals: {
                weekly: 3,
                monthly: 12
              },
              customer_id: '',
              date_of_birth: null,
            },
            formValidated: false,
            errors: {
              first_name: '',
              last_name: '',
              email: '',
              phone_number: '',
              membership_type: ''
            }
          }
        },
        filters: {
          paymentFilter: function(value) {
            return numeral(value).format('$,0.00')
          }
        },
        computed: {

        },
        methods: {

          createNewMember: function() {
            this.addMemberData.signup_date = new Date();
            db.collection("clients").add(this.addMemberData)
              .then(docRef => {
                this.$router.push({name: 'Member Detail', params: {member_id: docRef.id}})
                this.submissionSuccess()
            })
              .catch(function(error) {
                console.log("Error while attempting to add document:", error)
              })
          },

          validateNewMember: function() {
            this.errors.first_name = this.addMemberData.first_name ? '' : 'first name required.';
            this.errors.last_name = this.addMemberData.last_name ? '' : 'last name required.'
            this.errors.email = this.addMemberData.email ? '' : 'email required.';
            this.errors.phone_number = this.addMemberData.phone_number ? '' : 'phone number required.';
            this.errors.membership_type = this.addMemberData.membership_type ? '' : 'program plan required.'

            if (
              !this.errors.first_name &&
              !this.errors.last_name &&
              !this.errors.email &&
              !this.errors.phone_number &&
              !this.errors.membership_type
            ) {
              this.createNewMember()
            } else {
              this.submissionFailure();
            }
          },

          submissionFailure: function() {
            this.$notify({
              group: 'master',
              title: 'Missing Info',
              text: 'Please fill out all required information.',
              type: 'error'
            })
          },
          submissionSuccess: function() {
            this.$notify({
              group: 'master',
              title: 'Member Added',
              text: this.addMemberData.first_name + ' ' + this.addMemberData.last_name + ' was successfully added.',
              type: 'success'
            })
          }
        },
        watch: {
          'addMemberData.membership_type': function() {
            if (this.addMemberData.membership_type !== 'Punchcard') {
              this.addMemberData.punchcard_count = null;
            } else if (this.addMemberData.membership_type === 'Punchcard') {
              this.addMemberData.punchcard_count = 10;
            }
          },
          'phone_number_input': function() {
            return this.addMemberData.phone_number = this.phone_number_input.replace(/\D+/g, "")
          }
        }
    }
</script>

<style scoped>

  /*---- FORM STYLE ----*/

  .label-wrapper {
    display: flex;
    flex-direction: row;
  }
  .program-item {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    margin: 0 0 0.75em 0;
    padding: 0.5em;
    background-color: #fbfbfb;
    border: 1px solid #c8ccd0;
    border-radius: 4px;

    transition: 0.2s all ease-in-out;
  }
  .program-item:hover {
    border: 1px solid #9299a0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  .program-item.selected {
    background-color: #ffffff;
    border: 1px solid #2c9fe5;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  .program-item p {
    /*text-align: left;*/
    color: #9299a0;
    font-size: 16px;
    font-weight: 400;
  }
  .program-item.selected p {
    /*font-weight: 600;*/
    color: #123968;
  }
  .program-item.disabled {
    cursor: not-allowed;
    color: #c6c8ca;
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
  }
  .program-item.disabled:hover {
    box-shadow: none;
  }
  .program-item-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .program-item-wrapper .program-item {
    margin-right: 0.75em;
    padding: 0.5em 0.75em;
  }

  .payment-wrapper {
    background: linear-gradient(300deg, rgba(18, 57, 104, 0.7) 0%, rgba(18, 57, 104, 0.9) 100%);
    margin-top: 2em!important;
    padding-top: 1em;
    padding-bottom: 1em;
    /*box-shadow: none;*/
  }
  .payment-wrapper .columns:not(last-child) {
    margin-bottom: 0;
  }
  .payment-underline {
    width: 100%;
    margin-bottom: 1em;
    height: 1px;
    /*background: linear-gradient(to right, #ffffff, rgba(255,255,255,0));*/
    background-color: #c6c8ca;
  }
  .payment-wrapper h3 {
    color: #ffffff;
  }
  .payment-wrapper p {
    color: #ffffff;
    font-size: 18px;
  }
  .payment-wrapper .column-header {
    margin: 0 0 0.5em 0.7em;
  }
  .payment-wrapper .label {
    color: #ffffff;
    padding: 0;
  }
  .payment-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background: rgba(18, 57, 104, 0.05);
    padding: 0.7em 1em;
    border-radius: 3px;
  }
  .payment-label-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .payment-label-section:first-child {
    text-align: left;
  }
  .payment-label-section:last-child {
    text-align: right;
  }
  .payment-label h5 {
    font-size: 16px;
    font-weight: 700;
    color: #123968;
  }
  .payment-label p {
    font-size: 16px;
    color: #5d646b;
  }
  .payment-info-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 1em 0;
  }
  .clear-program {
    color: rgba(18,57,104,0.8);
    transition: all 0.2s ease;
  }
  .clear-program:hover {
    color: #123968;
    cursor: pointer;
  }
  .payment-input {
    font-size: 14px;
    width: 50px;
    text-align: right;
    margin-bottom: 0;
    padding: 5px;
    border-radius: 4px;
  }
  .payment-input:focus {
    box-shadow: none;
  }
  .is-bold {
    font-weight: 800;
  }

  @media (min-width: 768px) {

    .payment-wrapper {
      /*padding-top: 2em;*/
      /*padding-bottom: 2em;*/
    }

  }



</style>

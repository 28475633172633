<template>
    <div class="admin">

      <div class="titlebar">

        <div class="titlebar-content">

          <div class="titlebar-item">

            <span class="hamburger" @click="sideMenuOpen = !sideMenuOpen">
              <span class="hamburger-bar"/>
              <span class="hamburger-bar"/>
              <span class="hamburger-bar"/>
            </span>

            <div class="rise-logo">
              <img src="./../assets/logo_4C.png" alt="">
            </div>
            <div class="vertical-divider" v-if="window_width > 768"></div>
            <h3 class="admin-title" v-if="window_width > 768">Admin</h3>

          </div>

          <div class="titlebar-item">

            <Search/>


            <div class="dropdown is-active is-right" v-on-clickaway="closeDropdownMenu">

              <div class="dropdown-trigger">
                <button class="account-button" :class="{'button-active': dropdownMenuOpen}" @click="dropdownMenuOpen = !dropdownMenuOpen">
                  <i class="far fa-user-circle" style="margin-right: 0.25rem;"/>
                  <span class="icon is-small"><i v-bind:class="{'arrow-rotate': dropdownMenuOpen}" class="fas fa-angle-down arrow-icon"/></span>
                </button>
              </div>

              <div v-bind:class="{'menu-open': dropdownMenuOpen}" class="dropdown-menu" id="dropdown-menu" role="menu">
                <div class="dropdown-content is-selectable">
                  <div class="not-selectable">
                    <h6 class="is-semibold has-text-left">{{ $store.state.user.first_name }} {{ $store.state.user.last_name }}</h6>
                    <p class="is-small">{{ $store.state.user.email }}</p>
                  </div>
                  <hr class="dropdown-divider">
                  <div class="dropdown-item font-primary-blue">Profile</div>
                  <div class="dropdown-item font-primary-blue" @click="signOut">Sign Out</div>
                </div>
              </div>

            </div>

          </div>

        </div>

      </div>

      <div class="body-container">

        <div class="tile">

          <Navbar class="side-menu" :class="{'menu-open': sideMenuOpen}"/>

          <transition enter-active-class="animated fadeIn faster" leave-active-class="animated fadeOut faster" mode="out-in">
            <div class="overlay-global" style="z-index: 9;" v-if="sideMenuOpen" @click="sideMenuOpen = false">
              <div class="overlay-background-transparent"></div>
            </div>
          </transition>

          <div class="tile is-parent">
            <div class="tile is-child">
              <div class="main-content">

                <transition name="fade" mode="out-in">
                  <router-view class="content-body" :key="$route.name + ($route.params.member_id || '')"/>
                </transition>

                <notifications group="master" class="notification-style"/>

              </div>
            </div>
          </div>


        </div>

      </div>

      <transition enter-active-class="animated fadeIn faster" leave-active-class="animated fadeOut faster" mode="out-in">
        <div class="overlay-global" v-if="fetchingUserData">
          <div class="overlay-background">
            <div class="loading-spinner margin-reset"></div>
            <p style="color: #5d646b;">Loading...</p>
          </div>
        </div>
      </transition>


    </div>
</template>

<script>
  import firebase from 'firebase/app'
  import Navbar from './../components/Navbar'
  import Search from './../components/Search'
  import { mixin as clickaway } from 'vue-clickaway';
  import { db } from '../../firebase/firebaseInit'

  export default {
    name: 'Admin',
    components: {
      Navbar,
      Search
    },
    mixins: [ clickaway ],
    data: function() {
      return {
        pageTitle: this.$route.name,
        notification: false,
        dropdownMenuOpen: false,
        sideMenuOpen: false,
        fetchingUserData: false,
        window_width: Number(),
        unread_messages: Number(0)
      }
    },
    filters: {
      emailLogoInitialsFilter(value) {
        if (typeof value !== 'undefined') {
          return value.charAt(0)
        }
      }
    },
    computed: {
    },
    methods: {
      windowWidth() {
        this.window_width = window.innerWidth
        if (this.window_width > 1216) {
          this.sideMenuOpen = false
        }
      },

      closeDropdownMenu: function() {
        this.dropdownMenuOpen = false
      },

      getUnreadMessages() {

        db.collection('smsmessages')
          .where('read_at', '==', null)
          .onSnapshot(querySnap => {
            this.$store.commit('updateUnreadMessages', querySnap.size);
          });
      },

      signOut: function() {
        firebase.auth().signOut().then(() => {
          this.$store.commit('clearTeamMemberData');
          this.$router.push('/login')
        })
      }

    },
    async created() {

      this.fetchingUserData = true;

      await this.$store.dispatch('fetchTeamMemberData', {
        uid: firebase.auth().currentUser.uid,
        email: firebase.auth().currentUser.email
      });

      this.fetchingUserData = false;

      await this.getUnreadMessages();

      },
    mounted() {
      this.windowWidth()
      window.addEventListener('resize', this.windowWidth)
    },
    destroyed() {
      window.removeEventListener('resize', this.windowWidth)
    },
    watch: {
      '$route': function() {
        this.pageTitle = this.$route.name;
        this.sideMenuOpen = false;
      }
    }
  }
</script>

<style scoped>
  .admin {
    display: flex;
    /*background: #f5f7f9;*/
    background: #f7f9fb;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
  }

  /*---- NAV BAR ----*/

  .titlebar {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 55px;
    padding: 0 1rem;
    position: fixed;
    z-index: 9999;
    top: 0;
    background: #ffffff;
    border-bottom: 1px solid #e4e7ea;
    /*box-shadow: rgba(0, 0, 0, 0.05) 0 4px 10px;*/
  }
  .titlebar-content {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
  }
  .titlebar-item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .hamburger {
    font-size: 20px;
    color: #123968;
    cursor: pointer;
    margin: 0 0.2rem 0 0;
    padding: 9px 11px;
    border-radius: 30px;
    transition: 0.23s all ease;
  }
  .hamburger:hover {
    background: #f5f8fb;
  }
  .hamburger-bar {
    display: block;
    width: 20px;
    height: 2px;
    /*border-radius: 1px;*/
    margin: 0.2em 0;
    background-color: #2c3e50;
  }

  .rise-logo {
    width: 80px;
  }
  .rise-logo img {
    vertical-align: bottom;
  }
  .vertical-divider {
    width: 1px;
    height: 25px;
    background-color: #c6c8ca;
    margin-left: 10px;
    margin-right: 10px;
  }
  .admin-title {
    color: #2f3539;
    font-weight: 400;
    line-height: 1;
  }
  .dropdown-trigger {
    display: flex;
    align-items: center;
    margin: 0 0.5rem;
  }
  .dropdown-trigger:hover {
    cursor: pointer;
  }
  .account-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f2f6ff;
    border-radius: 50%;
    height: 35px;
    width: 35px;
  }
  .account-logo:hover {
    cursor: pointer;
  }
  .account-logo p {
    color: #2c9fe5;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
  }

  .account-button {
    font-family: 'Proxima Nova', sans-serif;
    background: #eceff3;
    color: #0b2441;
    font-size: 16px;
    font-weight: 600;
    /*border: 1px solid #c8ccd0;*/
    border: none;
    border-radius: 6px;
    margin-left: 0.5rem;
    padding: 0.5rem 0.75rem;
    display: inline-flex;
    flex-wrap: nowrap;
    height: 34px;
    transition: all 0.2s ease;
  }
  .account-button:focus {
    outline: none;
  }
  .account-button:hover {
    cursor: pointer;
    outline: none;
    /*background: #f2f6ff;*/
    /*color: #2c9fe5;*/
    filter: brightness(0.95);
  }
  .account-button:active {
    filter: brightness(0.92);
    border: none;
    outline: none;
  }

  .arrow-icon {
    transition: 0.23s all ease-in-out;
  }
  .arrow-rotate {
    transition: 0.23s all ease-in-out;
    transform: rotate(180deg);
  }


  /*---- MAIN CONTENT VIEW ----*/

  .body-container {
    margin-top: 55px;
  }

  .side-menu-container {
    max-width: 300px;
    position: sticky;
    /*background: linear-gradient(130deg, #123968 0%, #0b2441 70%);*/
  }

  .side-menu {
    z-index: 999;
    position: fixed;
    width: 275px;
    height: calc(100vh - 55px);
    /*background: linear-gradient(to bottom, #ffffff 0%, #f0f4f7 100%);*/
    background: #ffffff;
    border-right: 1px solid #e4e7ea;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-left: -275px;
    overscroll-behavior: contain;

    transition: 0.23s margin-left ease;
  }
  .side-menu.menu-open {
    margin-left: 0;
  }

  .main-content {
    position: relative;
    min-width: 320px;
    flex-direction: column;
    flex-grow: 1;
    padding-bottom: 1.5rem;
  }

  .content-body {
    margin: 0 auto;
    max-width: 1400px;
  }
  p.user-email {
    font-weight: 300;
    color: #778088;
    display: none;
  }



  @media (min-width: 1216px) {

    .admin {
      display: flex;
      /*width: 100%;*/
      /*overflow: scroll;*/
    }
    .side-menu {
      width: 275px;
      margin-left: 0;
    }
    .main-content {
      margin-left: 275px;
    }

    .hamburger {
      display: none;
    }


    /*p.user-email {*/
      /*display: inherit;*/
    /*}*/
  }

</style>

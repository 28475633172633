<template>
  <div class="message-container">

    <div class="conversation-list">

      <div class="tile">
        <div class="toolbar-wrapper">
          <div class="toolbar">

            <div class="toolbar-section">
              <h2>Messages</h2>
            </div>

            <div class="toolbar-section">

              <div class="dropdown is-active is-right" v-on-clickaway="closeNewConversationModal">

                <div class="dropdown-trigger">
                  <button @click="newConversationModalOpen = !newConversationModalOpen" class="button primary-action-button" :class="{'button-active': newConversationModalOpen}"><i class="fas fa-plus"/></button>
                </div>

                <div class="dropdown-menu" :class="{'menu-open': newConversationModalOpen}" style="margin-right: -2px; width: 317px;">
                  <div class="dropdown-content is-selectable" style="padding: 1rem;">

                    <div class="field" style="max-width: 350px;">
                      <label class="label">To:</label>
                      <div class="control" style="display: flex; flex-direction: row;">
                        <input v-model="phone_number_input" v-mask="'(###) ###-####'" type="tel" placeholder="(218) 555-0100" maxlength="14">
                        <button @click="createNewConversation(new_conversation_number)" class="button-light" style="padding: 12px 18px; margin-left: 0.75rem;">Go</button>
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>
      </div>

      <div style="margin: 0.75rem 0.75rem 1.5rem;">
        <input v-model="conversationSearch" type="text" placeholder="Search">
      </div>

      <div class="loading-spinner" v-if="!conversationsLoaded"></div>

      <ul class="conversations animated fadeIn" v-if="conversationsLoaded">
        <li v-for="conversation in filteredConversations"
            :key="conversation.id"
            class="conversation-item animated fadeIn"
            :class="{'selected-conversation': selected_conversation_id === conversation.id}"
            @click="selected_conversation_id = conversation.id">

          <div class="conversation-item-content">

            <div class="conversation-item-title">

              <div style="display: flex; align-items: center;">

                <i class="fas fa-circle" style="font-size: 6px; color: #2c9fe5; margin-right: 0.5rem;" v-if="conversation.last_modified > conversation.last_read_at"/>

                <p v-if="conversation.member_name" class="conversation-name">{{ conversation.member_name }}</p>
                <p v-else-if="conversation.name" class="conversation-name">{{ conversation.name }}</p>
                <p v-else class="conversation-name">{{ conversation.phone_number | phoneNumberFilter }}</p>

              </div>

              <p class="is-small">{{ conversation.last_modified | conversationTimeFilter }}</p>

            </div>

            <p class="conversation-body is-small has-text-left"
               :class="{'unread-message': conversation.last_modified > conversation.last_read_at}">{{ conversation.latest_message ? conversation.latest_message : '&nbsp;'}}</p>

          </div>


        </li>

        <button @click="shownConversations += 20" class="button-light" style="width: 100%; display: flex; justify-content: center; margin-top: 1rem;">See More</button>

      </ul>

    </div>

    <div class="conversation-container">

      <template v-if="!selected_conversation_id">
        <h2 class="empty-state">No conversation selected.</h2>
      </template>

      <template v-if="selected_conversation_id">

        <div class="tile animated fadeIn" style="flex-grow: 0;">
          <div class="toolbar-wrapper">
            <div class="toolbar">

              <div class="toolbar-section">
                <h2>{{ conversationName | phoneNumberFilter }}</h2>
              </div>

              <div class="toolbar-section">
                <button class="button primary-action-button"><i class="fas fa-ellipsis-h"/></button>
              </div>

            </div>
          </div>
        </div>

        <div class="tile animated fadeIn">
          <div class="tile is-parent">
            <div class="tile is-child box" @scroll="calculateScrollPosition" id="messages-container">

              <template v-for="message in conversation_messages">

                <!-- message timestamp -->
                <p class="is-small"
                   v-if="setLatestTimestamp(message)"
                   :class="{'message-received-timestamp': message.from !== twilio_sms_number, 'message-sent-timestamp': message.from === twilio_sms_number}">{{ message.sent_at | messageTimeFilter }}</p>

                <!-- message -->
                <p class="message animated fadeIn faster"
                   :key="message.id"
                   :class="{'message-received': message.from !== twilio_sms_number, 'message-sent': message.from === twilio_sms_number}">{{ message.body }}</p>

                <!-- message date -->
                <p class="is-small" style="margin: 0.5rem 0;" v-if="setMessageDateLabel(message)">{{ message.sent_at | messageDateFilter }}</p>

              </template>


              <div style="height: 1.5rem;">&nbsp;</div>

            </div>
          </div>
        </div>

        <div class="message-input-container">

          <textarea class="textarea" id="message-input"
                    v-model="new_message_body"
                    :disabled="submitStatus"
                    placeholder="Text Message"/>

            <p class="helper-text"><span class="has-text-weight-bold">Return</span> add a new line &nbsp;&nbsp;&nbsp; <span class="has-text-weight-bold">Tab → Return</span> to send</p>

            <button class="button-dark"
                    @click="sendSmsMessage"
                    :class="{'is-loading': submitStatus}"
                    :disabled="submitStatus"
                    style="padding-top: 10px; padding-bottom: 10px; position: absolute; right: 1.75rem; bottom: 3rem; border-radius: 6px;">
<!--              <i class="fas fa-paper-plane"></i>-->
              Send
            </button>

        </div>

      </template>

    </div>

  </div>
</template>

<script>
  import {db, functions} from "../../firebase/firebaseInit";
  import { format, isSameDay, isToday, isYesterday } from 'date-fns';
  import {mixin as clickaway} from 'vue-clickaway';
  import MemberSearchInput from "@/components/MemberSearchInput";

  export default {
    name: 'Messages',
    components: { MemberSearchInput },
    mixins: [ clickaway ],
    data() {
        return {
          conversationsLoaded: false,
          submitStatus: false,
          shownConversations: 20,
          conversationSearch: '',

          newConversationModalOpen: false,
          phone_number_input: '',
          new_conversation_number: '',

          conversations: [],

          selected_conversation_id: '',
          selected_conversation_phone_number: null,
          conversation_name: '',
          conversation_messages: [],

          twilio_sms_number: process.env.VUE_APP_TWILIO_SMS_NUMBER,
          new_message_body: '',

          messageContainerScroll: '',
          unsubscribe: null
        }
    },
    filters: {
      conversationTimeFilter(value) {
        return isToday(value) ? format(value, 'h:mm a') : isYesterday(value) ? 'Yesterday' : format(value, 'M/d/y');
      },
      messageTimeFilter(value) {
        return format(value, 'h:mm a');
      },
      messageDateFilter(value) {
        return isToday(value) ? `Today, ${format(value, 'MMMM d')}` : isYesterday(value) ? `Yesterday, ${format(value, 'MMMM d')}` : format(value, 'EEEE, MMMM d');
      },
      phoneNumberFilter(value) {
        return value.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
      }
    },
    computed: {
      conversationName() {
        let index = this.conversations.findIndex(conversation => conversation.id === this.selected_conversation_id);
        return this.conversations[index].member_name ? this.conversations[index].member_name : this.conversations[index].name ? this.conversations[index].name : this.conversations[index].phone_number;

      },

      filteredConversations() {

        if (this.conversationSearch) {
          return this.conversations.filter(
            conversation =>
              conversation.member_name && conversation.member_name.toLowerCase().includes(this.conversationSearch.toLowerCase()) ||
              conversation.name && conversation.name.toLowerCase().includes(this.conversationSearch.toLowerCase()) ||
              conversation.phone_number.includes(this.conversationSearch)
          )
        } else {
          return this.conversations.filter((conversation, index) => index < this.shownConversations)
          // return this.conversations
        }

      }
    },
    methods: {

      closeNewConversationModal() {
        this.newConversationModalOpen = false;
      },

      async createNewConversation(phone_number, name) {

        const date = new Date();

        if (phone_number) {

          try {

            await db.collection('smsconversations')
              .where('phone_number', '==', phone_number)
              .get().then(querySnap => {

                if (!querySnap.empty) {

                  this.selected_conversation_id = querySnap.docs[0].id;

                } else {

                  let conversationData = {
                    phone_number: phone_number,
                    name: name ? name : '',
                    last_modified: date,
                    last_read_at: date,
                    latest_message: ''
                  };

                  db.collection('smsconversations').add(conversationData).then(doc => {
                    conversationData.id = doc.id;
                    conversationData.member_name = null;
                    conversationData.member_id = null;
                    this.conversations.unshift(conversationData);
                    this.selected_conversation_id = doc.id;
                  });

                }
              })

            this.newConversationModalOpen = false;


          } catch (err) {
            console.log(err.message)
          }

        } else {
          console.error('Phone number must be a valid value.')
        }



      },

      getConversations() {

        db.collection('smsconversations')
          .orderBy('last_modified', 'desc')
          .onSnapshot(async querySnap => {

            let conversations = [];

            for await (const doc of querySnap.docs) {

              const index = this.conversations.findIndex(conversation => conversation.id === doc.id);
              console.log(index)

              const conversationData = {
                id: doc.id,
                phone_number: doc.data().phone_number,
                name: doc.data().name,
                last_read_at: doc.data().last_read_at ? doc.data().last_read_at.toDate() : null,
                last_modified: doc.data().last_modified ? doc.data().last_modified.toDate() : null,
                latest_message: doc.data().latest_message ? doc.data().latest_message : '',
                member_name: index < 0 ? null : this.conversations[index].member_name,
                member_id: index < 0 ? null : this.conversations[index].member_id,
              };

              // await db.collection('clients')
              //   .where('phone_number', '==', doc.data().phone_number).get().then(querySnap => {
              //     if (!querySnap.empty) {
              //       conversationData.member_name = `${querySnap.docs[0].data().first_name} ${querySnap.docs[0].data().last_name}`;
              //       conversationData.member_id = querySnap.docs[0].id;
              //     }
              //   });

              // await db.collection('smsmessages')
              //   .where('conversation_id', '==', conversationData.id)
              //   .orderBy('sent_at', 'desc').limit(1).get().then(querySnap => {
              //     if (!querySnap.empty) {
              //       conversationData.latest_message = querySnap.docs[0].data().body;
              //     }
              //   });

              conversations.push(conversationData);

            }

            this.conversations = conversations;
            this.conversationsLoaded = true;

            querySnap.docChanges().forEach(change => {
              if (change.type === 'added') {
                // console.log(`Get NAME! => ${change.doc.id}`);
                this.getConversationNameAndId(change.doc.id, change.doc.data().phone_number);
              }
            })

          });


      },

      getConversationNameAndId(id, phone_number) {

        // console.log(this.conversations[0])
        const index = this.conversations.findIndex(conversation => conversation.id === id);

        db.collection('clients')
          .where('phone_number', '==', phone_number).get().then(querySnap => {
            if (!querySnap.empty) {
              this.conversations[index].member_name = `${querySnap.docs[0].data().first_name} ${querySnap.docs[0].data().last_name}`;
              this.conversations[index].member_id = querySnap.docs[0].id;
              // console.log(index)
              // console.log(this.conversations[index])
              console.log(querySnap.docs[0].data().first_name)
            }
          });

      },



      async getConversationMessages() {
        this.conversation_messages = [];

        this.unsubscribe = db.collection('smsmessages')
          .where('conversation_id', '==', this.selected_conversation_id)
          .orderBy('sent_at', 'desc').onSnapshot(querySnap => {

            let messagesArray = [];

            querySnap.forEach(doc => {

              const messageData = {
                id: doc.id,
                conversation_id: doc.data().conversation_id,
                to: doc.data().to,
                from: doc.data().from,
                body: doc.data().body,
                sent_at: doc.data().sent_at.toDate()
              };

              messagesArray.push(messageData);

            });

            this.conversation_messages = messagesArray;
            this.onConversationOpen();

          });

      },

      onConversationOpen() {

        const date = new Date();

        db.collection('smsconversations').doc(this.selected_conversation_id).update({
          last_read_at: new Date()
        }).catch(err => {
          console.log(err.message)
        })

        db.collection('smsmessages')
          .where('conversation_id', '==', this.selected_conversation_id)
          .where('read_at', '==', null).get().then(querySnap => {
            querySnap.forEach(doc => {

              db.collection('smsmessages').doc(doc.id).update({
                'read_at': date
              })

            })
        }).catch(err => {
          console.log(err.message)
        })

      },

      unsubscribeFromMessages() {
        typeof this.unsubscribe === 'function' ? this.unsubscribe() : null;
      },

      async sendSmsMessage() {


        if (this.new_message_body) {

          this.submitStatus = true;

          try {

            await functions.httpsCallable('sendSmsMessage')({
              to: this.selected_conversation_phone_number,
              from: process.env.VUE_APP_TWILIO_SMS_NUMBER,
              body: this.new_message_body
            }).then(async result => {

              console.log(result.data);

              const messageRef = db.collection('smsmessages').doc();
              const conversationRef = db.collection('smsconversations').doc(this.selected_conversation_id);
              const batch = db.batch();
              const date = new Date();

              batch.set(messageRef, {
                conversation_id: this.selected_conversation_id,
                to: this.selected_conversation_phone_number,
                from: process.env.VUE_APP_TWILIO_SMS_NUMBER,
                body: this.new_message_body,
                sent_at: date,
                read_at: date
              });

              batch.update(conversationRef, {
                last_modified: date,
                latest_message: this.new_message_body
              });

              await batch.commit();

              this.new_message_body = '';
              this.submitStatus = false;

            });

          } catch (err) {

            console.error(err);
            this.submitStatus = false;

          }

        }

      },

      calculateScrollPosition() {

        this.messageContainerScroll = document.getElementById('messages-container').scrollTop

      },

      setLatestTimestamp(message) {

        if (message.to === process.env.VUE_APP_TWILIO_SMS_NUMBER) {

          const latestReceivedMessage = this.conversation_messages.find(message => message.to === process.env.VUE_APP_TWILIO_SMS_NUMBER);
          return message.id === latestReceivedMessage.id;

        } else if (message.from === process.env.VUE_APP_TWILIO_SMS_NUMBER) {

          const latestSentMessage = this.conversation_messages.find(message => message.from === process.env.VUE_APP_TWILIO_SMS_NUMBER);
          return message.id === latestSentMessage.id;

        }

      },

      setMessageDateLabel(currentMessage) {

        const firstMessageOfDay = this.conversation_messages.slice().reverse().find(message => isSameDay(message.sent_at, currentMessage.sent_at));
        return currentMessage.id === firstMessageOfDay.id;

      }


    },
    mounted() {

    },
    created() {

      this.getConversations();

    },
    watch: {
      'selected_conversation_id': async function(newVal, oldVal) {
        if (newVal && !oldVal || newVal && oldVal) {

          let index = this.conversations.findIndex(conversation => conversation.id === newVal);
          this.selected_conversation_phone_number = this.conversations[index].phone_number;

          await this.unsubscribeFromMessages();
          await this.getConversationMessages();

        } else {

          this.selected_conversation_id = '';
          this.selected_conversation_phone_number = null;

        }
      },
      'conversationsLoaded': function(newVal, oldVal) {
        if (newVal && !oldVal) {
          if (this.$route.query.ph) {
            this.createNewConversation(this.$route.query.ph, '')
          }
        }
      },
      'phone_number_input': function() {
        return this.new_conversation_number = this.phone_number_input.replace(/\D+/g, "")
      },
      'messageContainerScroll': function (newVal) {
        if (newVal === 0) {
          console.log('top!')
        }
      }
    },
    destroyed() {
      this.unsubscribeFromMessages();
    }
  }

</script>

<style scoped>

  .message-container {
    display: flex;
    height: 100%;
    max-width: unset;
    flex-grow: 1;
  }

  .conversation-list {
    background: #ffffff;
    border-right: 1px solid #e4e7ea;
    width: 350px;
    /*display: flex;*/
    position: fixed;
    overflow-y: scroll;
    margin: -0.75rem;
    padding: 0.25rem;
    height: 100%;
    min-height: calc(100vh - 55px);
  }

  .conversation-container {
    display: flex;
    flex-direction: column;
    /*flex-grow: 1;*/
    justify-content: center;
    margin-left: 350px;
    margin-top: -0.75rem;
    margin-bottom: -0.75rem;
    max-height: calc(100vh - 80px);
    min-height: calc(100vh - 80px);
    /*height: 100%;*/
    flex: 1 1 0;
    /*flex-shrink: 1;*/
    /*overflow: auto;*/
  }

  ul.conversations {
    margin: 0 0.75rem 4.5rem;
  }

  li.conversation-item {
    width: 100%;
    /*border-top: 1px solid #e4e7ea;*/
    padding: 0.75rem 1rem;
    margin-bottom: 0.25rem;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    transition: 0.2s all ease;
  }
  li.conversation-item:hover {
    cursor: pointer;
    background: #f7f8fa;
  }

  .conversation-item-content {
    overflow: auto;
  }

  .conversation-item-title {
    display: flex;
    justify-content: space-between;
    flex-basis: 100%;
    align-items: center;
  }
  p.conversation-name {
    font-weight: 500;
  }
  li.conversation-item:hover p.conversation-name {
    color: #171a1c;
  }
  p.conversation-body {
    align-self: flex-start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 0.125rem;
  }
  .unread-message {
    font-weight: 500;
    color: #171a1c;
  }

  .selected-conversation {
    background: #f2f6ff!important;
  }
  .selected-conversation p.conversation-name {
    color: #2c9fe5!important;
  }

  #messages-container {
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
    height: calc(100vh - 260px);
    min-height: 0;
  }

  p.message {
    margin: 0.25rem;
    padding: 0.5rem 0.75rem;
    border-radius: 10px;
    max-width: 400px;
    text-align: left;
    white-space: pre-wrap;
  }

  p.message:last-child {
    /*margin-top: 1.5rem;*/
  }

  p.message-received {
    align-self: flex-start;
    background: #eceff3;
    color: #171a1c;
  }
  p.message-sent {
    align-self: flex-end;
    background: #2c9fe5;
    color: #ffffff;
    font-weight: 500;
  }

  p.message-received-timestamp {
    align-self: flex-start;
    font-size: 12px;
    margin-left: 0.5rem;
    margin-bottom: 0.25rem;
  }
  p.message-sent-timestamp {
    align-self: flex-end;
    font-size: 12px;
    margin-right: 0.5rem;
    margin-bottom: 0.25rem;
  }

  .message-input-container {
    display: flex;
    flex-direction: column;
    margin: 0.75rem 0.75rem 0;
  }

  #message-input {
    padding-right: 6rem;
    min-height: 68px;
    border-radius: 8px;
    resize: none;
  }
  #message-input:disabled {
    background: #f7f8fa;
    border: 1px solid #9299a0;
  }

  .helper-text {
    color: #9299a0;
    font-size: 12px;
    line-height: 1;
    align-self: flex-end;
    margin-left: 0.25rem;
  }


</style>

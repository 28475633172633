<template>
    <div>

      <div class="tile">
        <div class="toolbar-wrapper">
          <div class="toolbar">

            <div class="toolbar-section">
              <h2>Sessions</h2>
            </div>

            <div class="toolbar-section">

              <div class="dropdown is-active is-right" v-on-clickaway="closeDateSelectorModal">

                <div class="dropdown-trigger">
                  <button class="button button-outline margin-left" @click="dateSelectorModal = !dateSelectorModal" :class="{'button-active': dateSelectorModal}">
                    {{ selected_week[0] | monthDayFilter }} - {{ selected_week[6] | monthDayFilter }} &nbsp;
                    <i class="fas fa-chevron-down" style="font-size: 12px; transition: 0.2s all ease;" :class="{'arrow-rotate': dateSelectorModal}"/>
                  </button>
                </div>

                <div class="dropdown-menu" :class="{'menu-open': dateSelectorModal}">
                  <v-date-picker v-model="selected_day" is-inline/>
                </div>

              </div>

              <button @click="goToPreviousWeek" :disabled="!pageLoaded" class="button primary-action-button margin-left"><i class="fas fa-chevron-left"/></button>
              <button @click="goToNextWeek" :disabled="!pageLoaded" class="button primary-action-button margin-left"><i class="fas fa-chevron-right"/></button>

<!--              <router-link to="/sessions/newsession" class="button primary-action-button margin-left"><span class="iconify text-lg" data-icon="akar-icons:gear" data-inline="false"></span></router-link>-->
<!--              <router-link to="/sessions/newsession" class="button-dark margin-left">New Session</router-link>-->


            </div>

          </div>
        </div>
      </div>

      <div class="tile">
        <div class="tile is-parent">
          <div class="tile is-child box">

            <div class="loading-spinner" v-if="!pageLoaded"></div>

            <div class="table-container" v-if="pageLoaded">
              <table class="table is-fullwidth animated fadeIn">
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Start Time</th>
                  <th>Duration</th>
                  <th>Spots</th>
                  <th class="has-text-right">Status</th>
                </tr>
                </thead>

                <template v-for="day in selectedWeekSessions">

                  <thead>
                    <tr>
                      <td class="table-header" colspan="4">{{ day.date | dateFilter }}</td>
                      <td class="table-header has-text-right">
                        <button class="add-session-button" @click="openNewSessionMenu(day.date)">
                          <i class="fas fa-plus" style="font-size: 11px;"/>&nbsp;New
                        </button>
                      </td>
                    </tr>
                  </thead>

                  <tbody>

                  <router-link v-for="session in day.sessions" v-bind:key="session.id" :to="{name: 'Session Detail', params: {session_id: session.id}}" class="is-selectable" tag="tr">
                    <td class="font-primary-blue">{{ session.name }}</td>
                    <td class="font-secondary-blue">{{ session.start_date | timeOfDayFilter }}</td>
                    <td>{{ session.duration_in_minutes }} min.</td>
                    <td>{{ session.registrations }}/{{ session.total_spots }} reserved</td>
                    <td class="has-text-right">
                      <span v-if="session.registration_open && session.start_date > new Date()" class="status-label is-small light-blue-label">Open</span>
                      <span v-if="!session.registration_open && session.start_date > new Date()" class="status-label is-small danger-label">Closed</span>
                      <span v-if="session.start_date < new Date()" class="status-label is-small gray-label">Past</span>
                    </td>
                  </router-link>

                  <tr v-if="day.sessions.length === 0">
                    <td style="color: #c6c8ca;" colspan="5">No sessions found.</td>
                  </tr>

                  </tbody>

                </template>
              </table>
            </div>

          </div>
        </div>
      </div>

      <NewSessionSideMenu :new-session-menu-open="newSessionMenuOpen"
                          :start-date-input="new_session_date"
                          @close="closeNewSessionMenu"
                          @reload="getSessionsForCurrentWeek"/>

    </div>
</template>

<script>
  import {db, functions} from './../../firebase/firebaseInit'
  import { format, startOfWeek, endOfWeek, eachDayOfInterval, addDays, subDays, startOfDay, endOfDay, parseISO, isSameDay } from 'date-fns'
  import { mixin as clickaway } from 'vue-clickaway';
  import NewSessionSideMenu from './../components/NewSessionSideMenu';


  export default {
    name: "Sessions",
    components: { NewSessionSideMenu },
    mixins: [ clickaway ],
    data() {
      return {
        pageLoaded: false,
        dropdownMenu: null,
        dateSelectorModal: false,
        newSessionMenuOpen: false,
        new_session_date: null,

        selected_day: null,
        selected_week: [],

        selectedWeekSessions: [],
      }
    },
    filters: {
      monthDayFilter(value) {
        return format(value, 'MMM d');
      },
      dateFilter(value) {
        return format(value, 'EEEE, MMMM d')
      },
      timeOfDayFilter(value) {
        return format(value, 'h:mm a')
      }
    },
    computed: {

    },
    methods: {

      setCurrentWeek(day) {
        const firstDayOfWeek = startOfWeek(day, { weekStartsOn: 1 });
        const lastDayOfWeek = addDays(firstDayOfWeek, 6);

        this.selected_week = eachDayOfInterval({ start: firstDayOfWeek, end: lastDayOfWeek })
      },

      async getSessionsForCurrentWeek() {

        this.pageLoaded = false;
        this.selectedWeekSessions = [];
        await this.setCurrentWeek(this.selected_day);

        const currentWeekSessions = [];

        await db.collection('sessions')
          .where('start_date', '>=', startOfWeek(this.selected_day, {weekStartsOn: 1}))
          .where('start_date', '<=', endOfWeek(this.selected_day, {weekStartsOn: 1}))
          .orderBy('start_date', 'asc').get().then(querySnap => {
            querySnap.forEach(doc => {
              const sessionData = {
                'id': doc.id,
                'name': doc.data().name,
                'start_date': doc.data().start_date.toDate(),
                'duration_in_minutes': doc.data().duration_in_minutes,
                'total_spots': doc.data().total_spots,
                'description': doc.data().description,
                'instructor': doc.data().instructor,
                'registration_open': doc.data().registration_open,
                'registrations': doc.data().registrations
              };

              currentWeekSessions.push(sessionData);
              console.log(sessionData)
            });
          });

        for await (const day of this.selected_week) {

          const dateSessions = {
            date: day,
            sessions: []
          };

          currentWeekSessions.forEach(session => {
            if (isSameDay(session.start_date, day)) {
              dateSessions.sessions.push(session)
            }
          })

          this.selectedWeekSessions.push(dateSessions);

        }

        this.pageLoaded = true;

      },

      async goToPreviousWeek() {

        this.selected_day = subDays(this.selected_day, 7);
        // this.$router.push({path: '/sessions', query: {date: format(this.selected_day, 'yyyy-MM-dd')}})

      },

      async goToNextWeek() {

        this.selected_day = addDays(this.selected_day, 7);

      },

      closeDateSelectorModal() {
        this.dateSelectorModal = false;
      },

      openNewSessionMenu(day) {
        this.new_session_date = day ? day : null;
        this.newSessionMenuOpen = true;
      },
      closeNewSessionMenu() {
        this.newSessionMenuOpen = false;
      }

    },
    async created() {
      this.selected_day = this.$route.query.date ? parseISO(this.$route.query.date) : new Date();
      // await this.getSessionsForCurrentWeek();
    },
    watch: {
      'selected_day': function() {
        this.$router.push({path: '/sessions', query: {date: format(this.selected_day, 'yyyy-MM-dd')}})
        this.getSessionsForCurrentWeek();
        this.closeDateSelectorModal();
      }
    }

  }
</script>

<style scoped>

  .add-session-button {
    color: #123968;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025rem;
    line-height: 1.1;
    /*padding: 0;*/
    border: none;
    transition: 0.2s all ease;
  }
  .add-session-button:hover {
    color: #2c9fe5;
    cursor: pointer;
  }
  .add-session-button:active, .add-session-button:focus {
    outline-style: none;
  }



</style>

<template>
  <div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar toolbar-centered max-width-800">

          <div class="toolbar-section">
            <h2>Admin Tools</h2>
          </div>

        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent is-centered">
        <div class="tile is-child box max-width-800">

          <router-link to="/email" class="box box-clickable" tag="div">
            <p class="is-semibold font-primary-blue has-text-left">Email</p>
            <p class="is-small has-text-left">Send group or individual emails to Rise members.</p>
          </router-link>

          <router-link to="/verificationcodes" class="box box-clickable" tag="div">
            <p class="is-semibold font-primary-blue has-text-left">Verification Codes</p>
            <p class="is-small has-text-left">User generated verification codes for verifying authentication.</p>
          </router-link>

          <router-link to="/team" class="box box-clickable" tag="div">
            <p class="is-semibold font-primary-blue has-text-left">Team</p>
            <p class="is-small has-text-left">Rise Team info and user permissions.</p>
          </router-link>

          <router-link to="/apps" class="box box-clickable" tag="div">
            <p class="is-semibold font-primary-blue has-text-left">Rise Apps</p>
            <p class="is-small has-text-left">TV workout screens and sign-in app.</p>
          </router-link>


          <router-link to="/tools/qotw" class="box box-clickable" tag="div">
            <p class="is-semibold font-primary-blue has-text-left">Quote of the Week</p>
            <p class="is-small has-text-left">View & update the Rise QOTW.</p>
          </router-link>

          <router-link to="/tools/elevateanywhere" class="box box-clickable" tag="div">
            <p class="is-semibold font-primary-blue has-text-left">Elevate Anywhere</p>
            <p class="is-small has-text-left">Workout exercises for the Elevate Anywhere online program.</p>
          </router-link>

        </div>
      </div>
    </div>

<!--    <div class="tile">-->

<!--      <div class="tile is-parent">-->
<!--        <router-link to="/email" class="tile is-child box box-clickable" tag="div">-->

<!--          <p class="is-semibold font-primary-blue has-text-left">Email</p>-->
<!--          <p class="is-small has-text-left">Send group or individual emails to Rise members.</p>-->

<!--        </router-link>-->
<!--      </div>-->

<!--      <div class="tile is-parent">-->
<!--        <router-link to="/sessionsignin" class="tile is-child box box-clickable" tag="div">-->

<!--          <p class="is-semibold font-primary-blue has-text-left">Sign In</p>-->
<!--          <p class="is-small has-text-left">iPad sign in app for Elevate session attendance.</p>-->

<!--        </router-link>-->
<!--      </div>-->

<!--      <div class="tile is-parent">-->
<!--        <router-link to="/tools/qotw" class="tile is-child box box-clickable" tag="div">-->

<!--          <p class="is-semibold font-primary-blue has-text-left">Quote of the Week</p>-->
<!--          <p class="is-small has-text-left">View & update the Rise QOTW.</p>-->

<!--        </router-link>-->
<!--      </div>-->

<!--    </div>-->

<!--    <div class="tile">-->

<!--      <div class="tile is-parent">-->
<!--        <router-link to="/tools/elevateanywhere" class="tile is-child box box-clickable" tag="div">-->

<!--          <p class="is-semibold font-primary-blue has-text-left">Elevate Anywhere</p>-->
<!--          <p class="is-small has-text-left">Workout exercises for the Elevate Anywhere online program.</p>-->

<!--        </router-link>-->
<!--      </div>-->

<!--      <div class="tile is-parent">-->

<!--      </div>-->

<!--      <div class="tile is-parent">-->

<!--      </div>-->

<!--    </div>-->


  </div>
</template>

<script>

  export default {
    name: 'Tools',
    data() {
        return {

        }
    },
    filters: {

    },
    computed: {

    },
    methods: {

    },
    created() {

    }
  }


</script>

<style scoped>

</style>

<template>
  <div class="app-route">

    <div class="task-bar">

      <div class="logo-container">
        <router-link to="/apps">
          <img src="./../assets/logo_4C.png" alt="">
        </router-link>
      </div>

      <div class="task-bar-title">
        <h1 @click="$router.go()">{{ timer_title }}</h1>
      </div>

      <div class="task-bar-secondary">
        <h3>Work</h3>
        <h1>{{ initial_time }}</h1>
      </div>

      <div class="task-bar-secondary">
        <h3 style="color: #21c45a;">Rest</h3>
        <h1>{{ initial_rest_time }}</h1>
      </div>

    </div>

    <transition enter-active-class="animated fadeIn fast" leave-active-class="animated fadeOut fastest" mode="out-in">

      <div class="app-contents countdown-container" v-if="countdownActive" key="countdown">
        <h1 class="time">{{ countdown_time }}</h1>
      </div>

      <div class="app-contents timer-container" v-if="!countdownActive" key="timer">

        <div class="timer-circle-container">

          <v-touch class="timer-toggle" @tap="toggleTimer">
            <progress-circle
              :total-steps="current_status === 'work' ? initial_time : initial_rest_time"
              :completed-steps="time"
              :start-color="current_status === 'work' ? '#60b6eb' : '#3bde74'"
              :stop-color="current_status === 'work' ? '#2c9fe5' : '#1a9946'"
              :circle-color="'rgba(100,100,100,0.3)'"
              :diameter="windowWidth >= 1440 ? 700 : 350"
              :circle-width="windowWidth >= 1440 ? 48 : 24"
            >

              <h1 class="time">{{ time }}</h1>

            </progress-circle>
          </v-touch>

        </div>


        <div class="timer-status-container">

          <transition enter-active-class="animated fadeInUp fast" leave-active-class="animated fadeOutUp fastest" mode="out-in">
            <div class="rest-container" v-if="current_status === 'work'" key="round">
              <h1>Round <span class="font-secondary-blue">{{ current_round }}</span></h1>
              <h2 class="rest-subheader">Next Up: Rest</h2>
            </div>
            <div class="rest-container" v-if="current_status === 'rest'" key="rest">
              <h1 style="color: #21c45a">Rest</h1>
              <h2 class="rest-subheader">Next Up: Round {{ current_round + 1 }}</h2>
            </div>
          </transition>

        </div>


      </div>

    </transition>

    <v-touch class="reset-button" @tap="resetTimer"><i class="fas fa-history"/></v-touch>

    <div v-if="!is_initialized" class="flex justify-center items-center fixed inset-0 bg-rise_gray-900 bg-opacity-50 backdrop-filter backdrop-blur">
      <div class="bg-white rounded-xl w-80 p-4">
        <p class="text-lg font-bold text-rise_gray-900 mb-4">Initialize Timer Audio</p>
        <button class="button-dark w-full block" @click="is_initialized = true">Start Audio</button>
      </div>
    </div>

  </div>
</template>

<script>
  import { db } from './../../firebase/firebaseInit';
  import { endOfDay, startOfDay } from 'date-fns';
  import { ProgressCircle } from 'vue-progress-circle';
  import { Howl } from 'howler';

  const begin = new Howl({src: require('@/assets/beginround.mp3')});
  const halfway = new Howl({src: require('@/assets/halfway.mp3')});
  const rest = new Howl({src: require('@/assets/rest.mp3')});
  const beep = new Howl({src: require('@/assets/beep.mp3')});
  const three = new Howl({src: require('@/assets/three.mp3')});
  const two = new Howl({src: require('@/assets/two.mp3')});
  const one = new Howl({src: require('@/assets/one.mp3')});

  export default {
    name: 'SessionTimer',
    components: { ProgressCircle },
    data() {
        return {
          timerActive: false,
          timer_title: 'Timer',

          current_status: 'work',
          current_round: Number(1),
          initial_time: Number(50),
          time: Number(50),
          initial_rest_time: Number(20),

          countdownActive: false,
          countdown_time: Number(),

          is_initialized: false,
        }
    },
    filters: {

    },
    computed: {
      is_halfway() {
        if (this.current_status === 'work') {
          return this.time <= this.initial_time / 2
        } else return false
      },
      windowWidth() {
        return window.innerWidth
      }
    },
    methods: {

      playBeginAudio() {
        begin.play();
      },
      playHalfwayAudio() {
        halfway.play();
      },
      playRestAudio() {
        rest.play();
      },
      playBeepAudio() {
        beep.play();
      },
      playCountdownAudio(num) {
        if (num === 3) {
          three.play();
        } else if (num === 2) {
          two.play();
        } else if (num === 1) {
          one.play();
        }
      },

      setTimer() {

        const endOfCurrentDay = endOfDay(new Date());
        const startOfCurrentDay = startOfDay(new Date());

        db.collection('workouts')
          .where('date', '<', endOfCurrentDay)
          .where('date', '>=', startOfCurrentDay)
          .get().then(querySnap => {
          if (!querySnap.empty) {
            this.timer_title = querySnap.docs[0].data().timer.title;
            this.time = querySnap.docs[0].data().timer.work_time;
            this.initial_time = querySnap.docs[0].data().timer.work_time;
            this.initial_rest_time = querySnap.docs[0].data().timer.rest_time;
          }
        })
      },

      toggleTimer() {
        if (this.current_round === 1 && this.time === this.initial_time) {
          this.startCountdownTimer();
        } else {
          this.timerActive ? this.stopTimer() : this.startTimer();
        }
      },

      startCountdownTimer() {
        this.countdown_time = Number(3);
        this.countdown_interval = setInterval(this.countdownInterval, 1000);
        this.countdownActive = true;
        this.playCountdownAudio(this.countdown_time);
      },

      countdownInterval() {
        this.countdown_time--;

        if (this.countdown_time <= 3) {
          this.playCountdownAudio(this.countdown_time);
        }

        if (this.countdown_time === 0) {
          clearInterval(this.countdown_interval);
          this.startTimer();
          this.countdownActive = false;
          this.countdown_time = Number();
        }
      },

      startTimer() {
        if (!this.timerActive) {
          this.interval = setInterval(this.timerInterval, 1000);
          this.timerActive = true;
          this.playBeginAudio();
        }
      },

      timerInterval() {
        this.time--;

        if (this.time <= 3) {
          this.playCountdownAudio(this.time);
        }

        if (this.time === 0) {
          if (this.current_status === 'work') {
            this.time = this.initial_rest_time;
            this.current_status = 'rest';
            this.playRestAudio();
          } else if (this.current_status === 'rest') {
            this.time = this.initial_time;
            this.current_status = 'work';
            this.playBeginAudio();
            this.current_round++;
          }
        }
      },

      stopTimer() {
        clearInterval(this.interval);
        this.timerActive = false;
        this.playBeepAudio();
      },

      resetTimer() {
        if (this.interval) {
          clearInterval(this.interval)
        }
        this.time = this.initial_time;
        this.current_status = 'work';
        this.timerActive = false;
        this.current_round = Number(1);
        this.playBeepAudio();
      },

      startCountdownOrTimer() {
        if (this.time === this.initial_time && this.current_round === 1) {
          this.startCountdownTimer()
        } else {
          this.startTimer()
        }
      }

    },
    created() {
      this.setTimer();

    },

    mounted() {
      this.unsubscribe = db.collection('admin').doc('workout-timer').onSnapshot(doc => {
        // alert(`is timer running? -> ${doc.data().is_running}`)
        doc.data().is_running ? this.startCountdownOrTimer() : this.stopTimer()

        if (doc.data().reset) {
          this.resetTimer()
        }
      })
    },
    beforeDestroy() {
      this.unsubscribe()
    },

    watch: {
      'is_halfway': function(newVal) {
        if (newVal) {
          this.playHalfwayAudio();
        }
      }
    }

  }


</script>

<style scoped>

  .timer-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
  }
  .timer-circle-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 48%;
  }
  .timer-status-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 2 0 auto;
  }
  .timer-status-container h1 {
    font-size: 84px;
  }
  h1.time {
    font-size: 160px;
    /*text-shadow: 0 5px 17px rgba(44,159,229,0.3);*/
  }
  .timer-toggle {
    cursor: pointer;
  }

  .rest-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  h2.rest-subheader {
    color: #4E6F96;
    font-size: 40px;
    font-weight: 800;
    text-transform: uppercase;
  }

  .circle-progress-container {
    /*box-shadow: 0 4px 4px 4px rgba(0,0,0,0.3);*/
  }

  .countdown-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .reset-button {
    position: fixed;
    bottom: 0;
    right: 0;
    height: 50px;
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(18,57,104,0.3);
    font-size: 30px;
  }

  @media (min-width: 1440px) {

    .timer-status-container h1 {
      font-size: 168px;
    }
    h1.time {
      font-size: 320px;
    }
    h2.rest-subheader {
      font-size: 80px;
    }
    .reset-button {
      height: 100px;
      width: 100px;
      font-size: 60px;
    }

  }

</style>

<template>
  <div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar">
          <div class="toolbar-section">
            <h2>Admin Emails</h2>
          </div>

          <div class="toolbar-section">
            <router-link to="/email/newemail" class="button-dark">New Email</router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent">
        <div class="tile is-child box">

          <div class="loading-spinner" v-if="!emailsLoaded"></div>
          <h2 class="empty-state animated fadeIn fast" v-if="emailsLoaded && !emails.length">There are no sent emails.</h2>

          <div class="table-container">
            <table class="table is-fullwidth animated fadeIn fast" v-if="emailsLoaded && emails.length">

              <thead>
              <tr>
                <th>Subject</th>
                <th>Type</th>
                <th>To</th>
                <th class="has-text-right" style="width: 15%;">Sent</th>
              </tr>
              </thead>

              <tbody>

              <tr v-for="email in emails" v-bind:key="email.id" class="is-selectable">
                <td class="font-primary-blue">{{ email.subject }}</td>
                <td><span class="status-label is-small"
                          :class="{
                        'light-blue-label': email.email_type === 'group',
                        'dark-blue-label': email.email_type === 'individual'
                        }">{{ email.email_type | capitalizeFilter }}</span></td>
                <td>
                  <span v-if="email.email_type === 'group'">{{ email.recipient_group }}</span>
                  <span v-if="email.email_type === 'individual'">
                  <span v-if="email.individual_recipients.length > 1">Multiple Recipients</span>
                  <span v-else>{{ email.individual_recipients[0] }}</span>
                </span>
                </td>
                <td class="has-text-right font-secondary-blue">{{ email.sent_at | dateAndTimeFilter }}</td>
              </tr>

              </tbody>

            </table>
          </div>


        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {db} from './../../firebase/firebaseInit'
  import {format} from 'date-fns'

  export default {
    name: 'AthletePrograms',
    data: function() {
      return {
        emailsLoaded: false,
        emails: []
      }
    },
    filters: {
      dateAndTimeFilter: function(value) {
        return format(value, 'M/d/y - h:mm a')
      },
      capitalizeFilter(value) {
        return value.charAt(0).toUpperCase() + value.slice(1)
      }
    },
    methods: {
      getAdminEmails: function() {
        db.collection('emails').orderBy('sent_at', 'desc').get().then(
          querySnapshot => {
            querySnapshot.forEach(doc => {
              const emailData = {
                'id': doc.id,
                'email_type': doc.data().email_type,
                'sender': doc.data().sender,
                'recipient_group': doc.data().recipient_group,
                'individual_recipients': doc.data().individual_recipients,
                'subject': doc.data().subject,
                'message': doc.data().message,
                'sent_at': doc.data().sent_at.toDate(),
                'status': doc.data().status,
              }

              this.emails.push(emailData)
            })
            this.emailsLoaded = true
        })
      }
    },
    created: function() {
      this.getAdminEmails()
    }
  }
</script>

<style scoped>

</style>

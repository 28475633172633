<template>
  <div class="app-route">

    <div class="task-bar">

      <div class="logo-container">
        <router-link to="/apps/tvs">
          <img src="./../assets/logo_4C.png" alt="">
        </router-link>
      </div>

      <div class="task-bar-title">
        <h1 @click="$router.go()">{{ workout_data.title }}</h1>
      </div>

      <div class="task-bar-secondary">
        <h3>Sets</h3>
        <h1 style="text-transform: none;">{{ workout_data.sets }}</h1>
      </div>

      <div class="task-bar-secondary">
        <h3>Format</h3>
        <h1 style="text-transform: none;">{{ workout_data.format }}</h1>
      </div>

    </div>

    <div class="app-contents workout-container">

      <template v-for="(exercise, index) in workout_data.exercises">
        <div v-if="exercise.name" :key="index" class="exercise-row">
          <div class="exercise-key"><h2 :style="{'color': keyColor(exercise.key)}">{{ exercise.key }}</h2></div>
          <div class="exercise-name"><h3>{{ exercise.name }}</h3></div>
          <div class="exercise-reps"><h4 style="text-transform: none;" :style="{'color': keyColor(exercise.key)}">{{ exercise.reps }}</h4></div>
        </div>
      </template>


    </div>

  </div>
</template>

<script>
  import { db } from '../../firebase/firebaseInit';
  import { endOfDay, startOfDay } from 'date-fns';


  export default {
    name: 'WorkoutTVScreen',
    data() {
        return {
          pageLoaded: true,

          workout_data: {
            title: '',
            sets: '',
            format: '',
            exercises: [
              { key: '', name: '', reps: '' },
              { key: '', name: '', reps: '' },
              { key: '', name: '', reps: '' },
              { key: '', name: '', reps: '' },
              { key: '', name: '', reps: '' },
              { key: '', name: '', reps: '' }
            ]
          }
        }
    },
    filters: {

    },
    computed: {
      // keyColor() {
      //   return '#46FFA3'
      // }
    },
    methods: {

      keyColor(key) {

        const colors = [
          { letter: 'A', color: '#46FFA3' },
          { letter: 'B', color: '#4BE3FF' },
          { letter: 'C', color: '#C67AEF' },
          { letter: 'D', color: '#f87171' },
          { letter: 'E', color: '#FFBA69' },
          { letter: 'F', color: '#fde047' },
          { letter: 'G', color: '#5eead4' },
          { letter: 'H', color: '#60a5fa' },
          { letter: 'I', color: '#a78bfa' },
          { letter: 'J', color: '#f472b6' },
          { letter: 'K', color: '#fbbf24' },
          { letter: 'L', color: '#a3e635' },
        ]

        const index = colors.findIndex(color => color.letter === key.charAt(0))
        if (index > -1) return colors[index].color
      },

      getWorkout() {

        const endOfCurrentDay = endOfDay(new Date());
        const startOfCurrentDay = startOfDay(new Date());

        db.collection('workouts')
          .where('date', '<', endOfCurrentDay)
          .where('date', '>=', startOfCurrentDay)
          .onSnapshot(querySnap => {
            if (!querySnap.empty) {
              this.workout_data = querySnap.docs[0].data().tvs[this.$route.params.tv];
            }
          })

      }

    },
    created() {
      this.getWorkout();
    }
  }

</script>

<style scoped>

  .workout-container {
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .exercise-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
  }

  .exercise-key {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 120px;
  }
  .exercise-key > h2 {
    color: #ffffff;
    font-size: 36px;
    font-weight: 800;
    font-style: italic;
  }
  .exercise-name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-grow: 1;
    height: 100%;
  }
  .exercise-name > h3 {
    color: #ffffff;
    font-size: 36px;
    font-weight: 700;
    letter-spacing: initial;
    text-transform: none;
    text-align: left;
  }
  .exercise-reps {
    display: flex;
    align-items: center;
    height: 100%;
  }
  .exercise-reps > h4 {
    color: #87BBF0;
    font-size: 34px;
    font-weight: 800;
    /*white-space: nowrap;*/
    text-align: right;
    margin-right: 2rem;
  }

  .exercise-row:not(:last-child) .exercise-name, .exercise-row:not(:last-child) .exercise-reps {
    border-bottom: 2px solid #4E80BE;
  }

  @media (min-width: 1440px) {
    .exercise-key {
      width: 250px;
    }
    .exercise-key > h2 {
      font-size: 72px;
    }
    .exercise-name > h3 {
      font-size: 72px;
    }
    .exercise-reps > h4 {
      font-size: 68px;
      margin-right: 4rem;
    }
  }

</style>

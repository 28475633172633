<template>
    <div>

      <div class="tile max-width-1400">
        <div class="toolbar-wrapper">
          <div class="toolbar">
            <div class="toolbar-section">
              <router-link to="/members" tag="button" class="button primary-action-button"><i class="fas fa-chevron-left"></i></router-link>
              <h2 class="margin-left">Member Profile</h2>
            </div>

            <div class="toolbar-section">

              <router-link v-if="id" :to="{name: 'Edit Member', params:{member_id: id}}" class="button primary-action-button"><i class="fas fa-pen" style="font-size: 11px;"/>&nbsp; Edit</router-link>

              <!-- Actions Dropdown -->
              <div class="dropdown is-active is-right margin-left" v-on-clickaway="closeActionModal">

                <div class="dropdown-trigger">
                  <button @click="actionModal = !actionModal" class="button primary-action-button" :class="{'button-active': actionModal}"><i class="fas fa-ellipsis-h"></i></button>
                </div>

                <div class="dropdown-menu" :class="{'menu-open': actionModal}" style="min-width: 210px;">
                  <div class="dropdown-content is-selectable">
                    <router-link v-if="id" :to="{name: 'Edit Member', params: {member_id: id}}" class="dropdown-item" tabindex="0" tag="div">Edit Member</router-link>
                    <router-link v-if="id" :to="{name: 'Messages', query: {ph: phone_number}}" class="dropdown-item" tabindex="0" tag="div">Send Message</router-link>
                    <hr class="dropdown-divider">
                    <div @click="sendResetPasswordEmail" class="dropdown-item" tabindex="0" v-if="account_exists">Send Reset Password Email</div>
                    <div @click="createMemberAccount" class="dropdown-item" tabindex="0" v-if="!account_exists"><span>Create an Account</span></div>
                    <div @click="enableMemberAccount" class="dropdown-item" tabindex="0" v-if="account_exists && custom_claims.is_disabled"><span>Enable Account</span></div>
                    <div @click="disableMemberAccount" class="dropdown-item" tabindex="0" v-if="account_exists && !custom_claims.is_disabled"><span>Disable Account</span></div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="tile max-width-1400">

        <div class="tile is-parent">
          <div class="tile is-child box">

            <div class="loading-spinner" v-if="!memberLoaded"></div>

            <div class="detail-info animated fadeIn fast" v-if="memberLoaded">

              <div class="detail-title-row">

                <div class="detail-title-item">
                  <h1 class="gradient-header">{{ first_name }} {{ last_name }}</h1>
                  <div style="display: flex; margin-bottom: 1rem;">
                    <h5>{{ email }}</h5>
                    <h5> &nbsp; • &nbsp; </h5>
                    <h5>{{ phone_number | phoneNumberFilter }}</h5>
                  </div>
                </div>

              </div>

              <div class="detail-info-row">

                <div class="status-label-container">

                  <div v-if="is_active" class="status-label">
                    <span class="status-circle is-active" style="padding-right: 0.2rem;"><i class="fas fa-circle"/></span>
                    Active Member
                  </div>

                  <div v-if="!is_active" class="status-label">
                    <span class="status-circle is-inactive" style="padding-right: 0.2rem;"><i class="far fa-circle"/></span>
                    Inactive Member
                  </div>

<!--                  <div v-if="primary_member" class="status-label">-->
<!--                    <span class="user-icon" style="padding-right: 0.2rem;"><i class="far fa-user"/></span>-->
<!--                    Primary Member-->
<!--                  </div>-->

<!--                  <div v-if="!primary_member" class="status-label">-->
<!--                    <span class="user-icon" style="padding-right: 0.2rem;"><i class="far fa-user"/></span>-->
<!--                    Secondary Member-->
<!--                  </div>-->

                  <div v-if="account_exists && custom_claims.is_member && !custom_claims.is_disabled" class="status-label">
                    <span class="user-icon" style="padding-right: 0.2rem;"><i class="fas fa-shield-alt"/></span>
                    Account Enabled
                  </div>

                  <div v-if="account_exists && custom_claims.is_member && custom_claims.is_disabled" class="status-label">
                    <span class="user-icon" style="padding-right: 0.2rem;"><i class="fas fa-shield-alt" style="color: #df203d;"/></span>
                    Account Disabled
                  </div>

                  <div v-if="account_exists === false || account_exists && custom_claims.is_member === false" class="status-label">
                    <span class="user-icon" style="padding-right: 0.2rem;"><i class="fas fa-shield-alt" style="color: #9299a0;"/></span>
                    No Account Linked
                  </div>

                  <div v-if="customer_id" class="status-label">
                    <span class="user-icon" style="padding-right: 0.2rem; font-size: unset;"><i class="fab fa-stripe" style="font-size: 20px; font-weight: normal; color: #123968; vertical-align: middle;"/></span>
                    Enabled
                  </div>

                  <div v-if="!customer_id" class="status-label">
                    <span class="user-icon" style="padding-right: 0.2rem; font-size: unset;"><i class="fab fa-stripe" style="font-size: 20px; font-weight: normal; color: #9299a0; vertical-align: middle"/></span>
                    Disabled
                  </div>

                </div>


              </div>

              <div class="content-break-line" style="margin-bottom: 2rem;"></div>

              <div class="detail-info-row">

                <div class="detail-info-item">
                  <h4>Membership Type</h4>
                  <p class="is-bold">{{ membership_type }}</p>
                </div>

                <div v-if="membership_type === 'Punchcard' || membership_plan === 'Punchcard'" class="detail-info-item">
                  <h4>Punches Left</h4>
                  <p class="is-bold">{{ punchcard_count }} sessions</p>
                </div>

                <div class="detail-info-item">
                  <h4>In Person</h4>
                  <p class="is-bold">{{ sessions_completed.in_person }} sessions</p>
                </div>

                <div class="detail-info-item">
                  <h4>Online</h4>
                  <p class="is-bold">{{ sessions_completed.online }} sessions</p>
                </div>

                <div class="detail-info-item">
                  <h4>Member Since</h4>
                  <p class="is-bold">{{ signup_date | signupDateFilter }}</p>
                </div>

              </div>

              <div v-if="notes" class="detail-info-row">

                <div class="detail-info-item">
                  <h4>Notes</h4>
                  <p class="detail-notes">{{ notes }}</p>
                </div>

              </div>

            </div>

          </div>
        </div>

<!--        <div class="tile is-parent is-5">-->
<!--          <div class="tile is-child box">-->
<!--            <h3>Membership Plan</h3>-->
<!--          </div>-->
<!--        </div>-->

      </div>

      <div class="tile">
        <div class="toolbar-wrapper">
          <div class="toolbar">
            <div class="toolbar-section">
              <h2>Sessions Attended</h2>
            </div>

            <div class="toolbar-section">
              <button @click="goToPreviousMonth" class="button primary-action-button"><i class="fas fa-chevron-left"></i></button>
              <button class="button button-outline margin-left">{{ current_month_date | currentMonthFilter}}</button>
              <button @click="goToNextMonth" class="button primary-action-button margin-left"><i class="fas fa-chevron-right"></i></button>
              <button @click="openAddAttendanceModal" class="button primary-action-button margin-left"><i class="fas fa-plus" style="font-size: 12px;"/>&nbsp; Add</button>
            </div>
          </div>
        </div>
      </div>

      <div class="tile">
        <div class="tile is-parent">
          <div class="tile is-child box">

            <div class="loading-spinner" v-if="!attendanceLoaded"></div>

            <h2 v-if="attendanceLoaded && !attendance.length" class="empty-state animated fadeIn fast">There are no attendance records available.</h2>

            <div class="table-container" v-if="attendance.length > 0">
              <table class="table is-fullwidth animated fadeIn fast">

                <thead>
                  <tr>
                    <th>Session Name</th>
                    <th>Session Date</th>
                    <th>Session Time</th>
                    <th>Signed In</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>

                  <tr v-for="(record, index) in attendance" v-bind:key="record.id">
                    <td class="font-primary-blue">{{ record.session_name }}</td>
                    <td class="font-secondary-blue">{{ record.session_date | sessionDateFilter }}</td>
                    <td>{{ record.session_date | timeFilter}}</td>
                    <td>{{ record.signed_in_at | timeFilter }}</td>
                    <td class="td-has-button" style="text-align: right;">
<!--                      <span @click="openEditAttendanceModal(index)" class="table-edit-button">Edit</span>-->
                      <button class="button primary-action-button" @click="openEditAttendanceModal(index)" style="height: unset">Edit</button>
                    </td>
                  </tr>
                </tbody>

                <tfoot>
                  <tr>
                    <td colspan="5">{{ attendance.length }} Total</td>
                  </tr>
                </tfoot>

              </table>
            </div>


          </div>
        </div>
      </div>

      <!-- Add Attendance Modal -->
      <div class="modal" v-bind:class="{'is-active': addAttendanceModal}">
        <div class="modal-background" @click="closeAddAttendanceModal"></div>
        <div class="modal-content">
          <div class="box">

            <h3 class="column-header" style="margin-bottom: 0.1em;">Add Attendance</h3>
            <div class="content-break-line"></div>

            <div class="modal-detail-wrapper">

              <div class="field">
                <label class="label">Session Name</label>
                <div class="control">
                  <input v-model="addAttendanceSessionName"
                         type="text"
                         placeholder="Name"
                         v-on:blur="validateAddAttendanceSessionName"
                         :class="{'error-border': addAttendanceErrors.session_name}">
                </div>
              </div>

              <div class="field">
                <label class="label">Session Date</label>
                <div class="control">
                  <v-date-picker
                    mode='single'
                    v-model="addAttendanceSessionDate"
                    class="modal-date-picker"
                    v-on:blur="validateAddAttendanceSessionDate"
                    :class="{'error-border': addAttendanceErrors.session_date}">
                  </v-date-picker>
                </div>
              </div>

              <div class="time-selector-wrapper">
                <div class="field">
                  <label class="label">Session Time</label>
                  <div class="control">
                    <!--<v-date-picker mode="single" v-model="editAttendanceSignedInAtDate" class="modal-date-picker"></v-date-picker>-->
                    <input v-model="addAttendanceSessionTime"
                           type="time"
                           class="modal-time-picker"
                           v-on:blur="validateAddAttendanceSessionTime"
                           :class="{'error-border': addAttendanceErrors.session_time}">
                  </div>
                </div>
                <div class="field" style="margin-left: 1rem;">
                  <label class="label">Signed In At</label>
                  <div class="control">
                    <input v-model="addAttendanceSignedInAtTime"
                           type="time"
                           class="modal-time-picker"
                           v-on:blur="validateAddAttendanceSignedInAtTime"
                           :class="{'error-border': addAttendanceErrors.signed_in_at}"
                    >
                  </div>
                </div>
              </div>

              <div class="content-break-line" style="margin-top: 1rem;"></div>

              <h4 class="session-count">Lifetime session count:
                <span>{{ sessions_completed.in_person }}</span>
                &nbsp;<i class="fas fa-long-arrow-alt-right" style="font-size: 14px; color: #c6c8ca;"></i>&nbsp;
                <span style="color: #2c9fe5; font-weight: 700;">{{ sessions_completed.in_person + 1 }}</span>
              </h4>

              <h4 v-if="membership_type === 'Punchcard' || membership_plan === 'Punchcard'" class="session-count">Punchcard count:
                <span>{{ punchcard_count }}</span>
                &nbsp;<i class="fas fa-long-arrow-alt-right" style="font-size: 14px; color: #c6c8ca;"></i>&nbsp;
                <span style="color: #2c9fe5; font-weight: 700;">{{ punchcard_count - 1 }}</span>
              </h4>

            </div>

            <div class="toolbar action-bar">

              <div class="toolbar-section"></div>

              <div class="toolbar-section">
                <button @click="closeAddAttendanceModal" class="button-light">Cancel</button>
                <button @click="validateAddAttendance" class="button-dark margin-left">Add &nbsp;<i class="fas fa-angle-right"></i></button>
              </div>

            </div>

          </div>
        </div>
      </div>

      <!-- Edit Attendance Modal -->
      <div class="modal" v-bind:class="{'is-active': editAttendanceModal}">
        <div class="modal-background" @click="closeEditAttendanceModal"></div>
        <div class="modal-content">
          <div class="box">

            <h3 class="column-header" style="margin-bottom: 0.1em;">Edit Attendance</h3>
            <div class="content-break-line"></div>


            <div class="modal-detail-wrapper">

              <div class="field">
                <label class="label">Session Name</label>
                <div class="control">
                  <input v-model="editAttendanceSessionName" type="text" placeholder="Elevate">
                </div>
              </div>

              <div class="field">
                <label class="label">Session Date</label>
                <div class="control">
                  <v-date-picker mode='single' v-model="editAttendanceSessionDate" class="modal-date-picker"></v-date-picker>
                </div>
              </div>

              <div class="time-selector-wrapper">
              <div class="field">
                <label class="label">Session Time</label>
                <div class="control">
                  <!--<v-date-picker mode="single" v-model="editAttendanceSignedInAtDate" class="modal-date-picker"></v-date-picker>-->
                  <input type="time" v-model="editAttendanceSessionTime" class="modal-time-picker">
                </div>
              </div>
              <div class="field" style="margin-left: 1rem;">
                <label class="label">Signed In At</label>
                <div class="control">
                  <input type="time" v-model="editAttendanceSignedInAtTime" class="modal-time-picker">
                </div>
              </div>
              </div>

            </div>

            <div class="toolbar action-bar" v-if="memberLoaded">

              <div class="toolbar-section">
                <!--<button @click="deleteAttendanceRecord" class="button-danger">Delete</button>-->
              </div>

              <div class="toolbar-section">
                <button @click="closeEditAttendanceModal" class="button-light">Cancel</button>
                <button @click="saveEditAttendanceRecord" class="button-dark margin-left">Save &nbsp;<i class="fas fa-angle-right"></i></button>
              </div>

            </div>


          </div>
        </div>
      </div>

    </div>
</template>

<script>
  import firebase from 'firebase/app'
  import { db, functions } from './../../firebase/firebaseInit'
  import { mixin as clickaway } from 'vue-clickaway';
  import { format, addMonths, subMonths, startOfMonth, endOfMonth, startOfDay, setHours, setMinutes } from 'date-fns'
  import {inputCheck, dateObjectCheck, timeCheck} from './../errors';
  // import numeral from 'numeral'


    export default {
      name: "MemberDetail",
      mixins: [ clickaway ],
      data: function() {
        return {
          memberLoaded: false,
          actionModal: false,

          // member data
          id: '',
          first_name: '',
          last_name: '',
          email: '',
          phone_number: '',
          is_active: '',
          membership_type: '',
          membership_plan: '',
          signup_date: '',
          punchcard_count: '',
          notes: '',
          customer_id: '',
          sessions_completed: {
            in_person: 0,
            online: 0
          },
          // primary_member: null,
          // secondary_members: [],

          // account data
          account_exists: null,
          account_id: null,
          custom_claims: {
            is_member: null,
            member_id: '',
            is_disabled: null
          },

          // primaryMemberData: [],
          // secondaryMembersData: [],

          attendanceLoaded: false,
          attendance: [],
          current_month_date: new Date(),

          // Add Attendance Data
          addAttendanceModal: false,
          addAttendanceSessionName: '',
          addAttendanceSessionDate: null,
          addAttendanceSessionTime: null,
          addAttendanceSignedInAtTime: null,
          addAttendanceErrors: {
            session_name: '',
            session_date: '',
            session_time: '',
            signed_in_at: ''
          },

          // Edit Attendance Data
          editAttendanceModal: false,
          editAttendanceId: '',
          editAttendanceSessionName: '',
          editAttendanceSessionDate: '',
          editAttendanceSessionTime: '',
          editAttendanceSignedInAtTime: '',
          attendanceIndex: null
        }
      },
      filters: {
        signupDateFilter: function(value) {
          return format(value, 'MMMM d, y')
        },
        phoneNumberFilter: function(value) {
          return value.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
        },
        sessionDateFilter: function(value) {
          return format(value, 'EEEE, MMMM d')
        },
        timeFilter: function(value) {
          return format(value, 'h:mm a')
        },
        currentMonthFilter: function(value) {
          return format(value, 'MMMM y')
        }
      },
      computed: {
        addAttendanceSessionDateAndTime: function() {
          if (this.addAttendanceSessionDate) {
            let time = this.addAttendanceSessionTime.split(':')
            let hours = parseInt(time[0])
            let minutes = parseInt(time[1])
            return setHours(setMinutes(this.addAttendanceSessionDate, minutes), hours)
          }
        },
        addAttendanceSignedInAt: function() {
          if (this.addAttendanceSessionDate) {
            let time = this.addAttendanceSignedInAtTime.split(':')
            let hours = parseInt(time[0])
            let minutes = parseInt(time[1])
            return setHours(setMinutes(this.addAttendanceSessionDate, minutes), hours)
          }
        },

        editAttendanceSessionDateAndTime: function() {
          if (this.editAttendanceSessionDate) {
            let time = this.editAttendanceSessionTime.split(':')
            let hours = parseInt(time[0])
            let minutes = parseInt(time[1])
            return setHours(setMinutes(this.editAttendanceSessionDate, minutes), hours)
          }
        },
        editAttendanceSignedInAt: function() {
          if (this.editAttendanceSessionDate) {
            let time = this.editAttendanceSignedInAtTime.split(':')
            let hours = parseInt(time[0])
            let minutes = parseInt(time[1])
            return setHours(setMinutes(this.editAttendanceSessionDate, minutes), hours)
          }
        }
      },
      methods: {

       async getMember() {
          this.memberLoaded = false;
          this.primaryMemberData = [];
          this.secondaryMembersData = [];

          await db.collection('clients').doc(this.$route.params.member_id).get().then(
            doc => {
              if (doc.exists) {
                const {first_name, last_name, email, phone_number, is_active, membership_type, membership_plan, signup_date, punchcard_count, notes, total_sessions_attended, customer_id } = doc.data();

                this.id = doc.id;
                this.first_name = first_name;
                this.last_name = last_name;
                this.email = email;
                this.phone_number = phone_number;
                this.is_active = is_active;
                this.membership_type = membership_type;
                this.membership_plan = membership_plan;
                this.signup_date = signup_date.toDate();
                this.punchcard_count = punchcard_count;
                this.notes = notes;
                // this.total_sessions_attended = total_sessions_attended;
                this.sessions_completed.in_person = doc.data().sessions_completed.in_person;
                this.sessions_completed.online = doc.data().sessions_completed.online;
                this.customer_id = customer_id;
                // this.primary_member = primary_member;
                // this.secondary_members = secondary_members;

                // Get Secondary Members Info
                // secondary_members.forEach(member => {
                //   db.collection('clients').doc(member).get().then(doc => {
                //     const secondaryMember = {
                //       id: doc.id,
                //       first_name: doc.data().first_name,
                //       last_name: doc.data().last_name,
                //       is_active: doc.data().is_active,
                //       membership_type: doc.data().membership_type
                //     };
                //
                //     this.secondaryMembersData.push(secondaryMember)
                //   })
                // });

                // Get Primary Member Info
                // if (!primary_member) {
                //   db.collection('clients').where('secondary_members', 'array-contains', this.$route.params.member_id).get().then(
                //     querySnap => {
                //       querySnap.forEach(doc => {
                //         const memberData = {
                //           id: doc.id,
                //           first_name: doc.data().first_name,
                //           last_name: doc.data().last_name,
                //           is_active: doc.data().is_active,
                //           membership_type: doc.data().membership_type
                //         }
                //
                //         this.primaryMemberData.push(memberData)
                //       })
                //     }
                //   )
                // }

                this.memberLoaded = true;
              } else {
                this.$router.push({name: 'Members'})
              }

            }).catch(err => {
              console.log(err)
            })
        },

        async memberAccountExistsCheck() {

          this.account_exists = null;
          this.account_id = null;
          this.custom_claims.is_member = null;
          this.custom_claims.member_id = '';
          this.custom_claims.is_disabled = null;

          await functions.httpsCallable('memberAccountExistsCheck')({
            email: this.email
          }).then(result => {
            console.log(result.data);
            this.account_exists = result.data.account_exists;
            this.account_id = result.data.account_id;
          }).catch(err => {
            console.log(err);
          });

        },

        async getMemberCustomClaims() {

          await functions.httpsCallable('getMemberCustomClaims')({
            email: this.email
          }).then(result => {
            this.custom_claims.is_member = result.data.is_member;
            this.custom_claims.member_id = result.data.member_id;
            this.custom_claims.is_disabled = result.data.is_disabled;
          })

        },

        getAttendance: function() {

          this.attendanceLoaded = false;
          this.attendance = [];
          const endOfCurrentMonth = endOfMonth(this.current_month_date);
          const startOfCurrentMonth = startOfMonth(this.current_month_date);

          db.collection('attendance')
            .where('member_id', '==', this.$route.params.member_id)
            .where('signed_in_at', '>=', startOfCurrentMonth)
            .where('signed_in_at', '<', endOfCurrentMonth)
            .orderBy('signed_in_at', 'desc')
            .get().then(
            querySnapshot => {
              querySnapshot.forEach(doc => {
                const attendanceRecord = {
                  'id': doc.id,
                  'member_id': doc.data().member_id,
                  'session_name': doc.data().session_name,
                  'session_date': doc.data().session_date.toDate(),
                  'signed_in_at': doc.data().signed_in_at.toDate()
                }
                this.attendance.push(attendanceRecord)
              })
              this.attendanceLoaded = true
            }
          )

        },

        goToNextMonth: function() {
          this.current_month_date = addMonths(this.current_month_date, 1)
        },
        goToPreviousMonth: function() {
          this.current_month_date = subMonths(this.current_month_date, 1)
        },

        // Add Attendance

        openAddAttendanceModal: function() {
          this.addAttendanceModal = true;
          this.addAttendanceSessionDate = startOfDay(new Date())
          this.addAttendanceSessionName = 'Elevate'
        },

        closeAddAttendanceModal: function() {
          this.addAttendanceModal = false;
          this.addAttendanceSessionDate = null;
          this.addAttendanceSessionName = 'Elevate';
          this.addAttendanceSessionTime = null;
          this.addAttendanceSignedInAtTime = null;
        },

        addAttendanceRecord: function() {
          const increment = firebase.firestore.FieldValue.increment(1);
          const decrement = firebase.firestore.FieldValue.increment(-1);

          const attendanceRef = db.collection('attendance').doc()
          const clientRef = db.collection('clients').doc(this.$route.params.member_id)

          const batch = db.batch()

          // new attendance record
          batch.set(attendanceRef, {
            'member_id': this.$route.params.member_id,
            'session_name': this.addAttendanceSessionName,
            'session_date': this.addAttendanceSessionDateAndTime,
            'signed_in_at': this.addAttendanceSignedInAt
          })

          // increment total sessions
          batch.update(clientRef, { 'sessions_completed.in_person': increment });

          // decrement punchcard
          if (this.membership_type === 'Punchcard' || this.membership_plan === 'Punchcard') {
            batch.update(clientRef, { punchcard_count: decrement })
          }


          batch.commit().then(() => {
            this.closeAddAttendanceModal()
            this.addAttendanceSuccess()
            this.getAttendance()
            this.getMember()
          }).catch(err => {
            this.errorNotification(err)
            console.log(err)
          })

        },

        // Edit Attendance

        openEditAttendanceModal: function(index) {
          this.attendanceIndex = index;
          this.editAttendanceModal = true;
          this.editAttendanceId = this.attendance[this.attendanceIndex].id;
          this.editAttendanceSessionName = this.attendance[this.attendanceIndex].session_name;
          this.editAttendanceSessionDate = startOfDay(this.attendance[this.attendanceIndex].session_date);
          this.editAttendanceSessionTime = format(this.attendance[this.attendanceIndex].session_date, 'HH:mm');
          this.editAttendanceSignedInAtTime = format(this.attendance[this.attendanceIndex].signed_in_at, 'HH:mm');
        },

        closeEditAttendanceModal: function() {
          this.editAttendanceId = '';
          this.editAttendanceModal = false;
          this.editAttendanceSessionName = '';
          this.editAttendanceSessionDate = null;
          this.editAttendanceSessionTime = '';
          // this.editAttendanceSignedInAtDate = null;
          this.editAttendanceSignedInAtTime = '';
        },

        saveEditAttendanceRecord: function() {
          db.collection('attendance').doc(this.editAttendanceId).update({
            'session_name': this.editAttendanceSessionName,
            'session_date': this.editAttendanceSessionDateAndTime,
            'signed_in_at': this.editAttendanceSignedInAt
          }).then(() => {
            this.closeEditAttendanceModal()
            this.editAttendanceSuccess()
            this.getAttendance()
          }).catch(err => {
            this.errorNotification(err.message)
            console.log(err)
          })
        },

        deleteAttendanceRecord: function() {
          db.collection('attendance').doc(this.editAttendanceId).delete().then(() => {
            this.closeEditAttendanceModal()
            this.deleteAttendanceSuccess()
            this.getAttendance()
          }).catch(err => {
            this.errorNotification(err)
            console.log(err)
          })
        },

        // Form Validation

        validateAddAttendanceSessionName: function() {
          this.addAttendanceErrors.session_name = inputCheck(this.addAttendanceSessionName)
        },
        validateAddAttendanceSessionDate: function() {
          this.addAttendanceErrors.session_date = dateObjectCheck(this.addAttendanceSessionDate)
        },
        validateAddAttendanceSessionTime: function() {
          this.addAttendanceErrors.session_time = timeCheck(this.addAttendanceSessionTime)
        },
        validateAddAttendanceSignedInAtTime: function() {
          this.addAttendanceErrors.signed_in_at = timeCheck(this.addAttendanceSignedInAtTime)
        },

        validateAddAttendance: function() {
          this.validateAddAttendanceSessionName()
          this.validateAddAttendanceSessionDate()
          this.validateAddAttendanceSessionTime()
          this.validateAddAttendanceSignedInAtTime()

          if (
            !this.addAttendanceErrors.session_name &&
            !this.addAttendanceErrors.session_date &&
            !this.addAttendanceErrors.session_time &&
            !this.addAttendanceErrors.signed_in_at
          ) {
            this.addAttendanceRecord()
          }
        },


        // Actions

        closeActionModal() {
          this.actionModal = false;
        },

        createMemberAccount() {

          functions.httpsCallable('createMemberAccount')({
            email: this.email,
            password: 'Temporary123!',
            member_id: this.id
          }).then(result => {
            console.log(result.data);
            this.submissionSuccess('Account Created', 'Member account successfully created!');
          }).catch(err => {
            console.log(err);
            this.submissionFailure(err.message)
          });

        },

        enableMemberAccount() {

          functions.httpsCallable('enableAccount')({
            uid: this.account_id
          }).then(() => {
            this.submissionSuccess('Account Enabled', 'Member account successfully enabled.');
            this.getMemberCustomClaims();
          }).catch(err => {
            this.submissionFailure(err.message)
          })

        },

        disableMemberAccount() {

         functions.httpsCallable('disableAccount')({
           uid: this.account_id
         }).then(() => {
           this.submissionSuccess('Account Disabled', 'Member account successfully disabled.');
           this.getMemberCustomClaims();
         }).catch(err => {
           this.submissionFailure(err.message)
         })

        },


        sendResetPasswordEmail() {

         firebase.auth().sendPasswordResetEmail(this.email).then(() => {

           this.submissionSuccess('Email Sent', 'Password reset email was sent.');

         }).catch(err => {
           this.submissionFailure(err.message)
           console.error(err)
         })

        },



        // Notifications

        addAttendanceSuccess: function() {
          this.$notify({
            group: 'master',
            title: 'Attendance Added',
            text: 'Attendance record was successfully added.',
            type: 'success'
          })
        },
        editAttendanceSuccess: function() {
          this.$notify({
            group: 'master',
            title: 'Attendance Updated',
            text: 'Attendance record was successfully updated.',
            type: 'success'
          })
        },
        deleteAttendanceSuccess: function() {
          this.$notify({
            group: 'master',
            title: 'Attendance Deleted',
            text: 'Attendance record was successfully deleted.',
            type: 'success'
          })
        },
        errorNotification: function(err_msg) {
          this.$notify({
            group: 'master',
            title: 'An Error Occurred',
            text: err_msg,
            type: 'error'
          })
        },

        submissionSuccess(title, message) {
          this.$notify({
            group: 'master',
            title: `${title}`,
            text: `${message}`,
            type: 'success'
          })
        },
        submissionFailure(message) {
          this.$notify({
            group: 'master',
            title: 'An Error Occurred',
            text: `${message}`,
            type: 'error'
          })
        }

      },
      async created() {
        await this.getMember();
        await this.memberAccountExistsCheck();
        if (this.account_exists) {
          await this.getMemberCustomClaims();
        }
        this.getAttendance();
      },
      watch: {
        'current_month_date': function() {
          this.getAttendance();
        }
      }
    }
</script>

<style scoped>

  .additional-members-container {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .additional-member {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    max-width: 33.33333%;
  }
  .additional-member:not(:last-child) {
    margin-right: 1.5rem;
  }

  .additional-member-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  p.additional-member-plan {
    margin-left: 1.2rem;
    color: #9299a0;
    font-size: 14px;
    line-height: 1.2;

  }

  /*---- ADD/EDIT ATTENDANCE MODAL ----*/

  .modal-content {
    overflow: visible;
  }

  .modal-detail-wrapper {
    width: 80%;
    max-width: 400px;
    margin: 0 auto;
  }

  .time-selector-wrapper {
    display: flex;
  }

  .time-selector-wrapper > .field {
    flex-grow: 1;
  }

  .modal-detail-section .control {
    flex-grow: 0.5;
  }

  .modal-date-picker {
    flex-grow: 1;
    margin-right: 1rem;
  }
  .modal-time-picker {
    /*width: 125px;*/
    flex-grow: 1;
  }

  h4.session-count {
    text-align: left;
    font-size: 14px;
    margin-top: 0.5rem;
  }

  @media (min-width: 768px) {
    .client-info-row {
      flex-direction: row;
    }

    .client-info-item {
      margin-right: 40px;
      margin-bottom: 0;
    }

  }

</style>

<template>
  <div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar">

          <div class="toolbar-section">

            <h2>Attendance</h2>
<!--            <button @click="getAttendance" class="button primary-action-button margin-left"><i class="fas fa-sync-alt"/></button>-->

          </div>

          <div class="toolbar-section">

            <div class="dropdown is-active is-right" v-on-clickaway="closeDateSelectorModal">

              <div class="dropdown-trigger">
                <button @click="dateSelectorModal = !dateSelectorModal" class="button button-outline margin-left" :class="{'button-active': dateSelectorModal}">
                  {{ currentDate | currentDateFilter }} &nbsp;
                  <i class="fas fa-chevron-down" style="font-size: 12px; transition: 0.2s all ease;" :class="{'arrow-rotate': dateSelectorModal}"/>
                </button>
              </div>

              <div class="dropdown-menu" :class="{'menu-open': dateSelectorModal}">
                <v-date-picker v-model="currentDate" is-inline></v-date-picker>
              </div>


            </div>

            <button @click="setCurrentDateToYesterday" class="button primary-action-button margin-left"><i class="fas fa-chevron-left"/></button>

            <button @click="setCurrentDateToTomorrow" class="button primary-action-button margin-left"><i class="fas fa-chevron-right"/></button>



          </div>
        </div>
      </div>
    </div>

    <div class="tile">

      <div class="tile is-parent">
        <div class="tile is-child box">


          <div class="loading-spinner" v-if="!attendanceLoaded"></div>
          <h2 class="empty-state animated fadeIn fast" v-if="attendanceLoaded && !attendance.length">There are no attended sessions.</h2>

          <div class="table-container" v-if="attendanceLoaded && attendance.length > 0">
            <table class="table is-fullwidth animated fadeIn fast">
              <thead>
              <tr>
                <th style="width: 25%;">Member Name</th>
                <th style="width: 20%;">Membership Type</th>
                <th style="width: 20%;">Session Name</th>
                <th style="width: 15%; text-align: right;">Session Time</th>
                <th style="width: 10%; text-align: right;">Signed In</th>
              </tr>
              </thead>

              <tbody>

              <router-link v-for="member in sortedAttendance" v-bind:key="member.id" tag="tr" :to="{name: 'Member Detail', params: {member_id: member.member_id}}" class="is-selectable">
                <td class="font-primary-blue">{{ member.name }}</td>
                <td><span class="status-label is-small light-blue-label">{{ member.membership_type }}</span></td>
                <td>{{ member.session_name }}</td>
                <td class="font-secondary-blue" style="text-align: right;">{{ member.session_date | timeFilter }}</td>
                <td style="text-align: right;">{{ member.signed_in_at | timeFilter }}</td>
              </router-link>

              </tbody>

              <tfoot>
                <tr>
                  <td colspan="5">{{ attendance.length }} Total</td>
                </tr>
              </tfoot>

            </table>
          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
  import {db} from './../../firebase/firebaseInit'
  import { mixin as clickaway } from 'vue-clickaway';
  import {format, startOfDay, endOfDay, addDays} from 'date-fns'
  import index from "@/store";

  export default {
    name: 'Attendance',
    mixins: [ clickaway ],
    data: function() {
      return {
        attendanceLoaded: false,
        dateSelectorModal: false,
        currentDate: new Date(),
        attendance: []
      }
    },
    filters: {
      currentDateFilter: function(value) {
        return format(value, 'EEEE, MMMM d')
      },
      dateFilter: function(value) {
        return format(value, 'MMMM d, y')
      },
      timeFilter: function(value) {
        return format(value, 'h:mm a')
      }
    },
    computed: {
      sortedAttendance: function() {
        return this.attendance.sort((a, b) => {
          return a.signed_in_at - b.signed_in_at
        })
      },
      previousDate: function() {
        return addDays(this.currentDate, -1)
      },
      nextDate: function() {
        return addDays(this.currentDate, 1)
      }
    },
    methods: {

      async getAttendance() {

        this.attendance = [];
        this.attendanceLoaded = false;
        const selectedDayAttendance = [];

        const endOfCurrentDay = endOfDay(this.currentDate);
        const startOfCurrentDay = startOfDay(this.currentDate);

        await db.collection('attendance')
          .where('signed_in_at', '<', endOfCurrentDay)
          .where('signed_in_at', '>=', startOfCurrentDay)
          .orderBy('signed_in_at', 'asc').get().then(async querySnap => {

            console.log(querySnap);
            querySnap.docs.map(doc => {

              const attendanceData = {
                id: doc.id,
                name: '',
                membership_type: '',
                member_id: doc.data().member_id,
                session_name: doc.data().session_name,
                signed_in_at: doc.data().signed_in_at.toDate(),
                session_date: doc.data().session_date.toDate()
              };

              selectedDayAttendance.push(attendanceData);

            });

            const memberInfo = selectedDayAttendance.map(record => db.collection('clients').doc(record.member_id).get());
            const result = await Promise.all(memberInfo);
            result.forEach((member, index) => {
              selectedDayAttendance[index].name = `${member.data().first_name} ${member.data().last_name}`;
              selectedDayAttendance[index].membership_type = member.data().membership_type;
            })

        });

        this.attendance = selectedDayAttendance;
        this.attendanceLoaded = true;

      },

      setCurrentDateToTomorrow: function() {
        this.currentDate = addDays(this.currentDate, 1)
      },

      setCurrentDateToYesterday: function() {
        this.currentDate = addDays(this.currentDate, -1)
      },

      closeDateSelectorModal() {
        this.dateSelectorModal = false;
      }

    },
    created: function() {
      this.getAttendance()
    },
    watch: {
      'currentDate': function() {
        this.attendance = [];
        this.getAttendance();
        this.dateSelectorModal = false;
      }
    }
  }
</script>

<style scoped>


</style>

import Vue from 'vue';

const notifySuccess = (title, text) => {

  Vue.notify({
    group: 'master',
    title: `${title}`,
    text: `${text}`,
    type: 'success'
  });

};

const notifyError = (title, text) => {

  Vue.notify({
    group: 'master',
    title: `${title}`,
    text: `${text}`,
    type: 'error'
  });

};

export { notifySuccess, notifyError };

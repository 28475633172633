<template>
  <div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar">
          <div class="toolbar-section">
            <router-link to="/sessions" tag="button" class="button primary-action-button"><i class="fas fa-chevron-left"></i></router-link>
            <h2 class="margin-left">Session Details</h2>
          </div>

          <div class="toolbar-section">
            <button v-if="id" class="button-light">Edit</button>
          </div>
        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent">
        <div class="tile is-child box">

          <div class="loading-spinner" v-if="!pageLoaded"></div>

          <div class="detail-info animated fadeIn" v-if="pageLoaded">

            <div class="detail-title-row">

              <div class="detail-title-item">
                <h1 class="gradient-header">{{ name }}</h1>
                <h5 style="margin-bottom: 0.75rem;">{{ start_date | dateFilter }} &nbsp;•&nbsp; {{ start_date | timeFilter }}</h5>
              </div>
            </div>

            <div class="detail-info-row">

              <div class="status-label-container">

                <div v-if="registration_open" class="status-label">
                  <span class="status-circle is-active" style="padding-right: 0.2rem;"><i class="fas fa-circle"/></span>
                  Registration Open
                </div>

                <div v-if="!registration_open" class="status-label">
                  <span class="status-circle is-inactive" style="padding-right: 0.2rem;"><i class="far fa-circle"/></span>
                  Registration Closed
                </div>

                <div v-if="is_public" class="status-label">
                  <span class="user-icon" style="padding-right: 0.2rem;"><i class="far fa-user"/></span>
                  Public
                </div>

                <div v-if="!is_public" class="status-label">
                  <span class="user-icon" style="padding-right: 0.2rem;"><i class="far fa-user"/></span>
                  Members Only
                </div>

              </div>

            </div>

            <div class="detail-info-row">

<!--              <div class="detail-info-item">-->
<!--                <h4>Session ID</h4>-->
<!--                <p>{{ id }}</p>-->
<!--              </div>-->

              <div class="detail-info-item">
                <h4>Duration</h4>
                <p>{{ duration_in_minutes }} minutes</p>
              </div>

              <div class="detail-info-item">
                <h4>Registrations</h4>
                <p>{{ registrations }}/{{ total_spots }} reserved</p>
              </div>

              <div class="detail-info-item">
                <h4>Spots Remaining</h4>
                <p>{{ total_spots - registrations }} spots left</p>
              </div>

            </div>

            <div class="content-break-line"></div>

            <div class="detail-info-row">

              <div class="detail-info-item">
                <h4>Description</h4>
                <p class="detail-notes">{{ description}}</p>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar">
          <div class="toolbar-section">
            <h2>Registered Members</h2>
          </div>

          <!--<div class="toolbar-section">-->
          <!--<button v-if="id" class="button-light"><i class="fas fa-edit"></i>&nbsp; Edit</button>-->
          <!--</div>-->
        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent">
        <div class="tile is-child box">

          <div class="loading-spinner" v-if="!registrationsLoaded"></div>

          <h2 class="empty-state" v-if="registrationsLoaded && !registeredMembers.length">There are no registered members.</h2>

          <div class="table-container" v-if="registrationsLoaded && registeredMembers.length > 0">
            <table class="table is-fullwidth animated fadeIn">

              <thead>
              <tr>
                <th style="width: 25%;">Name</th>
                <th style="width: 25%;">Membership Type</th>
                <th style="width: 25%;">Registered At</th>
                <th style="width: 25%;" class="has-text-right">Status</th>
              </tr>
              </thead>

              <tbody>

              <tr v-for="member in registeredMembers" v-bind:key="member.member_id">
                <td><span class="font-primary-blue">{{ member.name }}</span></td>
                <td><span class="status-label is-small light-blue-label">{{ member.membership_type }} - {{ member.membership_plan }} <span v-if="member.membership_plan === 'Punchcard'">({{ member.punchcard_count }})</span></span></td>
                <td>{{ member.created_at | monthDayTimeFilter }}</td>
                <td class="has-text-right td-has-button">

                  <button class="button primary-action-button"
                          :class="{'is-loading': memberSignInStatus && memberSignInID === member.member_id}"
                          style="height: unset;"
                          @click="signMemberIn(member.member_id, member.membership_type, member.membership_plan)"
                          :disabled="memberSignInStatus && memberSignInID === member.member_id"
                          v-if="!member.attendance_record">Sign In
                  </button>

                  <button class="button primary-action-button"
                          style="height: unset; color: #21c45a; background: #d3f8e0;"
                          v-else><i class="fas fa-check" style="font-size: 11px;"/>&nbsp;Signed In
                  </button>

                </td>
              </tr>

              </tbody>

            </table>
          </div>


        </div>
      </div>
    </div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar">
          <div class="toolbar-section">
            <h2>Session Timer</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent">
        <div class="tile is-child box">

          <div class="flex justify-between items-center">
            <p class="text-xl font-bold">Timer is <span :class="workout_timer.is_running ? 'text-green-500' : 'text-blue-400'">{{ workout_timer.is_running ? 'Running' : 'Not Running' }}</span></p>
            <div class="flex">
              <button @click="startTimer" v-if="!workout_timer.is_running" class="button-light bg-green-500 text-white" key="play">
                <span class="iconify" data-icon="ic:round-play-arrow" data-inline="false"></span>
                Start
              </button>
              <button @click="pauseTimer" v-if="workout_timer.is_running" class="button-light bg-blue-400 text-white" key="pause">
                <span class="iconify" data-icon="ic:baseline-pause" data-inline="false"></span>
                Pause
              </button>
              <button @click="resetTimer" class="button-light bg-red-500 text-white ml-2">
                <span class="iconify text-xs mt-px mr-1" data-icon="fa6-solid:arrow-rotate-right" data-inline="false"></span>
                Reset
              </button>

            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {db} from './../../firebase/firebaseInit'
  import {format} from 'date-fns'
  import firebase from 'firebase/app';

  export default {
    name: 'SessionDetail',
    data: function() {
      return {
        pageLoaded: false,
        registrationsLoaded: false,
        memberSignInStatus: false,
        memberSignInID: '',

        id: '',
        name: '',
        start_date: null,
        duration_in_minutes: null,
        total_spots: null,
        description: '',
        instructor: null,
        registration_open: null,
        registrations: null,
        is_public: null,

        registeredMembers: [],

        workout_timer: {
          is_running: false,
          reset: false,
        }
      }
    },
    filters: {
      dateFilter(value) {
        return format(value, 'MMMM d, y')
      },
      timeFilter(value) {
        return format(value, 'h:mm a')
      },
      monthDayTimeFilter(value) {
        return format(value, 'M/d @ h:mm a')
      }
    },
    methods: {

      async getSession() {
        await db.collection('sessions').doc(this.$route.params.session_id).get().then(
          doc => {
            const {name, start_date, duration_in_minutes, total_spots, description, instructor, registration_open, registrations, is_public} = doc.data();

            this.id = doc.id;
            this.name = name;
            this.start_date = start_date.toDate();
            this.duration_in_minutes = duration_in_minutes;
            this.total_spots = total_spots;
            this.description = description;
            this.instructor = instructor;
            this.registration_open = registration_open;
            this.registrations = registrations;
            this.is_public = is_public;

          }
        ).catch(err => {
          console.log(err)
        });

        this.pageLoaded = true;

      },

      async getRegisteredMembers() {

        let registrations = [];

        await db.collection('sessionregistrations')
          .where('session_id', '==', this.$route.params.session_id)
          .where('is_deregistered', '==', false)
          .orderBy('created_at', 'asc').get().then(querySnap => {
            querySnap.forEach(doc => {

              const registration_data = {
                member_id: doc.data().member_id,
                created_at: doc.data().created_at.toDate()
              };

              registrations.push(registration_data);
            })
          }).catch(err => {
            console.log(err)
          });

        for (const registration of registrations) {

          await db.collection('clients').doc(registration.member_id).get().then(doc => {
            registration.name = `${doc.data().first_name} ${doc.data().last_name}`;
            registration.membership_type = doc.data().membership_type;
            registration.membership_plan = doc.data().membership_plan ?? null;
            registration.punchcard_count = doc.data().punchcard_count;
          }).catch(err => {
            console.log(err)
          });

        }

        for (const registration of registrations) {

          await db.collection('attendance')
            .where('session_id', '==', this.$route.params.session_id)
            .where('member_id', '==', registration.member_id).get().then(querySnap => {

              registration.attendance_record = querySnap.empty ? null : querySnap.docs[0].id;


            }).catch(err => {
              console.log(err)
            });

        }

        this.registeredMembers = registrations;
        this.registrationsLoaded = true;

      },

      async signMemberIn(member_id, membership_type, membership_plan) {

        try {

          this.memberSignInStatus = true;
          this.memberSignInID = member_id;

          const increment = firebase.firestore.FieldValue.increment(1);
          const decrement = firebase.firestore.FieldValue.increment(-1);

          const attendanceRef = db.collection('attendance').doc();
          const memberRef = db.collection('clients').doc(member_id);
          const batch = db.batch();

          batch.set(attendanceRef, {
            member_id: member_id,
            signed_in_at: new Date(),
            session_date: this.start_date,
            session_name: this.name,
            session_id: this.$route.params.session_id
          });

          batch.update(memberRef, { 'sessions_completed.in_person': increment });

          if (membership_type === 'Punchcard' || membership_plan === 'Punchcard') {
            batch.update(memberRef, { punchcard_count: decrement })
          }

          await batch.commit().then(() => {
            let updateIndex = this.registeredMembers.findIndex(member => member.member_id === member_id);
            this.registeredMembers[updateIndex].attendance_record = attendanceRef.id;
            this.submissionSuccess('Member Signed In', 'Member has been signed in to this session.');
          });

        } catch (err) {
          this.submissionFailure(err.message)
        }

        this.memberSignInStatus = false;
        this.memberSignInID = '';

      },

      startTimer() {
        db.collection('admin').doc('workout-timer').update({
          is_running: true,
        })
      },

      pauseTimer() {
        db.collection('admin').doc('workout-timer').update({
          is_running: false,
        })
      },

      async resetTimer() {
        // trigger reset timer
        await db.collection('admin').doc('workout-timer').update({
          is_running: false,
          reset: true,
        })

        // reset db config value
        await db.collection('admin').doc('workout-timer').update({
          reset: false,
        })
      },

      submissionSuccess(title, message) {
        this.$notify({
          group: 'master',
          title: `${title}`,
          text: `${message}`,
          type: 'success'
        })
      },
      submissionFailure(message) {
        this.$notify({
          group: 'master',
          title: 'An Error Occurred',
          text: `${message}`,
          type: 'error'
        })
      }

    },
    async created() {
      await this.getSession();
      await this.getRegisteredMembers();
    },
    mounted() {
      this.unsubscribe = db.collection('admin').doc('workout-timer').onSnapshot(doc => {
        this.workout_timer.is_running = doc.data().is_running
      })
    },
    beforeDestroy() {
      this.unsubscribe()
    }
  }
</script>

<style scoped>

  .td-has-button {
    padding-top: 0;
    padding-bottom: 0;
  }

  button[disabled] {
    opacity: 0.75;
  }


</style>

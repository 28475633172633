<template>
  <div class="login">
    <img src="./../assets/logo_4C_outline_full.png">

    <div class="title-wrapper">
      <h2>Rise Admin</h2>
      <h3>Login for Rise Team Members.</h3>
    </div>

    <form class="login-form">

      <div class="error-message">
        <span v-if="error_message">
          <p class="error-message">{{ error_message }}</p>
        </span>
      </div>

      <div class="input-wrapper">
        <input type="email" v-model="email" placeholder="email" autofocus><br>
        <span class="input-icon"><i class="fas fa-user"/></span>
      </div>

      <div class="input-wrapper">
        <input type="password" v-model="password" placeholder="password"><br>
        <span class="input-icon"><i class="fas fa-lock"/></span>
      </div>

      <div class="button-wrapper">
        <button class="button-dark"
                @click.prevent="validateSignIn"
                :class="{'is-loading': signin_status}"
                :disabled="signin_status">Login &nbsp;<i class="fas fa-angle-right"/></button>
      </div>

    </form>
  </div>
</template>

<script>
  import firebase from 'firebase/app'
  import { db, functions } from './../../firebase/firebaseInit'

  export default {
    name: 'Login',
    data: function() {
      return {
        email: '',
        password: '',
        error_message: '',
        signin_status: false,
        user: {
          team_member: null
        }
      }
    },
    methods: {

      validateSignIn() {
        if (this.email && this.password) {
          this.signIn()
        } else {
          this.error_message = 'Please provide an email and password.'
        }
      },

      async signIn() {
        this.signin_status = true;
        this.user = {};

        // Get Team Member Custom Claims
        await functions.httpsCallable('getTeamMemberCustomClaims')({
          email: this.email
        }).then(result => {
          this.user.team_member = result.data.team_member;
        }).catch(err => {
          console.log(err);
          this.error_message = err.message;
          this.signin_status = false;
        });

        if (!this.user.team_member) {

          this.signin_status = false;
          this.user = {};
          this.error_message = 'Account user does not exist or is not a team member.';

        } else if (this.user.team_member === true) {

          await firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(() => {

            this.$router.push('/dashboard');

            }).catch(err => {
            this.error_message = err.message;
            this.signin_status = false
          })

        }

      }
    }

  }
</script>

<style scoped>
  .login {
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 350px;
  }
  img {
    width: 130px;
    margin-bottom: 60px;
  }
  .login-form {

  }
  .title-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    margin-bottom: 1.5em;
  }
  h2 {
    color: #123968;
    font-weight: 700;
    font-size: 28px;
  }
  h3 {
    color: #171a1c;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  p {
    font-size: 12px;
  }
  p a {
    text-decoration: underline;
    cursor: pointer;
  }
  .error-message {
    color: #ff0033;
    text-align: left;
  }
  .input-wrapper {
    display: block;
    /*flex-flow: row-reverse;*/
    width: 350px;
    margin-bottom: -20px;
  }
  .input-icon {
    color: #c6c8ca;
    /*color: #123968;*/
    font-size: 14px;
    display: block;
    position: relative;
    top: -42px;
    left: 5px;
    width: 30px;
    /*align-items: center;*/
    /*justify-content: center;*/
  }
  input {
    margin: 8px 0;
    /*width: 20%;*/
    /*min-width: 300px;*/
    padding: 12px 15px 12px 37px!important;
    font-size: 16px;
  }
  input:focus {
    /*border: 1px solid #338fff;*/
  }
  .button-dark {
    transition: all 0.2s ease;
  }
  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;
  }
  .login-onclick {
    filter: saturate(0.5);
    /*cursor: not-allowed!important;*/
  }
  .login-spinner {
    display: inline-block;
    vertical-align: sub;
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #338fff;
    width: 15px;
    height: 15px;
    margin-left: 6px;
    animation: spin 0.6s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

</style>

<template>
  <div class="app-template">

    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>

  </div>
</template>

<script>

  export default {
    name: 'AppTemplate',
    data() {
      return {

      }
    },
    filters: {

    },
    computed: {

    },
    methods: {

    },
    created() {

    }

  }

</script>

<style scoped>

  .app-template {
    display: flex;
    height: 100vh;
    /*background: linear-gradient(to bottom right, #0b2441 15%, #020f1d 85%);*/
    background: linear-gradient(to bottom right, #171a1c 15%, #000000 85%);
    line-height: 1!important;
  }
  >>> .app-route {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
  }

  >>> h1 {
    color: #ffffff;
    font-size: 36px;
    font-weight: 800;
    text-transform: uppercase;
  }
  >>> h3 {
    color: #2c9fe5;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  >>> .task-bar {
    /*background-color: #051222;*/
    background-color: #000000;
    border-bottom: 2px solid #4E80BE;
    width: 100%;
    height: 75px;
    display: flex;
    flex-grow: 0;
    align-items: center;
  }
  >>> .logo-container {
    height: 100%;
    border-right: 2px solid #4E80BE;
    display: flex;
    flex: 0 0 125px;
    justify-content: center;
    align-items: center;
  }
  >>> .logo-container img {
    width: 80px;
    height: auto;
  }
  >>> .task-bar-title {
    height: 100%;
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 1.5rem;
  }
  >>> .task-bar-secondary {
    border-left: 2px solid #4E80BE;
    height: 100%;
    display: flex;
    flex: 0 1 auto;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0 2rem;
  }

  >>> .app-contents {
    display: flex;
    flex-grow: 1;
  }


  >>> .apps-container {
    justify-content: center;
    align-items: center;
  }

  >>> .app-wrapper {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border: 2px solid #4E6F96;
    border-radius: 25%;
    height: 140px;
    width: 140px;
    margin: 0 1rem;
  }
  >>> .app-icon {
    color: #2c9fe5;
    font-size: 44px;
    padding-bottom: 1rem;
  }
  >>> .app-wrapper h2 {
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
  }


  @media (min-width: 1440px) {

    >>> h1 {
      font-size: 72px;
    }
    >>> h3 {
      font-size: 26px;
    }
    >>> .task-bar {
      height: 150px;
    }
    >>> .logo-container {
      flex: 0 0 250px;
    }
    >>> .logo-container img {
      width: 160px;
    }

    >>> .task-bar-title {
      padding: 0 3rem;
    }
    >>> .task-bar-secondary {
      padding: 0 4rem;
    }


  }


</style>

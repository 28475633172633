<template>
  <div>


    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar toolbar-centered max-width-800">

          <div class="toolbar-section">
            <h2>New Online Workout</h2>
          </div>

        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent is-centered">
        <div class="tile is-child box max-width-800">

          <!-- Name -->
          <div class="field">
            <div class="label-container">
              <label class="label">Workout Name</label>
              <p class="validation-error">{{ errors.name }}</p>
            </div>
            <div class="control">
              <input v-model="new_workout.name" type="text" placeholder="Elevate" :class="{'error-border': errors.name}">
            </div>
          </div>

          <!-- Format -->
          <div class="field">
            <div class="label-container">
              <label class="label">Format</label>
              <p class="validation-error">{{ errors.format }}</p>
            </div>
            <div class="select">
              <select v-model="new_workout.format" class="select-full-width" :class="{'error-border': errors.format}" required>
                <option value="" disabled selected>Select</option>
                <option value="Guided Video">Guided Video</option>
                <option value="Whiteboard" disabled>Whiteboard</option>
                <option value="Live" disabled>Live</option>
              </select>
            </div>
          </div>

          <div class="form-line-wrapper" v-if="new_workout.format === 'Guided Video' || new_workout.format === 'Live'">

            <!-- Video ID -->
            <div class="field" style="max-width: 250px;">
              <div class="label-container">
                <label class="label">Video ID <span style="color: #9299a0;">(YouTube)</span></label>
                <p class="validation-error">{{ errors.video_id }}</p>
              </div>
              <div class="control">
                <input v-model="new_workout.video_id" type="text" placeholder="XYZ789" :class="{'error-border': errors.video_id}">
              </div>
            </div>

            <!-- Instructor -->
            <div class="field">
              <label class="label">Instructor</label>
              <div class="select">
                <select v-model="new_workout.instructor" class="select-full-width" required>
                  <option value="" disabled selected>Select an Instructor</option>
                  <option v-for="instructor in instructors" :value="instructor">{{ instructor }}</option>
                </select>
              </div>
            </div>

          </div>

          <div v-if="new_workout.video_id.length >= 11" class="animated fadeIn" style="display: flex; align-items: flex-start; flex-direction: column;">
            <label class="label">Preview</label>
            <a :href="`https://youtube.com/watch?v=${new_workout.video_id}`" target="_blank" style="display: flex; height: 125px; margin-bottom: 1.5rem;">
              <img :src="`https://img.youtube.com/vi/${new_workout.video_id}/maxresdefault.jpg`" style="width: auto; border-radius: 6px;">
            </a>
          </div>


          <div class="form-line-wrapper">

            <!-- Workout Type -->
            <div class="field">
              <label class="label">Workout Type</label>
              <div class="select">
                <select v-model="new_workout.workout_type" class="select-full-width" required>
                  <option value="" disabled selected>Select a Type</option>
                  <option value="Elevate">Elevate</option>
                  <option value="Cardio">Cardio</option>
                  <option value="Strength">Strength</option>
                  <option value="Yoga">Yoga</option>
                  <option value="Pilates">Pilates</option>
                  <option value="Flexibility">Flexibility</option>
                </select>
              </div>
            </div>

            <!-- Workout Focus -->
            <div class="field">
              <label class="label">Workout Focus</label>
              <div class="select">
                <select v-model="new_workout.workout_focus" class="select-full-width" required>
                  <option value="" disabled selected>Select a Focus</option>
                  <option value="Full Body">Full Body</option>
                  <option value="Upper Body">Upper Body</option>
                  <option value="Lower Body">Lower Body</option>
                  <option value="Core">Core</option>
                  <option value="Well-Being">Well-Being</option>
                </select>
              </div>
            </div>

          </div>

          <!-- Duration -->
          <div class="field" style="flex: 1 1 0%; max-width: 180px;">
            <div class="label-container">
              <label class="label">Duration <span style="color: #9299a0;">(Minutes)</span></label>
            </div>
            <div class="control">
              <input v-model.number="new_workout.duration_in_minutes" type="number" placeholder="0" min="0" :class="{'error-border': errors.duration_in_minutes}">
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent is-centered">
        <div class="tile is-child box max-width-800">

          <!-- Equipment -->
          <div class="field" style="margin-bottom: 2rem;">
            <label class="label">Equipment</label>

            <div v-for="(item, index) in new_workout.equipment" :key="index" class="equipment-select-container">

              <div class="select">
                <select v-model="new_workout.equipment[index]" class="" required>
                  <option value="" disabled selected>Choose Equipment</option>
                  <option v-for="item in equipmentItems" :value="item" :disabled="new_workout.equipment.some(equipment => equipment === item)">{{ item }}</option>
                </select>
              </div>

              <button @click="new_workout.equipment.splice(index, 1)" class="button-light remove-array-item"><i class="fas fa-times"></i></button>

            </div>

            <button @click="new_workout.equipment.push('')" class="add-array-item"><i class="fas fa-plus" style="font-size: 10px; margin: 0 0.2rem 0 0;"></i> Add Equipment</button>

          </div>

          <!-- Description -->
          <div class="field" style="margin-bottom: 2rem;">
            <div class="label-container">
              <label class="label">Description</label>
              <p class="validation-error">{{ errors.description }}</p>
            </div>
            <textarea v-model="new_workout.description" class="textarea" rows="2"/>
          </div>

          <!-- Workout Plan -->
          <div class="field" style="margin-bottom: 2rem;">
            <label class="label">Workout Plan</label>

            <div v-for="(item, index) in new_workout.workout_plan" :key="index" class="form-line-wrapper" style="margin-bottom: 0.5rem;">

              <div class="control" style="flex: 1 1 75%;">
                <input v-model="new_workout.workout_plan[index].name" type="text" placeholder="Warm-Up">
              </div>

              <div class="control">
                <input v-model.number="new_workout.workout_plan[index].duration" type="number" placeholder="Duration (min.)">
              </div>

              <button @click="new_workout.workout_plan.splice(index, 1)" class="button-light remove-array-item"><i class="fas fa-times"></i></button>

            </div>

            <button @click="new_workout.workout_plan.push({ name: '', duration: null })" class="add-array-item"><i class="fas fa-plus" style="font-size: 10px; margin: 0 0.2rem 0 0;"></i> Add Phase</button>

          </div>


        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent is-centered">
        <div class="tile is-child box max-width-800">

          <div class="form-line-wrapper">

            <div class="field">
              <div class="label-container">
                <label class="label">Goes Live At</label>
<!--                <p class="validation-error">{{ errors.start_date }}</p>-->
              </div>
              <div class="control">
                <v-date-picker mode="single"
                               v-model="published_at_date"
                               :popover="{visibility: 'focus'}">
                  <input type='text' placeholder="MM/DD/YYYY"
                         slot-scope="{ inputProps, inputEvents }"
                         v-bind="inputProps"
                         v-on="inputEvents">
                </v-date-picker>
              </div>
            </div>

            <div class="field">
              <div class="label-container">
                <label class="label">&nbsp;</label>
<!--                <p class="validation-error">{{ errors.start_time }}</p>-->
              </div>
              <div class="control">
                <input v-model="published_at_time" type="time">
              </div>
            </div>

          </div>

          <hr class="content-break-line">

          <div class="field checkbox-container">
            <div class="checkbox-info">
              <p class="is-semibold font-primary-blue">Active</p>
              <p class="is-small">Members can find and participate in this workout.</p>
            </div>
            <label class="switch">
              <input v-model="new_workout.is_active" type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>

          <hr class="content-break-line">

          <div class="field checkbox-container">
            <div class="checkbox-info">
              <p class="is-semibold font-primary-blue">Featured</p>
              <p class="is-small">Display as a featured workout.</p>
            </div>
            <label class="switch">
              <input v-model="new_workout.is_featured" type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>

        </div>
      </div>
    </div>

    <!-- Action Buttons -->
    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar toolbar-centered max-width-800">

          <div class="toolbar-section"></div>
          <div class="toolbar-section">
            <router-link to="/onlineworkouts" class="button-light">Cancel</router-link>
            <button @click="createNewWorkout"
                    class="button-dark margin-left"
                    :class="{'is-loading': submitStatus}"
                    :disabled="submitStatus">Create &nbsp;<i class="fas fa-angle-right"/></button>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { db, functions } from './../../firebase/firebaseInit';
  import {inputCheck, firstNameCheck, lastNameCheck, emailCheck, phoneNumberCheck} from '../errors';
  import { setHours, setMinutes } from 'date-fns';

  export default {
    name: 'NewOnlineWorkout',
    data() {
        return {
          submitStatus: false,
          published_at_date: null,
          published_at_time: null,

          // member data
          new_session: {
            name: '',
            start_date: null,
            duration_in_minutes: Number(),
            total_spots: Number(),
            description: '',
            instructor: null,
            registration_open: true,
            registrations: Number(0),
            is_public: false
          },

          // equipment
          equipmentItems: [ 'None', 'Workout Mat', 'Light Dumbbells', 'Moderate Dumbbells', 'Heavy Dumbbells', 'Light Kettlebell', 'Moderate Kettlebell', 'Heavy Kettlebell', 'Medicine Ball', 'Stability Ball', 'Resistance Band', 'Box', 'Box/Elevated Surface', 'Elevate Surface', 'Jump Rope', 'Stationary Bike', 'Yoga Mat', 'Yoga Block' ],

          // instructors
          instructors: [],

          // workout data
          new_workout: {
            name: '',
            format: '',
            video_id: '',
            created_at: null,
            published_at: null,
            duration_in_minutes: Number(0),
            workout_type: '',
            workout_focus: '',
            equipment: [''],
            is_active: true,
            is_featured: false,
            ratings: {},
            difficulty: {},
            sessions_started: 0,
            sessions_completed: 0,
            instructor: '',
            description: '',
            workout_plan: [{ name: '', duration: null }],
            workout: {}
          },

          // errors
          errors: {
            name: '',
            format: '',
            video_id: '',
            published_at: '',
            duration_in_minutes: '',
            workout_type: '',
            workout_focus: '',
            equipment: '',
            instructor: '',
            description: '',
          }
        }
    },
    filters: {

    },
    computed: {

    },
    methods: {

      // validateNewTeamMember() {
      //   this.errors.first_name = firstNameCheck(this.new_session.first_name)
      //   this.errors.last_name = lastNameCheck(this.new_session.last_name)
      //   this.errors.email = emailCheck(this.new_session.email)
      //   this.errors.phone_number = phoneNumberCheck(this.new_session.phone_number)
      //   this.errors.position = inputCheck(this.new_session.position)
      //
      //   if (!this.errors.first_name && !this.errors.last_name && !this.errors.email && !this.errors.phone_number && !this.errors.position) {
      //     this.createNewTeamMember()
      //   } else {
      //     this.submissionFailure('Please fill out required information.')
      //   }
      //
      //
      // },

      setPublishedAtDate() {
        if (this.published_at_date && this.published_at_time) {
          let time = this.published_at_time.split(':');
          let hours = parseInt(time[0]);
          let minutes = parseInt(time[1]);
          this.new_workout.published_at = setHours(setMinutes(this.published_at_date, minutes), hours);
        } else {
          this.new_workout.published_at = null;
        }
      },

      async createNewWorkout() {
        // this.submitStatus = true;
        // this.setStartDate();

        this.new_workout.created_at = new Date();
        //
        await db.collection('onlineworkouts').add(this.new_workout).then(doc => {
          this.submissionSuccess('Workout Created', 'New workout successfully created.');
          this.$router.push('/onlineworkouts');
        }).catch(err => {
          this.submissionFailure(err.message);
          this.submitStatus = false;
        });
      },

      submissionSuccess(title, message) {
        this.$notify({
          group: 'master',
          title: `${title}`,
          text: `${message}`,
          type: 'success'
        })
      },
      submissionFailure(message) {
        this.$notify({
          group: 'master',
          title: 'An Error Occurred',
          text: `${message}`,
          type: 'error'
        })
      }

    },
    async created() {

      await db.collection('admin').doc('online-instructors').get().then(doc => {
        this.instructors = doc.data().instructors;
      });

    },
    watch: {
      'new_workout.format'() {
        this.new_workout.video_id = '';
      },
      'published_at_date'() {
        this.setPublishedAtDate();
      },
      'published_at_time'() {
        this.setPublishedAtDate();
      }
    }
  }


</script>

<style scoped>

.select {
  flex: 1 1 auto;
}

.equipment-select-container {
  display: flex;
  justify-content: space-between;
}

button.remove-array-item {
  height: 38px;
  margin-left: 1rem;
}
button.add-array-item {
  font-family: 'Proxima Nova', sans-serif;
  background: transparent;
  border: none;
  color: #2c9fe5;
  font-weight: 700;
  letter-spacing: normal;
  align-self: flex-start;
}
button.add-array-item:hover {
  filter: brightness(1.1);
  cursor: pointer;
}
button.add-array-item:focus {
  outline: none;
}

</style>

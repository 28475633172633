import {setHours} from "date-fns";import {setMinutes} from "date-fns";
<template>
  <div>


    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar toolbar-centered max-width-800">

          <div class="toolbar-section">
            <h2>New Session</h2>
          </div>

        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent is-centered">
        <div class="tile is-child box max-width-800">

          <div class="field checkbox-container">
            <div class="checkbox-info">
              <p class="is-semibold font-secondary-blue">Registration Status</p>
              <p class="is-small">Allow members to register for this session.</p>
            </div>
            <label class="switch">
              <input v-model="new_session.registration_open" type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>

          <hr class="content-break-line">

          <div class="field">
            <div class="label-container">
              <label class="label">Session Name</label>
              <p class="validation-error">{{ errors.name }}</p>
            </div>
            <div class="control">
              <input v-model="new_session.name" type="text" placeholder="Elevate">
            </div>
          </div>

          <div class="form-line-wrapper">

            <div class="field">
              <div class="label-container">
                <label class="label">Date</label>
                <p class="validation-error">{{ errors.start_date }}</p>
              </div>
              <div class="control">
                <v-date-picker mode="single"
                               v-model="start_date_input"
                               :min-date="new Date()"
                               :popover="{visibility: 'focus'}">
                  <input type='text' placeholder="MM/DD/YYYY"
                         slot-scope="{ inputProps, inputEvents }"
                         v-bind="inputProps"
                         v-on="inputEvents">
                </v-date-picker>
              </div>
            </div>

            <div class="field">
              <div class="label-container">
                <label class="label">Start Time</label>
                <p class="validation-error">{{ errors.start_time }}</p>
              </div>
              <div class="control">
                <input v-model="start_time_input" type="time">
              </div>
            </div>

          </div>

          <div class="form-line-wrapper">

            <div class="field form-line-child" style="flex-grow: 1;">
              <div class="label-container">
                <label class="label">Duration (minutes)</label>
                <p class="validation-error">{{ errors.duration_in_minutes }}</p>
              </div>
              <div class="control">
                <input v-model.number="new_session.duration_in_minutes" type="number" placeholder="0" min="0">
              </div>
            </div>

            <div class="field form-line-child" style="flex-grow: 1;">
              <div class="label-container">
                <label class="label">Total Spots</label>
                <p class="validation-error">{{ errors.total_spots }}</p>
              </div>
              <div class="control">
                <input v-model.number="new_session.total_spots" type="number" placeholder="0" min="0">
              </div>
            </div>

          </div>

          <div class="field">
            <div class="label-container">
              <label class="label">Description</label>
              <p class="validation-error">{{ errors.description }}</p>
            </div>
            <textarea v-model="new_session.description" class="textarea" rows="4"/>
          </div>

        </div>
      </div>
    </div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar toolbar-centered max-width-800">

          <div class="toolbar-section"></div>
          <div class="toolbar-section">
            <router-link to="/sessions" class="button-light">Cancel</router-link>
            <button @click="createNewSession"
                    class="button-dark margin-left"
                    :class="{'is-loading': submitStatus}"
                    :disabled="submitStatus">Create &nbsp;<i class="fas fa-angle-right"/></button>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { db, functions } from './../../firebase/firebaseInit'
  import {inputCheck, firstNameCheck, lastNameCheck, emailCheck, phoneNumberCheck} from '../errors'
  import { setHours, setMinutes } from 'date-fns'

  export default {
    name: 'NewSession',
    data() {
        return {
          submitStatus: false,
          start_date_input: null,
          start_time_input: null,

          // member data
          new_session: {
            name: '',
            start_date: null,
            duration_in_minutes: Number(),
            total_spots: Number(),
            description: '',
            instructor: null,
            registration_open: true,
            registrations: Number(0),
            is_public: false
          },

          // errors
          errors: {
            name: '',
            start_date: '',
            duration_in_minutes: '',
            total_spots: '',
            description: '',
            instructor: '',
            registration_open: '',
            registrations: ''
          }
        }
    },
    filters: {

    },
    computed: {

    },
    methods: {

      // validateNewTeamMember() {
      //   this.errors.first_name = firstNameCheck(this.new_session.first_name)
      //   this.errors.last_name = lastNameCheck(this.new_session.last_name)
      //   this.errors.email = emailCheck(this.new_session.email)
      //   this.errors.phone_number = phoneNumberCheck(this.new_session.phone_number)
      //   this.errors.position = inputCheck(this.new_session.position)
      //
      //   if (!this.errors.first_name && !this.errors.last_name && !this.errors.email && !this.errors.phone_number && !this.errors.position) {
      //     this.createNewTeamMember()
      //   } else {
      //     this.submissionFailure('Please fill out required information.')
      //   }
      //
      //
      // },

      setStartDate() {
        if (this.start_date_input && this.start_time_input) {
          let time = this.start_time_input.split(':');
          let hours = parseInt(time[0]);
          let minutes = parseInt(time[1]);
          this.new_session.start_date = setHours(setMinutes(this.start_date_input, minutes), hours)
        } else {
          this.new_session.start_date = null
        }
      },

      async createNewSession() {
        this.submitStatus = true;
        this.setStartDate();

        db.collection('sessions').add(this.new_session).then(doc => {
          this.submissionSuccess('Session Created', 'New session successfully created.');
          this.$router.push('/sessions');
        }).catch(err => {
          this.submissionFailure(err.message);
          this.submitStatus = false;
        })
      },

      submissionSuccess(title, message) {
        this.$notify({
          group: 'master',
          title: `${title}`,
          text: `${message}`,
          type: 'success'
        })
      },
      submissionFailure(message) {
        this.$notify({
          group: 'master',
          title: 'An Error Occurred',
          text: `${message}`,
          type: 'error'
        })
      }

    },
    created() {

    },
    watch: {

    }
  }


</script>

<style scoped>

</style>

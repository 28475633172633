import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/app'

import Login from './views/Login'
import Admin from './views/Admin'
import Dashboard from './views/Dashboard'
import Members from './views/Members'
import Attendance from './views/Attendance'
import Workouts from './views/Workouts'
import VerificationCodes from './views/VerificationCodes';
import Schedule from './views/Schedule'
import Team from './views/Team'
import NewTeamMember from "./views/NewTeamMember";
import NewMember from './views/NewMember'
import MemberDetail from './views/MemberDetail'
import EditMember from './views/EditMember'
import Athletes from './views/Athletes';
import NewAthlete from './views/NewAthlete';
import AthletePrograms from './views/AthletePrograms'
import NewAthleteProgram from './views/NewAthleteProgram'
import AthleteProgramDetail from './views/AthleteProgramDetail'
import AthleteIndividualPrograms from './views/AthleteIndividualPrograms';
import NewAthleteIndividualProgram from './views/NewAthleteIndividualProgram';
import AthleteIndividualProgramDetail from './views/AthleteIndividualProgramDetail';
import SignInTemplate from './views/SignInTemplate'
import SessionSignIn from './views/SessionSignIn'
import SignInSuccess from './views/SignInSuccess'
import SessionTimer from './views/SessionTimer'
import NewMembershipPlan from './views/NewMembershipPlan'
import Email from './views/Email'
import NewEmail from './views/NewEmail'
import QuoteOfTheWeek from './views/QuoteOfTheWeek'
import Tools from './views/Tools'
import ElevateAnywhere from './views/ElevateAnywhere';
import AppTemplate from './views/AppTemplate'
import Apps from './views/Apps'
import WorkoutTVs from './views/WorkoutTVs';
import WorkoutTVScreen from './views/WorkoutTVScreen';
import Sessions from "./views/Sessions";
import NewSession from "./views/NewSession";
import SessionDetail from "./views/SessionDetail";
import Messages from "./views/Messages";
import OnlineWorkouts from './views/OnlineWorkouts';
import NewOnlineWorkout from './views/NewOnlineWorkout';


Vue.use(VueRouter)

let router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '*',
      redirect: '/login'
    },
    {
      path: '/',
      redirect: '/login'
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/admin',
      name: 'Admin',
      component: Admin,
      meta: {
        requiresAuth: true
      },
      redirect: '/dashboard',
      children: [
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: Dashboard
        },
        {
          path: '/members',
          name: 'Members',
          component: Members
        },
        {
          path: '/members/newmember',
          name: 'New Member',
          component: NewMember
        },
        {
          path: '/members/:member_id',
          name: 'Member Detail',
          component: MemberDetail
        },
        {
          path: '/members/:member_id/edit',
          name: 'Edit Member',
          component: EditMember
        },
        {
          path: '/attendance',
          name: 'Attendance',
          component: Attendance
        },
        {
          path: '/workouts',
          name: 'Workouts',
          component: Workouts
        },
        {
          path: '/verificationcodes',
          name: 'Verification Codes',
          component: VerificationCodes
        },
        {
          path: '/schedule',
          name: 'Schedule',
          component: Schedule
        },
        {
          path: '/team',
          name: 'Team',
          component: Team
        },
        {
          path: '/team/newteammember',
          name: 'New Team Member',
          component: NewTeamMember
        },
        {
          path: '/tools',
          name: 'Tools',
          component: Tools,
          meta: {
            requiresAdmin: true
          }
        },
        {
          path: '/tools/qotw',
          name: 'Quote of the Week',
          component: QuoteOfTheWeek,
          meta: {
            requiresAdmin: true
          }
        },
        {
          path: '/tools/elevateanywhere',
          name: 'Elevate Anywhere',
          component: ElevateAnywhere
        },
        {
          path: '/email',
          name: 'Email',
          component: Email
        },
        {
          path: '/email/newemail',
          name: 'NewEmail',
          component: NewEmail
        },
        {
          path: '/newmembershipplan',
          name: 'New Membership Plan',
          component: NewMembershipPlan
        },
        {
          path: '/athletes',
          name: 'Athletes',
          component: Athletes
        },
        {
          path: '/athletes/new',
          name: 'New Athlete',
          component: NewAthlete
        },
        {
          path: '/athleteprograms',
          name: 'Athlete Programs',
          component: AthletePrograms
        },
        {
          path: '/athleteprograms/newathleteprogram',
          name: 'New Athlete Program',
          component: NewAthleteProgram
        },
        {
          path: '/athleteprograms/:program_id',
          name: 'Athlete Program Detail',
          component: AthleteProgramDetail
        },
        {
          path: '/ap-individual',
          name: 'Athlete Performance Individual Programs',
          component: AthleteIndividualPrograms
        },
        {
          path: '/ap-individual/new',
          name: 'New Athlete Performance Individual Program',
          component: NewAthleteIndividualProgram
        },
        {
          path: '/ap-individual/:id',
          name: 'Athlete Individual Program Detail',
          component: AthleteIndividualProgramDetail
        },
        {
          path: '/sessions',
          name: 'Sessions',
          component: Sessions
        },
        {
          path: '/sessions/newsession',
          name: 'New Session',
          component: NewSession
        },
        {
          path: '/sessions/:session_id',
          name: 'Session Detail',
          component: SessionDetail
        },
        {
          path: '/messages',
          name: 'Messages',
          component: Messages
        },
        {
          path: '/onlineworkouts',
          name: 'Online Workouts',
          component: OnlineWorkouts
        },
        {
          path: '/onlineworkouts/new',
          name: 'New Online Workout',
          component: NewOnlineWorkout
        }
      ]
    },
    {
      path: '/signintemplate',
      name: 'Sign In Template',
      component: SignInTemplate,
      meta: {
        requiresAuth: true
      },
      redirect: '/sessionsignin',
      children: [
        {
          path: '/sessionsignin',
          name: 'Session Sign In',
          component: SessionSignIn,
        },
        {
          path: '/sessionsignin/:member_id',
          name: 'Sign In Success',
          component: SignInSuccess
        }
      ]
    },
    {
      path: '/apptemplate',
      name: 'App Template',
      component: AppTemplate,
      meta: {
        requiresAuth: true
      },
      redirect: '/apps',
      children: [
        {
          path: '/apps',
          name: 'Apps',
          component: Apps
        },
        {
          path: '/apps/timer',
          name: 'Session Timer',
          component: SessionTimer
        },
        {
          path: '/apps/tvs',
          name: 'Workout TVs',
          component: WorkoutTVs
        },
        {
          path: '/apps/tvs/:tv',
          name: 'Workout TV',
          component: WorkoutTVScreen
        }
      ]
    },
  ]
});

router.beforeEach(async(to, from, next) => {

  // let currentUser = firebase.auth().currentUser;
  let currentUser = null;
  let currentUserIsAdmin = null;
  if (firebase.auth().currentUser) {
    await firebase.auth().currentUser.getIdTokenResult().then(
      idTokenResult => {
        currentUser = typeof idTokenResult.claims.team_member === 'undefined' ? false : idTokenResult.claims.team_member;
        currentUserIsAdmin = typeof idTokenResult.claims.is_admin === 'undefined' ? false : idTokenResult.claims.is_admin;
      });
  }

  let requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  let requiresAdmin = to.matched.some(record => record.meta.requiresAdmin);

  if (requiresAuth && !currentUser) next('login')
  else if (!requiresAuth && currentUser) next('dashboard')
  else if (requiresAdmin && !currentUserIsAdmin) next('dashboard')
  else next()
});

export default router

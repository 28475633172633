<template>
  <div class="signin">

    <div class="tile" style="flex-basis: 125px; flex-grow: 0;">

      <div class="tile is-parent">
        <div class="tile is-child signin-title">

          <div class="signin-title-item">
            <div class="logo" @click="$router.go()">
              <img src="./../assets/logo_4C_outline_full_white.png" alt="">
            </div>
            <div class="vertical-divider"></div>
            <h1>Sign In</h1>
          </div>

          <div class="signin-title-item date-and-time">
            <h2>{{ dateNow | dateFilter }}</h2>
            <h2>{{ dateNow | timeFilter}}</h2>
          </div>


        </div>
      </div>

    </div>

    <div class="tile">
      <div class="tile is-parent">
        <div class="tile is-child box bottom-container">

            <router-view style="display: flex; flex-grow: 1;"></router-view>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {format} from 'date-fns'

  export default {
    name: 'SignInTemplate',
    data: function() {
      return {
        dateNow: ''
      }
    },
    filters: {
      dateFilter: function(value) {
        return format(value, 'EEEE, MMMM d, y')
      },
      timeFilter: function(value) {
        return format(value, 'h:mm a')
      }
    },
    methods: {

      // Get Current Date and Time
      getCurrentDate: function() {
        this.dateNow = new Date()
      }

    },
    created: function() {
      this.getCurrentDate()
    },
    mounted: function() {
      this.time_interval = setInterval(this.getCurrentDate, 1000)
    },
    beforeDestroy: function() {
      clearInterval(this.time_interval)
    }
  }
</script>

<style scoped>

  .signin {
    display: flex;
    flex-direction: column;
    /*background: linear-gradient(to bottom right, rgba(18, 57, 104, 0.95) 0%, rgba(11, 36, 65, 1) 90%);*/
    /*background: #1e262f;*/
    background: linear-gradient(to bottom right, #123968 0%, #08182B 80%);
    height: 100vh;
    padding: 1em 1em 1em;
    /*overflow: hidden;*/
  }
  .signin-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .signin-title-item {
    display: flex;
    /*justify-content: space-between;*/
    flex-direction: row;
    align-items: center;
  }
  .date-and-time {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
  .logo {
    min-width: 100px;
    width: 100px;
  }
  .logo img {
    vertical-align: bottom;
  }
  .vertical-divider {
    height: 60px;
    width: 1px;
    background: #ffffff;
    margin: 0 1.25rem;
  }

  .bottom-container {
    display: flex;
    background: rgba(255,255,255,0.04);
    border: none;
    box-shadow: 0 8px 20px rgba(0,0,0,0.1);
  }


  /*---- Fonts ----*/
  >>> h1 {
    color: #ffffff;
    font-size: 50px;
    font-weight: 800;
    text-shadow: 0 3px 6px rgba(0,0,0,0.3);
    line-height: 1;
  }
  >>> h1.small-title {
    font-size: 44px;
    margin-bottom: 0.5em;
  }
  >>> h2 {
    color: #ffffff;
    font-size: 24px;
    font-weight: 300;
  }
  >>> h3 {
    color: #ffffff;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
  >>> h4 {
    color: #ffffff;
    font-size: 24px;
    font-weight: 800;
    text-transform: uppercase;
    letter-spacing: 1px;
    text-align: left;
  }
  >>> h5 {
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
  }
  >>> h6 {
    color: #2c9fe5;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  >>> .client-stat-title {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: rgba(255,255,255,0.2);
    font-size: 36px;
    font-weight: 200;
  }
  >>> .client-stat {
    color: #ffffff;
    font-size: 72px;
    font-weight: 800;
    /*text-transform: uppercase;*/
    line-height: 1;
  }
  >>> .client-stat-label {
    color: #2c9fe5;
  }

  /*---- SUBMIT BUTTON ----*/
  >>> .submit-button {
    font-family: proxima-nova, sans-serif;
    color: #ffffff;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    background: #2c9fe5;
    border: none;
    border-radius: 40px;
    width: 275px;
    padding: 1.1rem 0;
    outline: none;
    transition: 0.3s all ease;
  }
  >>> .button-spinner {
    vertical-align: initial;
  }

</style>

<template>
  <div>

          <div v-if="!client_signed_in" class="columns animated fadeIn">

            <div class="column is-two-fifths phone-number-container">

              <h3>Enter Phone Number</h3>

              <input v-model="phone_number_input"
                     v-mask="'(###) ###-####'"
                     class="phone-number-input"
                     type="tel" placeholder="(701) 555-0100" maxlength="14" readonly>

              <div class="input-container">

                <div class="input-row">
                  <v-touch class="input-item" @tap="addInputNumber(1)">1</v-touch>
                  <!--<div class="input-item" @click="addInputNumber(1)">1</div>-->
                  <v-touch class="input-item" @tap="addInputNumber(2)">2</v-touch>
                  <v-touch class="input-item" @tap="addInputNumber(3)">3</v-touch>
                </div>

                <div class="input-row">
                  <v-touch class="input-item" @tap="addInputNumber(4)">4</v-touch>
                  <v-touch class="input-item" @tap="addInputNumber(5)">5</v-touch>
                  <v-touch class="input-item" @tap="addInputNumber(6)">6</v-touch>
                </div>

                <div class="input-row">
                  <v-touch class="input-item" @tap="addInputNumber(7)">7</v-touch>
                  <v-touch class="input-item" @tap="addInputNumber(8)">8</v-touch>
                  <v-touch class="input-item" @tap="addInputNumber(9)">9</v-touch>
                </div>

                <div class="input-row">
                  <v-touch class="input-item no-bg input-clear-btn" @tap="clearPhoneNumberInput">Clear</v-touch>
                  <v-touch class="input-item" @tap="addInputNumber(0)">0</v-touch>
                  <v-touch class="input-item no-bg" @tap="deleteInputNumber"><i class="fas fa-backspace" style="font-size: 26px;"></i></v-touch>
                </div>

              </div>

              <v-touch class="submit-button" @tap="signIn" v-bind:class="{'button-onclick': loading_status}" tag="button">
                Let's Go&nbsp;
                <span>
                  <i class="fas fa-angle-right" style="padding: 0 2px;" v-if="!loading_status"></i>
                  <span v-if="loading_status" class="button-spinner"></span>
                </span>
              </v-touch>

            </div>


            <div class="column column-right animated fadeIn" v-show="!loading_status && !error.title">

              <div class="class-times-container">

                <h4>Today's Schedule</h4>

                <div class="class-times-header">
                  <h6>Session Name</h6>
                  <h6>Time</h6>
                </div>

                <div class="class-times-list animated fadeIn" id="enable-scroll">
                  <div v-for="(session, index) in sessions" :key="index" class="class-times-item">
                    <h5>{{ session.session_name }}</h5>
                    <h5>
                      {{ session.start_time | timeFilter }}
                      <span v-if="session.open_period"> - {{ session.end_time | timeFilter }}</span>
                    </h5>
                  </div>
                </div>

              </div>

              <!--<div class="class-times-scroll">-->
                <!--<h6 class="scroll-text"><i class="fas fa-long-arrow-alt-left scroll-arrow"></i> Scroll <i class="fas fa-long-arrow-alt-right scroll-arrow"></i></h6>-->
              <!--</div>-->

            </div>

            <!-- Error Screen -->
            <div class="column column-right animated fadeIn" v-show="!loading_status && error.title" style="align-self: center;">

              <div class="animated fadeIn">
                <h1 class="has-text-left small-title">{{ error.title }}</h1>
                <h2 class="has-text-left">{{ error.message }}</h2>
              </div>

            </div>

            <!-- Loading Screen -->
            <div class="column column-right animated fadeIn" v-show="loading_status" style="align-self: center;">

              <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
              </div>

            </div>

          </div>

  </div>
</template>

<script>
  import firebase from 'firebase/app'
  import {db} from './../../firebase/firebaseInit'
  import {addMinutes, format, isFuture, isWithinInterval, getDay} from 'date-fns'
  import debounce from 'lodash.debounce'
  import {disableBodyScroll} from 'body-scroll-lock'
  import VueScrollTo from 'vue-scrollto'

  export default {
    name: "SessionSignIn",
    data: function() {
      return {
        // dateNow: '',
        loading_status: false,
        client_signed_in: false,
        error: {},
        phone_number_input: '',
        phone_number: '',
        member_id: '',
        day_of_week: null,
        // class_times: [],
        // class_names: [],
        session_data: {},
        client_stats: {},
        todays_sessions: [],
        sessions: [],
        // session_times: [],
        selected_session_index: '',
        active_session_time: null,
        active_session_index: null
      }
    },
    filters: {
      timeFilter: function(value) {
        return format(value, 'h:mm a')
      }
    },
    computed: {

    },
    methods: {

      // Get Current Date and Time
      getActiveSessionTime: function() {
        this.active_session_time = new Date()
      },

      // Phone Number Input
      addInputNumber: function(number) {
        this.phone_number_input += number
      },
      deleteInputNumber: function() {
        this.phone_number_input = this.phone_number_input.slice(0, -1);
      },
      clearPhoneNumberInput: function() {
        this.phone_number_input = ''
      },

      // Get and Set Today's Sessions
      getSessions: async function() {
        this.day_of_week = getDay(new Date()).toString();

        await db.collection('schedule').doc(this.day_of_week).get().then(
          doc => {
            this.todays_sessions = doc.data().sessions;

            this.setSessions();
          }
        ).catch(err => {
          this.error.title = 'An error occurred. Please see a staff member for assistance.'
          this.error.message = err
          console.log(err)
        })
      },

      setSessions: function() {
        const date = new Date();
        const today = date.setHours(0,0,0,0);

        this.todays_sessions.forEach(session => {
          let sessionData = {
            'session_name': session.session_name,
            'open_period': session.open_period,
            'start_time': null,
            'end_time': null
          };

          let start_time = session.start_time.split(':');
          let session_minutes = (parseInt(start_time[0]) * 60) + parseInt(start_time[1])
          sessionData.start_time = addMinutes(today, session_minutes)

          if (session.open_period) {
            let end_time = session.end_time.split(':');
            let session_minutes = (parseInt(end_time[0]) * 60) + parseInt(end_time[1])
            sessionData.end_time = addMinutes(today, session_minutes)

          }

          this.sessions.push(sessionData)
        })

      },

      setCurrentSession: function() {
        if (document.getElementById('current-session')) {
          document.getElementById('current-session').removeAttribute('id');
        }

        function findActiveSession(session) {
          if (!session.open_period) {
            return isFuture(addMinutes(session.start_time, 10))
          } else if (session.open_period) {
            return isFuture(session.start_time) || isWithinInterval(new Date(), {start: session.start_time, end: session.end_time})
          }
        }
        this.active_session_index = this.sessions.findIndex(findActiveSession);

        let list = document.getElementsByClassName('class-times-list')[0];
        list.getElementsByClassName('class-times-item')[this.active_session_index].setAttribute('id', 'current-session');

      },

      scrollToCurrentSession: function() {
        VueScrollTo.scrollTo('#current-session', 1000, {container: '#enable-scroll'})
      },




      //Session Sign In
      signIn: function() {

        this.sessions = [];
        this.setSessions();
        this.member_id = '';
        this.error = {};
        this.loading_status = true;
        this.client_stats = {};

        db.collection('clients').where('phone_number', '==', this.phone_number).where('is_active', '==', true).where('program', '==', 'Adult Fitness').get().then(
          querySnapshot => {
            querySnapshot.forEach(doc => {
              this.member_id = doc.id;
            })

            this.clientExistsCheck();

          }).catch(err => {
          console.log(err);
          this.loading_status = false;
        })
      },

      clientExistsCheck: function() {
        if (!this.member_id) {
          this.error.title = 'No membership found.'
          this.error.message = 'An active member with that phone number does not exist.'
          this.loading_status = false
        } else if (this.member_id) {
          this.error = {}

          this.setAttendanceData();
        }
      },

      setAttendanceData: function() {

        function findSession(session) {
          if (!session.open_period) {
            return isFuture(addMinutes(session.start_time, 10))
          } else if (session.open_period) {
            return isFuture(session.start_time) || isWithinInterval(new Date(), {start: session.start_time, end: session.end_time})
          }
        }

        this.selected_session_index = this.sessions.findIndex(findSession);

        if (this.selected_session_index >= 0) {
          this.session_data.member_id = this.member_id;
          this.session_data.signed_in_at = new Date();
          this.session_data.session_date = this.sessions[this.selected_session_index].start_time;
          this.session_data.session_name = this.sessions[this.selected_session_index].session_name;
          this.createRecordAndUpdateMember();
        } else {
          this.error.title = 'No sessions available.'
          this.error.message = 'There are no available session times right now.'
          this.loading_status = false
        }

      },

      createRecordAndUpdateMember: async function() {
        const increment = firebase.firestore.FieldValue.increment(1);
        const decrement = firebase.firestore.FieldValue.increment(-1);

        await db.collection('clients').doc(this.member_id).get().then(doc => {
          this.client_stats.membership_type = doc.data().membership_type;
        })

        const attendanceRef = db.collection('attendance').doc()
        const memberRef = db.collection('clients').doc(this.member_id)

        const batch = db.batch()
        batch.set(attendanceRef, this.session_data)
        batch.update(memberRef, { total_sessions_attended: increment })

        if (this.client_stats.membership_type === 'Punchcard') {
          batch.update(memberRef, { punchcard_count: decrement })
        }

        batch.commit().then(() => {
          this.$router.push({name: 'Sign In Success', params: {member_id: this.member_id}})
        }).catch(err => {
          this.error.title = 'An error occurred.'
          this.error.message = 'Please see a staff member for assistance.'
          console.log(err)
          })

      },

      disableScroll: function() {
        disableBodyScroll(document.querySelector('#enable-scroll'))
      }

    },
    created: async function() {
      await this.getSessions();
      this.setCurrentSession();
    },
    mounted: function() {
      this.disableScroll();

      // Interval for refreshing date and time
      this.date_interval = setInterval(this.getActiveSessionTime, 1000 * 60)

      // setTimeout(this.scrollToCurrentSession(), 5000);
      setTimeout(() => {this.scrollToCurrentSession()}, 1000)
    },
    beforeDestroy: function() {
      clearInterval(this.date_interval)
    },
    watch: {
      'phone_number_input': function() {
        return this.phone_number = this.phone_number_input.replace(/\D+/g, "")
      },
      'error': function() {
        clearTimeout(this.timer)
        this.timer = setTimeout(() => { this.error = {} }, 6000)
      },
      'active_session_time': function() {
        this.setCurrentSession();
      }
    }
  }
</script>

<style scoped>

  .columns {
    flex: 1 1 auto;
    /*align-items: center;*/
  }

  .column {
    margin: 0.75rem;
  }

  .signin {
    display: flex;
    flex-direction: column;
    background: linear-gradient(to bottom right, rgba(18, 57, 104, 0.95) 0%, rgba(11, 36, 65, 1) 90%);
    height: 100vh;
    padding: 1em 1em 1.5em;
    /*overflow: hidden;*/
  }
  .signin-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  .signin-title-item {
    display: flex;
    /*justify-content: space-between;*/
    flex-direction: row;
    align-items: center;
  }
  .date-and-time {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
  }
  .logo {
    min-width: 100px;
    width: 100px;
  }
  .logo img {
    vertical-align: bottom;
  }
  .vertical-divider {
    height: 60px;
    width: 1px;
    background: #ffffff;
    margin: 0 1.25rem;
  }

  .bottom-container {
    display: flex;
    background: rgba(255,255,255,0.06);
    border: none;
    box-shadow: 0 8px 20px rgba(0,0,0,0.1);
  }

  .phone-number-container {
    align-self: center;
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
  .phone-number-input {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #ffffff!important;
    font-size: 36px;
    font-weight: 200;
    max-width: 265px;
    border: none;
    border-bottom: 1px solid #ffffff!important;
    border-radius: 0;
    pointer-events: none;
  }
  .phone-number-input:focus, .phone-number-input:hover, .phone-number-input:active {
    outline: none;
    box-shadow: none;
  }
  input::-webkit-input-placeholder, form textarea::-webkit-input-placeholder {
    color: rgba(255,255,255,0.2)!important;
  }
  .input-error-border {
    border-bottom: 1px solid #df203d;
  }

  .input-container {
    display: flex;
    flex-direction: column;
    width: 300px;
    margin: 1.5em auto 0.5em;
  }
  .input-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 1em;
  }
  .input-item {
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(216,216,216,0.05);
    width: 75px;
    height: 75px;
    border-radius: 40px;

    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    color: #ffffff;
    font-size: 38px;
    font-weight: 600;
    line-height: 1;
    -webkit-tap-highlight-color: rgba(0,0,0,0)!important;

    transition: 0.2s all ease;
  }
  .input-item.no-bg {
    background: none;
  }
  .input-clear-btn {
    font-family: proxima-nova, sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
  .input-item:active, .input-item.no-bg:active {
    background: rgba(216,216,216,0.3);
  }
  ul {
    color: #ffffff;
  }

  .column-right {
    /*border-left: 1px solid rgba(255, 255, 255, 0.2);*/
    padding-left: 2rem;
    padding-right: 2rem;
    display: flex;
    flex-direction: row;
  }
  .class-times-container {
    min-height: 100%;
    flex-grow: 1;
  }
  .class-times-scroll {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 35px;
    margin-top: 3rem;
    margin-left: 0.5rem;
  }
  .scroll-text {
    transform: rotate(-90deg);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
  }
  .scroll-arrow {
    padding: 1em;
    color: #338fff;
  }
  .class-times-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 0.5em;
    padding-right: 0.5em;
    margin: 1em 0 0.6em 0;
  }
  .class-times-list {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    overflow: scroll;
    max-height: 430px;
    /*border-top: 1px solid rgba(255,255,255,0.2);*/
  }
  .class-times-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 1.1em 0.5em;
    min-height: 55px;
    max-height: 55px;
    border-top: 1px solid rgba(255,255,255,0.1);
  }
  .class-times-item h5 {
    color: rgba(255,255,255,0.4);
  }
  .class-times-item:nth-child(odd) {
    /*background: rgba(255,255,255,0.03);*/
    /*border-top: 1px solid rgba(255,255,255,0.1);*/
    /*border-bottom: 1px solid rgba(255,255,255,0.1);*/
    /*border-radius: 6px;*/
  }
  .class-times-item:last-child {
    /*border-bottom: none;*/
  }
  .class-time-past {
    /*color: rgba(255,255,255,0.3);*/
  }
  #current-session {
    background: rgba(255,255,255,0.05);
    border: 1px solid #2c9fe5;
    /*box-shadow: 0 3px 4px rgba(0,0,0,0.05);*/
    border-radius: 6px;
    /*border-bottom: none;*/
  }
  #current-session h5 {
    color: #ffffff;
    font-weight: 700;
  }
  #current-session + .class-times-item {
    border-top: none;
  }

  .client-stat-item {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
  }
  .client-stat-item:last-child {
    border-right: none;
  }

  /*-- Loading Animation --*/
  .spinner {
    margin: 100px auto 0;
    width: 75px;
    text-align: center;
  }

  .spinner > div {
    width: 16px;
    height: 16px;
    background-color: #ffffff;
    margin-left: 3px;
    margin-right: 3px;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }

  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
  }

  @keyframes sk-bouncedelay {
    0%, 80%, 100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% {
        -webkit-transform: scale(1.0);
        transform: scale(1.0);
      }
  }



</style>

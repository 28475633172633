import { render, staticRenderFns } from "./Workouts.vue?vue&type=template&id=67b74522&scoped=true&"
import script from "./Workouts.vue?vue&type=script&lang=js&"
export * from "./Workouts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67b74522",
  null
  
)

export default component.exports
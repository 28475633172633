<template>
  <div class="control" v-on-clickaway="blurSearchInput">

    <div class="input-container">
      <input v-model="searchInput" type="text" placeholder="Search..." id="member-search-input"
             @keypress.enter="getSearchResults"
             @keyup.esc="blurSearchInput"
             @focus="searchInputFocused = true">
    </div>

    <transition enter-active-class="animated fadeIn faster" leave-active-class="animated fadeOut faster" mode="out-in">
      <div class="search-container animated fadeIn faster" v-if="searchInputFocused">

        <div class="loading-spinner" v-if="searchLoading" style="margin: 1.5em auto;"></div>
        <p class="search-help-text has-text-centered" v-else-if="searchInput && !typingActive && !searchLoading && !searchResults.length">No results found.</p>
        <p class="search-help-text has-text-centered" v-else-if="!searchResults.length">
          Search by
          <span class="status-label gray-label is-small">first name</span> ,
          <span class="status-label gray-label is-small">last name</span> ,
          <span class="status-label gray-label is-small">email address</span> , or
          <span class="status-label gray-label is-small">phone number</span> .
        </p>

        <div v-for="member in searchResults"
                     v-bind:key="member.id"
                     v-show="!searchLoading"
                     class="search-member"
                     @click="selectMember(member.id)">

          <div class="search-member-item">
            <h1>{{ member.first_name }} {{ member.last_name }}</h1>
            <h2 class="has-text-left">{{ member.email }} &nbsp;•&nbsp; {{ member.phone_number | phoneNumberFilter }}</h2>
          </div>

          <div class="search-member-item">
            <h3 v-if="members.includes(member.id)"><i class="fas fa-check-circle" style="color: #21c45a;"></i></h3>
            <h3 v-else><i class="far fa-circle" style="color: #e4e7ea;"></i></h3>
          </div>

        </div>

      </div>
    </transition>

  </div>
</template>

<script>
  import {db} from './../../firebase/firebaseInit'
  import {mixin as clickaway} from 'vue-clickaway';
  import debounce from 'lodash.debounce'

  export default {
    name: 'MemberSearchInput',
    mixins: [ clickaway ],
    props: ['members'],
    data: function() {
      return {
        searchInputFocused: false,
        typingActive: false,

        searchInput: '',
        searchResults: [],
        searchLoading: false
      }
    },
    filters: {
      phoneNumberFilter: function(value) {
        return value.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
      }
    },
    computed: {
      // searchContainerActive: function() {
      //   return this.searchInputFocused;
      // },
      searchInputArray: function() {
        let name = this.searchInput.trim().split(' ');
        name[0] = '' ? '-' : name[0];
        if (typeof name[1] === 'undefined' || name[1] === '') {
          name[1] = name[0]
        }
        return name.map(item => item.toLowerCase())
      },
      firstAndLastInputArray() {
        return this.searchInputArray.map(item => item.charAt(0).toUpperCase() + item.slice(1))
      },
      searchMessage() {
        if (this.searchInput) {
          if (this.searchResults) {
            return ''
          } else {
            return 'No results found.'
          }
        } else {
          return 'Search by first name, last name, email address, or phone number.'
        }
      }
    },
    methods: {

      getSearchResults: async function() {
        const fetchedResults = [];
        this.searchLoading = true;

        if (this.searchInput) {

          await db.collection('clients')
            .orderBy('first_name')
            .startAt(this.firstAndLastInputArray[0])
            .endAt(this.firstAndLastInputArray[0] + '\uf8ff')
            .get().then(
            querySnapshot => {
              querySnapshot.forEach(doc => {
                const clientData = {
                  'id': doc.id,
                  'first_name': doc.data().first_name,
                  'last_name': doc.data().last_name,
                  'membership_type': doc.data().membership_type,
                  'email': doc.data().email,
                  'phone_number': doc.data().phone_number,
                  'is_active': doc.data().is_active
                };

                fetchedResults.push(clientData)
              });
            }
          );

          await db.collection('clients')
            .orderBy('last_name')
            .startAt(this.firstAndLastInputArray[1])
            .endAt(this.firstAndLastInputArray[1] + '\uf8ff')
            .get().then(
            querySnapshot => {
              querySnapshot.forEach(doc => {
                const clientData = {
                  'id': doc.id,
                  'first_name': doc.data().first_name,
                  'last_name': doc.data().last_name,
                  'membership_type': doc.data().membership_type,
                  'email': doc.data().email,
                  'phone_number': doc.data().phone_number,
                  'is_active': doc.data().is_active
                };

                let memberIds = [];
                fetchedResults.forEach(member => {
                  memberIds.push(member.id)
                });

                if (!memberIds.includes(clientData.id)) {
                  fetchedResults.push(clientData)
                }

              });
            }
          );

          await db.collection('clients')
            .orderBy('email')
            .startAt(this.searchInputArray[0])
            .endAt(this.searchInputArray[0] + '\uf8ff')
            .get().then(
              querySnapshot => {
                querySnapshot.forEach(doc => {
                  const clientData = {
                    'id': doc.id,
                    'first_name': doc.data().first_name,
                    'last_name': doc.data().last_name,
                    'membership_type': doc.data().membership_type,
                    'email': doc.data().email,
                    'phone_number': doc.data().phone_number,
                    'is_active': doc.data().is_active
                  };

                  let memberIds = [];
                  fetchedResults.forEach(member => {
                    memberIds.push(member.id)
                  });

                  if (!memberIds.includes(clientData.id)) {
                    fetchedResults.push(clientData)
                  }

                });
              }
            );

          await db.collection('clients')
            .orderBy('phone_number')
            .startAt(this.searchInputArray[0])
            .endAt(this.searchInputArray[0] + '\uf8ff')
            .get().then(
              querySnapshot => {
                querySnapshot.forEach(doc => {
                  const clientData = {
                    'id': doc.id,
                    'first_name': doc.data().first_name,
                    'last_name': doc.data().last_name,
                    'membership_type': doc.data().membership_type,
                    'email': doc.data().email,
                    'phone_number': doc.data().phone_number,
                    'is_active': doc.data().is_active
                  };

                  let memberIds = [];
                  fetchedResults.forEach(member => {
                    memberIds.push(member.id)
                  });

                  if (!memberIds.includes(clientData.id)) {
                    fetchedResults.push(clientData)
                  }

                });
              }
            );

          this.searchResults = fetchedResults;
          this.searchResultsFetched()

        } else if (this.searchInput === '') {
          this.searchLoading = false;
          this.searchResults = [];
          // this.searchMessage = '';
        }
      },

      onSearchInput: debounce(function() {
        this.typingActive = false;
        this.getSearchResults()
      }, 500, {
        'leading': false,
        'trailing': true
      }),

      searchResultsFetched: function() {
        this.searchLoading = false;
        if (this.searchResults.length === 0) {
          // this.searchMessage = 'No results found.'
        }
      },

      selectMember: function(member_id) {
        if (!this.members.includes(member_id)) {
          this.$emit('addMember', member_id)
        }
        this.searchInput = '';
        this.blurSearchInput();
      },

      blurSearchInput: function() {
        this.searchInputFocused = false;
        document.getElementById('member-search-input').blur()
      }

    },
    watch: {
      'searchInput': function(newVal) {
        this.typingActive = true;
        if (this.searchInputArray[0] || this.searchInputArray[1]) {
          this.onSearchInput()
        } else {
          this.searchResults = [];
          this.typingActive = false;
        }
        // if (!newVal) {
        //   this.searchMessage = ''
        // }
      }
    }
  }
</script>

<style scoped>

  .input-container {
    display: flex;
  }

  #member-search-input {

  }

  .search-button {
    padding-top: 9px;
    padding-bottom: 9px;
    /*margin-left: 1rem;*/
    height: 38px;
    border-radius: 0 6px 6px 0;
    position: absolute;
    right: 0;
  }

  .search-container {
    border-radius: 6px;
    position: absolute;
    top: 50px;
    padding: 0.75rem;
    transition: 0.3s all ease;

    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
    background: #ffffff;
    border: 1px solid #e4e7ea;
    box-shadow: rgba(0, 0, 0, 0.1) 0 20px 40px;
    z-index: 99;
  }
  .search-member {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
  }
  .search-member:hover {
    background: #f2f6ff;
    cursor: pointer;
  }
  .search-member:not(:last-child) {
    border-bottom: 1px solid #f2f6ff;
  }
  .search-member:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .search-member:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .search-member h1 {
    color: #123968;
    font-size: 16px;
    font-weight: 700;
    line-height: 1;
    text-align: left;
  }
  .search-member h1 {
    color: #123968;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.3;
    text-align: left;
    transition: 0.2s all ease;
  }
  .search-member:hover h1 {
    color: #2c9fe5;
  }
  .search-member h2 {
    color: #9299a0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.3;
    /*text-transform: uppercase;*/
    /*letter-spacing: 0.05rem;*/
  }
  .search-member h3 {
    color: #9299a0;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
  }

  .search-help-text {
    color: #5d646b;
    font-size: 16px;
    font-weight: 500;
    /*text-transform: uppercase;*/
    /*letter-spacing: 0.1em;*/
    margin: 1.5rem 0;
  }




</style>

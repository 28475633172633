<template>
  <div class="side-menu-container">


    <div class="flex justify-between items-center mb-6">
      <h2>Athlete Details</h2>
  <!--            <button class="button primary-action-button"><i class="fas fa-times"/></button>-->
      <button class="close-x" @click="$router.push($route.path)">
        <span class="x-bar"/>
        <span class="x-bar"/>
      </button>
    </div>

    <p class="text-left gradient-header text-3xl font-extrabold">{{ athlete.athlete_first_name }} {{ athlete.athlete_last_name }}</p>
    <p class="text-left text-xl text-rise_gray-500">{{ athlete.athlete_school }}</p>

    <div class="w-full h-px bg-rise_gray-200 mt-4"></div>

    <div class="flex space-x-8 mt-4">

      <div class="flex flex-col items-start">
        <p class="label p-0">DoB</p>
        <p>{{ athlete.athlete_dob | dateFilter }}</p>
      </div>

      <div class="flex flex-col items-center">
        <p class="label p-0">Grade</p>
        <p>{{ athlete.athlete_grade }}</p>
      </div>

    </div>

    <div class="flex flex-col items-start mt-6">
      <p class="label p-0">Sports</p>
      <ul class="flex flex-col items-start">
        <li v-for="sport in athlete.sports_played" class="text-lg">&bull; {{ sport }}</li>
      </ul>
    </div>





<!--    <div class="field">-->
<!--      <div class="label-container">-->
<!--        <label class="label">Session Name</label>-->
<!--  &lt;!&ndash;              <p class="validation-error">{{ errors.name }}</p>&ndash;&gt;-->
<!--      </div>-->
<!--      <div class="control">-->
<!--        <input :class="{'error-border': errors.name}" type="text" placeholder="Elevate">-->
<!--      </div>-->
<!--    </div>-->



<!--    <div class="form-line-wrapper">-->

<!--      <div class="field" style="flex-grow: 1;">-->
<!--        <div class="label-container">-->
<!--          <label class="label">Start Time</label>-->
<!--  &lt;!&ndash;                <p class="validation-error">{{ errors.start_time }}</p>&ndash;&gt;-->
<!--        </div>-->
<!--        <div class="control">-->
<!--          <input :class="{'error-border': errors.start_date}" type="time">-->
<!--        </div>-->
<!--      </div>-->

<!--      <div class="field form-line-child" style="flex-grow: 1;">-->
<!--        <div class="label-container">-->
<!--          <label class="label">Duration <span style="color: #c6c8ca;">(minutes)</span></label>-->
<!--  &lt;!&ndash;                <p class="validation-error">{{ errors.duration_in_minutes }}</p>&ndash;&gt;-->
<!--        </div>-->
<!--        <div class="control">-->
<!--          <input type="number" placeholder="0" min="0">-->
<!--        </div>-->
<!--      </div>-->

<!--    </div>-->

<!--    <div class="field form-line-child" style="flex-grow: 1;">-->
<!--      <div class="label-container">-->
<!--        <label class="label">Total Spots</label>-->
<!--  &lt;!&ndash;              <p class="validation-error">{{ errors.total_spots }}</p>&ndash;&gt;-->
<!--      </div>-->
<!--      <div class="control">-->
<!--        <input  :class="{'error-border': errors.total_spots}" type="number" placeholder="0" min="0">-->
<!--      </div>-->
<!--    </div>-->

<!--    <div class="field">-->
<!--      <div class="label-container">-->
<!--        <label class="label">Description</label>-->
<!--  &lt;!&ndash;              <p class="validation-error">{{ errors.description }}</p>&ndash;&gt;-->
<!--      </div>-->
<!--      <textarea  class="textarea" rows="4"/>-->
<!--    </div>-->

<!--    <div class="field checkbox-container">-->
<!--      <div class="checkbox-info">-->
<!--        <p class="is-semibold font-secondary-blue">Registration Status</p>-->
<!--        <p class="is-small">Allow members to register for this session.</p>-->
<!--      </div>-->
<!--      <label class="switch">-->
<!--        <input  type="checkbox">-->
<!--        <span class="slider round"></span>-->
<!--      </label>-->
<!--    </div>-->

<!--    <button @click=""-->
<!--            class="button-dark full-width-button"-->
<!--            :class="{'is-loading': submitStatus}"-->
<!--            :disabled="submitStatus"-->
<!--            style="margin-top: 3rem;">Create Session</button>-->


  </div>
</template>

<script>
  import { setHours, setMinutes, format } from 'date-fns'
  import {db} from "../../firebase/firebaseInit";
  import {inputCheck} from "../errors";

  export default {
    name: 'NewSessionSideMenu',
    // props: ['newSessionMenuOpen', 'startDateInput'],
    data() {
        return {
          menuOpen: false,
          submitStatus: false,

          newSessionMenuOpen: true,
          startDateInput: null,

          // member data
          athlete: {
            id: '',
            athlete_first_name: '',
            athlete_last_name: '',
            athlete_phone_number: '',
            athlete_grade: '',
            athlete_dob: null,
            athlete_school: '',
            contact_first_name: '',
            contact_last_name: '',
            contact_email: '',
            contact_phone_number: '',
            created_at: null,
            schedule_option: '',
            times: [],
            payments: [],
            invoices: [],
            sports_played: []
          },

          // errors
          errors: {
            name: '',
            start_date: '',
            duration_in_minutes: '',
            total_spots: ''
          }

        }
    },
    filters: {
      dateFilter(value) {
        return format(value, 'M/d/yyyy')
      }
    },
    computed: {},
    methods: {

      // closeSideMenu() {
      //   this.$emit('close');
      //   this.resetDefaultDetails();
      // },

      async getAthleteDetails() {

        await db.collection('athleteindividualregistrations').doc(this.$route.query.athlete_details).get().then(doc => {

          this.athlete.id = doc.id;
          this.athlete.athlete_first_name = doc.data().athlete_first_name;
          this.athlete.athlete_last_name = doc.data().athlete_last_name;
          this.athlete.athlete_phone_number = doc.data().athlete_phone_number;
          this.athlete.athlete_grade = doc.data().athlete_grade;
          this.athlete.athlete_dob = doc.data().athlete_dob.toDate();
          this.athlete.athlete_school = doc.data().athlete_school;
          this.athlete.contact_first_name =  doc.data().contact_first_name;
          this.athlete.contact_last_name = doc.data().contact_last_name;
          this.athlete.contact_email = doc.data().contact_email;
          this.athlete.contact_phone_number = doc.data().contact_phone_number;
          this.athlete.created_at = doc.data().created_at.toDate();
          this.athlete.schedule_option = doc.data().schedule_option;
          this.athlete.times = [];
          this.athlete.payments = doc.data().payments;
          this.athlete.invoices = doc.data().invoices;
          this.athlete.sports_played = doc.data().sports_played;

        })

      }



    },
    async created() {
      await this.getAthleteDetails();
    },
    watch: {

    }
  }


</script>

<style scoped>

  .side-menu-background {
    position: fixed;
    z-index: 9;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(249, 252, 255, 0.7);
  }

  .side-menu-container {
    position: fixed;
    z-index: 99;
    top: 55px;
    right: 0;
    width: 100%;
    max-width: 450px;
    height: calc(100vh - 55px);
    background: #ffffff;
    border-left: 1px solid #e4e7ea;
    overflow-y: scroll;
    overflow-x: hidden;

    padding: 1.5rem;

    /*transition: 0.23s margin-left ease;*/
  }

  .close-x {
    font-size: 20px;
    color: #123968;
    cursor: pointer;
    margin: 0;
    padding: 11px 10px;
    background: transparent;
    border: none;
    border-radius: 6px;
    transition: 0.2s all ease;
  }
  .close-x:hover {
    background: #eceff3;
  }
  .close-x:focus {
    outline: none;
  }
  .x-bar {
    display: block;
    width: 20px;
    height: 2px;
    margin: 0.2em 0;
    background-color: #2c3e50;
  }
  .x-bar:first-child {
    transform: translate(0,3px) rotate(45deg);
  }
  .x-bar:last-child {
    transform: translate(0,-3px) rotate(-45deg);
  }

  .full-width-button {
    display: block;
    width: 100%;
    text-align: center!important;
  }
  .full-width-button:active {
    transform: scale(0.99);
  }


</style>

<template>
  <div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar">
          <div class="toolbar-section">
            <h2>Program Info</h2>
          </div>

          <div class="toolbar-section">
            <button v-if="id" class="button-light">Edit</button>
          </div>
        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent">
        <div class="tile is-child box">

          <div class="loading-spinner" v-if="!programLoaded"></div>

          <div class="detail-info animated fadeIn fast" v-if="programLoaded">

            <div class="detail-title-row">

              <div class="detail-title-item client-title">
                <h1 class="gradient-header">{{ name }}</h1>
              </div>
            </div>

            <div class="detail-info-row">

              <div v-if="is_active" class="status-label"><span class="status-circle is-active" style="padding-right: 0.2rem;"><i class="fas fa-circle"></i></span>Active</div>
              <div v-if="!is_active" class="status-label"><span class=" status-circle is-inactive" style="padding-right: 0.2rem;"><i class="far fa-circle"></i></span>Inactive</div>

            </div>

            <div class="detail-info-row">

              <div class="detail-info-item">
                <h4>Program ID</h4>
                <p>{{ id }}</p>
              </div>

              <div class="detail-info-item">
                <h4>Dates</h4>
                <p>{{ start_date | dateFilter }} - {{ end_date | dateFilter }}</p>
              </div>

            </div>

            <div class="detail-info-row">

              <div class="detail-info-item">
                <h4 class="mb-2">Schedules</h4>
                <div class="flex flex-wrap">
                  <div v-for="option in schedule" :key="option.id" class="border rounded-md mr-2 mb-2 px-3 py-2">
                    <p class="text-rise_gray-900 font-medium ">{{ option.name }}</p>
                    <p class="text-rise_gray-500 text-sm">{{ option.start_date.seconds | scheduleDateFilter }}  &nbsp;-&nbsp; {{ option.end_date.seconds | scheduleDateFilter }}</p>
                  </div>
                </div>
              </div>

            </div>

            <div class="detail-info-row">

              <div class="detail-info-item">
                <h4 class="mb-2">Times</h4>
                <div class="flex flex-wrap">
                  <button v-for="time in times" :key="time.id" class="text-rise_gray-700 font-medium bg-white bg-rise_gray-200 rounded-md mr-3 mb-3 px-2 py-1 focus:outline-none focus:ring ring-rise_gray-400">{{ time.day }} @ {{ time.start_time | timeFilter }}</button>
                </div>
              </div>

            </div>

            <div class="detail-info-row">

              <div class="detail-info-item">
                <h4>Description</h4>
                <p>{{ description }}</p>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar">
          <div class="toolbar-section">
            <h2>Registered Athletes</h2>
          </div>

          <div class="toolbar-section">

            <div class="flex items-center mr-6">
              <p class="font-semibold text-rise_blue-900 mr-3">View</p>

              <div class="flex bg-rise_gray-300 rounded-lg px-1.5 py-1.5">
                <button @click="athleteViewFormat = 'athlete'" class="text-sm font-semibold px-2 py-1 rounded mr-1 focus:outline-none" :class="{'selected-list-format': athleteViewFormat === 'athlete'}"><i class="fas fa-list text-xs"></i> Athlete</button>
                <button @click="athleteViewFormat = 'date'" class="text-sm font-semibold px-2 py-1 rounded focus:outline-none" :class="{'selected-list-format': athleteViewFormat === 'date'}"><i class="far fa-calendar text-xs"></i> Date</button>
              </div>

            </div>

            <button v-if="id" class="button-dark"><i class="fas fa-plus text-xs"></i>&nbsp; Add</button>
          </div>
        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent">
        <div class="tile is-child box">

<!--          <div class="flex items-center mb-4">-->
<!--            <p class="font-semibold text-rise_blue-900 mr-4">View</p>-->

<!--            <div class="flex bg-rise_gray-300 rounded-lg px-1.5 py-1.5">-->
<!--              <button @click="athleteViewFormat = 'athlete'" class="text-sm font-semibold px-2 py-1 rounded mr-1 focus:outline-none" :class="{'selected-list-format': athleteViewFormat === 'athlete'}"><i class="fas fa-list text-xs"></i> Athlete</button>-->
<!--              <button @click="athleteViewFormat = 'date'" class="text-sm font-semibold px-2 py-1 rounded focus:outline-none" :class="{'selected-list-format': athleteViewFormat === 'date'}"><i class="far fa-calendar text-xs"></i> Date</button>-->
<!--            </div>-->

<!--          </div>-->

          <!-- Registered Athletes Table -->
          <div class="loading-spinner" v-if="!athletesLoaded"></div>

          <h2 class="empty-state" v-if="athletesLoaded && !registeredAthletes.length">There are no registered athletes available.</h2>

          <div class="table-container" v-if="athletesLoaded && registeredAthletes.length > 0 && athleteViewFormat === 'athlete'">
            <table class="table is-fullwidth animated fadeIn fast">

              <thead>
              <tr>
                <th style="width: 3%; min-width: 40px;"></th>
                <th>Athlete Name</th>
                <th>Schedule Option</th>
                <th>Times</th>
              </tr>
              </thead>

              <tbody>

              <tr v-for="athlete in registeredAthletes" v-bind:key="athlete.id" @click="$router.push({query: {athlete_details: athlete.id}})" class="is-selectable">
                <td><span class="status-label is-small light-blue-label">{{ athlete.athlete_grade }}</span></td>
                <td><span class="font-primary-blue">{{ athlete.athlete_first_name }} {{ athlete.athlete_last_name }}</span></td>
                <td>{{ athlete.schedule_option }}</td>
                <td class="space-x-2"><span v-for="time in athlete.times" class="text-sm font-medium bg-rise_gray-200 px-2 py-1 rounded-md">{{ time.day.slice(0,3) }}. @ {{ time.start_time | timeFilter }}</span></td>
              </tr>

              </tbody>

            </table>
          </div>

          <div class="table-container" v-if="athletesLoaded && registeredAthletes.length > 0 && athleteViewFormat === 'date'">
            <table class="table is-fullwidth animated fadeIn fast">

              <thead>
              <tr>
                <th style="width: 3%; min-width: 40px;"></th>
                <th>Athlete Name</th>
                <th>Schedule Option</th>
                <th>Workouts</th>
              </tr>
              </thead>

              <template v-for="day in athletesByDate">

                <thead>
                <tr>
                  <td class="table-header" colspan="4">{{ day.time.day }} @ {{ day.time.start_time | timeFilter }}</td>
                  <td class="table-header has-text-right">
<!--                    <button class="add-session-button" @click="openNewSessionMenu(day.date)">-->
<!--                      <i class="fas fa-plus" style="font-size: 11px;"/>&nbsp;New-->
<!--                    </button>-->
                  </td>
                </tr>
                </thead>

                <tbody>

                <tr v-for="(athlete, index) in day.athletes" :key="index">
                  <td><span class="status-label is-small light-blue-label">{{ athlete.athlete_grade }}</span></td>
                  <td><span class="font-primary-blue">{{ athlete.athlete_first_name }} {{ athlete.athlete_last_name }}</span></td>
                  <td>{{ athlete.schedule_option }}</td>
                  <td class="">{{ athlete.times.length }} workouts/week</td>
                </tr>

                <tr v-if="day.athletes.length === 0">
                  <td class="text-rise_gray-400" colspan="5">No athletes registered.</td>
                </tr>

                </tbody>

              </template>



            </table>
          </div>


        </div>
      </div>
    </div>

    <transition enter-active-class="animated slideInRight fastest" leave-active-class="animated slideOutRight fastest" mode="out-in">
      <IndividualAthleteSideMenu v-if="$route.query.athlete_details"></IndividualAthleteSideMenu>
    </transition>
    <transition name="fade" mode="out-in">
      <div class="fixed inset-0 bg-rise_gray-50 bg-opacity-70" v-if="$route.query.athlete_details" @click="$router.push($route.path)"></div>
    </transition>

  </div>
</template>

<script>
  import { db } from './../../firebase/firebaseInit';
  import { format, toDate } from 'date-fns';
  import IndividualAthleteSideMenu from '@/components/IndividualAthleteSideMenu';

  export default {
    name: 'AthleteIndividualProgramDetail',
    components: { IndividualAthleteSideMenu },
    data: function() {
      return {
        programLoaded: false,
        athletesLoaded: true,
        athleteViewFormat: 'athlete', // athlete or date

        id: '',
        name: '',
        is_active: null,
        start_date: null,
        end_date: null,
        description: '',

        schedule: [],
        times: [],

        registeredAthletes: []
      }
    },
    filters: {
      dateFilter: function(value) {
        return format(value, 'MMMM d, y')
      },
      scheduleDateFilter(value) {
        return format(toDate(value * 1000), 'EEEE, MMMM d')
      },
      timeFilter: function(value) {
        let time = value.split(':');
        let hours = parseInt(time[0]);
        let minutes = parseInt(time [1]);
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return `${hours}:${minutes} ${ampm}`
      },
      phoneNumberFilter: function(value) {
        return value.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
      }
    },
    computed: {
      athletesByDate() {

        let list = [];

        this.times.forEach(individualTime => {

          const timeSlot = {
            time: null,
            athletes: []
          };

          timeSlot.time = individualTime;

          timeSlot.athletes = this.registeredAthletes.filter(athlete => athlete.times.some(time => time.id === individualTime.id));
          // timeSlot.athletes = this.registeredAthletes;

          list.push(timeSlot);

        })

        return list;

      }
    },
    methods: {

      async getProgram() {

        this.programLoaded = false;

        await db.collection('athleteindividualprograms').doc(this.$route.params.id).get().then(
          doc => {
            const {name, is_active, start_date, end_date, description, schedule } = doc.data();

            this.id = doc.id;
            this.name = name;
            this.is_active = is_active;
            this.start_date = start_date.toDate();
            this.end_date = end_date.toDate();
            this.description = description;
            this.schedule = schedule;

          }
          );

        await db.collection('athleteindividualtimes')
          .where('program_id', '==', this.$route.params.id)
          .orderBy('day_index', 'asc')
          .orderBy('start_time')
          .get().then(querySnap => {
          querySnap.forEach(doc => {

            const time = {
              id: doc.id,
              program_id: doc.data().program_id,
              is_open: doc.data().is_open,
              day_index: doc.data().day_index,
              day: doc.data().day,
              start_time: doc.data().start_time,
              end_time: doc.data().end_time,
              spots: doc.data().spots
            }

            this.times.push(time);

          })
        })

        this.programLoaded = true;

      },

      async getRegisteredAthletes() {

        this.athletesLoaded = false;

        await db.collection('athleteindividualregistrations')
          .where('program_id', '==', this.$route.params.id)
          .orderBy('created_at', 'asc')
          .get().then(
          querySnapshot => {
            querySnapshot.forEach(doc => {

              const athleteData = {
                id: doc.id,
                athlete_first_name: doc.data().athlete_first_name,
                athlete_last_name: doc.data().athlete_last_name,
                athlete_phone_number: doc.data().athlete_phone_number,
                athlete_grade: doc.data().athlete_grade,
                athlete_dob: doc.data().athlete_dob.toDate(),
                contact_first_name: doc.data().contact_first_name,
                contact_last_name: doc.data().contact_last_name,
                contact_email: doc.data().contact_email,
                contact_phone_number: doc.data().contact_phone_number,
                created_at: doc.data().created_at.toDate(),
                schedule_option: doc.data().schedule_option,
                times: [],
                payments: doc.data().payments,
                invoices: doc.data().invoices,
              }

              const timeIds = doc.data().times;
              timeIds.forEach(id => {
                const data = this.times.find(time => time.id === id);
                athleteData.times.push(data)
              });

              this.registeredAthletes.push(athleteData)

            })
            this.athletesLoaded = true;
          }
        ).catch(err => {
          console.error(err)
          this.athletesLoaded = true;
        })
      }

    },
    async created() {
      await this.getProgram();
      await this.getRegisteredAthletes();
    }
  }
</script>

<style scoped>

.selected-list-format {
  @apply text-rise_gray-900 bg-white shadow;
}

</style>

<template>
    <div>

      <div class="tile">
        <div class="toolbar-wrapper">
          <div class="toolbar toolbar-centered max-width-800">
            <div class="toolbar-section">
              <h2 class="has-text-left">Athlete Info</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="tile">
        <div class="tile is-parent is-centered">
          <div class="tile is-child box max-width-800">

            <div class="field">
              <label class="label">Status</label>
              <label class="switch">
                <input v-model="newAthlete.is_active" type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>

            <div class="field">
              <div class="label-container">
                <label class="label">First Name</label>
                <p class="validation-error">{{ errors.first_name }}</p>
              </div>
              <div class="control">
                <input v-model="newAthlete.first_name" type="text" placeholder="John" required>
              </div>
            </div>

            <div class="field">
              <div class="label-container">
                <label class="label">Last Name</label>
                <p class="validation-error">{{ errors.last_name }}</p>
              </div>
              <div class="control">
                <input v-model="newAthlete.last_name" type="text" placeholder="Doe" required>
              </div>
            </div>

            <div class="field">
              <div class="label-container">
                <label class="label">Email</label>
                <p class="validation-error">{{ errors.email }}</p>
              </div>
              <div class="control">
                <input v-model="newAthlete.email" type="email" placeholder="example@me.com" required>
              </div>
            </div>

            <div class="field">
              <div class="label-container">
                <label class="label">Phone Number</label>
                <p class="validation-error">{{ errors.phone_number }}</p>
              </div>
              <div class="control">
                <input v-model="phone_number_input" v-mask="'(###) ###-####'" type="tel" placeholder="(218) 555-0100" maxlength="14">
              </div>
            </div>

            <div class="field">
              <div class="label-container">
                <label class="label">School Name</label>
                <p class="validation-error">{{ errors.first_name }}</p>
              </div>
              <div class="control">
                <input v-model="newAthlete.school" type="text" placeholder="Moorhead High School" required>
              </div>
            </div>

            <div class="flex">

              <div class="field mr-4">
                <label class="label">Date of Birth</label>
                <div class="control w-40">
                  <v-date-picker mode="single"
                                 v-model="newAthlete.date_of_birth"
                                 :popover="{visibility: 'focus'}">
                    <input type='text' placeholder="MM/DD/YYYY"
                           slot-scope="{ inputProps, inputEvents }"
                           v-bind="inputProps"
                           v-on="inputEvents">
                  </v-date-picker>
                </div>
              </div>

              <div class="field">
                <label class="label">HS Class</label>
                <div class="select">
                  <select v-model="newAthlete.hs_class_year" @focus="" @change="" required>
                    <option value="" disabled selected>Select a Class</option>
                    <option v-for="year in years" :value="year">{{ year }}</option>
                  </select>
                </div>
              </div>

            </div>

            <hr class="bg-rise_gray-300 w-full h-px my-6">

            <div class="field">
              <label class="label">Goals</label>
              <textarea v-model="newAthlete.goals" class="textarea" rows="2"></textarea>
            </div>

            <div class="field">
              <label class="label">Notes</label>
              <textarea v-model="newAthlete.notes" class="textarea" rows="2"></textarea>
            </div>

          </div>
        </div>
      </div>

      <div class="tile">
        <div class="toolbar-wrapper">
          <div class="toolbar toolbar-centered max-width-800">
            <div class="toolbar-section">
              <h2 class="has-text-left">Parent/Guardian Info</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="tile">
        <div class="tile is-parent is-centered">
          <div class="tile is-child box max-width-800">

            <div class="field">
              <div class="label-container">
                <label class="label">First Name</label>
              </div>
              <div class="control">
                <input v-model="newAthlete.contact_first_name" type="text" placeholder="John" required>
              </div>
            </div>

            <div class="field">
              <div class="label-container">
                <label class="label">Last Name</label>
              </div>
              <div class="control">
                <input v-model="newAthlete.contact_last_name" type="text" placeholder="Doe" required>
              </div>
            </div>

            <div class="field">
              <div class="label-container">
                <label class="label">Email</label>
              </div>
              <div class="control">
                <input v-model="newAthlete.contact_email" type="email" placeholder="example@me.com" required>
              </div>
            </div>

            <div class="field">
              <div class="label-container">
                <label class="label">Phone Number</label>
              </div>
              <div class="control">
                <input v-model="contact_phone_number_input" v-mask="'(###) ###-####'" type="tel" placeholder="(218) 555-0100" maxlength="14">
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="tile">
        <div class="toolbar-wrapper">
          <div class="toolbar toolbar-centered max-width-800">
            <div class="toolbar-section"></div>

            <div class="toolbar-section">
              <router-link to="/members" class="button-light">Cancel</router-link>
              <button @click="validateNewAthlete" class="button-dark ml-4" :class="{'is-loading': submitStatus}" :disabled="submitStatus">Add Member</button>
            </div>
          </div>
        </div>
      </div>

    </div>
</template>

<script>
  import { db } from './../../firebase/firebaseInit';
  import numeral from 'numeral';
  import { notifySuccess, notifyError } from '@/notifications';

  export default {
        name: "NewMember",
        data: function() {
          return {
            submitStatus: false,
            phone_number_input: '',
            contact_phone_number_input: '',

            newAthlete: {
              is_active: true,
              first_name: '',
              last_name: '',
              email: '',
              phone_number: '',
              school: '',
              date_of_birth: null,
              hs_class_year: null,
              signup_date: null,
              goals: '',
              notes: '',

              contact_first_name: '',
              contact_last_name: '',
              contact_email: '',
              contact_phone_number: ''
            },

            errors: {
              first_name: '',
              last_name: '',
              email: '',
              phone_number: '',
              membership_type: ''
            }

          }
        },
        filters: {

        },
        computed: {
          years() {
            let years = [];
            let i = 2015;
            while (i <= (new Date().getFullYear() + 6)) {
              years.push(i)
              i++
            }
            return years;
          },
        },
        methods: {

          validateNewAthlete() {
            this.errors.first_name = this.newAthlete.first_name ? '' : 'first name required.';
            this.errors.last_name = this.newAthlete.last_name ? '' : 'last name required.'
            this.errors.email = this.newAthlete.email ? '' : 'email required.';
            this.errors.phone_number = this.newAthlete.phone_number ? '' : 'phone number required.';

            if (
              !this.errors.first_name &&
              !this.errors.last_name &&
              !this.errors.email &&
              !this.errors.phone_number
            ) {
              this.createNewAthlete();
            } else {
              notifyError('Missing Info', 'Please fill out all required information.')
            }
          },

          async createNewAthlete() {

            this.submitStatus = true;
            this.newAthlete.signup_date = new Date();

            await db.collection('athletes').add(this.newAthlete).then(doc => {
              // this.$router.push({name: 'Athlete Detail', params: {member_id: docRef.id}})
              this.$router.push('/athletes');
              notifySuccess('Athlete Created', `${this.newAthlete.first_name} ${this.newAthlete.last_name} was created.`);
            }).catch(function(err) {
              console.error(err);
              notifyError('Error', err.message);
            });

            this.submitStatus = false;

          }

        },
        watch: {
          'phone_number_input'() {
            return this.newAthlete.phone_number = this.phone_number_input.replace(/\D+/g, "");
          },
          'contact_phone_number_input'() {
            return this.newAthlete.contact_phone_number = this.contact_phone_number_input.replace(/\D+/g, "");
          }
        }
    }
</script>

<style scoped>

  /*---- FORM STYLE ----*/

  .label-wrapper {
    display: flex;
    flex-direction: row;
  }
  .program-item {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    margin: 0 0 0.75em 0;
    padding: 0.5em;
    background-color: #fbfbfb;
    border: 1px solid #c8ccd0;
    border-radius: 4px;

    transition: 0.2s all ease-in-out;
  }
  .program-item:hover {
    border: 1px solid #9299a0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  .program-item.selected {
    background-color: #ffffff;
    border: 1px solid #2c9fe5;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  .program-item p {
    /*text-align: left;*/
    color: #9299a0;
    font-size: 16px;
    font-weight: 400;
  }
  .program-item.selected p {
    /*font-weight: 600;*/
    color: #123968;
  }
  .program-item.disabled {
    cursor: not-allowed;
    color: #c6c8ca;
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
  }
  .program-item.disabled:hover {
    box-shadow: none;
  }
  .program-item-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .program-item-wrapper .program-item {
    margin-right: 0.75em;
    padding: 0.5em 0.75em;
  }

  .payment-wrapper {
    background: linear-gradient(300deg, rgba(18, 57, 104, 0.7) 0%, rgba(18, 57, 104, 0.9) 100%);
    margin-top: 2em!important;
    padding-top: 1em;
    padding-bottom: 1em;
    /*box-shadow: none;*/
  }
  .payment-wrapper .columns:not(last-child) {
    margin-bottom: 0;
  }
  .payment-underline {
    width: 100%;
    margin-bottom: 1em;
    height: 1px;
    /*background: linear-gradient(to right, #ffffff, rgba(255,255,255,0));*/
    background-color: #c6c8ca;
  }
  .payment-wrapper h3 {
    color: #ffffff;
  }
  .payment-wrapper p {
    color: #ffffff;
    font-size: 18px;
  }
  .payment-wrapper .column-header {
    margin: 0 0 0.5em 0.7em;
  }
  .payment-wrapper .label {
    color: #ffffff;
    padding: 0;
  }
  .payment-label {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    background: rgba(18, 57, 104, 0.05);
    padding: 0.7em 1em;
    border-radius: 3px;
  }
  .payment-label-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .payment-label-section:first-child {
    text-align: left;
  }
  .payment-label-section:last-child {
    text-align: right;
  }
  .payment-label h5 {
    font-size: 16px;
    font-weight: 700;
    color: #123968;
  }
  .payment-label p {
    font-size: 16px;
    color: #5d646b;
  }
  .payment-info-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 1em 0;
  }
  .clear-program {
    color: rgba(18,57,104,0.8);
    transition: all 0.2s ease;
  }
  .clear-program:hover {
    color: #123968;
    cursor: pointer;
  }
  .payment-input {
    font-size: 14px;
    width: 50px;
    text-align: right;
    margin-bottom: 0;
    padding: 5px;
    border-radius: 4px;
  }
  .payment-input:focus {
    box-shadow: none;
  }
  .is-bold {
    font-weight: 800;
  }

  @media (min-width: 768px) {

    .payment-wrapper {
      /*padding-top: 2em;*/
      /*padding-bottom: 2em;*/
    }

  }



</style>

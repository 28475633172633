<template>
  <div>


    <transition enter-active-class="animated slideInRight fastest" leave-active-class="animated slideOutRight fastest" mode="out-in">
        <div class="side-menu-container" :class="{'container-open': newSessionMenuOpen}" v-if="newSessionMenuOpen" @click.stop>


          <div style="display: flex; justify-content: space-between; align-items: center; margin-bottom: 2rem;">
            <h2>New Session</h2>
<!--            <button class="button primary-action-button"><i class="fas fa-times"/></button>-->
            <button class="close-x" @click="closeSideMenu">
              <span class="x-bar"/>
              <span class="x-bar"/>
            </button>
          </div>

          <div class="field">
            <div class="label-container">
              <label class="label">Session Name</label>
<!--              <p class="validation-error">{{ errors.name }}</p>-->
            </div>
            <div class="control">
              <input v-model="new_session.name" :class="{'error-border': errors.name}" type="text" placeholder="Elevate">
            </div>
          </div>

          <div class="field" style="flex-grow: 1;">
            <div class="label-container">
              <label class="label">Date</label>
<!--              <p class="validation-error">{{ errors.start_date }}</p>-->
            </div>
            <div class="control">
              <v-date-picker mode="single"
                             v-model="startDateInput"
                             :min-date="new Date()"
                             :popover="{visibility: 'focus'}">
                <input type='text' placeholder="MM/DD/YYYY"
                       slot-scope="{ inputProps, inputEvents }"
                       v-bind="inputProps"
                       v-on="inputEvents"
                       :class="{'error-border': errors.start_date}">
              </v-date-picker>
            </div>
          </div>

          <div class="form-line-wrapper">

            <div class="field" style="flex-grow: 1;">
              <div class="label-container">
                <label class="label">Start Time</label>
<!--                <p class="validation-error">{{ errors.start_time }}</p>-->
              </div>
              <div class="control">
                <input v-model="start_time_input" :class="{'error-border': errors.start_date}" type="time">
              </div>
            </div>

            <div class="field form-line-child" style="flex-grow: 1;">
              <div class="label-container">
                <label class="label">Duration <span style="color: #c6c8ca;">(minutes)</span></label>
<!--                <p class="validation-error">{{ errors.duration_in_minutes }}</p>-->
              </div>
              <div class="control">
                <input v-model.number="new_session.duration_in_minutes" :class="{'error-border': errors.duration_in_minutes}" type="number" placeholder="0" min="0">
              </div>
            </div>

          </div>

          <div class="field form-line-child" style="flex-grow: 1;">
            <div class="label-container">
              <label class="label">Total Spots</label>
<!--              <p class="validation-error">{{ errors.total_spots }}</p>-->
            </div>
            <div class="control">
              <input v-model.number="new_session.total_spots" :class="{'error-border': errors.total_spots}" type="number" placeholder="0" min="0">
            </div>
          </div>

          <div class="field">
            <div class="label-container">
              <label class="label">Description</label>
<!--              <p class="validation-error">{{ errors.description }}</p>-->
            </div>
            <textarea v-model="new_session.description" class="textarea" rows="4"/>
          </div>

          <div class="field checkbox-container">
            <div class="checkbox-info">
              <p class="is-semibold font-secondary-blue">Registration Status</p>
              <p class="is-small">Allow members to register for this session.</p>
            </div>
            <label class="switch">
              <input v-model="new_session.registration_open" type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>

          <button @click="validateNewSession"
                  class="button-dark full-width-button"
                  :class="{'is-loading': submitStatus}"
                  :disabled="submitStatus"
                  style="margin-top: 3rem;">Create Session</button>


        </div>
    </transition>

    <transition name="fade" mode="out-in">
      <div class="side-menu-background" v-if="newSessionMenuOpen" @click="closeSideMenu"></div>
    </transition>


  </div>
</template>

<script>
  import { setHours, setMinutes } from 'date-fns'
  import {db} from "../../firebase/firebaseInit";
  import {inputCheck} from "../errors";

  export default {
    name: 'NewSessionSideMenu',
    props: ['newSessionMenuOpen', 'startDateInput'],
    data() {
        return {
          menuOpen: false,
          submitStatus: false,

          // member data
          // start_date_input: null,
          start_time_input: null,

          new_session: {
            name: 'Elevate',
            start_date: null,
            duration_in_minutes: Number(45),
            total_spots: Number(12),
            description: `Elevate is a high intensity circuit training group fitness session. As a well-rounded workout, it consists of a warm-up, cardio circuit, strength training, and cool down.

Sessions are structured in a variety of formats, and every day is different than the last with unique movements and exercises.`,
            instructor: null,
            registration_open: true,
            registrations: Number(0),
            is_public: false
          },

          // errors
          errors: {
            name: '',
            start_date: '',
            duration_in_minutes: '',
            total_spots: ''
          }

        }
    },
    filters: {},
    computed: {},
    methods: {

      closeSideMenu() {
        this.$emit('close');
        this.resetDefaultDetails();
      },

      reloadSessions() {
        this.$emit('reload');
      },

      resetDefaultDetails() {
        this.new_session.name = 'Elevate';
        this.start_time_input = null;
        this.new_session.start_date = null;
        this.new_session.duration_in_minutes = Number(45);
        this.new_session.total_spots = Number(12);
        this.new_session.description = `Elevate is a high intensity circuit training group fitness session. As a well-rounded workout, it consists of a warm-up, cardio circuit, strength training, and cool down.

Sessions are structured in a variety of formats, and every day is different than the last with unique movements and exercises.`;
      },

      setStartDate() {
        if (this.startDateInput && this.start_time_input) {
          let time = this.start_time_input.split(':');
          let hours = parseInt(time[0]);
          let minutes = parseInt(time[1]);
          this.new_session.start_date = setHours(setMinutes(this.startDateInput, minutes), hours)
        } else {
          this.new_session.start_date = null
        }
      },

      validateNewSession() {
        this.setStartDate();

        this.errors.name = inputCheck(this.new_session.name);
        this.errors.start_date = inputCheck(this.new_session.start_date);
        this.errors.duration_in_minutes = inputCheck(this.new_session.duration_in_minutes);
        this.errors.total_spots = inputCheck(this.new_session.total_spots);

        if (!this.errors.name && !this.errors.start_date && !this.errors.duration_in_minutes && !this.errors.total_spots) {
          this.createNewSession();
        } else {
          this.submissionFailure('Please enter required information.');
        }
      },

      async createNewSession() {
        this.submitStatus = true;
        // this.setStartDate();

        db.collection('sessions').add(this.new_session).then(doc => {
          this.submissionSuccess('Session Created', 'New session successfully created.');
          // this.$router.push('/sessions');
          this.closeSideMenu();
          this.reloadSessions();
          this.submitStatus = false;
        }).catch(err => {
          this.submissionFailure(err.message);
          this.submitStatus = false;
        })
      },

      submissionSuccess(title, message) {
        this.$notify({
          group: 'master',
          title: `${title}`,
          text: `${message}`,
          type: 'success'
        })
      },
      submissionFailure(message) {
        this.$notify({
          group: 'master',
          title: 'An Error Occurred',
          text: `${message}`,
          type: 'error'
        })
      }

    },
    created() {

    },
    watch: {

    }
  }


</script>

<style scoped>

  .side-menu-background {
    position: fixed;
    z-index: 9;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: rgba(249, 252, 255, 0.7);
  }

  .side-menu-container {
    position: fixed;
    z-index: 99;
    top: 55px;
    right: 0;
    width: 100%;
    max-width: 450px;
    height: calc(100vh - 55px);
    background: #ffffff;
    border-left: 1px solid #e4e7ea;
    overflow-y: scroll;
    overflow-x: hidden;

    padding: 1.5rem;

    transition: 0.23s margin-left ease;
  }

  .close-x {
    font-size: 20px;
    color: #123968;
    cursor: pointer;
    margin: 0;
    padding: 11px 10px;
    background: transparent;
    border: none;
    border-radius: 6px;
    transition: 0.2s all ease;
  }
  .close-x:hover {
    background: #eceff3;
  }
  .close-x:focus {
    outline: none;
  }
  .x-bar {
    display: block;
    width: 20px;
    height: 2px;
    margin: 0.2em 0;
    background-color: #2c3e50;
  }
  .x-bar:first-child {
    transform: translate(0,3px) rotate(45deg);
  }
  .x-bar:last-child {
    transform: translate(0,-3px) rotate(-45deg);
  }

  .full-width-button {
    display: block;
    width: 100%;
    text-align: center!important;
  }
  .full-width-button:active {
    transform: scale(0.99);
  }


</style>

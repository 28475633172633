<template>
  <div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar toolbar-centered max-width-800">
          <h2>Program Information</h2>
        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent is-centered">
        <div class="tile is-child box max-width-800">

          <div class="columns">
            <div class="column">

              <!-- IS ACTIVE -->
              <div class="field">
                <label class="label">Status</label>
                <label class="switch">
                  <input v-model="addNewProgram.is_active" type="checkbox">
                  <span class="slider round"></span>
                </label>
              </div>

              <!-- PROGRAM TYPE -->
              <div class="field">

                <div class="label-container">
                  <label class="label">Program Type</label>
                  <p class="validation-error">{{ errors.program_type }}</p>
                </div>

                  <div @click="addNewProgram.program_type = 'Performance'" v-bind:class="{'selected': addNewProgram.program_type === 'Performance'}" class="program-item">
                    <p>Performance</p>
                  </div>

                  <div @click="addNewProgram.program_type = 'Camp'" v-bind:class="{'selected': addNewProgram.program_type === 'Camp'}" class="program-item">
                    <p>Camp</p>
                  </div>

              </div>

              <!-- PROGRAM ID -->
              <div class="field">

                <div class="label-container">
                  <label class="label">Program ID</label>
                  <p class="validation-error">{{ errors.program_id }}</p>
                </div>


                <div class="control">
                  <input v-model="program_id" type="text" placeholder="AthleteSummer19" required @blur="programIdToLowerCase">
                </div>
              </div>

              <!-- PROGRAM NAME -->
              <div class="field">

                <div class="label-container">
                  <label class="label">Program Name</label>
                  <p class="validation-error">{{ errors.name }}</p>
                </div>

                <div class="control">
                  <input v-model="addNewProgram.name" type="text" placeholder="Athlete Performance - Summer 2019" required>
                </div>
              </div>


              <!-- START AND END DATE -->
              <div class="form-line-wrapper">

                <div class="field form-line-child">

                  <div class="label-container">
                    <label class="label">Start Date</label>
                    <p class="validation-error">{{ errors.start_date }}</p>
                  </div>

                  <div class="control">
                    <v-date-picker mode="single"
                                   v-model="addNewProgram.start_date"
                                   :min-date="new Date()"
                                   :disabled-dates="{ weekdays: [1, 7] }"
                                   :popover="{visibility: 'focus'}">
                      <input type='text' placeholder="MM/DD/YYYY"
                             slot-scope="{ inputProps, inputEvents }"
                             v-bind="inputProps"
                             v-on="inputEvents">
                    </v-date-picker>
                  </div>

                </div>

                <div class="field form-line-child">

                  <div class="label-container">
                    <label class="label">End Date</label>
                    <p class="validation-error">{{ errors.end_date }}</p>
                  </div>

                  <div class="control">
                    <v-date-picker mode="single"
                                   v-model="addNewProgram.end_date"
                                   :min-date="new Date()"
                                   :disabled-dates="{ weekdays: [1, 7] }"
                                   :popover="{visibility: 'focus'}">
                      <input type='text' placeholder="MM/DD/YYYY"
                             slot-scope="{ inputProps, inputEvents }"
                             v-bind="inputProps"
                             v-on="inputEvents">
                    </v-date-picker>
                  </div>

                </div>

              </div>

              <!-- DAYS OF WEEK -->
              <div class="field animated fadeIn fast" v-if="addNewProgram.program_type === 'Camp'">

                <label class="label">Select Days</label>

                <div class="day-select-container">
                  <div class="day-select-item" v-for="day in daysOfWeek" v-bind:key="day.id">
                    <label class="switch">
                      <input type="checkbox" v-model="selectedDaysOfWeek" v-bind:value="day">
                      <span class="slider round"></span>
                      <h4 class="has-text-centered" style="margin-top: 5px;">{{ day.name | dayFilter }}</h4>
                    </label>
                  </div>
                </div>

              </div>

              <!-- START AND END TIME -->
              <div class="form-line-wrapper animated fadeIn fast" v-if="addNewProgram.program_type === 'Camp'">

                <div class="field form-line-child" style="max-width: 130px;">

                  <label class="label">Start Time</label>
                  <div class="control">
                    <input type="time" v-model="addNewProgram.start_time">
                  </div>


                </div>

                <div class="field form-line-child" style="max-width: 130px;">

                  <label class="label">End Time</label>
                  <div class="control">
                    <input type="time" v-model="addNewProgram.end_time">
                  </div>

                </div>

              </div>

              <!-- TOTAL COST -->
              <div class="form-line-wrapper"></div>
              <div class="field" v-if="addNewProgram.program_type === 'Camp'">
                <label class="label">Paid Camp</label>
                <label class="switch">
                  <input v-model="addNewProgram.is_paid" type="checkbox">
                  <span class="slider round"></span>
                </label>
              </div>

              <div class="field animated fadeIn fast" v-if="addNewProgram.program_type === 'Camp' && addNewProgram.is_paid">
                <label class="label">Total Cost</label>
                <div class="control">
                  <money v-model="addNewProgram.cost"></money>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar toolbar-centered max-width-800">
          <div class="toolbar-section"></div>

          <div class="toolbar-section">
            <router-link to="/athleteprograms" class="button-light">Cancel</router-link>
            <button @click="submitNewAthleteProgram" class="button-dark margin-left">Save &nbsp;<i class="fas fa-angle-right"></i></button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {db} from './../../firebase/firebaseInit'
  import debounce from 'lodash.debounce'

  export default {
    name: 'NewAthleteProgram',
    data: function() {
      return {
        submissionStatus: false,
        programs: [],
        program_id: '',
        addNewProgram: {
          name: '',
          start_date: null,
          end_date: null,
          is_active: true,
          program_type: '',
          days_of_week: [],
          start_time: null,
          end_time: null,
          is_paid: false,
          cost: 0
        },
        daysOfWeek: [{name:'Monday', id:1}, {name:'Tuesday', id:2}, {name:'Wednesday', id:3}, {name:'Thursday', id:4}, {name:'Friday', id:5}],
        selectedDaysOfWeek: [],
        errors: {
          program_id: '',
          name: '',
          start_date: '',
          end_date: '',
          program_type: ''
        }
      }
    },
    filters: {
      dayFilter: function(value) {
        return value.slice(0,3) + '.'
      }
    },
    computed: {
      sortedDaysOfWeek: function() {
        return this.selectedDaysOfWeek.sort((a, b) => {
          return a.id - b.id
        })
      }
    },
    methods: {

      programIdToLowerCase() {
        this.program_id = this.program_id.toLowerCase()
      },

      submitNewAthleteProgram: debounce(function() {
        if (this.newAthleteProgramValidation()) {
          this.createNewAthleteProgram()
        } else {
          this.submissionFailure()
        }
      }, 2000, {
        'leading': true,
        'trailing': false
      }),

      newAthleteProgramValidation: function() {
        this.errors.program_id = this.program_id ? '' : 'Please enter an ID.'
        this.errors.name = this.addNewProgram.name ? '' : 'Please enter a name.'
        this.errors.start_date = this.addNewProgram.start_date ? '' : 'Start date required.'
        this.errors.end_date = this.addNewProgram.end_date ? '' : 'End date required.'
        this.errors.program_type = this.addNewProgram.program_type ? '' : 'Please select a program type.'

        if (
          !this.errors.program_id &&
          !this.errors.name &&
          !this.errors.start_date &&
          !this.errors.end_date &&
          !this.errors.program_type
        ) {
          return true
        } else {
          return false
        }
      },

      createNewAthleteProgram: function() {
        this.addNewProgram.days_of_week = this.sortedDaysOfWeek.map(day => day.name);

        db.collection('athleteprograms').doc(this.program_id).set(this.addNewProgram).then(
          doc => {
            this.$router.push({name: 'Athlete Program Detail', params: {program_id: this.program_id}});
            this.submissionSuccess()
          }
        ).catch(err => {
          console.log(err)
        })
      },


      submissionFailure: function() {
        this.$notify({
          group: 'master',
          title: 'Missing Info',
          text: 'Please fill out all required information.',
          type: 'error'
        })
      },
      submissionSuccess: function() {
        this.$notify({
          group: 'master',
          title: 'Athlete Program Created',
          text: 'New athlete program was successfully created.',
          type: 'success'
        })
      }
    },
    watch: {
      'addNewProgram.is_paid': function() {
        return this.addNewProgram.cost = this.addNewProgram.is_paid ? this.addNewProgram.cost : 0
      }
    }
  }
</script>

<style scoped>

  /*-- SELECTABLE BUTTON STYLE --*/
  .program-item {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    margin: 0 0 0.75em 0;
    padding: 0.5em;
    background-color: #fbfbfb;
    border: 1px solid #c8ccd0;
    border-radius: 4px;

    transition: 0.2s all ease-in-out;
  }
  .program-item:hover {
    border: 1px solid #9299a0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  .program-item.selected {
    /*background: linear-gradient(300deg, rgba(18, 57, 104, 0.75) 0%, rgba(18, 57, 104, 1) 100%);*/
    /*background-color: #446185;*/
    background-color: #ffffff;
    border: 1px solid #2c9fe5;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  .program-item p {
    /*text-align: left;*/
    color: #9299a0;
    font-size: 16px;
    font-weight: 400;
  }
  .program-item.selected p {
    /*font-weight: 600;*/
    color: #123968;
  }
  .program-item.disabled {
    cursor: not-allowed;
    color: #c6c8ca;
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
  }
  .program-item.disabled:hover {
    box-shadow: none;
  }
  .program-item-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .program-item-wrapper .program-item {
    margin-right: 0.75em;
  }
  .form-line-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .form-line-child {
    flex-grow: 1;
  }
  .form-line-wrapper .form-line-child:not(:last-child) {
    margin-right: 1em;
  }

  /*---- DAYS OF WEEK SELECT SLIDERS ----*/
  .day-select-container {
    display: flex;
    justify-content: space-between;
    padding: 1.25em 1.25em 0.75em;
    border: 1px solid #c8ccd0;
    border-radius: 6px;
   }
  .day-select-item {

  }


</style>


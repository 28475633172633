<template>
  <div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar">
          <div class="toolbar-section">
            <h2>Athlete Programs</h2>
          </div>

          <div class="toolbar-section">
            <router-link to="/athleteprograms/newathleteprogram" class="button-dark">Add Program</router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent">
        <div class="tile is-child box">

          <div class="loading-spinner" v-if="!programsLoaded"></div>

          <div class="table-container" v-if="programsLoaded && programs.length > 0">
            <table class="table is-fullwidth animated fadeIn fast" >

              <thead>
              <tr>
                <th style="width: 3%; min-width: 40px;"></th>
                <th>Name</th>
                <th>ID</th>
                <th>Dates</th>
                <th class="has-text-right">Type</th>
                <!--<th style="text-align: right;">Date Joined</th>-->
              </tr>
              </thead>

              <tbody>

              <router-link v-for="program in programs" v-bind:key="program.id" tag="tr" :to="{name: 'Athlete Program Detail', params: {program_id: program.id}}" class="is-selectable">
                <td>
                  <span v-if="program.is_active" class="status-circle is-active"><i class="fas fa-circle"></i></span>
                  <span v-else class="status-circle is-inactive"><i class="far fa-circle"></i></span>
                </td>
                <td class="font-primary-blue">{{ program.name }}</td>
                <td>{{ program.id }}</td>
                <td>{{ program.start_date | startDateFilter }} - {{ program.end_date | endDateFilter }}</td>
                <td class="has-text-right">
                  <span v-if="program.program_type === 'Performance'" class="status-label is-small dark-blue-label">{{ program.program_type }}</span>
                  <span v-if="program.program_type === 'Camp'" class="status-label is-small light-blue-label">{{ program.program_type }}</span>
                </td>
              </router-link>

              </tbody>

            </table>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {db} from './../../firebase/firebaseInit'
  import {format} from 'date-fns'

  export default {
    name: 'AthletePrograms',
    data: function() {
      return {
        programsLoaded: false,
        programs: []
      }
    },
    filters: {
      startDateFilter: function(value) {
        return format(value, 'M/d/y')
      },
      endDateFilter: function(value) {
        return format(value, 'M/d/y')
      }
    },
    methods: {
      getAthletePrograms: function() {
        db.collection('athleteprograms').orderBy('start_date', 'asc').get().then(
          querySnapshot => {
            querySnapshot.forEach(doc => {
              const programData = {
                'id': doc.id,
                'name': doc.data().name,
                'start_date': doc.data().start_date.toDate(),
                'end_date': doc.data().end_date.toDate(),
                'days_and_times': doc.data().days_and_times,
                'is_active': doc.data().is_active,
                'program_type': doc.data().program_type
              }

              this.programs.push(programData)
            })
            this.programsLoaded = true
        })
      }
    },
    created: function() {
      this.getAthletePrograms()
    }
  }
</script>

<style scoped>

</style>

<template>
  <div v-on-clickaway="disableSearchInput">
    <div class="control has-icons-left has-icons-right" style="margin-bottom: 0;">
      <input v-model="searchInput" type="text" placeholder="Search" @focus="activateSearchInput" @keyup.esc="disableSearchInput" class="search-input" :class="{'input-active': searchInputActive}" id="search-input">
      <span class="icon is-small is-left search-icon"><i class="fas fa-search"/></span>
      <span class="icon is-small is-right" v-if="searchLoading"><i class="is-loading"/></span>
    </div>
    <div class="search-container animated fadeIn faster" v-show="searchContainerActive">

      <p class="search-help-text animated fadeIn faster" v-if="searchInputActive && !searchResults.length && searchMessage">{{ searchMessage }}</p>

      <router-link v-for="member in searchResults"
                   v-bind:key="member.id"
                   class="search-member animated fadeIn faster"
                   @click.native="disableSearchInput"
                   :to="{name: 'Member Detail', params: {member_id: member.id}}" tag="div">

        <div class="search-member-item">
          <h1>{{ member.first_name }} {{ member.last_name }}</h1>
          <h2 class="has-text-left">{{ member.email }} &nbsp;•&nbsp; {{ member.phone_number | phoneNumberFilter }}</h2>
        </div>

        <div class="search-member-item">
          <h3><i class="fas fa-chevron-right"></i></h3>
        </div>

      </router-link>

    </div>
  </div>
</template>

<script>
  import {db} from './../../firebase/firebaseInit'
  import {mixin as clickaway} from 'vue-clickaway';
  import debounce from 'lodash.debounce'

  export default {
    name: 'Search',
    mixins: [ clickaway ],
    data: function() {
      return {
        searchInput: '',
        searchResults: [],
        searchInputActive: false,
        searchLoading: false,
        searchMessage: ''
      }
    },
    filters: {
      phoneNumberFilter: function(value) {
        return value.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
      },
    },
    computed: {
      // searchPlaceholder: function() {
      //   return this.searchInputActive ? 'Search...' : '\uf002'
      // },
      searchContainerActive: function() {
        return this.searchInputActive && this.searchResults.length || this.searchMessage;
      },
      searchInputArray: function() {
        let name = this.searchInput.trim().split(' ');
        name[0] = '' ? '-' : name[0];
        if (typeof name[1] === 'undefined' || name[1] === '') {
          name[1] = name[0]
        }
        return name.map(item => item.toLowerCase())
      },
      firstAndLastInputArray() {
        return this.searchInputArray.map(item => item.charAt(0).toUpperCase() + item.slice(1));
      }
    },
    methods: {

      getSearchResults: debounce(async function() {
        const fetchedResults = [];
        this.searchLoading = true;


        if (this.searchInput) {

          await db.collection('clients')
            .orderBy('first_name')
            .startAt(this.firstAndLastInputArray[0])
            .endAt(this.firstAndLastInputArray[0] + '\uf8ff')
            .get().then(
            querySnapshot => {
              querySnapshot.forEach(doc => {
                const clientData = {
                  'id': doc.id,
                  'first_name': doc.data().first_name,
                  'last_name': doc.data().last_name,
                  'membership_type': doc.data().membership_type,
                  'email': doc.data().email,
                  'phone_number': doc.data().phone_number,
                  'is_active': doc.data().is_active
                };

                fetchedResults.push(clientData)
              });
            }
          );

          await db.collection('clients')
            .orderBy('last_name')
            .startAt(this.firstAndLastInputArray[1])
            .endAt(this.firstAndLastInputArray[1] + '\uf8ff')
            .get().then(
            querySnapshot => {
              querySnapshot.forEach(doc => {
                const clientData = {
                  'id': doc.id,
                  'first_name': doc.data().first_name,
                  'last_name': doc.data().last_name,
                  'membership_type': doc.data().membership_type,
                  'email': doc.data().email,
                  'phone_number': doc.data().phone_number,
                  'is_active': doc.data().is_active
                };

                let memberIds = [];
                fetchedResults.forEach(member => {
                  memberIds.push(member.id)
                });

                if (!memberIds.includes(clientData.id)) {
                  fetchedResults.push(clientData)
                }

              });
            }
          );

          await db.collection('clients')
            .orderBy('email')
            .startAt(this.searchInputArray[0])
            .endAt(this.searchInputArray[0] + '\uf8ff')
            .get().then(
              querySnapshot => {
                querySnapshot.forEach(doc => {
                  const clientData = {
                    'id': doc.id,
                    'first_name': doc.data().first_name,
                    'last_name': doc.data().last_name,
                    'membership_type': doc.data().membership_type,
                    'email': doc.data().email,
                    'phone_number': doc.data().phone_number,
                    'is_active': doc.data().is_active
                  };

                  let memberIds = [];
                  fetchedResults.forEach(member => {
                    memberIds.push(member.id)
                  });

                  if (!memberIds.includes(clientData.id)) {
                    fetchedResults.push(clientData)
                  }

                });
              }
            );

          await db.collection('clients')
            .orderBy('phone_number')
            .startAt(this.searchInputArray[0])
            .endAt(this.searchInputArray[0] + '\uf8ff')
            .get().then(
              querySnapshot => {
                querySnapshot.forEach(doc => {
                  const clientData = {
                    'id': doc.id,
                    'first_name': doc.data().first_name,
                    'last_name': doc.data().last_name,
                    'membership_type': doc.data().membership_type,
                    'email': doc.data().email,
                    'phone_number': doc.data().phone_number,
                    'is_active': doc.data().is_active
                  };

                  let memberIds = [];
                  fetchedResults.forEach(member => {
                    memberIds.push(member.id)
                  });

                  if (!memberIds.includes(clientData.id)) {
                    fetchedResults.push(clientData)
                  }

                });
              }
            );

          this.searchResults = fetchedResults;
          this.searchResultsFetched()

        } else if (this.searchInput === '') {
          this.searchLoading = false;
          this.searchResults = [];
          this.searchMessage = '';
        }

      }, 700, {
        'leading': false,
        'trailing': true
      }),

      searchResultsFetched: function() {
        this.searchLoading = false;
        if (this.searchResults.length === 0) {
          this.searchMessage = 'No results found.'
        }
      },

      activateSearchInput: function() {
        this.searchInputActive = true;
      },
      disableSearchInput: function() {
        this.searchInput = '';
        this.searchResults = [];
        this.searchMessage = '';
        document.getElementById('search-input').blur();
        this.searchInputActive = false;
      }

    },
    watch: {
      'searchInput': function() {
        if (this.searchInputArray[0] || this.searchInputArray[1]) {
          this.getSearchResults()
        }
      },
      '$route': function() {
        this.disableSearchInput()
      }
    }
  }
</script>

<style scoped>

  .search-input {
    height: 34px;
    width: 36px;
    padding-left: 2.25rem!important;
    font-size: 15px;
  }
  .search-input:hover {
    cursor: pointer;
  }

  .input-active {
    width: 375px;
  }
  .input-active:hover {
    cursor: text;
  }

  .search-icon {
    height: 100%!important;
    width: 2.25rem!important;
    color: #0b2441!important;
    margin-left: 1px;
  }

  .search-container {
    /*border: 1px solid #c6c8ca;*/
    border-radius: 8px;
    position: absolute;
    top: 52px;
    /*right: 85px;*/
    padding: 0.5rem;
    transition: 0.3s all ease;

    width: 100%;
    max-width: 375px;
    max-height: 400px;
    overflow-y: scroll;
    background: #ffffff;
    border: 1px solid #e4e7ea;
    /*box-shadow: rgba(0, 0, 0, 0.1) 0 20px 40px;*/
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.1);
    z-index: 99;
  }
  .search-member {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
    transition: 0.2s background ease;
  }
  .search-member:hover {
    background: #f2f6ff;
    cursor: pointer;
  }
  .search-member:not(:last-child) {
    border-bottom: 1px solid #f2f6ff;
  }
  .search-member:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }
  .search-member:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
  .search-member h1 {
    color: #123968;
    font-size: 15px;
    font-weight: 600;
    line-height: 1.3;
    text-align: left;
    transition: 0.2s all ease;
  }
  .search-member:hover h1 {
    color: #2c9fe5;
  }
  .search-member h2 {
    color: #9299a0;
    font-size: 13px;
    font-weight: 400;
    line-height: 1.3;
    /*text-transform: uppercase;*/
    /*letter-spacing: 0.05rem;*/
  }
  .search-member h3 {
    color: #9299a0;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
  }

  .search-help-text {
    color: #2f3539;
    font-size: 14px;
    font-weight: 500;
    /*text-transform: uppercase;*/
    /*letter-spacing: 0.1em;*/
    margin: 1.5rem 0;
  }




</style>

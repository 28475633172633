<template>
  <div class="app-route">

    <div class="task-bar">

      <div class="logo-container">
        <router-link to="/apps">
          <img src="./../assets/logo_4C.png" alt="">
        </router-link>
      </div>

      <div class="task-bar-title">
        <h1 @click="$router.go()">Elevate TVs</h1>
      </div>

    </div>

    <div class="app-contents apps-container">


      <router-link to="/apps/tvs/one">
        <div class="app-wrapper">
          <i class="fas fa-tv app-icon"/>
          <h2>TV 1</h2>
        </div>
      </router-link>

      <router-link to="/apps/tvs/two">
        <div class="app-wrapper">
          <i class="fas fa-tv app-icon"/>
          <h2>TV 2</h2>
        </div>
      </router-link>

      <router-link to="/apps/tvs/three">
        <div class="app-wrapper">
          <i class="fas fa-tv app-icon"/>
          <h2>TV 3</h2>
        </div>
      </router-link>


    </div>

  </div>
</template>

<script>

  export default {
    name: 'WorkoutTVs',
    data() {
        return {

        }
    },
    filters: {

    },
    computed: {

    },
    methods: {

    },
    created() {

    }
  }

</script>

<style scoped>

</style>

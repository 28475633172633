<template>
  <div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar toolbar-centered max-width-800">
          <h2>New Individual Program</h2>
        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent is-centered">
        <div class="tile is-child box max-width-800">

          <!-- IS ACTIVE -->
          <div class="field">
            <label class="label">Status</label>
            <label class="switch">
              <input v-model="newProgram.is_active" type="checkbox">
              <span class="slider round"></span>
            </label>
          </div>

          <!-- PROGRAM NAME -->
          <div class="field">

            <div class="label-container">
              <label class="label">Program Name</label>
              <p class="validation-error">{{ errors.name }}</p>
            </div>

            <div class="control">
              <input v-model="newProgram.name" type="text" placeholder="Summer 2021" required>
            </div>
          </div>

          <div class="flex space-x-4">

            <div class="field">
              <div class="label-container">
                <label class="label">Start Date</label>
                <p class="validation-error">{{ errors.start_date }}</p>
              </div>

              <div class="control">
                <v-date-picker mode="single"
                               v-model="newProgram.start_date"
                               :min-date="new Date()"
                               :disabled-dates="{ weekdays: [1, 7] }"
                               :popover="{visibility: 'focus'}">
                  <input type='text' placeholder="MM/DD/YYYY"
                         slot-scope="{ inputProps, inputEvents }"
                         v-bind="inputProps"
                         v-on="inputEvents">
                </v-date-picker>
              </div>
            </div>

            <div class="field">
              <div class="label-container">
                <label class="label">End Date</label>
                <p class="validation-error">{{ errors.end_date }}</p>
              </div>

              <div class="control">
                <v-date-picker mode="single"
                               v-model="newProgram.end_date"
                               :min-date="new Date()"
                               :disabled-dates="{ weekdays: [1, 7] }"
                               :popover="{visibility: 'focus'}">
                  <input type='text' placeholder="MM/DD/YYYY"
                         slot-scope="{ inputProps, inputEvents }"
                         v-bind="inputProps"
                         v-on="inputEvents">
                </v-date-picker>
              </div>
            </div>

          </div>

          <!-- DESCRIPTION -->
          <div class="field">
            <label class="label" for="description">Description</label>
            <div class="control">
              <textarea v-model="newProgram.description" name="description" id="description" rows="6" class="textarea"></textarea>
            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar toolbar-centered max-width-800">
          <h2>Schedule Options</h2>
        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent is-centered">
        <div class="tile is-child box max-width-800">

          <!-- ADD NEW SCHEDULE ITEM -->
          <div class="flex items-end space-x-4 mb-4">

              <div class="field flex-1">
                <div class="label-container">
                  <label class="label">Program Name</label>
                  <p class="validation-error"></p>
                </div>
                <div class="control">
                  <input v-model="newScheduleItem.name" type="text" placeholder="Summer A" required>
                </div>
              </div>

            <div class="field">
              <label class="label">Start Date</label>
              <div class="control">
                <v-date-picker mode="single"
                               v-model="newScheduleItem.start_date"
                               :min-date="new Date()"
                               :disabled-dates="{ weekdays: [1, 7] }"
                               :popover="{visibility: 'focus'}">
                  <input type='text' placeholder="MM/DD/YYYY"
                         slot-scope="{ inputProps, inputEvents }"
                         v-bind="inputProps"
                         v-on="inputEvents">
                </v-date-picker>
              </div>
            </div>

            <div class="field">
              <label class="label">End Date</label>
              <div class="control">
                <v-date-picker mode="single"
                               v-model="newScheduleItem.end_date"
                               :min-date="new Date()"
                               :disabled-dates="{ weekdays: [1, 7] }"
                               :popover="{visibility: 'focus'}">
                  <input type='text' placeholder="MM/DD/YYYY"
                         slot-scope="{ inputProps, inputEvents }"
                         v-bind="inputProps"
                         v-on="inputEvents">
                </v-date-picker>
              </div>
            </div>

            <button @click="addScheduleItem" class="button-light mb-4" style="height: 40px;">Add</button>

          </div>

<!--          <hr class="w-full h-px bg-rise_gray-300 my-4">-->

          <div v-for="(option, index) in newProgram.schedule" :key="index" v-if="newProgram.schedule.length > 0" class="flex space-x-6 border rounded-md mb-4 px-3 py-3">

            <div class="flex-1 flex justify-start items-center">
              <p class="text-rise_gray-900 font-medium text-lg">{{ option.name }}</p>
              <p class="text-rise_gray-500 ml-6">{{ option.start_date | dateFilter }} - {{ option.end_date | dateFilter }}</p>
            </div>

            <button @click="newProgram.schedule.splice(index, 1)" class="bg-rise_gray-200 h-8 w-8 rounded-md focus:outline-none focus:bg-rise_gray-300"><i class="fas fa-times"/></button>

          </div>

          <div class="bg-rise_gray-100 w-full flex justify-center items-center rounded-lg p-4" v-if="newProgram.schedule.length <= 0">
            <p class="text-rise_gray-500 text-lg">Add a schedule item</p>
          </div>


        </div>
      </div>
    </div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar toolbar-centered max-width-800">
          <h2>Add Times</h2>
        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent is-centered">
        <div class="tile is-child box max-width-800">

          <div class="flex items-end space-x-4 mb-4">

            <div class="field" style="flex-grow: 1;">
              <label class="label">Day</label>
              <div class="select">
                <select v-model="newTime.day_index">
                  <option value="" disabled>Select a Day</option>
                  <option v-for="day in daysOfTheWeek" :value="day.day_index">{{ day.day }}</option>
                </select>
              </div>
            </div>

            <div class="field">
              <div class="label-container">
                <label class="label">Start Time</label>
              </div>
              <div class="control">
                <input v-model="newTime.start_time" type="time">
              </div>
            </div>

            <div class="field">
              <div class="label-container">
                <label class="label">End Time</label>
              </div>
              <div class="control">
                <input v-model="newTime.end_time" type="time">
              </div>
            </div>

            <button @click="addNewTime" class="button-light mb-4" style="height: 40px;">Add</button>

          </div>

          <div class="flex flex-wrap justify-start">
            <div v-for="(time, index) in times" :key="index" class="flex bg-rise_gray-100 rounded-md px-2.5 py-1.5 mr-4 mb-4">
              <p>{{ time.day }} @ {{ time.start_time | timeFilter }}</p>
              <button @click="times.splice(index, 1)" class="bg-rise_gray-100 h-6 w-6 rounded ml-2 focus:outline-none"><i class="fas fa-times"/></button>
            </div>
          </div>


          <div class="bg-rise_gray-100 w-full flex justify-center items-center rounded-lg p-4" v-if="times.length <= 0">
            <p class="text-rise_gray-500 text-lg">Add a time</p>
          </div>


        </div>
      </div>
    </div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar toolbar-centered max-width-800">
          <div class="toolbar-section"></div>

          <div class="toolbar-section">
            <router-link to="/ap-individual" class="button-light">Cancel</router-link>
            <button @click="validateNewProgram" :class="{'is-loading': submitStatus}" :disabled="submitStatus" class="button-dark margin-left">Save &nbsp;<i class="fas fa-angle-right"></i></button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { db } from './../../firebase/firebaseInit';
  import {setHours, format, startOfWeek, addSeconds, endOfWeek, differenceInWeeks} from 'date-fns';
  import { notifySuccess, notifyError } from '@/notifications';

  export default {
    name: 'NewAthleteIndividualProgram',
    data() {
      return {
        submitStatus: false,

        newProgram: {
          name: '',
          is_active: true,
          start_date: null,
          end_date: null,
          schedule: [],
          description: ''
        },

        newScheduleItem: {
          name: '',
          start_date: null,
          end_date: null
        },

        newTime: {
          program_id: '',
          is_open: true,
          day_index: null,
          day: '',
          start_time: '',
          end_time: '',
          spots: null,
        },

        times: [],

        daysOfTheWeek: [ { day_index: 0, day: 'Monday' }, { day_index: 1, day: 'Tuesday' }, { day_index: 2, day: 'Wednesday' }, { day_index: 3, day: 'Thursday' }, { day_index: 4, day: 'Friday' }, { day_index: 5, day: 'Saturday' }, { day_index: 6, day: 'Sunday' },],

        errors: {
          name: '',
          start_date: null,
          end_date: null,
          description: ''
        }

      }
    },
    filters: {
      dateFilter(value) {
        return format(value, 'MMMM d');
      },
      timeFilter: function(value) {
        let time = value.split(':');
        let hours = parseInt(time[0]);
        let minutes = parseInt(time [1]);
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return `${hours}:${minutes} ${ampm}`
      },
    },
    computed: {

    },
    methods: {

      addScheduleItem() {

        if (this.newScheduleItem.name && this.newScheduleItem.start_date && this.newScheduleItem.end_date) {

          const start = startOfWeek(this.newScheduleItem.start_date, {weekStartsOn: 1});
          const end = addSeconds(endOfWeek(this.newScheduleItem.end_date, {weekStartsOn: 1}), 1);

          this.newProgram.schedule.push({
            name: this.newScheduleItem.name,
            start_date: setHours(this.newScheduleItem.start_date, 12),
            end_date: setHours(this.newScheduleItem.end_date, 12),
            duration_in_weeks: differenceInWeeks(end, start)
          });

          this.newScheduleItem.name = '';

        }

      },

      addNewTime() {

        if (this.newTime.day_index >= 0 && this.newTime.start_time && this.newTime.end_time) {

          // add 'day name' to new time
          this.newTime.day = this.daysOfTheWeek[this.newTime.day_index].day;

          // push new time to array
          this.times.push({...this.newTime});
        }

      },

      validateNewProgram: function() {
        this.errors.name = this.newProgram.name ? '' : 'Please enter a name.'
        this.errors.start_date = this.newProgram.start_date ? '' : 'Start date required.'
        this.errors.end_date = this.newProgram.end_date ? '' : 'End date required.'

        this.createNewProgram();


      },

      async createNewProgram() {

        this.submitStatus = true;
        let program_id = '';

        try {

          // create program record
          await db.collection('athleteindividualprograms').add(this.newProgram).then(doc => {
            program_id = doc.id;
          });

          // create time records for day and time slots
          const batch = db.batch();
          const timesRef = this.times.map(time => db.collection('athleteindividualtimes').doc());

          timesRef.forEach((doc, index) => {
            batch.set(doc, {
              program_id: program_id,
              is_open: true,
              day_index: this.times[index].day_index,
              day: this.times[index].day,
              start_time: this.times[index].start_time,
              end_time: this.times[index].end_time,
              spots: null
            })
          });

          await batch.commit();

          this.$router.push('/ap-individual');
          notifySuccess('Program created.', 'Individual program successfully created.');

        } catch (err) {

          console.error(err);
          notifyError('Something went wrong', `${err.message}.`);
          this.submitStatus = false;

        }

      },

      // createNewAthleteProgram: function() {
      //   this.addNewProgram.days_of_week = this.sortedDaysOfWeek.map(day => day.name);
      //
      //   db.collection('athleteprograms').doc(this.program_id).set(this.addNewProgram).then(
      //     doc => {
      //       this.$router.push({name: 'Athlete Program Detail', params: {program_id: this.program_id}});
      //       this.submissionSuccess()
      //     }
      //   ).catch(err => {
      //     console.log(err)
      //   })
      // },

    },
    watch: {
    }
  }
</script>

<style scoped>

  /*-- SELECTABLE BUTTON STYLE --*/
  .program-item {
    display: flex;
    justify-content: flex-start;
    cursor: pointer;
    margin: 0 0 0.75em 0;
    padding: 0.5em;
    background-color: #fbfbfb;
    border: 1px solid #c8ccd0;
    border-radius: 4px;

    transition: 0.2s all ease-in-out;
  }
  .program-item:hover {
    border: 1px solid #9299a0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  .program-item.selected {
    /*background: linear-gradient(300deg, rgba(18, 57, 104, 0.75) 0%, rgba(18, 57, 104, 1) 100%);*/
    /*background-color: #446185;*/
    background-color: #ffffff;
    border: 1px solid #2c9fe5;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  .program-item p {
    /*text-align: left;*/
    color: #9299a0;
    font-size: 16px;
    font-weight: 400;
  }
  .program-item.selected p {
    /*font-weight: 600;*/
    color: #123968;
  }
  .program-item.disabled {
    cursor: not-allowed;
    color: #c6c8ca;
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
  }
  .program-item.disabled:hover {
    box-shadow: none;
  }
  .program-item-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .program-item-wrapper .program-item {
    margin-right: 0.75em;
  }
  .form-line-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .form-line-child {
    flex-grow: 1;
  }
  .form-line-wrapper .form-line-child:not(:last-child) {
    margin-right: 1em;
  }

  /*---- DAYS OF WEEK SELECT SLIDERS ----*/
  .day-select-container {
    display: flex;
    justify-content: space-between;
    padding: 1.25em 1.25em 0.75em;
    border: 1px solid #c8ccd0;
    border-radius: 6px;
   }
  .day-select-item {

  }


</style>


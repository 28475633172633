<template>
    <div>

      <div class="tile">
        <div class="toolbar-wrapper">
          <div class="toolbar">

            <div class="toolbar-section">
              <h2>Team Members</h2>
            </div>

            <div class="toolbar-section">
              <router-link to="/team/newteammember" class="button-dark">New User</router-link>
            </div>

          </div>
        </div>
      </div>

      <div class="tile">
        <div class="tile is-parent">
          <div class="tile is-child box">

            <div class="loading-spinner" v-if="!pageLoaded"></div>

            <div class="table-container" v-if="pageLoaded" style="padding-bottom: 200px; margin-bottom: -200px;">
              <table class="table is-fullwidth animated fadeIn fast">
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Position</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                </tr>
                </thead>

                <tbody>

                <tr v-for="(member, index) in team_members" v-bind:key="member.uid" :class="{'user-disabled': member.is_disabled}">
                  <td class="font-primary-blue">{{ member.first_name }} {{ member.last_name }} &nbsp;
                    <span v-if="member.is_admin" class="status-label is-small light-blue-label">Admin</span>
                    <span v-if="member.is_disabled" class="status-label is-small danger-label">Disabled</span>
                  </td>
                  <td class="font-secondary-blue">{{ member.position }}</td>
                  <td>{{ member.email }}</td>
                  <td>{{ member.phone_number | phoneNumberFilter }}</td>
                  <td class="has-text-right">

<!--                    <div class="dropdown is-active is-right" @click="toggleDropdownMenu(index)" v-if="$store.state.user.is_admin">-->
                    <div class="dropdown is-active is-right" v-if="$store.state.user.is_admin">

                      <div class="dropdown-trigger">
<!--                        <button class="button secondary-action-button" @blur="closeDropdownMenu"><i class="fas fa-ellipsis-h"/></button>-->
                        <button class="button secondary-action-button dropdown-button"><i class="fas fa-ellipsis-h"/></button>
                      </div>

<!--                      <div v-bind:class="{'menu-open': dropdownMenu === index}" class="dropdown-menu" id="dropdown-menu" role="menu">-->
                      <div class="dropdown-menu" id="dropdown-menu" role="menu">
                        <div class="dropdown-content is-selectable">
                          <div @mousedown.prevent @mouseup="enableTeamMemberAccess(member.uid)" class="dropdown-item font-primary-blue" v-if="!member.team_member">Enable as Team Member</div>
                          <div @mousedown.prevent @mouseup="removeTeamMemberAccess(member.uid)" class="dropdown-item font-primary-blue" v-if="member.team_member">Remove as Team Member</div>
                          <div @mousedown.prevent @mouseup="enableAdminAccess(member.uid)" class="dropdown-item font-primary-blue" v-if="!member.is_admin">Enable Admin Access</div>
                          <div @mousedown.prevent @mouseup="removeAdminAccess(member.uid)" class="dropdown-item font-primary-blue" v-if="member.is_admin">Remove Admin Access</div>
                          <hr class="dropdown-divider">
                          <div @mousedown.prevent @mouseup="enableAccount(member.uid)" class="dropdown-item font-primary-blue" v-if="member.is_disabled">Enable Account</div>
                          <div @mousedown.prevent @mouseup="disableAccount(member.uid)" class="dropdown-item is-danger" v-if="!member.is_disabled">Disable Account</div>
                        </div>
                      </div>

                    </div>

                  </td>
                </tr>

                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>

    </div>
</template>

<script>
  import firebase from 'firebase/app'
  import {db, functions} from './../../firebase/firebaseInit'

  export default {
    name: "Team",
    data() {
      return {
        pageLoaded: false,
        dropdownMenu: null,
        team_members: []
      }
    },
    filters: {
      phoneNumberFilter: function(value) {
        return value.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
      },
    },
    computed: {

    },
    methods: {

      async getTeamMembers() {

        this.pageLoaded = false;
        const teamMembers = [];

        await db.collection('team').orderBy('last_name', 'asc').get().then(
          querySnap => {
            querySnap.forEach(doc => {
              const teamMemberData = {
                'uid': doc.id,
                'first_name': doc.data().first_name,
                'last_name': doc.data().last_name,
                'email': doc.data().email,
                'phone_number': doc.data().phone_number,
                'position': doc.data().position,
                'member_since': doc.data().member_since.toDate(),
                'team_member': null,
                'is_admin': null,
                'is_disabled': null
              };

              teamMembers.push(teamMemberData);
            })

          }).catch(err => {
            console.log(err)
        })

        async function getAdminData() {
          for (const member of teamMembers) {
            await functions.httpsCallable('getTeamMemberCustomClaims')({
              email: member.email
            }).then(result => {
              member.team_member = result.data.team_member;
              member.is_admin = result.data.is_admin;
              member.is_disabled = result.data.is_disabled;
            }).catch(err => {
              console.log(err)
            });
          }
        }

        await getAdminData();
        this.team_members = teamMembers;
        this.pageLoaded = true
      },

      // checkUserAdminStatus() {
      //   firebase.auth().currentUser.getIdTokenResult().then(
      //     idTokenResult => {
      //       console.log(idTokenResult.claims)
      //     }
      //   )
      // },

      enableTeamMemberAccess(uid) {
        functions.httpsCallable('enableTeamMemberAccess')({
          uid: uid
        }).then(() => {
          this.getTeamMembers()
          this.submissionSuccess('Member Enabled', 'Team member was successfully enabled.')
        }).catch(err => {
          console.log(err)
          this.submissionFailure(`${err.message}`)
        })
        document.activeElement.blur();
      },

      removeTeamMemberAccess(uid) {
        functions.httpsCallable('removeTeamMemberAccess')({
          uid: uid
        }).then(() => {
          this.getTeamMembers()
          this.submissionSuccess('Access Removed', 'Team member access was successfully removed.')
        }).catch(err => {
          console.log(err)
          this.submissionFailure(`${err.message}`)
        })
        document.activeElement.blur();
      },

      enableAdminAccess(uid) {
        functions.httpsCallable('enableAdminAccess')({
          uid: uid
        }).then(() => {
          this.getTeamMembers()
          this.submissionSuccess('Admin Enabled', 'Team member admin privileges successfully enabled.')
        }).catch(err => {
          console.log(err)
          this.submissionFailure(`${err.message}`)
        })
        document.activeElement.blur();
      },

      removeAdminAccess(uid) {
        functions.httpsCallable('removeAdminAccess')({
          uid: uid
        }).then(() => {
          this.getTeamMembers()
          this.submissionSuccess('Admin Enabled', 'Team member admin privileges successfully removed.')
        }).catch(err => {
          console.log(err)
          this.submissionFailure(`${err.message}`)
        })
        document.activeElement.blur();
      },

      enableAccount(uid) {
        functions.httpsCallable('enableAccount')({
          uid: uid
        }).then(() => {
          this.getTeamMembers()
          this.submissionSuccess('Account Enabled', 'Team member account was successfully enabled.')
        })
        document.activeElement.blur();
      },

      disableAccount(uid) {
        functions.httpsCallable('disableAccount')({
          uid: uid
        }).then(() => {
          this.getTeamMembers()
          this.submissionSuccess('Account Disabled', 'Team member account was successfully disabled.')
        }).catch(err => {
          console.log(err)
          this.submissionFailure(`${err.message}`)
        })
        document.activeElement.blur();
      },

      submissionSuccess(title, message) {
        this.$notify({
          group: 'master',
          title: `${title}`,
          text: `${message}`,
          type: 'success'
        })
      },
      submissionFailure(message) {
        this.$notify({
          group: 'master',
          title: 'An Error Occurred',
          text: `${message}`,
          type: 'error'
        })
      }

    },
    created() {
      this.getTeamMembers()
      // this.checkUserAdminStatus()
    }

  }
</script>

<style scoped>

  .user-disabled > td {
    color: #c6c8ca!important;
  }

  .dropdown:focus-within > .dropdown-menu {
    pointer-events: auto;
    user-select: text;
    opacity: 1;
  }


</style>

<template>
    <div>

      <div class="tile">
        <div class="toolbar-wrapper">
          <div class="toolbar toolbar-centered max-width-800">

            <div class="toolbar-section">
              <h2>Quote of the Week</h2>
            </div>

          </div>
        </div>
      </div>

      <div class="tile">
        <div class="tile is-parent is-centered">
          <div class="tile is-child box max-width-800">

            <div class="loading-spinner" v-if="!pageLoaded"></div>

            <div v-if="pageLoaded" class="animated fadeIn fast">

              <div class="field">
                <label class="label">Author</label>
                <div class="control">
                  <input v-model="qotw.author" type="text" placeholder="Name">
                </div>
              </div>

              <div class="field">
                <label class="label">Quote</label>
                <div class="control">
                  <textarea v-model="qotw.quote" class="textarea"/>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>

      <div class="tile" v-if="pageLoaded">
        <div class="toolbar-wrapper">
          <div class="toolbar toolbar-centered max-width-800">

            <div class="toolbar-section"></div>

            <div class="toolbar-section">

              <router-link to="/tools" class="button-light">Cancel</router-link>
              <button @click="saveQOTW"
                      :class="{'is-loading': submitStatus}"
                      :disabled="submitStatus"
                      class="button-dark margin-left">Save &nbsp;<i data-v-770a0bd4="" class="fas fa-angle-right"/></button>

            </div>


          </div>
        </div>
      </div>

    </div>
</template>

<script>
  import {db} from './../../firebase/firebaseInit'

  export default {
    name: 'QuoteOfTheWeek',
    data() {
      return {
        pageLoaded: true,
        submitStatus: false,

        qotw: {
          author: '',
          quote: ''
        }
      }
    },
    filters: {

    },
    computed: {

    },
    methods: {

      getQuoteOfTheWeek() {
        this.pageLoaded = false;

        db.collection('admin').doc('qotw').get().then(
          doc => {
            const {author, quote} = doc.data();
            this.qotw.author = author;
            this.qotw.quote = quote;
            this.pageLoaded = true;
          }
        ).catch(err => {
          this.errorNotification(err.message)
        })
      },

      saveQOTW: function() {
        this.submitStatus = true;

        db.collection('admin').doc('qotw').update(this.qotw).then(() => {
          this.successNotification();
          this.$router.push({name: 'Tools'})
        }).catch(err => {
          this.errorNotification(err.message)
          this.submitStatus = false
        })
      },

      successNotification() {
        this.$notify({
          group: 'master',
          title: 'Quote of the Week Updated',
          text: 'Quote of the Week was successfully updated.',
          type: 'success'
        })
      },
      errorNotification(message) {
        this.$notify({
          group: 'master',
          title: 'Error',
          text: message,
          type: 'error'
        })
      }


    },
    created() {
      this.getQuoteOfTheWeek()
    }
  }
</script>

<style scoped>

</style>

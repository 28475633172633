<template>
  <div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar">
          <div class="toolbar-section">
            <h2>Rise Online Workouts</h2>
          </div>

          <div class="toolbar-section">
            <router-link to="/onlineworkouts/new" class="button-dark">New Workout</router-link>
          </div>
        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent">
        <div class="tile is-child box">

          <div class="loading-spinner" v-if="!pageLoaded"></div>
          <h2 class="empty-state animated fadeIn fast" v-if="pageLoaded && !online_workouts.length">There are no online workouts.</h2>

          <div class="table-container" v-if="pageLoaded && online_workouts.length > 0">
            <table class="table is-fullwidth animated fadeIn fast" >

              <thead>
              <tr>
                <th style="width: 3%; min-width: 40px;"></th>
                <th>Name</th>
                <th>Format</th>
                <th>Type</th>
                <th>Instructor</th>
                <th class="has-text-right">Live At</th>
              </tr>
              </thead>

              <tbody>

              <router-link v-for="workout in online_workouts" v-bind:key="workout.id" tag="tr" :to="{name: 'Online Workout Detail', params: {workout_id: workout.id}}" class="is-selectable">
                <td>
                  <span v-if="workout.is_active" class="status-circle is-active"><i class="fas fa-circle"></i></span>
                  <span v-else class="status-circle is-inactive"><i class="far fa-circle"></i></span>
                </td>
                <td class="font-primary-blue">{{ workout.name }}</td>
                <td>
                  <span class="status-label is-small" style="color: #D946EF; background: #FAE8FF; border: none;">{{ workout.format }}</span>
                  <span class="status-label is-small light-blue-label" style="margin-left: 0.25rem;">{{ workout.duration_in_minutes }} min</span>
                </td>
                <td>{{ workout.workout_type }} <span style="color: #9299a0;"> - {{ workout.workout_focus }}</span></td>
                <td><i class="far fa-user" style="color: #9299a0; font-size: 13px;"/> {{ workout.instructor }}</td>
                <td class="has-text-right">{{ workout.published_at | dateFilter }}</td>
              </router-link>

              </tbody>

            </table>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { db } from './../../firebase/firebaseInit';
  import { format } from 'date-fns';

  export default {
    name: 'OnlineWorkouts',
    data: function() {
      return {
        pageLoaded: false,

        online_workouts: []
      }
    },
    filters: {
      dateFilter(value) {
        return format(value, 'MMM. d');
      }
    },
    methods: {


      async getAthletePrograms() {

        this.pageLoaded = false;

        await db.collection('onlineworkouts').orderBy('published_at', 'desc').get().then(
          querySnapshot => {
            querySnapshot.forEach(doc => {

              const workoutData = {

                id: doc.id,
                name: doc.data().name,
                format: doc.data().format,
                video_id: doc.data().video_id,
                created_at: doc.data().created_at.toDate(),
                published_at: doc.data().published_at.toDate(),
                duration_in_minutes: doc.data().duration_in_minutes,
                workout_type: doc.data().workout_type,
                workout_focus: doc.data().workout_focus,
                equipment: doc.data().equipment,
                is_active: doc.data().is_active,
                is_featured: doc.data().is_featured,
                ratings: doc.data().ratings,
                difficulty: doc.data().difficulty,
                sessions_started: doc.data().sessions_started,
                sessions_completed: doc.data().sessions_completed,
                instructor: doc.data().instructor,
                description: doc.data().description,
                workout_plan: doc.data().workout_plan,
                workout: doc.data().workout

              }

              this.online_workouts.push(workoutData);
            })
        }).catch(err => {
          console.error(err);
        });

        this.pageLoaded = true;

      }
    },
    async created() {
      await this.getAthletePrograms()
    }
  }
</script>

<style scoped>

</style>

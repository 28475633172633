import './../src/css/index.css'
import Vue from 'vue'
import App from './App'
import router from './router'
import firebase from 'firebase/app'
import './../firebase/firebaseInit'
// import './../node_modules/bulma/css/bulma.css'
import Notifications from 'vue-notification'
import VCalendar from 'v-calendar'
// import 'v-calendar/lib/v-calendar.min.css'
import numeral from 'numeral'
import VueMask from 'v-mask'
import money from 'v-money'
import VueTouch from 'vue-touch'
import store from './store'

Vue.config.productionTip = false;
Vue.use(Notifications);
Vue.use(VCalendar, {
  popoverVisibility: "focus"
});
Vue.use(numeral);
Vue.use(VueMask);
Vue.use(money, {decimal: '.', thousands: ',', prefix: '$', precision: 2, masked: false});
Vue.use(VueTouch, {name: 'v-touch'});
VueTouch.config.tap = {
  time: 1000,
  threshold: 70
};

// Initialize Vue App once Firebase is initialized
let app;


firebase.auth().onAuthStateChanged(function(user) {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
});

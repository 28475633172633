// Form Validation Error Checks

// Generic Form Input

export function inputCheck(input) {
  if (!input) {
    return 'This field is required.'
  } else {
    return ''
  }
}

// First Name
export function firstNameCheck(input) {
  if (!input) {
    return 'Please enter a first name.'
  } else {
    return ''
  }
}

// Last Name
export function lastNameCheck(input) {
  if (!input) {
    return 'Please enter a last name.'
  } else {
    return ''
  }
}

// Email Address
export function emailCheck(input) {
  if (!input) {
    return 'Please enter an email address.'
  } else if (input) {
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    let value = '';
    if (emailRegex.test(input)) {
      value = ''
    } else {
      value = 'Please enter a valid email.'
    }
    return value
  }
}

// Phone Number
export function phoneNumberCheck(input) {
  if (!input) {
    return 'Please enter a phone number.'
  } else if (input) {
    let phoneRegex = /^[0-9]{10}$/
    let value = '';
    if (phoneRegex.test(input)) {
      value = ''
    } else {
      value = 'Phone number must be 10 digits.'
    }
    return value
  }
}

// Future Date
import {isPast, addMinutes} from 'date-fns'
export function dateCheck(date, time) {
  if (!date) {
    return 'Select a date.'
  } else if (date) {
    let value = '';
    let dateAndTime = addMinutes(date, time);
    if (isPast(dateAndTime)) {
      value = 'Must be in future.'
    } else {
      value = ''
    }
    return value
  }
}

// Date Object Type
export function dateObjectCheck(date) {
  if (!date) {
    return 'Select a date.'
  } else if (date instanceof Date) {
    return ''
  } else {
    return 'Must be a valid date.'
  }
}

// Time Select
export function timeCheck(input) {
  if (!input) {
    return 'Select a time.'
  } else if (input) {
    return ''
  }
}


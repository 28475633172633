import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import firebaseConfig from './firebaseConfig'

const firebaseApp = firebase.initializeApp(firebaseConfig);
// const firestore = firebase.firestore();

// Enable Firebase emulator for local Cloud Functions development & testing
// if (window.location.hostname === 'localhost') {
//   firebaseApp.functions().useEmulator('localhost', 5001);
// }

const db = firebaseApp.firestore();
const functions = firebaseApp.functions();
const storage = firebaseApp.storage();

export {db, functions, storage};

<template>
    <div>

      <div class="tile">

        <div class="tile is-vertical">

          <div class="tile">
            <div class="tile is-parent">
            <div class="tile is-child welcome-header">
              <h1 class="gradient-header">Hello, {{ $store.state.user.first_name }}!</h1>
              <p class="subtitle">You are logged in as {{ $store.state.user.email }}.</p>
<!--              <button class="button" @click="changeRecord">Change Record</button>-->
            </div>
            </div>
          </div>

          <div class="tile">

            <div class="tile is-parent">
              <div class="tile is-child box">

                <div class="stat-icon">
                  <i class="fas fa-chart-bar"/>
                </div>

                <h5 class="stat-title has-text-left">Attendance Today</h5>

                <div class="loading-spinner" v-if="!attendanceStatsLoaded"></div>

                <div class="stat-container animated fadeIn fast" v-if="attendanceStatsLoaded">

                  <h1>{{ attendanceStats.today }}</h1>

                </div>

              </div>
            </div>

            <div class="tile is-parent">
              <div class="tile is-child box">

                <h5 class="stat-title has-text-left">Active Members</h5>

                <div class="stat-icon">
                  <i class="fas fa-users"/>
                </div>

                <div class="loading-spinner" v-if="!memberStatsLoaded"></div>

                <div class="stat-container animated fadeIn fast" v-if="memberStatsLoaded">

                  <h1>{{ memberStats.elevate_active }}</h1>

                </div>

              </div>
            </div>

            <div class="tile is-parent">
              <div class="tile is-child box">

                <h5 class="stat-title has-text-left">New Members</h5>

                <div class="stat-icon">
                  <i class="fas fa-user-plus"/>
                </div>

                <div class="loading-spinner" v-if="!memberStatsLoaded"></div>

                <div class="stat-container animated fadeIn fast" v-if="memberStatsLoaded">

                  <h1>{{ new_members }}<span style="font-size: 16px; font-weight: 400;"> this month</span></h1>

                </div>

              </div>
            </div>

          </div>

        </div>

      </div>

      <div class="tile">
        <div class="toolbar-wrapper">
          <div class="toolbar">
            <div class="toolbar-section">
              <h2>Quick Actions</h2>
            </div>
          </div>
        </div>
      </div>

      <div class="tile">

        <router-link to="/members/newmember" class="tile is-parent quick-action-container">
          <div class="quick-action-item">
            <div class="plus-icon">
              <i class="fas fa-plus"/>
            </div>
            <h3>New member</h3>
          </div>
        </router-link>

        <router-link to="/email/newemail" class="tile is-parent quick-action-container">
          <div class="quick-action-item">
            <div class="plus-icon">
              <i class="fas fa-plus"/>
            </div>
            <h3>New email</h3>
          </div>
        </router-link>

      </div>




      <h2 class="text-left ml-4 mt-6">Registered Sessions</h2>
      <p class="text-sm text-rise_gray-500 text-left ml-4">Upcoming sessions for Trial and Promo membership plans.</p>




      <div class="tile">
        <div class="tile is-parent">
          <div class="tile is-child box">

            <div class="loading-spinner" v-if="!signupsLoaded"></div>

            <h2 class="empty-state animated fadeIn fast" v-if="signupsLoaded && !newMemberRegistrations.length">There are no sessions available.</h2>

            <div class="table-container" v-if="newMemberRegistrations.length">
              <table class="table is-fullwidth animated fadeIn fast">
                <thead>
                <tr>
                  <th class="w-1/4">Member Name</th>
                  <th class="w-1/4">Membership Type</th>
                  <th class="w-1/4">Session Name</th>
                  <th class="w-1/4">Date</th>
                </tr>
                </thead>

                <tbody>

                <tr v-for="registration in newMemberRegistrations" :key="registration.registration_id">
                  <td class="w-1/4">{{ registration.member_name }}</td>
                  <td class="w-1/4"><span class="status-label is-small light-blue-label">{{ registration.membership_type }}</span></td>
                  <td class="w-1/4">{{ registration.name }}</td>
                  <td class="w-1/4"><span class="text-rise_blue-400">{{ registration.start_date | sessionDateFilter }}</span></td>
                </tr>

                </tbody>
              </table>
            </div>

          </div>

        </div>
      </div>

      <div class="tile">
        <div class="toolbar-wrapper">
          <div class="toolbar">
            <div class="toolbar-section">
              <h2>Free Session Signups</h2>
            </div>

            <div class="toolbar-section">
              <button class="button button-outline" @click="getUpcomingfreeSessionSignups" v-bind:class="{'button-active': futureSignups}">Upcoming</button>
              <button class="button button-outline" style="margin-left: 0.5rem;" @click="getPastfreeSessionSignups" v-bind:class="{'button-active': !futureSignups}">Past</button>
            </div>
          </div>
        </div>
      </div>

      <div class="tile">
        <div class="tile is-parent">
          <div class="tile is-child box">

            <!-- Free Session Table -->
            <div class="loading-spinner" v-if="!signupsLoaded"></div>

            <h2 class="empty-state animated fadeIn fast" v-if="signupsLoaded && !freeSessionSignups.length">There are no sessions available.</h2>

            <div class="table-container" v-if="signupsLoaded && freeSessionSignups.length > 0">
              <table class="table is-fullwidth animated fadeIn fast">
                <thead>
                <tr>
                  <th>Name</th>
                  <th>Session Date</th>
                  <th>Email</th>
                  <th>Phone Number</th>
                </tr>
                </thead>

                <tbody>

                <tr v-for="client in freeSessionSignups" v-bind:key="client.id">
                  <td>{{ client.first_name }} {{ client.last_name }}</td>
                  <td>
                    <span class="font-secondary-blue">{{ client.session_date | sessionDateFilter }}</span>
                  </td>
                  <td>{{ client.email }}</td>
                  <td>{{ client.phone_number | phoneNumberFilter }}</td>
                </tr>

                </tbody>
              </table>
            </div>

          </div>

        </div>
      </div>

    </div>
</template>

<script>
  import {db} from './../../firebase/firebaseInit'
  import firebase from 'firebase/app'
  import { format, startOfDay, endOfDay, startOfMonth, endOfMonth, subDays } from 'date-fns'

    export default {
      name: "Dashboard",
      data: function() {
        return {
          // Member Stats Data
          memberStatsLoaded: false,
          memberStats: {
            elevate_active: null,
            elevate_inactive: null
          },
          // Attendance Data
          attendanceStatsLoaded: false,
          attendanceStats: {
            today: Number()
          },
          // QOTW Data
          qotwLoaded: false,
          QOTWModal: false,
          qotw: {
            author: '',
            quote: ''
          },
          editQOTW: {
            author: '',
            quote: ''
          },
          // New Members
          new_members: Number(),
          // Free Session Data
          signupsLoaded: false,
          futureSignups: true,
          freeSessionSignups: [],

          newMemberRegistrations: []
        }
      },
      filters: {
        phoneNumberFilter: function(value) {
          return value.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
        },
        sessionDateFilter: function(value) {
          return format(value, 'EEEE, MMM. d – h:mm a')
        },
        sessionTimeFilter: function(value) {
          return format(value, 'h:mm a')
        }
      },
      computed: {
        totalMembers: function() {
          return this.memberStats.elevate_active + this.memberStats.elevate_inactive
        }
      },
      methods: {

        // changeRecord: function() {
        //   db.collection('clients').get().then(querySnap => {
        //     querySnap.forEach(async doc => {
        //
        //       const completed = doc.data().total_sessions_attended;
        //
        //       await db.collection('clients').doc(doc.id).update({
        //
        //         sessions_completed: {
        //           in_person: completed,
        //           online: 0
        //         },
        //
        //         goals: {
        //           weekly: 3,
        //           monthly: 12
        //         }
        //
        //         // 'contact_email': firebase.firestore.FieldValue.delete(),
        //
        //       }).then(() => console.log('Updates completed!')
        //       )
        //     })
        //   })
        // },

        // Total Clients Stats
        getTotalClients: function() {
          db.collection('admin').doc('total_members').get().then(
            doc => {
            this.memberStats.elevate_active = doc.data().elevate_active;
            this.memberStats.elevate_inactive = doc.data().elevate_inactive;

            this.memberStatsLoaded = true;
          }).catch(err => {
            console.log('An error occurred: ' + err)
          })
        },

        // Attendance Stats
        getTodaysAttendance: function() {

            // const endOfCurrentDay = endOfDay(this.currentDate);
            // const startOfCurrentDay = startOfDay(this.currentDate);

            db.collection('attendance')
              .where('signed_in_at', '<', endOfDay(new Date()))
              .where('signed_in_at', '>=', startOfDay(new Date()))
              .orderBy('signed_in_at', 'asc').get().then(querySnapshot => {
              this.attendanceStats.today = querySnapshot.size;
              this.attendanceStatsLoaded = true;
            }).catch(err => {
              console.log(err)
            })

        },

        getNewMembers() {
          db.collection('clients')
            .where('signup_date', '<', endOfMonth(new Date()))
            .where('signup_date', '>=', startOfMonth(new Date()))
            .get().then(querySnap => {
              this.new_members = querySnap.size;
          })
        },

        //Free Session Signups
        getUpcomingfreeSessionSignups: function() {
          this.signupsLoaded = false;
          this.futureSignups = true;
          this.freeSessionSignups = [];
          db.collection('freesession').where('session_date', '>', new Date()).orderBy('session_date', 'asc').get().then(
            querySnapshot => {
              querySnapshot.forEach(doc => {
                const freeSessionData = {
                  'id': doc.id,
                  'first_name': doc.data().first_name,
                  'last_name': doc.data().last_name,
                  'email': doc.data().email,
                  'phone_number': doc.data().phone_number,
                  'session_date': doc.data().session_date.toDate(),
                  'signup_date': doc.data().signup_date.toDate()
                };
                this.freeSessionSignups.push(freeSessionData)
              })
              this.signupsLoaded = true
            }
          ).catch(err => {
            console.log('An error occurred getting Free Session Signups: ' + err)
          })
        },

        getPastfreeSessionSignups: function() {
          this.signupsLoaded = false;
          this.futureSignups = false;
          this.freeSessionSignups = [];
          db.collection('freesession').where('session_date', '<', new Date()).orderBy('session_date', 'desc').get().then(
            querySnapshot => {
              querySnapshot.forEach(doc => {
                const freeSessionData = {
                  'id': doc.id,
                  'first_name': doc.data().first_name,
                  'last_name': doc.data().last_name,
                  'email': doc.data().email,
                  'phone_number': doc.data().phone_number,
                  'session_date': doc.data().session_date.toDate(),
                  'signup_date': doc.data().signup_date.toDate()
                };
                this.freeSessionSignups.push(freeSessionData)
              })
              this.signupsLoaded = true
            }
          ).catch(err => {
            console.log('An error occurred getting Free Session Signups: ' + err)
          })
        },

        async getNewMemberRegisteredSessions() {

          const promoMembers = await db.collection('clients').where('membership_type', '==', 'Promo').get().then(querySnap => {
            return querySnap.docs.map(doc => {
              return { id: doc.id, ...doc.data(), }
            })
          })

          const trialMembers = await db.collection('clients').where('membership_type', '==', 'Trial').get().then(querySnap => {
            return querySnap.docs.map(doc => {
              return { id: doc.id, ...doc.data(), }
            })
          })

          console.log('promoMembers ->', promoMembers)
          console.log('trialMembers ->', trialMembers)

          const members = [...promoMembers, ...trialMembers]
          console.log('members ->', members)

          const registrationsQuery = members.map(member => {
            return db.collection('sessionregistrations')
              .where('member_id', '==', member.id)
              .where('created_at', '>=', subDays(new Date(), 7))
              .where('is_deregistered', '==', false)
              .get()
          })

          console.log('sessions ->', registrationsQuery)

          const registrationsPromise = await Promise.all(registrationsQuery)
          console.log('promise ->', registrationsPromise)

          const registrationsData = registrationsPromise.map(query => {
            return query.docs.map(doc => {
              return {
                registration_id: doc.id,
                ...doc.data(),
              }
            })
          })
          console.log('data ->', registrationsData)

          const flattenedRegistrations = registrationsData.reduce((flatten, arr) => [...flatten, ...arr], [])
          console.log('afterReduce ->', flattenedRegistrations)

          const sessionsQuery = flattenedRegistrations.map(registration => {
            return db.collection('sessions').doc(registration.session_id).get()
          })

          const sessionsPromise = await Promise.all(sessionsQuery)
          console.log('sessionPromise ->', sessionsPromise)

          const sessionsData = sessionsPromise.map(session => {
            return {
              session_id: session.id,
              ...session.data(),
              start_date: session.data().start_date.toDate()
            }
          })

          console.log('sessionDataResolved ->', sessionsData)

          const finalData = flattenedRegistrations.map((registration, index) => {
            const member = members.find(member => member.id === registration.member_id)
            return {
              ...registration,
              ...sessionsData[index],
              member_name: `${member.first_name} ${member.last_name}`,
              membership_type: member.membership_type,
            }
          })

          console.log('finalData ->', finalData)

          this.newMemberRegistrations = finalData.sort((a, b) => {
            return a.start_date - b.start_date
          });

        }

      },
      created: function() {
        this.getTotalClients();
        this.getTodaysAttendance();
        this.getNewMembers();
        this.getUpcomingfreeSessionSignups();
        this.getNewMemberRegisteredSessions();
      }
    }
</script>

<style scoped>

  .welcome-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
  }

  .welcome-header p {
    color: #9299a0;
    font-size: 20px;
    font-weight: 300;
  }

  .stat-title {
    /*color: #2c9fe5;*/
    /*font-size: 14px;*/
    font-weight: 500;
    /*color: #0b2441;*/
  }
  .stat-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 2rem 0 0 0;
  }

  .stat-container h1 {
    font-size: 36px;
    font-weight: 700;
    color: #123968;
    line-height: 1;
  }
  .stat-icon {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: #eceff3;
  }

  .quick-action-container {

  }
  .quick-action-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    padding: 1.5rem 1rem;
    /*background: linear-gradient( to bottom right, #123968, #0b2441 100%);*/
    /*box-shadow: 0 3px 5px -2px rgba(0,0,0,.3), 0 5px 6px -5px rgba(18,57,104,.7);*/
    background: transparent;
    border: 1px solid #c6c8ca;
    border-radius: 10px;

    transition: 0.3s all ease;
  }
  .quick-action-item:hover {
    /*filter: brightness(1.3);*/
    /*box-shadow: 0 5px 20px -5px rgba(0,0,0,.3), 0 10px 24px -11px rgba(18,57,104,.7);*/
    border-color: #2c9fe5;
    box-shadow: 0 0 0 1px #2c9fe5;
  }
  .quick-action-item h3 {
    /*color: #ffffff;*/
    color: #123968;
    text-align: center;
    line-height: 1;
    font-size: 18px;
  }
  .plus-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 0 1rem 0;
    /*color: rgba(255,255,255,0.4);*/
    color: #2c9fe5;
    font-size: 14px;
    height: 40px;
    width: 40px;
    /*border: 2px solid rgba(255,255,255,0.3);*/
    background-color: #e4edfd;
    /*border: 2px solid #c6c8ca;*/
    border-radius: 50%;
  }

  @media (min-width: 769px) {
    .quick-action-container {
      max-width: 225px;
    }
  }


</style>

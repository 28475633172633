<template>
  <div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar">
          <div class="toolbar-section">
            <h2>Program Info</h2>
          </div>

          <div class="toolbar-section">
            <button v-if="id" class="button-light">Edit</button>
          </div>
        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent">
        <div class="tile is-child box">

          <div class="loading-spinner" v-if="!programLoaded"></div>

          <div class="detail-info animated fadeIn fast" v-if="programLoaded">

            <div class="detail-title-row">

              <div class="detail-title-item client-title">
                <h1 class="gradient-header">{{ name }}</h1>
              </div>
            </div>

            <div class="detail-info-row">

              <div v-if="is_active" class="status-label"><span class="status-circle is-active" style="padding-right: 0.2rem;"><i class="fas fa-circle"></i></span>Active</div>
              <div v-if="!is_active" class="status-label"><span class=" status-circle is-inactive" style="padding-right: 0.2rem;"><i class="far fa-circle"></i></span>Inactive</div>

            </div>

            <div class="detail-info-row">

              <div class="detail-info-item">
                <h4>Program ID</h4>
                <p>{{ id }}</p>
              </div>

              <div class="detail-info-item">
                <h4>Program Type</h4>
                <p>{{ program_type }}</p>
              </div>

              <div class="detail-info-item">
                <h4>Dates</h4>
                <p>{{ start_date | dateFilter }} - {{ end_date | dateFilter }}</p>
              </div>

              <!--<div class="detail-info-item">-->
                <!--<h4>End Date</h4>-->
                <!--<p>{{ end_date | dateFilter }}</p>-->
              <!--</div>-->

            </div>

            <div class="content-break-line" v-if="program_type === 'Camp'"></div>

            <div class="detail-info-row" v-if="program_type === 'Camp'">

              <div class="detail-info-item">
                <h4>Days of Week</h4>
                <p><span v-for="day in days_of_week" :id="day.id" class="days-of-week" :class="{'days-of-week-multiple': days_of_week.length > 1}">{{ day }}</span></p>
              </div>

              <div class="detail-info-item">
                <h4>Time</h4>
                <p>{{ start_time | timeFilter }} - {{ end_time | timeFilter }}</p>
              </div>

              <!--<div class="detail-info-item">-->
                <!--<h4>End Time</h4>-->
                <!--<p>{{ end_time | timeFilter }}</p>-->
              <!--</div>-->

              <div class="detail-info-item">
                <h4>Cost</h4>
                <p>{{ cost | costFilter }}</p>
              </div>

            </div>

          </div>

        </div>
      </div>
    </div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar">
          <div class="toolbar-section">
            <h2>Registered Athletes</h2>
          </div>

          <!--<div class="toolbar-section">-->
          <!--<button v-if="id" class="button-light"><i class="fas fa-edit"></i>&nbsp; Edit</button>-->
          <!--</div>-->
        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent">
        <div class="tile is-child box">

          <!-- Registered Athletes Table -->
          <div class="loading-spinner" v-if="!athletesLoaded"></div>

          <h2 class="empty-state" v-if="athletesLoaded && !registeredAthletes.length">There are no registered athletes available.</h2>

          <div class="table-container" v-if="athletesLoaded && registeredAthletes.length > 0">
            <table class="table is-fullwidth animated fadeIn fast">

              <thead>
              <tr>
                <th style="width: 3%; min-width: 40px;"></th>
                <th>Athlete Name</th>
                <th>Athlete Phone</th>
                <th>Contact Name</th>
                <th>Contact Email</th>
                <th>Contact Phone</th>
              </tr>
              </thead>

              <tbody>

              <tr v-for="athlete in registeredAthletes" v-bind:key="athlete.id">
                <td><span class="status-label is-small light-blue-label">{{ athlete.athlete_grade }}</span></td>
                <td><span class="font-primary-blue">{{ athlete.athlete_first_name }} {{ athlete.athlete_last_name }}</span></td>
                <td>{{ athlete.athlete_phone_number | phoneNumberFilter }}</td>
                <td>{{ athlete.contact_first_name }} {{ athlete.contact_last_name }}</td>
                <td>{{ athlete.contact_email }}</td>
                <td>{{ athlete.contact_phone_number | phoneNumberFilter }}</td>
              </tr>

              </tbody>

            </table>
          </div>


        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {db} from './../../firebase/firebaseInit'
  import {format} from 'date-fns'
  import numeral from 'numeral'

  export default {
    name: 'AthleteProgramDetail',
    data: function() {
      return {
        programLoaded: false,
        athletesLoaded: false,

        id: '',
        name: '',
        is_active: null,
        program_type: '',
        start_date: null,
        end_date: null,
        days_of_week: [],
        start_time: null,
        end_time: null,
        cost: null,

        registeredAthletes: []
      }
    },
    filters: {
      dateFilter: function(value) {
        return format(value, 'MMMM d, y')
      },
      timeFilter: function(value) {
        let time = value.split(':');
        let hours = parseInt(time[0]);
        let minutes = parseInt(time [1]);
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        minutes = minutes < 10 ? '0' + minutes : minutes;
        return `${hours}:${minutes} ${ampm}`
      },
      costFilter: function(value) {
        return numeral(value).format('$0,0.00')
      },
      phoneNumberFilter: function(value) {
        return value.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
      }
    },
    methods: {

      getProgram: function() {
        db.collection('athleteprograms').doc(this.$route.params.program_id).get().then(
          doc => {
            const {name, is_active, program_type, start_date, end_date, days_of_week, start_time, end_time, cost} = doc.data();

            this.id = doc.id;
            this.name = name;
            this.is_active = is_active;
            this.program_type = program_type;
            this.start_date = start_date.toDate();
            this.end_date = end_date.toDate();
            this.days_of_week = days_of_week;
            this.start_time = start_time;
            this.end_time = end_time;
            this.cost = cost;

            this.programLoaded = true;

          }
        )
      },

      getRegisteredAthletes: function() {
        db.collection('athleteregistrations').where('program_id', '==', this.$route.params.program_id).orderBy('signup_date', 'desc').get().then(
          querySnapshot => {
            querySnapshot.forEach(doc => {
              const athleteData = {
                'id': doc.id,
                'athlete_first_name': doc.data().athlete_first_name,
                'athlete_last_name': doc.data().athlete_last_name,
                'athlete_grade': doc.data().athlete_grade,
                'athlete_phone_number': doc.data().athlete_phone_number,
                'contact_first_name': doc.data().contact_first_name,
                'contact_last_name': doc.data().contact_last_name,
                'contact_email': doc.data().contact_email,
                'contact_phone_number': doc.data().contact_phone_number,
                'signup_date': doc.data().signup_date.toDate(),
                'invoices': []
              }

              doc.data().invoices.forEach(invoice => {
                athleteData.invoices.push(invoice)
              })

              this.registeredAthletes.push(athleteData)

            })
            this.athletesLoaded = true
          }
        ).catch(err => {
          console.log(err)
          this.athletesLoaded = true
        })
      }

    },
    created: function() {
      this.getProgram()
      this.getRegisteredAthletes()
    }
  }
</script>

<style scoped>

  .days-of-week:after {
    content: ' - ';
  }
  .days-of-week:last-child:after {
    content: '';
  }

</style>

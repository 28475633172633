<template>
  <div>

    <aside class="menu has-text-left">

      <div class="menu-section">

        <p class="menu-label">General</p>
        <ul class="menu-list">
          <li><router-link to="/dashboard"><span class="icon-wrap"><i class="fas fa-chart-bar"></i></span><span class="link-text">Dashboard</span></router-link></li>
          <li>
            <router-link to="/messages" style="justify-content: space-between;">
              <div>
                <span class="icon-wrap"><i class="far fa-comments"/></span>
                <span class="link-text">Messages</span>
              </div>
              <span class="unread-messages-label" v-if="$store.state.unread_messages">{{ $store.state.unread_messages }}</span>
            </router-link>
          </li>
<!--          <li><router-link to="/athleteprograms"><span class="icon-wrap"><i class="fas fa-volleyball-ball"></i></span><span class="link-text">Athlete Programs</span></router-link></li>-->
          <li v-if="$store.state.user.is_admin"><router-link to="/tools"><span class="icon-wrap"><i class="fas fa-tools"></i></span><span class="link-text">Tools</span></router-link></li>
        </ul>
      </div>

      <div class="menu-section">
        <p class="menu-label">Elevate</p>
        <ul class="menu-list">
          <li><router-link to="/members"><span class="icon-wrap"><i class="fas fa-users"></i></span><span class="link-text">Members</span></router-link></li>
          <li><router-link to="/attendance"><span class="icon-wrap"><i class="far fa-clipboard"></i></span><span class="link-text">Attendance</span></router-link></li>
          <li><router-link to="/workouts"><span class="icon-wrap"><i class="fas fa-heartbeat"></i></span><span class="link-text">Workouts</span></router-link></li>
<!--          <li><router-link to="/verificationcodes"><span class="icon-wrap"><i class="far fa-comment"></i></span><span class="link-text">Verification Codes</span></router-link></li>-->
<!--          <li><router-link to="/schedule"><span class="icon-wrap"><i class="far fa-calendar-alt"></i></span><span class="link-text">Schedule</span></router-link></li>-->
          <li><router-link to="/sessions"><span class="icon-wrap"><i class="far fa-calendar-alt"></i></span><span class="link-text">Sessions</span></router-link></li>
        </ul>
      </div>

      <div class="menu-section">
        <p class="menu-label">Rise Online</p>
        <ul class="menu-list">
          <li><router-link to="/onlineworkouts"><span class="icon-wrap"><i class="fas fa-fire-alt"></i></span><span class="link-text">Online Workouts</span></router-link></li>
          <li><router-link to="#"><span class="icon-wrap"><i class="fas fa-list"></i></span><span class="link-text">Online Sessions</span></router-link></li>
        </ul>
      </div>

      <div class="menu-section">
        <p class="menu-label">Athlete Performance</p>
        <ul class="menu-list">
<!--          <li><router-link to="/athletes"><span class="icon-wrap"><i class="fas fa-user-plus"></i></span><span class="link-text">Athletes</span></router-link></li>-->
          <li><router-link to="/ap-individual"><span class="icon-wrap"><i class="fas fa-user-plus"></i></span><span class="link-text">Individual</span></router-link></li>
          <li><router-link to="/athleteprograms"><span class="icon-wrap"><i class="fas fa-campground"></i></span><span class="link-text">Camps</span></router-link></li>
        </ul>
      </div>

    </aside>

  </div>
</template>

<script>
  import firebase from 'firebase/app'

    export default {
      name: "Navbar",
      methods: {
        signOut: function() {
          firebase.auth().signOut().then(() => {
            this.$router.push('/login')
          })
        }
      }
    }
</script>

<style scoped>

  .menu {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;
    justify-content: space-between;
    /*padding: 2em;*/
    width: 100%;
    margin-bottom: 2rem;
    /*min-width: 275px;*/
    /*border: 1px solid hotpink;*/
  }
  .menu-section {
    width: 100%;
    margin-top: 2rem;
  }
  .menu-settings {
    padding-bottom: 2em;
  }
  .menu-label {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
    /*color: #171a1c;*/
    color: #c8ccd0;
    margin-left: 1.7rem;

    /*opacity: 0;*/
  }
  .menu-list li {
    background-color: transparent;
    margin: 0 1rem;
  }
  .menu-list li a {
    /*font-size: 14px;*/
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #9299a0;
    /*color: #c6c8ca;*/
    padding: 10px 30px 10px 16px;
    border-radius: 8px;
    border-left: 4px solid transparent;
    /*text-transform: uppercase;*/
    /*letter-spacing: 1px;*/
    transition: 0.2s all ease;
  }
  .menu-list li.active a {

  }
  .menu-list li:hover {

  }
  .menu-list li:hover a {
    background: #f7f8fa;
    color: #171a1c;
  }
  .menu-list li.active:hover a {

  }

  /*-- unread messages label --*/
  .unread-messages-label {
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;
    background: #2c9fe5;
    /*border: 1px solid #c8ccd0;*/
    border-radius: 6px;
    padding: 1px 8px;
    margin-right: -0.75rem;
  }

  .router-link-active {
    color: #2c9fe5!important;
    background: #f2f6ff;
    /*border-left: 4px solid #2c9fe5!important;*/
  }
  .router-link-active:hover {
    background: #f2f6ff!important;
  }
  .icon-wrap {
    display: inline-flex;
    /*border: 1px solid red;*/
    width: 38px;
  }
  .link-text {

  }

  i.fas, i.far {
    margin: 0 auto;
    padding-right: 15px;
    /*font-size: 18px;*/
  }

  @media (min-width: 1216px) {


  }

</style>

<template>
  <div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar">

          <div class="toolbar-section">
            <h2>{{ day_of_week_title }}'s Schedule</h2>
          </div>

          <div class="toolbar-section">

            <div class="dropdown is-active is-right">

              <button class="button button-outline" @click="daySelectorDropdown = !daySelectorDropdown" :class="{'button-active': daySelectorDropdown}">
                Select Day &nbsp;
                <i class="fas fa-chevron-down" style="font-size: 12px; transition: 0.23s all ease-in-out;" :class="{'arrow-rotate': daySelectorDropdown}"></i>
              </button>

              <div v-bind:class="{'menu-open': daySelectorDropdown}" class="dropdown-menu" id="dropdown-menu" role="menu">

                <div class="dropdown-content is-selectable">

                  <div class="dropdown-item" @click="setDayOfWeek('1', 'Monday')">
                <span class="filter-status-icon">
                  <i v-bind:class="day_of_week === '1' ? 'fa-check-circle' : 'fa-circle'" class="far"></i>
                </span>Monday
                  </div>
                  <div class="dropdown-item" @click="setDayOfWeek('2', 'Tuesday')">
                <span class="filter-status-icon">
                  <i v-bind:class="day_of_week === '2' ? 'fa-check-circle' : 'fa-circle'" class="far"></i>
                </span>Tuesday
                  </div>
                  <div class="dropdown-item" @click="setDayOfWeek('3', 'Wednesday')">
                <span class="filter-status-icon">
                  <i v-bind:class="day_of_week === '3' ? 'fa-check-circle' : 'fa-circle'" class="far"></i>
                </span>Wednesday
                  </div>
                  <div class="dropdown-item" @click="setDayOfWeek('4', 'Thursday')">
                <span class="filter-status-icon">
                  <i v-bind:class="day_of_week === '4' ? 'fa-check-circle' : 'fa-circle'" class="far"></i>
                </span>Thursday
                  </div>
                  <div class="dropdown-item" @click="setDayOfWeek('5', 'Friday')">
                <span class="filter-status-icon">
                  <i v-bind:class="day_of_week === '5' ? 'fa-check-circle' : 'fa-circle'" class="far"></i>
                </span>Friday
                  </div>
                  <div class="dropdown-item" @click="setDayOfWeek('6', 'Saturday')">
                <span class="filter-status-icon">
                  <i v-bind:class="day_of_week === '6' ? 'fa-check-circle' : 'fa-circle'" class="far"></i>
                </span>Saturday
                  </div>

                </div>

              </div>

            </div>

            <div class="dropdown is-active is-right">

              <button class="button button-outline margin-left" @click="newSessionDropdown = !newSessionDropdown" :class="{'button-active': newSessionDropdown}">
                Add Session &nbsp;
                <i class="fas fa-chevron-down" style="font-size: 12px; transition: 0.23s all ease-in-out;" :class="{'arrow-rotate': newSessionDropdown}"></i>
              </button>

              <div class="dropdown-menu" v-bind:class="{'menu-open': newSessionDropdown}" role="menu" style="min-width: 300px;">

                <div class="dropdown-content">

                  <div class="dropdown-item">
                    <div class="field">
                      <label class="label">Session Name</label>
                      <div class="control">
                        <input v-model="addNewSession.session_name" type="text" placeholder="Name">
                      </div>
                    </div>
                  </div>

                  <div class="dropdown-item">
                    <div class="field">
                      <label class="label">Open Period</label>
                      <label class="switch" style="margin: 0.4em;">
                        <input v-model="addNewSession.open_period" type="checkbox">
                        <span class="slider round"></span>
                      </label>
                    </div>
                  </div>

                  <div class="dropdown-item">
                    <div class="field" style="flex-basis: 110px;">
                      <label class="label">Start Time</label>
                      <div class="control">
                        <input type="time" v-model="addNewSession.start_time">
                      </div>
                    </div>
                  </div>

                  <div class="dropdown-item">
                    <div class="field" style="flex-basis: 110px;">
                      <label class="label">End Time</label>
                      <div class="control">
                        <input type="time" :class="{'is-disabled': !addNewSession.open_period}" v-model="addNewSession.end_time" :disabled="!addNewSession.open_period">
                      </div>
                    </div>
                  </div>

                  <div class="dropdown-item is-flex" style="justify-content: flex-end">
                    <button @click="addNewSessionTime" :disabled="validateNewClassTime" class="button-dark">Add</button>
                  </div>

                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent">
        <div class="tile is-child box">

          <!--<div class="content-break-line"></div>-->

          <div class="loading-spinner" v-if="!scheduleLoaded"></div>
          <h2 v-if="scheduleLoaded && !sessions.length" class="empty-state animated fadeIn fast" style="margin: 2rem 0;">There are no sessions available.</h2>

          <div class="table-container" v-if="scheduleLoaded && sessions.length > 0">
            <table class="table is-fullwidth animated fadeIn fast">

              <thead>
              <tr>
                <th>Session Name</th>
                <th class="has-text-centered">Start Time</th>
                <th class="has-text-centered">End Time</th>
                <th class="has-text-right">Type</th>
              </tr>
              </thead>

              <tbody>
              <tr v-for="(session, index) in sortedSessions" v-bind:key="index" class="not-selectable">
                <td><span class="font-primary-blue">{{ session.session_name }}</span></td>
                <td class="has-text-centered">{{ session.start_time | timeFilter }}</td>
                <td class="has-text-centered">{{ session.end_time | timeFilter }}</td>
                <!--<td>{{ session.open_period }}</td>-->
                <td class="has-text-right">
                  <!--<label class="switch">-->
                  <!--<input v-model="session.open_period" type="checkbox" disabled>-->
                  <!--<span class="slider round"></span>-->
                  <!--</label>-->
                  <span v-if="session.open_period" class="status-label is-small light-blue-label">Open Period</span>
                  <span v-if="!session.open_period" class="status-label is-small gray-label">Single Session</span>
                </td>
              </tr>
              </tbody>

            </table>
          </div>


        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import {db} from './../../firebase/firebaseInit'
  import {format, getDay} from 'date-fns'

  export default {
    name: 'Schedule',
    data: function() {
      return {
        day_of_week: null,
        day_of_week_title: '',
        daySelectorDropdown: false,
        scheduleLoaded: false,
        newSessionDropdown: false,
        sessions: [],
        addNewSession: {
          session_name: '',
          start_time: '',
          end_time: null,
          open_period: false
        }
      }
    },
    filters: {
      timeFilter: function(value) {
        if (value) {
          let time = value.split(':');
          let hours = parseInt(time[0]);
          let minutes = parseInt(time [1]);
          let ampm = hours >= 12 ? 'PM' : 'AM';
          hours = hours % 12;
          hours = hours ? hours : 12;
          minutes = minutes < 10 ? '0' + minutes : minutes;
          return `${hours}:${minutes} ${ampm}`
        } else {
          return '-'
        }
      }
    },
    computed: {
      sortedSessions: function() {
        return this.sessions.sort((a, b) => {
          if(a.start_time < b.start_time) { return -1; }
          if(a.start_time > b.start_time) { return 1; }
          return 0;
        })
      },
      validateNewClassTime: function() {
        if (
          !this.addNewSession.session_name ||
          !this.addNewSession.start_time
        ) {
          return true
        } else if (this.addNewSession.open_period && !this.addNewSession.end_time) {
          return true
        } else {
          return false
        }
      }
    },
    methods: {

      setDayOfWeek: function(int, title) {
        if (int === '0') {
          this.day_of_week = '1';
          this.day_of_week_title = 'Monday';
        } else {
          this.day_of_week = int;
          this.day_of_week_title = title;
        }
        this.daySelectorDropdown = false
      },

      getScheduleData: async function() {
        this.scheduleLoaded = false

        await db.collection('schedule').doc(this.day_of_week).get().then(doc => {
          this.sessions = doc.data().sessions
        }).catch(err => {
          console.log(err)
        })

        this.scheduleLoaded = true
      },

      addNewSessionTime: function() {
        this.sessions.push(this.addNewSession)
        this.updateClassTimes()
      },

      updateClassTimes: async function() {
        try {
          await db.collection('schedule').doc(this.day_of_week).update({
            'sessions': this.sortedSessions
          })
          this.submissionSuccess()
          this.addNewSession = {session_name: '', start_time: '', end_time: null, open_period: false}

        } catch (err) {
          console.log(err)
          this.submissionFailure()
        }
      },

      // Notifications
      submissionFailure: function() {
        this.$notify({
          group: 'master',
          title: 'An Error Occurred',
          text: 'Session times not saved successfully.',
          type: 'error'
        })
      },
      submissionSuccess: function() {
        this.$notify({
          group: 'master',
          title: 'Session Times Updated',
          text: 'Session times were successfully updated.',
          type: 'success'
        })
      }

    },
    created: function() {
      this.setDayOfWeek(getDay(new Date()).toString(), format(new Date(), 'EEEE'))
      // this.getScheduleData()
    },
    watch: {
      'day_of_week': function() {
        this.getScheduleData()
      },
      'addNewSession.open_period': function() {
        if (!this.addNewSession.open_period) {
          this.addNewSession.end_time = null
        }
      },
      'daySelectorDropdown': function() {
        if (this.daySelectorDropdown) {
          this.newSessionDropdown = false
        }
      },
      'newSessionDropdown': function() {
        if (this.newSessionDropdown) {
          this.daySelectorDropdown = false
        }
        if (!this.newSessionDropdown) {
          this.addNewSession = {session_name: '', start_time: '', end_time: null, open_period: false}
        }
      }
    }
  }
</script>

<style scoped>

  .new-session-container {
    display: flex;
    justify-content: space-between;
    /*padding: 1rem;*/
  }
  .new-session-container > .field {
    /*flex-grow: 1;*/
    /*margin: 0 0.75rem;*/
  }
  .is-disabled {
    cursor: not-allowed;
    color: #c6c8ca;
    background-color: #f5f5f5;
    border: 1px solid #f5f5f5;
  }

  .arrow-rotate {
    transition: 0.23s all ease-in-out;
    transform: rotate(180deg);
  }

  .filter-status-icon {
    margin-right: 0.5em;
  }
  .filter-status-icon .fa-check-circle {
    color: #2c9fe5;
  }
  .filter-status-icon .fa-circle {
    color: #bbc0c4;
  }

</style>

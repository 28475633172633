<template>
  <div class="app-route">

    <div class="task-bar">

      <div class="logo-container">
        <img src="./../assets/logo_4C.png" alt="">
      </div>

      <div class="task-bar-title">
        <h1>Apps</h1>
      </div>

    </div>

    <div class="app-contents apps-container">


      <router-link to="/sessionsignin">
        <div class="app-wrapper">
          <i class="fas fa-tablet-alt app-icon"/>
          <h2>Sign In</h2>
        </div>
      </router-link>

      <router-link to="/apps/timer">
        <div class="app-wrapper">
          <i class="far fa-clock app-icon"/>
          <h2>Timer</h2>
        </div>
      </router-link>

      <router-link to="/apps/tvs">
        <div class="app-wrapper">
          <i class="fas fa-tv app-icon"/>
          <h2>TVs</h2>
        </div>
      </router-link>


    </div>

  </div>
</template>

<script>

  export default {
    name: 'Apps',
    data() {
        return {

        }
    },
    filters: {

    },
    computed: {

    },
    methods: {

    },
    created() {

    }
  }

</script>

<style scoped>

</style>

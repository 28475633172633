<template>
  <div id="app">
    <transition name="fade" mode="out-in">
      <router-view/>
    </transition>
  </div>
</template>

<script>
  export default {
    name: 'App'
  }
</script>

<style>
  /*@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:200,300,400,500,600,700,800,900');*/
  @import url('https://use.typekit.net/jci8kqr.css');

  #app {
    /*font-family: 'Nunito Sans', sans-serif;*/
    font-family: proxima-nova, sans-serif;
    /*font-weight: 600;*/
    /*-webkit-font-smoothing: antialiased;*/
    /*-moz-osx-font-smoothing: grayscale;*/
    text-align: center;
    color: #2c3e50;
  }

  .button {
    /*font-family: 'Nunito Sans', sans-serif;*/
    font-family: proxima-nova, sans-serif;
  }

  .is-parent.is-centered {
    justify-content: center;
  }

  /*---- FONTS ----*/

  h1 {
    font-size: 38px;
    font-weight: 800;
    color: #171a1c;
  }
  h2 {
    font-size: 22px;
    font-weight: 500;
    /*color: #2c3e50;*/
    color: #171a1c;
  }
  h3 {
    font-size: 18px;
    color: #2c3e50;
    font-weight: 500;
  }
  h4 {
    color: #9299a0;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
  }
  h5 {
    font-size: 18px;
    line-height: 1.3;
  }
  p {
    color: #474d52;
  }
  a {
    color: #2c9fe5;
  }
  a:hover {
    color: #1579c2;
  }

  .gradient-header {
    color: #2c9fe5;
    background: linear-gradient(to right, #1579C2, #123968);
    -webkit-background-clip: text!important;
    -webkit-text-fill-color: transparent;
  }
  .column-header {
    color: #123968;
    text-align: left;
    margin-bottom: 0.5em;
    font-weight: 800;
  }
  .is-bold {
    font-weight: 700!important;
  }
  .is-small {
    font-size: 14px;
    line-height: 1.3;
    color: #9299a0;
  }
  .is-semibold {
    font-weight: 600!important;
  }
  .is-success {
    color: #21c45a;
  }
  .is-danger {
    color: #df203d;
  }
  .font-primary-blue {
    color: #123968;
  }
  .font-secondary-blue {
    color: #2c9fe5;
  }
  .empty-state {
    color: #9299a0;
    font-weight: 500;
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }


  /*---- BUTTONS ----*/

  .button-light, .button-dark {
    font-family: proxima-nova, sans-serif;
    display: inline-flex;
    position: relative;
    font-size: 15px;
    font-weight: 700;
    line-height: 1;

    border: none;
    padding: 12px 16px;
    border-radius: 8px;

    transition: 0.15s all ease-in-out;
  }
  .button-light {
    color: #123968;
    background: rgba(228, 231, 234, 0.85);
  }
  .button-light:hover {
    cursor: pointer;
    color: #123968;
    filter: brightness(0.97);
  }
  .button-light:focus {
    box-shadow: 0 0 0 3px #d6dadd;
    cursor: pointer;
  }
  .button-light:active {
    transform: scale(0.96);
  }

  .button-dark {
    color: #ffffff;
    background: rgb(44,159,229);
    background: linear-gradient(90deg, rgba(44,159,229,1) 10%, rgba(28,128,199,1) 100%);
  }
  .button-dark:hover {
    color: #ffffff;
    cursor: pointer;
    filter: brightness(1.1);
  }
  .button-dark:focus {
    box-shadow: 0 0 0 3px #bbd5f7;
    cursor: pointer;
  }
  .button-dark:active {
    transform: scale(0.96);
    filter: brightness(0.94);
  }
  .button-light:focus, .button-dark:focus {
    outline: none;
  }



  /*button.button-light, button.button-dark, a.button-light, a.button-dark, button.button-danger {*/
  /*  !*font-family: 'Nunito Sans', sans-serif;*!*/
  /*  display: flex;*/
  /*  align-items: center;*/
  /*  font-family: proxima-nova, sans-serif;*/
  /*  font-size: 16px;*/
  /*  font-weight: 700;*/
  /*  border: none;*/
  /*  border-radius: 8px;*/
  /*  transition: 0.23s all ease-in-out;*/
  /*  position: relative;*/
  /*}*/
  /*button.button-light, button.button-dark {*/
  /*  padding: 9px 20px;*/
  /*}*/
  /*a.button-light, a.button-dark {*/
  /*  display: inline-flex;*/
  /*  align-items: center;*/
  /*  height: 42px;*/
  /*  padding: 14px 20px;*/
  /*}*/

  /*button.button-light, a.button-light {*/
  /*  color: #123968;*/
  /*  background: transparent;*/
  /*  border: 2px solid #c6c8ca;*/
  /*}*/
  /*button.button-light:hover, button.button-light:focus {*/
  /*  cursor: pointer;*/
  /*  border-color: #9299a0;*/
  /*}*/
  /*button.button-dark, a.button-dark {*/
  /*  color: #ffffff;*/
  /*  background: #2c9fe5;*/
  /*  border: 2px solid #2c9fe5;*/
  /*  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3), 0 8px 8px -11px rgba(18, 57, 104, 0.7);*/
  /*}*/
  /*button.button-dark:hover, button.button-dark:focus {*/
  /*  color: #ffffff;*/
  /*  cursor: pointer;*/
  /*  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.3), 0 10px 15px -11px rgba(18, 57, 104, 0.7);*/
  /*}*/
  /*button.button-light:focus, button.button-dark:focus {*/
  /*  outline: none;*/
  /*}*/


  /*a.button-light:hover, a.button-light:focus {*/
  /*  border-color: #9299a0;*/
  /*}*/
  /*a.button-dark:hover, a.button-dark:focus {*/
  /*  !*transform: translateY(-1px);*!*/
  /*  color: #ffffff;*/
  /*  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.3), 0 10px 15px -11px rgba(18, 57, 104, 0.7);*/
  /*}*/



  button.button-outline {
    font-family: proxima-nova, sans-serif;
    background: transparent;
    /*border: none;*/
    color: #5d646b;
    font-size: 16px;
    font-weight: 600;
    height: 100%;
    min-height: 38px;
    border-radius: 8px;
    border: 1px solid #c8ccd0;
    /*padding-top: 0;*/
    /*padding-bottom: 0;*/
    /*text-transform: uppercase;*/
    /*letter-spacing: 0.1em;*/
    transition: 0.2s all ease;
  }
  button.button-outline:hover {
    background: #eceff3;
    color: #0b2441;
    border: 1px solid #eceff3;
    /*background: #e4edfd;*/
    /*color: #2c9fe5;*/
  }
  button.button-outline:focus {
    color: #0b2441;
    border: 1px solid #eceff3;
  }
  button.button-outline:active {
    filter: brightness(0.95);
  }
  button.button-outline.button-active {
    /*color: #0b2441;*/
    background: #e4edfd;
    color: #2c9fe5;
    border: 1px solid #e4edfd;
  }
  button.button-outline:focus, button.button-outline:active {
    outline: none;
    box-shadow: none!important;
  }

  button.button-danger {
    color: #df203d;
    background: transparent;
    padding: 8px 20px;
    border: 2px solid transparent;
  }
  button.button-danger:hover, button.button-danger:focus {
    cursor: pointer;
    outline: none;
    border: 2px solid #ef8f9e;
  }

  .primary-action-button {
    background: rgba(228, 231, 234, 0.85);
    color: #0b2441;
    font-size: 15px;
    font-weight: 600;
    height: 38px;
    min-width: 38px;
    border: none;
    border-radius: 8px;
    padding-left: 12px;
    padding-right: 12px;
    transition: 0.2s all ease;
  }
  .primary-action-button > i {
    font-size: 14px;
  }
  .primary-action-button:hover {
    /*background: #e4edfd;*/
    color: #0b2441;
    filter: brightness(0.95);
  }
  .primary-action-button:focus {
    color: #0b2441;
    /*border: none;*/
    box-shadow: none!important;
  }
  .primary-action-button:active {
    filter: brightness(0.9);
    transform: scale(0.96);
  }
  .primary-action-button[disabled] {
    background: #eceff3;
    color: #9299a0;
  }
  .button-active {
    background: #e4edfd!important;
    color: #2c9fe5!important;
  }

  button.secondary-action-button {
    background: transparent;
    color: #c6c8ca;
    font-size: 11px;
    font-weight: 700;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    transition: 0.2s all ease;
  }
  button.secondary-action-button:hover {
    color: #5d646b;
  }
  button.secondary-action-button:focus {
    outline: none;
    border: 1px solid #1d86d1;
    box-shadow: 0 0 0 2px #87bbf0!important;
  }

  button.secondary-action-button.secondary-action-button-danger:hover {
    color: #df203d;
    border: 1px solid #df203d;
  }
  button.secondary-action-button.secondary-action-button-danger:focus {
    outline: none;
    border: 1px solid #df203d;
    box-shadow: 0 0 0 2px #f5bcc5!important;
  }

  /* Button Margin */

  .margin-left {
    margin-left: 0.75rem;
  }
  .margin-right {
    margin-right: 0.75rem;
  }

  /* Button Loading Spinner */

  .button-onclick {
    filter: saturate(0.5);
    pointer-events: none;
  }
  .button-spinner {
    display: inline-block;
    vertical-align: sub;
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #2c9fe5;
    width: 15px;
    height: 15px;
    animation: spin 0.7s linear infinite;
  }

  .is-loading {
    filter: saturate(1);
    color: transparent!important;
    pointer-events: none;
  }

  .is-loading::after {
    animation: spin 0.7s linear infinite;
    left: calc(50% - (1em / 2));
    top: calc(50% - (1em / 2));
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #2c9fe5;
    border-right: 2px solid #2c9fe5;
    width: 15px;
    height: 15px;
    content: "";
    display: inline-block;
    position: absolute!important;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  /* Button Utilities */
  button[disabled] {
    opacity: 0.6;
    /*background-color: #2c9fe5;*/
    /*border-color: #2c9fe5;*/
    pointer-events: none;
    box-shadow: none;
  }
  button[disabled]:hover {
    box-shadow: none;
  }

  /*---------------- TABLES ----------------*/
  table {
    display: table;
    overflow-x: scroll;
    white-space: nowrap;
  }
  thead {

  }
  thead th {
    padding-bottom: 1.25rem!important;
  }
  tr {
    border: none;
    border-radius: 4px;
  }
  .table {
    color: #2f3539;
  }
  .table th {
    font-size: 13px;
    color: #9299a0!important;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
  }
  .table th:first-child {
    /*padding-left: 1.5em;*/
  }
  .table tbody tr {
    transition: 0.2s all ease;
    border-top: 1px solid #eceff3;
  }
  .table th, .table td {
    border: none;
  }
  .table tbody tr td {
    padding: 1rem 0.75rem;
    vertical-align: middle;
    transition: 0.2s all ease;
  }

  .table tbody tr.is-selectable:hover {
    /*background-color: #fafbfc;*/
    background: #f7f8fa;
    color: #2c9fe5;
    cursor: pointer;
  }
  .table tbody tr.is-selectable:hover td {
    color: #171a1c!important;
  }

  td.table-header {
    background: #eceff3;
    color: #474d52;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025rem;
    padding: 0.25rem 0.75rem;
  }

  .td-has-button {
    padding-top: 0!important;
    padding-bottom: 0!important;
  }

  .table .table-edit-button {
    color: #c6c8ca;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    /*opacity: 0;*/
    border: 1px solid #c6c8ca;
    border-radius: 4px;
    padding: 0.3rem 0.5rem;
    /*margin-right: 1em;*/
    transition: 0.2s all ease;

  }
  .table tr:hover .table-edit-button {
    /*opacity: 1;*/
  }
  .table .table-edit-button:hover {
    color: #123968;
    border-color: #123968;
    cursor: pointer;
  }

  .table tfoot tr {
    border-top: 1px solid #eceff3;
  }
  .table tfoot tr td {
    color: #474d52;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.025rem;
    padding: 0.5rem 0.75rem 0.25rem;
  }

  /* TABLE LABEL STATUS */

  .table-label {
    color: #123968;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    text-align: left;
    vertical-align: middle;
  }

  .is-label-dark {
    color: #174782;
    background-color: #edf5fd;
    padding: 1px 10px;
    border: 1px solid #bad5f8;
    border-radius: 15px;
  }
  .is-label-light {
    color: #2c9fe5;
    background-color: #edf5fd;
    padding: 1px 10px;
    border: 1px solid #bad5f8;
    border-radius: 15px;
  }
  .is-label-active {
    color: #174782;
    background-color: #edf5fd;
    padding: 1px 10px;
    border: 1px solid #bad5f8;
    border-radius: 15px;
  }
  .is-label-inactive {
    color: #c6c8ca;
    /*background-color: #e3eefc;*/
    border: 1px solid #c6c8ca;
    padding: 1px 10px;
    border-radius: 15px;
  }

  .status-circle {
    font-size: 8px;
    text-align: center;
    vertical-align: middle;
    line-height: 1.5;
  }
  .status-circle.is-active {
    color: #21c45a;
  }
  .status-circle.is-inactive {
    color: #bbc0c4;
  }

  /*---------------- DETAIL SCREEN INFO ----------------*/

  .detail-info {
    display: flex;
    flex-flow: column wrap;
    align-items: flex-start;
  }
  .detail-title-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    /*width: 100%;*/
    flex-grow: 1;
    margin-bottom: 0.2rem;
  }
  .detail-title-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /*flex-basis: 33%;*/
    text-align: left;
  }
  .detail-info-row {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    margin: 0 0 1.2em 0;
  }
  .detail-info-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /*flex-basis: 33%;*/
    text-align: left;
    margin-right: 1.5rem;
    margin-bottom: 1rem;
  }
  .detail-info-item.client-title {
    flex-basis: 100%;
  }
  h3.detail-info-header {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }
  /*h3.detail-info-header::after {*/
    /*content: '';*/
    /*display: block;*/
    /*background-color: #2c9fe5;*/
    /*width: 35px;*/
    /*height: 3px;*/
  /*}*/
  .detail-info-item h4 {
    color: #9299a0;
  }
  .detail-info-item p {
    color: #123968;
    font-size: 18px;
    font-weight: 400;
  }
  .detail-notes {
    white-space: pre-line;
  }

  /*---- DETAIL SCREEN STATUS LABEL ----*/

  .status-label-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .status-label-container .status-label {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }

  @media (min-width: 768px) {
    .status-label-container {
      flex-direction: row;
    }
    .status-label-container .status-label {
      margin-bottom: 0;
    }
    .status-label-container .status-label:not(:last-child) {
      margin-right: 1rem;
    }
  }

  .status-label {
    /*font-size: 10px;*/
    color: #5d646b;
    font-size: 16px;
    font-weight: 600;
    /*letter-spacing: 0.1em;*/
    /*line-height: 12px;*/
    /*text-transform: uppercase;*/
    text-align: center;
    /*margin: 0 1rem 0 0;*/
    padding: 3px 10px;
    border: 1px solid #e4e7ea;
    border-radius: 6px;
    white-space: nowrap;
  }
  .status-label.is-small {
    font-size: 12px;
    padding: 3px 8px;
  }
  .active-label::before {
    font-family: "Font Awesome 5 Free", sans-serif;
    content: "\f111";
    color: #21c45a;
    font-size: 10px;
    vertical-align: middle;

    /*color: #1eae50;*/
    /*background-color: #e9fbf0;*/
    /*border: 1px solid #7ce9a2;*/
  }
  .inactive-label {
    color: #9299a0;
    background-color: #f1f2f3;
    border: 1px solid #c6c8ca;
  }
  .dark-blue-label {
    color: #123968;
    /*border: 1px solid #123968;*/
    background: #e9f1fb;
    border: none;
  }
  .light-blue-label {
    color: #2c9fe5;
    background: #edf5fd;
    border: none;
    /*border: 1px solid #2c9fe5;*/
  }
  .success-label {
    color: #21c45a;
    background: #d3f8e0;
    border: none;
  }
  .danger-label {
    color: #df203d;
    background: #fce9ec;
    /*border: 1px solid #df203d;*/
    border: none;
  }
  .gray-label {
    color: #9299a0;
    background: #eceff3;
    /*border: 1px solid #c6c8ca;*/
    border: none;
  }
  .user-icon {
    color: #2c9fe5;
    font-size: 14px;
  }

  /*---- BULMA BOX ----*/

  .box {
    position: relative;
    border: 1px solid #e4e7ea;
    box-shadow: 0 2px 10px -6px rgba(0,0,0,0.1);
    border-radius: 10px;
  }
  .box-clickable {
    transition: 0.3s all ease;
  }
  .box-clickable:hover {
    cursor: pointer;
    border: 1px solid #2c9fe5;
    box-shadow: 0 5px 15px -3px rgba(0,0,0,0.2), 0 0 0 1px #2c9fe5;
  }

  .max-width-800 {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
  }

  .max-width-1000 {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }

  .max-width-1200 {
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }

  .max-width-1400 {
    max-width: 1400px;
    margin-left: auto;
    margin-right: auto;
  }

  /*---- NOTIFICATIONS ----*/

  .notifications {
    z-index: 999999!important;
  }
  .notification-wrapper {
    overflow: visible!important;
  }
  .notification {
    box-shadow: 0 3px 20px 3px rgba(0,0,0,0.2);
    border-radius: 6px;
  }
  .notification-style {
    margin-top: 5em;
    margin-right: 1em;
  }
  .vue-notification {
    margin: 0;
    padding: 10px 14px!important;
    background-color: rgba(11, 36, 65, 0.95)!important;
    border-left: none!important;
  }
  .vue-notification.success {
    /*border: 2px solid #21c45a!important;*/
  }
  .vue-notification.success::before {
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    font-size: 8px;
    font-weight: 900;
    content: "\f00c";
    float: left;
    background: #21c45a;
    margin-top: 3px;
    margin-right: 8px;
    padding: 1px 3px;
    border-radius: 12px;
  }
  .vue-notification.error::before {
    display: inline-block;
    font-family: "Font Awesome 5 Free";
    font-size: 8px;
    font-weight: 900;
    content: "\f00d";
    float: left;
    background: #df203d;
    margin-top: 3px;
    margin-right: 8px;
    padding: 1px 4px;
    border-radius: 12px;
  }
  .notification-title {
    font-size: 14px;
    font-weight: 800!important;
  }
  .notification-content {
    margin-top: 0.1rem;
    font-size: 14px;
    line-height: 1.3;
  }

  /*---- PAGE TOOLBAR ----*/

  .toolbar-wrapper {
    display: flex;
    flex-grow: 1;
    padding: 1rem 0.75rem 0;
  }
  .toolbar {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    justify-content: space-between;
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .toolbar-section {
    display: flex;
    align-items: center;
  }

  .toolbar.action-bar {
    margin-bottom: 1rem!important;
    padding-left: 0;
    padding-right: 0;
  }

  .toolbar.toolbar-centered {
    margin-left: auto;
    margin-right: auto;
  }

  /*-------- SELECTABLE ITEM --------*/

  .selectable-item-wrapper {
    display: flex;
    flex-direction: column;
  }
  .selectable-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /*background-color: #fefefe;*/
    width: 100%;
    margin-bottom: 1rem;
    padding: 1rem;
    border: 1px solid #e4e7ea;
    border-radius: 8px;
    transition: all 0.2s ease;
  }
  .selectable-item:hover, .selectable-item:focus {
    cursor: pointer;
    border-color: #9299a0;
    outline: none;
  }
  .selectable-item.selected {
    border-color: #2c9fe5;
    box-shadow: 0 0 0 1px #2c9fe5!important;
    /*border: 1px solid #2c9fe5;*/
    /*box-shadow: 0 0 0 0.15rem #87bbf0!important;*/
  }

  .selectable-item:last-child {
    margin-bottom: unset;
  }

  .selectable-item .selectable-item-section {
    display: flex;
    flex-direction: column;
  }

  @media (min-width: 768px) {

    .selectable-item-wrapper.row {
      flex-direction: row;
    }

    .selectable-item-wrapper.row > .selectable-item {
      margin-bottom: unset;
    }
    .selectable-item-wrapper.row > .selectable-item:not(:first-child) {
      margin-left: 0.75rem;
    }
    .selectable-item-wrapper.row > .selectable-item:not(:last-child) {
      margin-right: 0.75rem;
    }

  }




  /*---------------- FORM INPUTS ----------------*/

  /*---- FORM LINE WRAP ----*/
  .form-line-wrapper {
    display: flex;
    flex-direction: row;
    padding-bottom: 0;
  }
  .form-line-wrapper > div {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
    flex: 1 1 auto;
  }
  .form-line-wrapper > div:first-child {
    margin-left: 0;
  }
  .form-line-wrapper > div:last-child {
    margin-right: 0;
  }

  .field {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .label-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .label {
    margin: 0;
    padding: 0 0 4px 4px;
    text-decoration: none;
    text-transform: uppercase;
    color: #123968;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.05rem;
  }
  .label:not(:last-child) {
    margin: 0;
  }

  .control {
    margin-bottom: 0.3em;
  }

  input {
    font-family: proxima-nova, sans-serif;
    width: 100%;
    /*margin-bottom: 0.3em;*/
    padding: 8px 0 8px 8px!important;
    background: transparent;
    /*border: transparent;*/
    border: 1px solid #c8ccd0;
    -webkit-appearance: none;
    border-radius: 6px;
    box-shadow: none!important;

    font-size: 16px;
    line-height: 1!important;
    font-weight: 400;
    color: #474d52!important;

    -webkit-transition: 0.3s all ease;
    transition: 0.3s all ease;
  }

  input:hover {
    border: 1px solid #9299a0;
  }

  input:focus {
    outline: none;
    /*border: 1px solid #1d86d1;*/
    border: 1px solid #2c9fe5;
    box-shadow: 0 0 0 0.15rem #87bbf0!important;
  }

  input[disabled] {
    background: #f7f8fa;
    color: #9299A0;
    -webkit-text-fill-color: #9299A0;
    opacity: 1; /* required on iOS */
  }

  input::-webkit-input-placeholder, form textarea::-webkit-input-placeholder {
    color: #c8ccd0!important; line-height: normal;  }
  input:-ms-input-placeholder, form textarea:-ms-input-placeholder { /* IE 10+ */
    color: #c8ccd0!important;    }
  input::-moz-placeholder, form textarea::-moz-placeholder { /* Firefox 19+ */
    color: #c8ccd0!important;
  }

  input[type=radio] {
    width: initial;
    -webkit-appearance: radio;
  }

  /* Select */

  select {
    font-family: proxima-nova, sans-serif;
    transition: 0.3s all ease;
    color: #474d52;
  }
  .select {
    margin-bottom: 0.75rem;
  }
  .select:hover {

  }
  .select select {
    color: #474D52;
    border: 1px solid #c8ccd0;
    border-radius: 6px;
    background-color: transparent;
    font-size: 16px;
    width: 100%;
    height: 38px;
  }
  .select select:focus {
    border: 1px solid #2c9fe5;
    box-shadow: 0 0 0 0.15rem #87bbf0!important;
  }

  select.select-full-width {
    width: 100%;
  }

  .select:not(.is-multiple):not(.is-loading)::after {
    border-color: #2c9fe5!important;
  }

  select:invalid {
    color: #c8ccd0!important;
  }

  .textarea {
    font-family: proxima-nova, sans-serif;
    border: 1px solid #c8ccd0;
    transition: 0.3s all ease;
    box-shadow: unset;
    border-radius: 6px;
    margin-bottom: 0.5rem;
  }
  .textarea:hover {
    border: 1px solid #9299a0;
  }
  .textarea:focus {
    /*border: 1px solid #1d86d1;*/
    border: 1px solid #2c9fe5;
    box-shadow: 0 0 0 0.15rem #87bbf0!important;
  }

  /*-- VALIDATION ERROR --*/

  .validation-error {
    color: #df203d;
    font-size: 12px;
    font-weight: 300;
    margin-left: 0.5em;
    text-align: right;
  }

  .error-border {
    border-color: #df203d;
  }

  /*---- CHECKBOX SLIDER ----*/

  .checkbox-container {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .checkbox-info > p {
    /*padding-left: 5px;*/
    /*margin-top: -5px;*/
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 52px;
    min-width: 52px;
    height: 28px;
  }

  .switch input:focus, .switch input:active {
    box-shadow: none;
    outline: none;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:focus, .slider:active {
    box-shadow: none;
    outline: none;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 5px;
    bottom: 4px;
    /*background: linear-gradient(to bottom, #fdfbfb 0%, #ebedee 100%);*/
    background: #ffffff;
    box-shadow: 0 1px 2px rgba(0,0,0,0.3);
    transition: 0.3s;
  }

  input:checked + .slider {
    background-color: #2c9fe5;
  }
  input:checked + .slider:before {

  }
  input:checked + .slider-light {
    background-color: #2c9fe5;
  }

  input:focus + .slider {
    box-shadow: 0 0 4px rgba(0,89,196,0.6);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 100px;
  }

  .slider.round:before {
    border-radius: 50%;
  }


  /*-- LINE BREAKS --*/

  .content-break-line {
    display: block;
    width: 100%;
    height: 1px;
    background-color: #e4e7ea;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
  }

  .vertical-line {
    width: 1px;
    background-color: #c6c8ca;
    align-self: normal;
  }

  /*---- GLOBAL TRANSITIONS ----*/

  .fade-enter-active, .fade-leave-active {
    transition: opacity 0.3s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }

  /*---- LOADING SPINNER ----*/
  .loading-spinner {
    margin: 3.75rem auto;
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top: 3px solid #2c9fe5;
    width: 25px;
    height: 25px;
    animation: spin 0.6s linear infinite;
  }
  .loading-spinner.margin-reset {
    margin: 0.5rem;
  }

  .loading-spinner.small {
    border-width: 2px;
    width: 18px;
    height: 18px;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  /*---- MODAL ----*/
  .modal .box {
    overflow: visible;
    box-shadow: none;
  }
  .modal-background {
    background-color: rgba(82, 90, 98, 0.5);
  }

  /*-- MODAL ANIMATION --*/
  .modal {
    transition: all 0.3s ease;
    display: flex;
    opacity: 0;
    visibility: hidden;
  }

  .modal.is-active {
    opacity: 1;
    visibility: visible;
    z-index: 99999;
  }

  /*---- GLOBAL LOADING OVERLAY ----*/
  .overlay-global {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
    visibility: visible;
    position: fixed;
    z-index: 99999;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .overlay-background {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(240, 243, 246, 1);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .overlay-background-transparent {
    background-color: rgba(249, 252, 255, 0.7);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  /*---- DROPDOWN MENU ----*/
  .dropdown-menu {
    opacity: 0;
    pointer-events: none;
    user-select: none;
    padding-top: 0;
    margin-top: 6px;
    border-radius: 8px;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, 0.1);

    transition: 0.23s all ease-in-out;
  }
  .dropdown-menu.menu-open {
    pointer-events: auto;
    user-select: text;
    opacity: 1;
  }
  .dropdown-content {
    box-shadow: none;
    border: 1px solid #e4e7ea;
    border-radius: 8px;
    transition: 0.23s all ease-in-out;
    padding: 0;
  }
  .dropdown-item {
    color: #171a1c;
    text-align: left;
    transition: 0.2s all ease;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    border-radius: 3px;
  }
  .dropdown-item:first-child {
  /*  border-top-left-radius: 2px;*/
  /*  border-top-right-radius: 2px;*/
    margin-top: 0.5rem;
  }
  .dropdown-item:last-child {
  /*  border-bottom-left-radius: 2px;*/
  /*  border-bottom-right-radius: 2px;*/
    margin-bottom: 0.5rem;
  }
  .is-selectable .dropdown-item:hover {
    cursor: pointer;
    color: #2c9fe5;
    background-color: #f2f6ff;
  }
  .dropdown-item > i {
    color: #c6c8ca;
  }
  .dropdown-content > .not-selectable {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.5rem 1rem;
  }
  .dropdown-divider {
    background-color: #e4e7ea;
  }
  .arrow-rotate {
    transition: 0.23s all ease-in-out;
    transform: rotate(180deg);
  }


  /*---- V-CALENDAR DATE PICKER ----*/

  .vc-container {
    font-family: proxima-nova, sans-serif!important;
  }
  .vc-popover-content-wrapper {
    z-index: 9999;
  }
  .v-date-picker-inline {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
    box-shadow: rgba(0, 0, 0, 0.1) 0 15px 30px;

    transition: all 0.3s ease;
  }
  .v-date-picker-inline.is-active {
    opacity: 1;
    visibility: visible;
  }

  /*---- VUE TEXT EDITOR ----*/

  .quillWrapper {
    border: 1px solid #c8ccd0;
    border-radius: 6px;
    transition: 0.3s all ease;
  }
  .quillWrapper.editor-focused {
    border: 1px solid #2c9fe5;
    box-shadow: 0 0 0 0.15rem #87bbf0!important;
  }
  .ql-toolbar.ql-snow {
    font-family: proxima-nova, sans-serif !important;
  }
  .ql-snow {
    border: none!important;
  }
  .ql-toolbar {
    border-bottom: 1px solid #e4e7ea!important;
  }
  .ql-container {
    font-family: proxima-nova, sans-serif !important;
  }
  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-radius: 4px;
  }
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected, .ql-snow .ql-toolbar .ql-picker-item:hover, .ql-snow .ql-toolbar .ql-picker-label.ql-active, .ql-snow .ql-toolbar .ql-picker-label:hover, .ql-snow .ql-toolbar button.ql-active, .ql-snow .ql-toolbar button:focus, .ql-snow .ql-toolbar button:hover, .ql-snow.ql-toolbar .ql-picker-item.ql-selected, .ql-snow.ql-toolbar .ql-picker-item:hover, .ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar button.ql-active, .ql-snow.ql-toolbar button:focus, .ql-snow.ql-toolbar button:hover {
    color: #2c9fe5!important;
  }
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow .ql-toolbar button.ql-active .ql-stroke, .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow .ql-toolbar button:focus .ql-stroke, .ql-snow .ql-toolbar button:focus .ql-stroke-miter, .ql-snow .ql-toolbar button:hover .ql-stroke, .ql-snow .ql-toolbar button:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter, .ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter, .ql-snow.ql-toolbar button:focus .ql-stroke, .ql-snow.ql-toolbar button:focus .ql-stroke-miter, .ql-snow.ql-toolbar button:hover .ql-stroke, .ql-snow.ql-toolbar button:hover .ql-stroke-miter {
    stroke: #2c9fe5!important;
  }
  .quillWrapper h1, .quillWrapper h2, .quillWrapper h3 {
    font-weight: normal;
    color: #474d52;
  }
  .ql-editor {
    line-height: 1;
  }

  /*---- ANIMATE CSS CUSTOM SPEED ----*/
  .fastest {
    animation-duration: 0.25s!important;
  }


  @media (min-width: 768px) {
    .detail-info-row {
      flex-direction: row;
    }

    /*.detail-info-item {*/
    /*  margin-right: 40px;*/
    /*  margin-bottom: 0;*/
    /*}*/

  }

</style>

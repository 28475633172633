<template>
  <div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar toolbar-centered max-width-800">
          <div class="toolbar-section">
            <h2 class="has-text-left">Personal Information</h2>
          </div>
        </div>
      </div>
    </div>


    <div class="tile">
      <div class="tile is-parent is-centered">
        <div class="tile is-child box max-width-800">

          <div class="loading-spinner" v-if="!pageLoaded"></div>

          <div class="animated fadeIn fast" v-if="pageLoaded">

            <div class="field">
              <label class="label">First Name</label>
              <div class="control">
                <input v-model="editMember.first_name" type="text" placeholder="John" required>
              </div>
            </div>

            <div class="field">
              <label class="label">Last Name</label>
              <div class="control">
                <input v-model="editMember.last_name" type="text" placeholder="Doe" required>
              </div>
            </div>

            <div class="field">
              <label class="label">Email</label>
              <div class="control">
                <input v-model="editMember.email" type="text" placeholder="example@me.com" required>
              </div>
            </div>

            <div class="field">
              <label class="label">Phone Number</label>
              <div class="control">
                <input v-model="editMember.phone_number" type="text" placeholder="701-555-0100" required>
              </div>
            </div>

          </div>

        </div>


      </div>
    </div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar toolbar-centered max-width-800">
          <div class="toolbar-section">
            <h2 class="has-text-left">Membership Information</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent is-centered">
        <div class="tile is-child box max-width-800">

          <div class="loading-spinner" v-if="!pageLoaded"></div>

          <div class="animated fadeIn fast" v-if="pageLoaded">

            <div class="field">
              <label class="label">Active Member</label>
              <label class="switch">
                <input v-model="editMember.is_active" type="checkbox">
                <span class="slider round"></span>
              </label>
            </div>

            <div class="field">
              <label class="label">Signup Date</label>
              <div class="control">
                <v-date-picker mode="single" v-model="editMember.signup_date" :popover="{visibility: 'focus'}">
                  <input type="text" placeholder="MM/DD/YYYY"
                         slot-scope="{ inputProps, inputEvents }"
                         v-bind="inputProps"
                         v-on="inputEvents">
                </v-date-picker>
              </div>
            </div>

            <div class="field">
              <label class="label">Membership Type</label>
              <div class="select">
                <select v-model="editMember.membership_type" class="select-full-width">
                  <option value="" disabled>Select</option>
                  <option value="Commitment">Commitment</option>
                  <option value="No Commitment">No Commitment</option>
                  <option value="Punchcard">Punchcard</option>
                  <option value="Promo">Promo</option>
                  <option value="Trial">Trial</option>
                  <option value="Special">Special</option>
                  <option disabled>– Deprecated –</option>
                  <option value="" disabled>Unlimited</option>
                  <option value="" disabled>3-Day</option>
                  <option value="" disabled>2-Day</option>
                </select>
              </div>
            </div>

            <div v-if="editMember.membership_type === 'Punchcard' || editMember.membership_plan === 'Punchcard'" class="field">
              <label class="label">Punchcard Count</label>
              <div class="control">
                <input v-model.number="editMember.punchcard_count" type="number" placeholder="0" min="0">
              </div>
            </div>

            <div class="field">
              <label class="label">Total Sessions Attended (In Person)</label>
              <div class="control">
                <input v-model.number="editMember.sessions_completed.in_person" type="number" placeholder="0" min="0">
              </div>
            </div>

          </div>

        </div>
      </div>
    </div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar toolbar-centered max-width-800">
          <div class="toolbar-section">
            <h2 class="has-text-centered">Advanced</h2>
          </div>
        </div>
      </div>
    </div>

    <div class="tile">
      <div class="tile is-parent is-centered">
        <div class="tile is-child box max-width-800">

          <div class="columns">
            <div class="column">

              <div class="field">
                <label class="label">Notes</label>
                <textarea v-model="editMember.notes" class="textarea" placeholder="Type notes here..." rows="3"></textarea>
              </div>

              <div class="field">
                <label class="label">Customer ID</label>
                <div class="control is-flex">
                  <input v-model="editMember.customer_id" type="text" placeholder="stripe_customer_id" required :disabled="!editCustomerId">
                  <button class="button-light" style="margin-left: 1rem;" @click="editCustomerId = true">Edit</button>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar toolbar-centered max-width-800">
          <div class="toolbar-section"></div>

          <div class="toolbar-section">
            <router-link :to="{name: 'Member Detail', params:{member_id: this.$route.params.member_id}}" class="button-light">Cancel</router-link>
            <button @click="saveMember" class="button-dark margin-left">Save &nbsp;<i class="fas fa-angle-right"></i></button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { db } from './../../firebase/firebaseInit';
  import MemberSearchInput from './../components/MemberSearchInput.vue';

  export default {
      name: "EditMember",
      components: {MemberSearchInput},
      data: function() {
        return {
          pageLoaded: false,
          editCustomerId: false,

          editMember: {
            first_name: '',
            last_name: '',
            email: '',
            phone_number: '',
            is_active: '',
            membership_type: '',
            membership_plan: '',
            signup_date: '',
            punchcard_count: null,
            sessions_completed: {
              in_person: 0,
              online: 0
            },
            customer_id: '',
            notes: '',
          },

        }
      },
      computed: {},
      methods: {

        getMember: async function() {
          await db.collection('clients').doc(this.$route.params.member_id).get().then(doc => {
              this.editMember.first_name = doc.data().first_name;
              this.editMember.last_name = doc.data().last_name;
              this.editMember.email = doc.data().email;
              this.editMember.phone_number = doc.data().phone_number;
              this.editMember.is_active = doc.data().is_active;
              this.editMember.membership_type = doc.data().membership_type;
              this.editMember.membership_plan = doc.data().membership_plan ?? null;
              this.editMember.signup_date = doc.data().signup_date.toDate();
              this.editMember.punchcard_count = doc.data().punchcard_count;
              this.editMember.notes = doc.data().notes ?? '';
              this.editMember.sessions_completed.in_person = doc.data().sessions_completed.in_person;
              this.editMember.sessions_completed.online = doc.data().sessions_completed.online;
              this.editMember.customer_id = doc.data().customer_id ?? '';
            })
          this.pageLoaded = true;
        },

        saveMember: function() {
          db.collection('clients').doc(this.$route.params.member_id).update(this.editMember).then(() => {
            this.updateSuccess()
            this.$router.push({name: 'Member Detail', params: {member_id: this.$route.params.member_id}})
            }
          )
        },

        updateSuccess: function() {
          this.$notify({
            group: 'master',
            title: 'Member Updated',
            text: this.editMember.first_name + ' ' + this.editMember.last_name + ' was successfully updated.',
            type: 'success'
          })
        }
      },
      created: function() {
        this.getMember()
      },
      watch: {}
    }
</script>

<style scoped>

</style>

<template>
  <!--Member Info-->
  <div class="container success-container animated fadeIn" style="width: 100%!important;" v-if="!loading_status">

    <div class="tile">
      <div class="tile is-parent">

        <div class="tile is-child">
          <h1 class="has-text-center" style="margin-top: 1rem; margin-bottom: 1rem; font-size: 40px;">Welcome Back, {{ client_data.first_name }}!</h1>
          <!--<span class="client-stat-title">Member Statistics:</span>-->
        </div>

      </div>
    </div>

    <div class="client-data-container">

      <div class="tile client-row-1">

        <div class="tile is-parent">

          <div class="tile is-child client-profile is-5">
            <div class="client-profile-item">
              <i class="fas fa-user-circle client-icon"></i>
            </div>
            <div class="client-profile-item">
              <h4>{{ client_data.first_name }} {{ client_data.last_name }}</h4>
              <p>{{ client_data.phone_number | phoneNumberFilter }}</p>
              <p>{{ client_data.email }}</p>
            </div>
          </div>

          <div class="tile is-child client-details">
            <div class="client-details-item">
              <p>Membership Plan</p>
              <h4>{{ client_data.membership_type }}</h4>
            </div>

            <div class="client-details-item">
              <p>Member Since</p>
              <h4>{{ client_data.signup_date | monthDateFilter }}</h4>
            </div>
          </div>

        </div>

      </div>

      <div class="tile client-row-2">

        <div class="tile is-parent client-stat-item" v-if="client_data.membership_type === 'Punchcard'">
          <div class="tile is-child">
            <span class="client-stat">{{ client_data.punchcard_count }}</span>
            <h5>Punches Left</h5>
          </div>
        </div>

        <div class="tile is-parent client-stat-item" v-if="client_data.membership_type !== 'Punchcard'">
          <div class="tile is-child">

            <span class="client-stat">{{ attendance_this_week.length | sessionDataFormat }}</span>
            <h5>Session This Week</h5>

          </div>
        </div>

        <div class="tile is-parent client-stat-item">
          <div class="tile is-child">
            <span class="client-stat">{{ attendance_this_month.length | sessionDataFormat }}</span>
            <h5>Session in {{ first_of_month_date | monthDateFilter }}</h5>
          </div>
        </div>

        <div class="tile is-parent client-stat-item">
          <div class="tile is-child">
            <span class="client-stat">{{ client_data.total_sessions_attended }}</span>
            <h5>Lifetime Sessions</h5>
          </div>
        </div>

      </div>

    </div>

    <div class="tile">

      <div class="tile is-parent" style="margin-top: 2rem;">
        <div class="tile is-child">
          <v-touch class="submit-button" @tap="returnToSignInScreen" tag="button">Return Home</v-touch>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
  import {db} from './../../firebase/firebaseInit'
  import {format, startOfMonth, startOfWeek} from 'date-fns'
  import numeral from 'numeral'

  export default {
    name: 'SignInSuccess',
    data: function() {
      return {
        loading_status: false,
        client_data: {},
        client_attendance: [],
        attendance_this_month: [],
        attendance_this_week: [],
        first_of_month_date: null,
        first_day_of_week: null
      }
    },
    filters: {
      phoneNumberFilter: function(value) {
        return value.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
      },
      monthDateFilter: function(value) {
        return format(value, 'MMMM y')
      },
      sessionDataFormat: function(value) {
        return numeral(value).format('0o')
      }
    },
    computed: {

    },
    methods: {

      getClientData: function() {

        this.loading_status = true;
        this.getAttendanceData();

        db.collection('clients').doc(this.$route.params.member_id).get().then(
          doc => {
            const {
              first_name,
              last_name,
              email,
              phone_number,
              program,
              membership_type,
              punchcard_count,
              total_sessions_attended,
              signup_date
            } = doc.data();

            this.client_data.id = doc.id;
            this.client_data.first_name = first_name;
            this.client_data.last_name = last_name;
            this.client_data.email = email;
            this.client_data.phone_number = phone_number;
            this.client_data.program = program;
            this.client_data.membership_type = membership_type;
            this.client_data.punchcard_count = punchcard_count;
            this.client_data.total_sessions_attended = total_sessions_attended;
            this.client_data.signup_date = signup_date.toDate();

            // this.client_signed_in = true
            this.loading_status = false

          }).catch(err => {
          console.log(err);
          this.loading_status = false;
        })
      },

      getAttendanceData: function() {
        this.first_of_month_date = startOfMonth(new Date());
        this.first_day_of_week = startOfWeek(new Date(), {weekStartsOn: 1});

        db.collection('attendance')
          .where('member_id', '==', this.$route.params.member_id)
          .where('signed_in_at', '>', this.first_of_month_date)
          .orderBy('signed_in_at', 'desc').get().then(
            querySnapshot => {
              querySnapshot.forEach(doc => {
                const attendanceRecord = {
                  'member_id': doc.data().member_id,
                  'signed_in_at': doc.data().signed_in_at.toDate(),
                  'session_date': doc.data().session_date.toDate(),
                  'class_name': doc.data().class_name
                }

                this.attendance_this_month.push(attendanceRecord)

              })
            }
        )

        db.collection('attendance')
          .where('member_id', '==', this.$route.params.member_id)
          .where('signed_in_at', '>', this.first_day_of_week)
          .orderBy('signed_in_at', 'desc').get().then(
            querySnapshot => {
              querySnapshot.forEach(doc => {
                const attendanceRecord = {
                  'member_id': doc.data().member_id,
                  'signed_in_at': doc.data().signed_in_at.toDate(),
                  'session_date': doc.data().session_date.toDate(),
                  'class_name': doc.data().class_name
                }

                this.attendance_this_week.push(attendanceRecord)
              })
            }
        )

      },

      returnToSignInScreen: function() {
        this.$router.push({name: 'Session Sign In'})
      }

    },
    created: function() {
      this.getClientData()
      this.timer = setTimeout(() => {this.returnToSignInScreen()}, 10000)
    },
    beforeDestroy: function() {
      clearTimeout(this.timer)
    }
  }
</script>

<style scoped>

  .success-container {
    flex-direction: column;
  }

  .client-data-container {
    background: rgba(255,255,255,0.04);
    border-radius: 6px;
    margin-left: 1em;
    margin-right: 1em;
    padding: 1em 1.5em;
  }
  .client-row-1 {
    /*margin-top: 2em;*/
    /*margin-bottom: 2em;*/
    padding-bottom: 1em;
    border-bottom: 1px solid rgba(198,200,202,0.2);
  }
  .client-profile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-right: 1em;
    border-right: 1px solid rgba(198,200,202,0.2);
  }
  .client-profile-item {
    margin: 0 0.5em;
  }
  .client-icon {
    font-size: 60px;
    color: rgba(255,255,255,0.3);
  }
  .client-details {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 1.5em;
  }
  .client-details-item {
    padding: 0 1em;
  }
  .client-row-2 {
    padding: 1.5em 0;
  }

  h4 {
    font-size: 22px;
    text-transform: none;
    letter-spacing: 0;
    line-height: 1.3;
  }
  h5 {
    font-size: 16px;
    font-weight: 800;
    color: #c6c8ca;
  }
  p {
    font-size: 14px;
    color: #c6c8ca;
    text-align: left;
    line-height: 1.3;
  }

</style>

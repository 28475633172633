<template>
  <div>

    <div class="tile">
      <div class="toolbar-wrapper">
        <div class="toolbar">

          <div class="toolbar-section">
            <h2>Verification Codes</h2>
          </div>

          <div class="toolbar-section">


            <div class="dropdown is-active is-right" v-on-clickaway="closeDateSelectorModal">

              <div class="dropdown-trigger">
                <button class="button button-outline" style="margin: 0 auto;" @click="dateSelectorModal = !dateSelectorModal" :class="{'button-active': dateSelectorModal}">
                  {{ currentDate | currentDateFilter }} &nbsp;<i class="fas fa-chevron-down" style="font-size: 12px; transition: 0.23s all ease-in-out;" :class="{'arrow-rotate': dateSelectorModal}"/>
                </button>
              </div>

              <div class="dropdown-menu" :class="{'menu-open': dateSelectorModal}">
                <v-date-picker v-model="currentDate" is-inline></v-date-picker>
              </div>

            </div>

            <button @click="setCurrentDateToYesterday" class="button primary-action-button margin-left"><i class="fas fa-chevron-left"/></button>
            <button @click="setCurrentDateToTomorrow" class="button primary-action-button margin-left"><i class="fas fa-chevron-right"/></button>


          </div>

        </div>
      </div>
    </div>

    <div class="tile">

      <div class="tile is-parent">
        <div class="tile is-child box">


          <div class="loading-spinner" v-if="!pageLoaded"></div>
          <h2 class="empty-state animated fadeIn fast" v-if="pageLoaded && !codes.length">There are no verification codes.</h2>

          <div class="table-container" v-if="pageLoaded && codes.length > 0">
            <table class="table is-fullwidth animated fadeIn fast">
              <thead>
              <tr>
                <th style="width: 30%;">Member Name</th>
                <th style="width: 20%;">Code</th>
                <th style="width: 20%;">Code Used</th>
                <th style="width: 10%; text-align: right;">Created At</th>
                <th style="width: 10%; text-align: right;">Expires At</th>
              </tr>
              </thead>

              <tbody>

              <tr v-for="(code, index) in codes" v-bind:key="index" class="is-selectable">
                <td class="font-primary-blue">{{ code.name }}</td>
                <td class="font-secondary-blue">{{ code.verification_code }}</td>
                <td>
                  <span v-if="code.code_used" class="status-label is-small light-blue-label">True</span>
                  <span v-if="!code.code_used" class="status-label is-small gray-label">False</span>
                </td>
                <td style="text-align: right;">{{ code.created_at | timeFilter }}</td>
                <td style="text-align: right;">{{ code.expires_at | timeFilter }}</td>
              </tr>

              </tbody>
            </table>
          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
  import {db} from './../../firebase/firebaseInit'
  import { mixin as clickaway } from 'vue-clickaway';
  import {format, startOfDay, endOfDay, addDays} from 'date-fns'

  export default {
    name: 'VerificationCodes',
    mixins: [ clickaway ],
    data: function() {
      return {
        pageLoaded: false,
        dateSelectorModal: false,
        currentDate: new Date(),
        codes: []
      }
    },
    filters: {
      currentDateFilter: function(value) {
        return format(value, 'EEEE, MMMM d, y')
      },
      dateFilter: function(value) {
        return format(value, 'MMMM d, y')
      },
      timeFilter: function(value) {
        return format(value, 'h:mm a')
      }
    },
    computed: {
      previousDate: function() {
        return addDays(this.currentDate, -1)
      },
      nextDate: function() {
        return addDays(this.currentDate, 1)
      }
    },
    methods: {

      closeDateSelectorModal() {
        this.dateSelectorModal = false;
      },

      async getVerificationCodes() {

        this.codes = [];
        this.pageLoaded = false;
        let verification_codes = [];

        const endOfCurrentDay = endOfDay(this.currentDate);
        const startOfCurrentDay = startOfDay(this.currentDate);

        await db.collection('verificationcodes')
          .where('created_at', '<', endOfCurrentDay)
          .where('created_at', '>=', startOfCurrentDay)
          .orderBy('created_at', 'asc').get().then(querySnapshot => {
            querySnapshot.forEach( doc => {

              const codeData = {
                'member_id': doc.data().member_id,
                'verification_code': doc.data().verification_code,
                'source': doc.data().source,
                'code_used': doc.data().code_used,
                'created_at': doc.data().created_at.toDate(),
                'expires_at': doc.data().expires_at.toDate()
              }

              verification_codes.push(codeData);
            });
        });

        async function getMemberInfo() {
          for (const member of verification_codes) {
            await db.collection('clients').doc(member.member_id).get().then(doc => {
              member.name = `${doc.data().first_name} ${doc.data().last_name}`
            })
          }
        }

        await getMemberInfo();
        this.codes = verification_codes;
        this.pageLoaded = true

      },

      setCurrentDateToTomorrow: function() {
        this.currentDate = addDays(this.currentDate, 1)
      },

      setCurrentDateToYesterday: function() {
        this.currentDate = addDays(this.currentDate, -1)
      },

      closeDatePicker() {
        this.dateSelectorModal = false
      }

    },
    created: function() {
      this.getVerificationCodes()
    },
    watch: {
      'currentDate': function() {
        this.codes = [];
        this.getVerificationCodes();
        this.dateSelectorModal = false;
      }
    }
  }
</script>

<style scoped>

  .arrow-rotate {
    transition: 0.23s all ease-in-out;
    transform: rotate(180deg);
  }


</style>

import Vue from 'vue'
import Vuex from 'vuex'
import {db, functions} from "../../firebase/firebaseInit";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    unread_messages: 0
  },
  mutations: {

    setTeamMemberData(state, payload) {
      state.user = payload
    },

    clearTeamMemberData(state) {
      state.user = {}
    },

    updateUnreadMessages(state, payload) {
      state.unread_messages = payload
    }

  },
  actions: {

    async fetchTeamMemberData ({commit}, payload) {

      const user_data = {};

      // Get Team Member info from Firestore
      await db.collection('team').doc(payload.uid).get().then(
        doc => {
          user_data.uid = doc.id;
          user_data.first_name = doc.data().first_name;
          user_data.last_name = doc.data().last_name;
          user_data.email = doc.data().email;
          user_data.phone_number = doc.data().phone_number;
        }
      ).catch(err => {
        console.log(err)
      });

      // Get Team Member Custom Claims
      await functions.httpsCallable('getTeamMemberCustomClaims')({
        email: payload.email
      }).then(result => {
        user_data.team_member = result.data.team_member;
        user_data.is_admin = result.data.is_admin;
        user_data.is_disabled = result.data.is_disabled;
      }).catch(err => {
        console.log(err);
      });

      commit('setTeamMemberData', user_data);

    }

  },
  modules: {
  }
})
